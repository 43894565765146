import AutoModeIcon from '@mui/icons-material/AutoMode';
import AssignmentIcon from '@mui/icons-material/AssessmentOutlined';
import { TemplateIcon } from 'assets/Icons';
import { AccessLevelTableHead, IRoleAccessMap, IToolsSideNavItem, RoleAccessLevelTableHead } from "Modules/Core/SettingsTs/SettingsModel";
import { ITableHeadersBase } from "shared/SharedModels";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';


export const AccessLevelsTableHeads: (ITableHeadersBase<AccessLevelTableHead>)[] = [
    { columnName: 'NAME', label: 'Name' },
    { columnName: 'EMAIL', label: 'Email' },
    { columnName: 'ROLE', label: 'Role' }
];

export const RoleAccessLevelsTableHeads: (ITableHeadersBase<RoleAccessLevelTableHead>)[] = [
    { columnName: 'ALL_JOBS', label: 'All Jobs' },
    { columnName: 'CREATE', label: 'Create' },
    { columnName: 'ASSIGN', label: 'Assign' },
    { columnName: 'VIEW', label: 'View' },
    { columnName: 'COMMENT', label: 'Comment' }
];

export const RoleAccessMap: IRoleAccessMap = {
    1: ['ALL_JOBS', 'CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    2: ['ALL_JOBS', 'CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    3: ['CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    4: ['VIEW', 'COMMENT'],
    5: ['VIEW', 'COMMENT'],
};

export const AccessLevelBullets: string[] = [
    `Administrators can create, assign, view and comment on all jobs company-wide.`,
    `Recruiters can create and assign jobs, plus view and comment on jobs they have created.`,
    `Hiring Managers can't create jobs, but they can view and comment on jobs assigned to them.`,
    `Observers can't create jobs, but can view and comment on all jobs company-wide.`
];

export const ToolsSideNavItems: IToolsSideNavItem[] = [
    {
        redirectTo: "/employer/settings/tools/templates/form-templates",
        label: "Questionnaires",
        feature: "Templates",
        icon: <AssignmentIcon />,
    },
    {
        redirectTo: "/employer/settings/tools/templates/hiring-pipeline",
        label: "Custom Hiring Pipeline",
        feature: "Hiring Pipeline",
        icon: <AccountTreeOutlinedIcon />,
    },
    {
        redirectTo: "/employer/settings/tools/templates",
        label: "Templates",
        feature: "Templates",
        icon: <TemplateIcon fontSize="small" height={"100%"} />,
        subMenuItems: [
            {
                redirectTo: "/employer/settings/tools/templates/rejection-notification",
                label: "Rejection Letters",
                feature: "Rejection Notification Template",
            },
            {
                redirectTo: "/employer/settings/tools/templates/interview-notification",
                label: "Interview Letters",
                feature: "Interview Invitation Template",
            },
            {
                redirectTo: "/employer/settings/tools/templates/application-received",
                label: "Thank You Letters",
                feature: "Application Received Template",
            },
        ],
    },
    {
        redirectTo: "/employer/settings/automation",
        label: "Automation",
        feature: "Automation",
        icon: <AutoModeIcon />,
        subMenuItems: [
            {
                redirectTo: "/employer/settings/automation/auto-reject/criteria",
                label: "Batch Auto Reject",
                feature: "Auto Reject",
            },
        ],
    },
];

