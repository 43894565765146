import React from 'react';
import { IActivityLogTable } from 'Modules/Core/Applicants/ApplicantsModel';
import { Sh,
    ShTable,
    ShTableRow
} from "shared/SharedStyles/ShDataDisplay";

export const ActivityLogTable : React.FC<IActivityLogTable> = ({ activityLogs }) => {
  return (
    <ShTable>
      <Sh.TableBody>
        {activityLogs.map((log) => (
          <ShTableRow key={log.id} cursor="default">
            <Sh.TableCell>{log.message}</Sh.TableCell>
          </ShTableRow>
        ))}
      </Sh.TableBody>
    </ShTable>
  );
};

export default ActivityLogTable;