import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateJobPreviewState } from 'Modules/Core/CreateJob/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialJobPreviewState: ICreateJobPreviewState = {
    aiJobDescription: undefined,
    getAiJobDescResponse: '',
    getAiJobDescStatus: 'idle',

    aiformattedJobDesc: undefined,
    getAiformattedJobDescResponse: '',
    getAiformattedJobDescStatus: 'idle',

    enhancedAiJobDesc: undefined,
    getEnhancedAiJobDescResponse: '',
    getEnhancedAiJobDescStatus: 'idle'
};

export const getAiJobDescription = createAsyncThunk<{ description: string }, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getAiJobDescription",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/ai_job_description`)
            .then((response: AxiosResponse<{ description: string }>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const formatJobDescriptionWithAi = createAsyncThunk<string, string, { rejectValue: IBaseResponse }>(
    "formatJobDescriptionWithAi",
    async (htmlContent: string, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.AI_FORMAT_JOB_DESC}`, { htmlContent })
            .then((response: AxiosResponse<{ formattedHtml: string }>) => response?.data.formattedHtml)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const enhanceJobDescriptionWithAi = createAsyncThunk<string, string, { rejectValue: IBaseResponse }>(
    "enhanceJobDescriptionWithAi",
    async (htmlContent: string, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.AI_ENHANCE_JOB_DESC}`, { htmlContent })
            .then((response: AxiosResponse<{ formattedHtml: string }>) => response?.data.formattedHtml)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);


const createJobPreviewSlice = createSlice({
    name: 'createJobPreviewSlice',
    initialState: initialJobPreviewState,
    reducers: {
        resetCreateJobPreviewState: () => initialJobPreviewState,
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobPreviewState;
        });
        // get ai job description
        builder.addCase(getAiJobDescription.pending, (state) => {
            state.getAiJobDescStatus = 'pending'
        });
        builder.addCase(getAiJobDescription.fulfilled, (state, action) => {
            state.getAiJobDescStatus = 'success';
            state.aiJobDescription = action.payload.description;
        });
        builder.addCase(getAiJobDescription.rejected, (state, action) => {
            state.getAiJobDescStatus = 'failed';
            state.getAiJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Format AI Job Desc
        builder.addCase(formatJobDescriptionWithAi.pending, (state) => {
            state.getAiformattedJobDescStatus = 'pending';
        });
        builder.addCase(formatJobDescriptionWithAi.fulfilled, (state, action) => {
            state.getAiformattedJobDescStatus = 'success';
            state.aiformattedJobDesc = action.payload;
        });
        builder.addCase(formatJobDescriptionWithAi.rejected, (state, action) => {
            state.getAiformattedJobDescStatus = 'failed';
            state.getAiformattedJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

         // Enhance AI Job Desc
        builder.addCase(enhanceJobDescriptionWithAi.pending, (state) => {
            state.getEnhancedAiJobDescStatus = 'pending';
        });
        builder.addCase(enhanceJobDescriptionWithAi.fulfilled, (state, action) => {
            state.getEnhancedAiJobDescStatus = 'success';
            state.enhancedAiJobDesc = action.payload;
        });
        builder.addCase(enhanceJobDescriptionWithAi.rejected, (state, action) => {
            state.getEnhancedAiJobDescStatus = 'failed';
            state.getEnhancedAiJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetCreateJobPreviewState } = createJobPreviewSlice.actions;
export default createJobPreviewSlice;
