import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Divider,
    IconButton,
    Stack,
    Tab,
    Tabs,
    ToggleButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { StagesFolders } from 'Modules/Core/Applicants/ApplicantsConstants';
import { ApplicantsFilters } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsFilters';
import { IApplicantsPipelineToolBar, TInterviewsStage, TInterviewStage } from 'Modules/Core/Applicants/ApplicantsModel';
import { useEffect, useState } from 'react';
import { ILabelValueBase } from 'shared/SharedModels';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { ShButton, ShInputBase, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { ShPaper, ShToolbarPaper } from 'shared/SharedStyles/ShSurfaces';
import { PaperVariant } from 'shared/SharedStyles/styleConstants';
import { KeyCodes } from 'shared/constants';
import { areAllElementsInArrayXInArrayY, toTitleCase } from 'shared/utils';
import { ApplicantListAutoReject } from '../ApplicantsList/ApplicantListAutoReject';

export const ApplicantsPipelineToolBar = ({ applicantList, applyFilters, selectedFilters,
     removeFilter, searchInApplicants, searchKey }: IApplicantsPipelineToolBar) => {

    const [_searchKey, _setSearchKey] = useState<string>("");
    const [selectedStages, setSelectedStages] = useState<TInterviewsStage[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle expand state
    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    // Update search key based on List component.
    useEffect(() => {
        _setSearchKey(() => { return searchKey });
    }, [searchKey]);

    const unselectAll = (currStages: (TInterviewsStage)[]) => {
        const newStages = [...currStages];
        newStages.splice(currStages.findIndex(stage => stage === 'all'), 1);
        setSelectedStages(newStages);
    }

    // Always update the selected stages array after filters are applied.
    useEffect(() => {
        const newSelectedStages: TInterviewsStage[] = [];
        const currSelectedStages = selectedFilters.stages.map(stage => stage.value);
        if (areAllElementsInArrayXInArrayY(['interview', 'second_interview', 'phone_screening'], currSelectedStages)) {
            newSelectedStages.push('interviews');
        }
        if (currSelectedStages.includes('new')) {
            newSelectedStages.push('new');
        }
        if (currSelectedStages.includes('rejected')) {
            newSelectedStages.push('rejected');
        }
        if (currSelectedStages.includes('reviewed')) {
            newSelectedStages.push('reviewed');
        }
        if (currSelectedStages.includes('offer')) {
            newSelectedStages.push('offer');
        }
        if (currSelectedStages.includes('hired')) {
            newSelectedStages.push('hired');
        }
        setSelectedStages([...newSelectedStages]);
        if (newSelectedStages.length === 0) {
            setSelectedStages(['all']);
        }
    }, [selectedFilters.stages]);

    const handleStageFilter = (stageValue: TInterviewsStage) => {
        if (stageValue === 'all') {
            // If 'All' is selected, remove the stage filter to show all applicants
            applyFilters({
                stages: [],
                fit: [],
                keywords: [],
                rating: []
            });
            if (selectedStages.includes('all')) {
                unselectAll([]);
            } else {
                setSelectedStages(['all']);
            }
        } else if (stageValue === 'interviews') {
            if (selectedStages.includes('interviews')) {
                const newStages = [...selectedStages];
                newStages.splice(selectedStages.findIndex(stage => stage === 'interviews'), 1);
                setSelectedStages(newStages);
                const appliedStageFilters = [...selectedFilters.stages];
                appliedStageFilters.splice(appliedStageFilters.findIndex(stage => stage.value === 'interview'), 1);
                appliedStageFilters.splice(appliedStageFilters.findIndex(stage => stage.value === 'second_interview'), 1);
                appliedStageFilters.splice(appliedStageFilters.findIndex(stage => stage.value === 'phone_screening'), 1);
                // If 'Interviews' is selected, apply filter for all interview-related stages
                applyFilters({
                    stages: appliedStageFilters,
                    fit: [],
                    keywords: [],
                    rating: []
                });
                if (selectedStages.includes('all')) {
                    unselectAll([...appliedStageFilters.map(stage => stage.value)]);
                }
            } else {
                // If 'Interviews' is selected, apply filter for all interview-related stages
                applyFilters({
                    stages: [
                        ...selectedFilters.stages,
                        { label: 'Interview', value: 'interview' },
                        { label: 'Final Interview', value: 'second_interview' },
                        { label: 'Phone Screening', value: 'phone_screening' }
                    ],
                    fit: [],
                    keywords: [],
                    rating: []
                });
                setSelectedStages([...selectedStages, stageValue]);
                if (selectedStages.includes('all')) {
                    unselectAll([...selectedStages, stageValue]);
                }
            }
        } else {
            const newFilters: ILabelValueBase<string, TInterviewStage>[] = [...selectedFilters.stages];
            if (newFilters.map(stage => stage.value).includes(stageValue)) {
                newFilters.splice(newFilters.findIndex(stage => stage.value === stageValue), 1);
            } else {
                newFilters.push({ label: toTitleCase(stageValue), value: stageValue });
            }
            applyFilters({
                stages: [...newFilters],
                fit: [],
                keywords: [],
                rating: []
            });
            setSelectedStages([...newFilters.map(stages => stages.value)]);
            if (selectedStages.includes('all')) {
                unselectAll([...selectedStages, stageValue]);
            }
        }
    };

    return (<>
        <ShToolbarPaper elevation={0}>
                <Stack className='tools-stack' columnGap={1} rowGap={1} width={'100%'}>
                    <Typography></Typography>
                    <Stack mr='auto' columnGap={1} alignContent='center'>
                        <Stack direction='row' spacing={1}>
                            <ShPaper className="custom-paper" variant={PaperVariant} width='100%'>
                                <ShInputBase sx={{width:'250px'}} id='search-applicants' placeholder='Search' inputProps={{ 'aria-label': 'Search Applicants' }}
                                    value={_searchKey} onChange={e => _setSearchKey(e.target.value)} onKeyUp={e => e.code === KeyCodes.Enter && searchInApplicants(_searchKey)} />
                                {/* Show clear search icon if there is any search key */}
                                {searchKey?.length > 0 && <Tooltip title="Clear search">
                                    <IconButton type="button" aria-label="clear" color='error' onClick={() => searchInApplicants('')}><ClearIcon /></IconButton>
                                </Tooltip>}
                                <Tooltip title="Search">
                                    <span>
                                        <IconButton disabled={_searchKey?.trim().length === 0} type="button" aria-label="search"
                                            onClick={() => searchInApplicants(_searchKey)}><SearchIcon /></IconButton>
                                    </span>
                                </Tooltip>
                                <Divider orientation="vertical" />
                                {/* ApplicantsFilters component holds all the filter options in a dropdown. */}
                                <ApplicantsFilters applyFilters={applyFilters} selectedFilters={selectedFilters} />
                            </ShPaper>
                        </Stack>
                    </Stack>
                    <Stack direction='row' alignItems='center' spacing={1} maxWidth={'100%'} overflow='auto' className='hide-scroll-bar' justifyContent='space-between'>
                        <Stack direction='row' spacing={1} ml='auto' alignItems='center'>
                            <ShToggleButtonGroup color="primary" size="small" exclusive value={selectedStages} variant='pill'>
                                {StagesFolders.map((stage) => (
                                    <ToggleButton value={stage.value} key={stage.value}
                                        onClick={() => handleStageFilter(stage.value)}>
                                        <Stack direction='row' columnGap={1} alignItems='center'>
                                            <FolderOpenIcon fontSize='small' />
                                            <Typography variant='body2'>
                                                {stage.label}
                                            </Typography>
                                        </Stack>
                                    </ToggleButton>
                                ))}
                            </ShToggleButtonGroup>
                            {/* Sort button displayed only in list view. */}
                            <ShButton size='small' borderRadius={5} variant='text' color='primary' onClick={toggleExpand}
                                endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                                <Typography variant='subtitle2'> Auto Reject Candidates </Typography>
                            </ShButton>
                        </Stack>
                    </Stack>
                </Stack>
                {/* Hide filter chips when filters are empty. */}
                <Stack columnGap={2}>
                    { //hide chips when a tab is selected
                        // selectedFilters.stages?.length > 0 || 
                        (selectedFilters.keywords?.length > 0 ||
                            selectedFilters.fit?.length > 0 || selectedFilters.rating?.length > 0) && (
                            <Stack className='filter-chips'>
                                {/* Styled MUI tabs component to show overflown filters with scroll buttons instead of default scroll bar. */}
                                <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="selected filters" value={0}>
                                    {/* {selectedFilters.stages?.map((f, indx) => (
                                        <Tab key={f.label} value={indx} disableRipple label={<Chip color='primary' label={f.label} onDelete={() => removeFilter('stages', f.value)} />} />
                                    ))} */}
                                    {selectedFilters.keywords?.map((f, indx) => (
                                        <Tab key={f.label} value={indx} disableRipple label={<ShChip borderRadius={'25px'} variant='outlined' size='small' label={f.label} onDelete={() => removeFilter('keywords', f.value)} />} />
                                    ))}
                                    {selectedFilters.fit?.map((f, indx) => (
                                        <Tab key={f.label} value={indx} disableRipple label={<ShChip borderRadius={'25px'} variant='outlined' size='small' label={f.label} onDelete={() => removeFilter('fit', f.value)} />} />
                                    ))}
                                    {selectedFilters.rating?.map((f, indx) => (
                                        <Tab key={f.label} value={indx} disableRipple label={<ShChip borderRadius={'25px'} variant='outlined' size='small' label={'Rating: ' + f.value} onDelete={() => removeFilter('rating', f.value)} />} />
                                    ))}
                                </Tabs>
                            </Stack>
                        )
                    }
                </Stack>
            {isExpanded && (
                <Box style={{ padding: '16px' }}>
                    <ApplicantListAutoReject />
                </Box>
            )}
        </ShToolbarPaper>
    </>);
};