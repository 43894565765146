import { Stack, Box, Typography, Divider } from "@mui/material";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { SignUpPageBenefits } from "./AppLoginConstants";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { useLocation } from "react-router-dom";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

export const EmployerSignupLeftPanel = () => {
  const totalJobsPosted = 15000;
  const totalCandidatesApplied = "3M";
  const totalCustomers = 500;
  const freeTrialHashInSignUpUrl =
    useLocation().hash.substring(1) === "freeTrial";
  return (
    <>
  <Stack spacing={3} sx={{ mt: -10 }}>
  <Box maxWidth={250} mb={2} sx={{ mt: -8 }}>
    <ShLogo link="/accounts/login" />
  </Box>

  <Typography variant="h4" fontWeight="bold">
    Sign up for SmoothHiring
  </Typography>

  <Typography variant="body1" mt={1}>
    Join us — Find and hire your best employee
  </Typography>

  {freeTrialHashInSignUpUrl && (
    <Stack direction="row" spacing={2} alignItems="center">
      <CheckOutlinedIcon />
      <Typography variant="body2">Full Application 30-Day Trial</Typography>
      <CheckOutlinedIcon />
      <Typography variant="body2">No Credit Card Required</Typography>
    </Stack>
  )}

  <Divider sx={{ my: 2 }} />

  <Stack spacing={1}>
    {SignUpPageBenefits.map((benefit, index) => (
      <Stack key={index} direction="row" alignItems="center" spacing={1}>
        <CheckBoxOutlinedIcon color="primary" />
        <Typography variant="body1">{benefit}</Typography>
      </Stack>
    ))}
  </Stack>

  {/* Stats Section */}
  <Box mt={4}>
    <Stack spacing={2} mt={2} direction="row">
      <Stack direction="row" alignItems="center">
        <Typography variant="h4" fontWeight="bold" color={ShGreen}>
          {totalJobsPosted}+
        </Typography>
        <Typography variant="body2" color="text.secondary">
          &nbsp; Jobs Posted
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center">
        <Typography variant="h4" fontWeight="bold" color={ShGreen}>
          {totalCandidatesApplied}+
        </Typography>
        <Typography variant="body2" color="text.secondary">
          &nbsp; Candidates Applied
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center">
        <Typography variant="h4" fontWeight="bold" color={ShGreen}>
          {totalCustomers}+
        </Typography>
        <Typography variant="body2" color="text.secondary">
          &nbsp; Trusted Partners
        </Typography>
      </Stack>
    </Stack>
  </Box>

  <Typography maxWidth={700} variant="body1" borderRadius={15}>
    SmoothHiring's AI-powered End to End Hiring Software makes it easy to
    post & Sponsor jobs, Predict best employees, schedule interviews, and
    attract top talent—all while making smarter, bias-free hiring decisions.
    Upgrade to a faster, more efficient hiring process today.
  </Typography>

  <ShButton
    variant="outlined"
    color="primary"
    size="large"
    borderRadius={15}
    href="https://smoothhiring.com/all-features/"
  >
    Learn More
  </ShButton>
</Stack>



    </>
  );
};
