import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Fade,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShTableContainer } from "shared/SharedStyles/ShDataDisplay";
import {
  ShButton,
  ShGreenBtn,
  ShTextFieldV2,
} from "shared/SharedStyles/ShInputs";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import {
  fetchEEOQuestions,
  updateEEOQuestions,
} from "store/slices/employer/create-job/create-job-shared-slices";
import { JobDetailsActionsStack } from "../CreateJob/CreateJob.styled";
import { EEOQutionaareData } from "./EEOConfig";
import {
  ICompanyInfo,
  IUpdateCompanyInfoPayload,
} from "Modules/Core/SettingsTs/SettingsModel";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";
import {
  getCompanyInfo,
  resetUpdateCompany,
  updateCompanyInfo,
} from "store/slices/employer/settingsTs/company-info-slice";

export const EEOJobPreview = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const { eeoQuestions, fetchEEOQuestionsStatus } = useAppSelector(
    (state) => state.employer.createJob.createJobShared
  );
  const {
    getCompanyInfoApiStatus,
    companyInfo,
    companyLogo,
    updateCompanyInfoApiStatus,
  } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
  const { employerId } = useAppSelector(
    (state) => state.auth.auth
  );

  const [_companyInfo, _setCompanyInfo] = useState<ICompanyInfo>({
    id: 0,
    company_name: "",
    website: "",
    phone_number: "",
    phone_area: "",
    masking_name: "",
    formatted_ph_no: "",
    company_logo: "",
    company_logo_url: "",
    is_eeo_enabled: undefined,
    avatar: "",
    calendly_token: "",
    country: { name: "" },
    state: { name_en: "" },
    address: {
      city: "",
      country_id: 0,
      createdAt: "",
      id: 0,
      postalcode: "",
      province_id: 0,
      updatedAt: "",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [includedQuestions, setIncludedQuestions] = useState<{
    [key: number]: boolean;
  }>(
    EEOQutionaareData.reduce((acc, _, idx) => {
      acc[idx] = false;
      return acc;
    }, {} as { [key: number]: boolean })
  );

  useEffect(() => {
    // Initialize the includedQuestions state based on the fetched eeoQuestions
    if (eeoQuestions) {
      const initialQuestions = EEOQutionaareData.reduce((acc, item, idx) => {
        // Set true for indices present in eeoQuestions
        acc[idx] = eeoQuestions.includes(item.idx);
        return acc;
      }, {} as { [key: number]: boolean });
      setIncludedQuestions(initialQuestions);
    }
  }, [eeoQuestions, fetchEEOQuestionsStatus]);

  const handleIncludeChange = (idx: number, checked: boolean) => {
    setIncludedQuestions((prev) => ({ ...prev, [idx]: checked }));
  };

  const handleSelectAllChange = (checked: boolean) => {
    const allIncluded = EEOQutionaareData.reduce((acc, _, idx) => {
      acc[idx] = checked;
      return acc;
    }, {} as { [key: number]: boolean });

    setIncludedQuestions(allIncluded);
  };

  const handleSave = () => {
    const eeoQuestions = EEOQutionaareData.filter(
      (item, idx) => includedQuestions[idx]
    ).map((item) => item.idx); // Map to get the 'idx' of each selected question
    if (jobId) {
      dispatch(updateEEOQuestions({ jobId, eeoQuestions }));
    }
  };

  useEffect(() => {
    if (jobId) {
      dispatch(fetchEEOQuestions({ jobId }));
      dispatch(getCompanyInfo());
    }
  }, [jobId, dispatch]);

  useEffect(() => {
    if (updateCompanyInfoApiStatus === "success") {
      dispatch(getCompanyInfo());
      if (employerId) {
        dispatch(getEmployerDetails({ empId: employerId }));
      }
      dispatch(resetUpdateCompany());
    }
  }, [dispatch, employerId, updateCompanyInfoApiStatus]);

  useEffect(() => {
    if (getCompanyInfoApiStatus === "success" && companyInfo !== undefined) {
      _setCompanyInfo({
        ...companyInfo,
        about_us: companyInfo.jobs?.companydescription,
        formatted_ph_no: companyInfo?.phone_area + companyInfo?.phone_number,
        company_logo_url: companyLogo,
        website: companyInfo.website, //
      });
    }
  }, [companyInfo, companyLogo, getCompanyInfoApiStatus]);

  const saveCompanyInfo = () => {
    const payload: IUpdateCompanyInfoPayload = {
      company_name: _companyInfo?.company_name,
      website: _companyInfo?.website,
      province_id: _companyInfo?.address?.province_id,
      city: _companyInfo?.address?.city,
      postal_code: _companyInfo?.address?.postalcode,
      phone_area: _companyInfo?.formatted_ph_no.substring(0, 3),
      phone_number: _companyInfo?.formatted_ph_no.substring(
        3,
        _companyInfo?.formatted_ph_no?.length
      ),
      calendly_token: _companyInfo?.calendly_token,
      masking_name: _companyInfo?.masking_name,
      about_us: _companyInfo.about_us,
      is_eeo_enabled: _companyInfo.is_eeo_enabled,
    };
    // Updating company info and logo with separate APIs
    dispatch(updateCompanyInfo({ id: _companyInfo.id, payload }));

    // Compress the image and upload.
    // if (_companyInfo.company_logo) {
    //     compressImage(_companyInfo.company_logo).then((cmpFile) => {
    //         // Pass image as form data with name 'logo'
    //         let formData = new FormData();
    //         formData.append("logo", cmpFile);
    //         dispatch(updateCompanyLogo({ payload: formData }));
    //     }).catch(err => console.error(err))
    // }
  };

  return (
    <>
      <Fade in={true} timeout={800}>
        <Stack alignContent="center">
          <ShPaper variant="outlined" borderRadius={0} headerSection>
            <Stack
              columnGap={1}
              flexDirection="row"
              alignItems="center"
              display="flex"
              paddingY={1.5}
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                display="flex"
                spacing={1}
              >
                <Typography variant="subtitle1">
                  Equal Employment Opportunity Questionnaire (Optional)
                </Typography>
                <ShTooltip
                  title={
                    "This section includes the EEO questionnaire, which gathers optional demographic details like gender, ethnicity, and disability status. This information is used for diversity tracking and fostering an inclusive workplace."
                  }
                  placement="right"
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    color="disabled"
                    sx={{ verticalAlign: "middle" }}
                  />
                </ShTooltip>
              </Stack>
              <ShGreenBtn
                variant="contained"
                size="small"
                disableElevation
                onClick={() => {
                  handleSave();
                  navigate(`/employer/job/${jobId}/bundles`);
                }}
              >
                Skip
              </ShGreenBtn>
            </Stack>
            <Stack
              columnGap={1}
              flexDirection="row"
              alignItems="center"
              display="flex"
              paddingY={1.5}
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                display="flex"
                spacing={1}
              >
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  maxWidth={800}
                >
                  This questionnaire gathers optional demographic details about
                  gender, ethnicity, and disability status, which are used for
                  diversity tracking and fostering an inclusive workplace. You
                  may choose which questions to include or disable the
                  questionnaire from the job creation flow in settings.
                </Typography>
              </Stack>
              <ShTooltip
                title="This EEO questionnaire will appear in the job creation flow"
                placement="right"
              >
                <FormControlLabel
                  label={
                    <Typography variant="body2" ml={1}>
                      {" "}
                      Remove EEO Questionniare for all jobs
                    </Typography>
                  }
                  control={
                    <ShSwitch
                      size="medium"
                      checked={!_companyInfo.is_eeo_enabled}
                      onChange={(e) =>
                        _setCompanyInfo({
                          ..._companyInfo,
                          is_eeo_enabled: !e.target.checked,
                        })
                      }
                    />
                  }
                />
              </ShTooltip>
            </Stack>
          </ShPaper>
          <ShPaper variant="outlined">
            <ShTableContainer>
              <Table size="small" aria-label="EEO Questionnaire table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ borderRight: "1px solid", borderColor: "divider" }}
                    >
                      <FormControlLabel
                        control={
                          <ShSwitch
                            onChange={(e) =>
                              handleSelectAllChange(e.target.checked)
                            }
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    <TableCell
                      sx={{ borderRight: "1px solid", borderColor: "divider" }}
                    >
                      <Typography variant="body1">Question</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">Answer Options</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {EEOQutionaareData.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <ShSwitch
                              checked={includedQuestions[idx] ?? true}
                              onChange={(e) =>
                                handleIncludeChange(idx, e.target.checked)
                              }
                            />
                          }
                          label=""
                        />
                      </TableCell>

                      <TableCell>
                        <Typography variant="body2">{item.label}</Typography>
                      </TableCell>

                      <TableCell>
                        {includedQuestions[idx] && (
                          <>
                            {item.type === "text" && (
                              <ShTextFieldV2
                                size="small"
                                variant="outlined"
                                placeholder="Enter your answer"
                                fullWidth
                                sx={{ width: "100%" }}
                              />
                            )}

                            {item.type === "radio" && (
                              <RadioGroup
                                sx={{
                                  justifyContent: "flex-start",
                                  flexDirection: "row",
                                }}
                              >
                                {item.options?.map((option, optionIdx) => (
                                  <Stack
                                    key={optionIdx}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Radio value={option} />
                                    <Typography variant="body2">
                                      {option}
                                    </Typography>
                                  </Stack>
                                ))}
                              </RadioGroup>
                            )}

                            {item.type === "dropdown" && (
                              <ShTextFieldV2
                                select
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Select option"
                              >
                                {item.options?.map((option, optionIdx) => (
                                  <MenuItem key={optionIdx} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </ShTextFieldV2>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ShTableContainer>
            <JobDetailsActionsStack paddingY={1}>
              <ShButton
                variant="contained"
                size="small"
                disableElevation
                onClick={() => {
                  saveCompanyInfo();
                  handleSave();
                  navigate(`/employer/job/${jobId}/additional-questions`);
                }}
              >
                Back
              </ShButton>
              <Stack direction="row" columnGap={1}>
                <ShGreenBtn
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    saveCompanyInfo();
                    handleSave();
                    navigate(`/employer/job/${jobId}/bundles`);
                  }}
                >
                  Next
                </ShGreenBtn>
              </Stack>
            </JobDetailsActionsStack>
          </ShPaper>
        </Stack>
      </Fade>
    </>
  );
};