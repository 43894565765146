import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
} from '@mui/material';
import { useAppSelector, useAppDispatch, IsMdScreen } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { fetchDashboardActiveJobsList } from 'store/slices/employer/employer/dashboard/dashboard-active-jobs-slice';
import { fetchDashboardApplicantsList } from 'store/slices/employer/employer/dashboard/dashboard-applicants-slice';
import { IApplicantsApiParams } from '../Applicants/ApplicantsModel';
import { DashboardListSize } from '../Dashboard/DashboardConstants';
import { IJob, IJobsApiParams } from '../JobsTs/JobsModel';
import { truncateText } from 'shared/utils';
import { DashboardMenuProps } from '../Dashboard/Dashboard.styled';
import { setSelectedJobId } from 'store/slices/employer/employer/dashboard/dashboard-recent-activity-selected-job-slice';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { AnalyticsApplicantPerformance } from './AnalyticsApplicantPerformance';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { Link as RouterLink } from "react-router-dom";
import { Edit, People } from '@mui/icons-material';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import UpdateIcon from '@mui/icons-material/Update';
import { AnalyticsApplicantStageCount } from './AnalyticsApplicantStageCount';
import { AnalyticsApplicantMatchTypeCount } from './AnalyticsApplicantMatchTypeCount';
import { AnalyticsResumeScore } from './AnalyticsResumeScore';


export const Analytics = () => {

    const [selectedJob, setSelectedJob] = useState<number>(0);
    const { jobsList: activeJobsList } = useAppSelector(state => state.employer.dashboard.dashboardActiveJobs);
    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForRecentActivity);
    const isMdScreen = IsMdScreen();

    const dispatch = useAppDispatch();
    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        // FOR FETCHING DRAFT JOBS
        dispatch(fetchDashboardActiveJobsList(params));
    }, [dispatch]);

    // Get jobs list on page load.
    useEffect(() => {
        getJobs();
    }, [dispatch, getJobs]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: 50,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };

        dispatch(fetchDashboardApplicantsList(params));
    }, [dispatch]);


    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);
            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);

    
    const handleDashboardRecentActivityChange = (event: SelectChangeEvent<string>) => {
        const jobId = parseInt(event.target.value);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }

    };

    const JobDetails = ({ job }: { job: IJob }) => {
        return (
            <Box key={job.id} margin={3}>
                <ShPaper variant="outlined">
                    <Typography variant="subtitle1" gutterBottom >
                        Job Information
                    </Typography>
                    <Stack direction={isMdScreen ? 'column' : 'row'} spacing={2} alignItems="center" marginBottom={1} >
                        <ShChip
                        icon={<WorkIcon />}
                        label={job.name}
                        variant='outlined'
                        size="small"
                        />
                        <ShChip
                        icon={<BadgeIcon />}
                        label={`Job Id: ${job.id}`}
                        size="small"
                        />
                        <ShChip
                        icon={<LocationCityIcon />}
                        label={`City: ${job.city}`}
                        size="small"
                        />
                        <ShChip
                        icon={<MapIcon />}
                        label={`State: ${job.state}`}
                        size="small"
                        />
                        <ShChip
                        icon={<PersonIcon />}
                        label={`Owner: ${job.owner}`}
                        size="small"
                        />
                    </Stack>
                
                    <Stack direction={isMdScreen ? 'column' : 'row'} spacing={2} alignItems="center" marginTop= {2}>
                        <ShChip
                        icon={<ManageAccountsIcon />}
                        label={`Hiring Manager: ${job.hiring_manager}`}
                        size="small"
                        />
                        <ShChip
                        icon={<EventIcon />}
                        label={`Start Date: ${job.starts_at}`}
                        size="small"
                        />
                        <ShChip
                        icon={<EventAvailableIcon />}
                        label={`End Date: ${job.ends}`}
                        size="small"
                        />
                    </Stack>
                
                    <Stack direction={isMdScreen ? 'column' : 'row'} spacing={2} alignItems="center" marginTop= {2} >
                        <ShChip
                        icon={<NewReleasesIcon />}
                        label={`New Candidates Count: ${job.newCandidateCount || 0}`}
                        size="small"
                        />
                        <ShChip
                        icon={<UpdateIcon />}
                        label={`New Stages: ${job.new_stage_count ?? 'N/A'}`}
                        size="small"
                        />
                    </Stack>
            
                </ShPaper>
            </Box>
            
        );
    };

    return (
        <Stack padding={1} rowGap={2}>
                <ShPaper variant='outlined'>
                    <Stack direction={isMdScreen ? 'column' : 'row'} justifyContent="space-between" margin={1} spacing={2}>
                        <Box maxWidth={400}>
                            <FormControl fullWidth size="small" disabled={activeJobsList.length === 0}>
                                <InputLabel style={{ fontSize: 'small' }}>
                                    {activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}
                                </InputLabel>
                                <Select
                                    MenuProps={DashboardMenuProps}
                                    onChange={handleDashboardRecentActivityChange}
                                    value={activeJobsList.length > 0 ? selectedJob.toString() : ''}
                                    input={<OutlinedInput label={activeJobsList.length > 0 ? 'Select a Job' : ''} />}>
                                    {activeJobsList.map((job) => (
                                        <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                            {truncateText(job.name, 40)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Stack direction="row" spacing={2}>

                            <ShButton component={RouterLink}
                                to={`/employer/job/${selectedJob}/title`}
                                size="small"
                                variant="contained"
                                disabled={selectedJob === 0}
                                startIcon={<Edit />}>
                                Edit Job
                            </ShButton>

                            <ShGreenBtn component={RouterLink}
                                to={`/employer/applicants/${selectedJob}`}
                                size="small"
                                variant="contained"
                                disabled={selectedJob === 0}
                                startIcon={<People />} >
                                See Candidates
                            </ShGreenBtn>
                        </Stack>

                    </Stack>
                </ShPaper>
                <ShPaper variant='outlined'>
                    <Box>
                    {activeJobsList
                        ?.filter((job: IJob) => job.id === selectedJob && job.name !== null && job.hiring_manager?.length > 0)
                        .map((job: IJob) => (
                            <JobDetails job={job} key={job.id} />
                        ))}
                    </Box>
                </ShPaper>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <ShPaper variant= 'outlined'>
                            <AnalyticsApplicantPerformance/>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <ShPaper variant= 'outlined'>
                            <AnalyticsApplicantStageCount/>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <ShPaper variant= 'outlined'>
                            <AnalyticsApplicantMatchTypeCount/>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <ShPaper>
                            <AnalyticsResumeScore/>
                        </ShPaper>
                    </Grid>
                </Grid>
        </Stack>
    );
}