import { Dispatch, SetStateAction } from "react";
import { ApiState, INavLink } from "shared/SharedModels";
import { SubscriptionPlan } from "./AccountSettings/Subscriptions/SubscriptionsModel";

export type TwoStepAuthType = 'EMAIL_CODE' | 'QUESTION_ANSWER';
export type SettingsPath = 'account-information' | 'company-information' | 'access-levels' | 'security' | 'branding' | 'integrations' | 'subscriptions';
export type TToolsPath = 'templates' | 'other';
export type RoleAccessLevelTableHead = 'ALL_JOBS' | 'CREATE' | 'ASSIGN' | 'VIEW' | 'COMMENT';
export type AccessLevelTableHead = 'NAME' | 'EMAIL' | 'ROLE';
export type Role = 'owner' | 'admin' | 'recruiter' | 'hiring_manager' | 'observer';
export type TSecurityUrlFragments = 'two-step' | 'password-change' | 'login-info';
export type TSubscriptionUrlFragments = 'manage-subscriptions' | 'view-subscriptions' | 'billing-payment-history';
export type TCareersPageUrlFragments = 'career-page-logos' | 'career-page-branding';

export enum SettingsPathEnum {
    AccountInformation = 'account-information',
    CompanyInformation = 'company-information',
    AccessLevels = 'access-levels',
    Security = 'security',
    Branding = 'branding',
    Integrations = 'integrations',
    CareersPage = 'customize-careers-page',
    Subscriptions = 'subscriptions'
};

export enum ToolsPathEnum {
    Templates = 'templates',
    Other = 'other',
};

export interface IRoleAccessMap {
    [id: number]: RoleAccessLevelTableHead[]
};

interface ICandidate {
    candidate_id: number | null,
}

export interface IAccountInfo {
    id: number,
    first_name: string,
    middle_name?: string,
    last_name: string,
    email: string,
    calendly_token: string,
    applicant_notifications: boolean,
    candidate?: ICandidate,
    timezone?: string,
    signup_source?: string
};

export interface IAccountInfoState {
    getAccInfoApiStatus: ApiState,
    getAccInfoApiResponse: string | undefined,
    getUserImageApiStatus?: ApiState,
    getUserImageApiResponse?: string | undefined,
    updateUserImageApiStatus?: ApiState,
    updateUserImageApiResponse?: string | undefined,
    accountInfo: IAccountInfo,
    userImage?: string,
    updateAccInfoApiStatus: ApiState,
    updateAccInfoApiResponse: string | undefined,
};

export interface IAccountInfoUpdatePayload {
    userId: number,
    payload: Partial<IAccountInfo>
};

export interface ICompanyInfo {
    id: number,
    company_name: string,
    stripe_subscription_id?: string;
    stripe_customer_id?:string;
    subscription_plan?: SubscriptionPlan | undefined;
    subscription_status?: "active" | "inactive" | "canceled" | "past_due" | "trialing" | undefined; 
    renewal_date?: string; // When the subscription renews
    createdAt?: string; // When the subscription was created
    updatedAt?: string; // Last update timestamp
    about_us?: string,
    website: string,
    phone_number: string,
    phone_area: string,
    formatted_ph_no: string,
    company_logo: File | '',
    company_logo_url: string | undefined,

    company_banner?: File | '',
    company_banner_url?: string | undefined,

    avatar: string,
    calendly_token: string,
    country: { name: string },
    state: { name_en: string },
    address: {
        city: string,
        country_id: number,
        createdAt: string,
        id: number,
        postalcode: string,
        province_id: number,
        updatedAt: string
    },
    masking_name: string,
    jobs?: {
        companydescription: string
    },
    employer_pricing_group_id?: number,
    is_eeo_enabled?: boolean
};

export interface IStates {
    abbreviation: string,
    country_id: number,
    hstrate: string,
    id: number,
    name_en: string,
    name_fr: string,
    taxtype: string
};

export interface ICompanyInfoState {
    getCompanyInfoApiStatus: ApiState,
    getCompanyInfoApiResponse: string | undefined,
    companyInfo: ICompanyInfo,
    getCompanyLogoApiStatus: ApiState,
    getCompanyLogoApiResponse: string | undefined,
    companyLogo: string | undefined,
    updateCompanyInfoApiStatus: ApiState,
    updateCompanyInfoApiResponse: string | undefined,
    updateCompanyLogoApiStatus: ApiState,
    updateCompanyLogoApiResponse: string | undefined,
    
    getCompanyBannerApiStatus: ApiState,
    getCompanyBannerApiResponse: string | undefined,
    companyBanner: string | undefined,
    updateCompanyBannerApiStatus: ApiState,
    updateCompanyBannerApiResponse: string | undefined,
};

export interface IUpdateCompanyInfoPayload {
    company_name: string,
    website: string,
    province_id: number,
    city: string,
    postal_code: string,
    phone_area: string,
    phone_number: string,
    calendly_token: string,
    masking_name?: string,
    about_us?: string,
    is_eeo_enabled?: boolean,
};

export interface IAccount {
    account: {
        email: string,
        first_name: string,
        last_name: string,
        middle_name: string
    },
    account_id: number,
    createdAt: string,
    deletedAt: null,
    employer_id: number,
    flags: [],
    id: number,
    role: { name: string, },
    role_id: number,
    updatedAt: string,
    fullName?: string
};

export interface IRole {
    display_name: string,
    id: number,
    name: Role
};

export interface IAccessLevelsState {
    getAccountListApiStatus: ApiState,
    getAccountListApiResponse: string | undefined,
    accountList: IAccount[],
    getRolesListApiStatus: ApiState,
    getRolesListApiResponse: string | undefined,
    rolesList: IRole[],
    addUserApiStatus: ApiState,
    addUserApiResponse: string | undefined,
    deleteUserApiStatus: ApiState,
    deleteUserApiResponse: string | undefined,
    updateRolesApiStatus: ApiState,
    updateRolesApiResponse: string | undefined
};

export interface INewUser {
    id?: number,
    first_name: string,
    last_name: string,
    email: string,
    role_id: number | ''
};

export interface IUpdateRoleUser {
    user_id: number,
    role_id: number
};

export interface IUpdateRolesParams {
    employerId: number,
    updatedRoles: { users: IUpdateRoleUser[] }
};

export interface IAuthQuestion {
    id: number,
    title: number
};

export interface IAuthQuestionAndAnswer {
    question_id?: number | null,
    auth_answer: string
};

export interface IResetAuthAns extends IAuthQuestionAndAnswer {
    password: string
};

export interface IUpdateAuthQuestion {
    current_question_id?: number | null,
    current_answer: string,
    new_question_id?: number,
    new_answer: string
};

export interface IChangePassPayload {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
};

export interface IUserSecurityAuthParams {
    emailVerification: boolean | undefined,
    securityVerification: boolean | undefined,
    authQuestionId?: number | null
};

export interface ISecurityState {
    changeUserPassApiStatus: ApiState,
    changeUserPassApiResponse: string | undefined,
    updateSecurityAuthApiStatus: ApiState,
    updateSecurityAuthApiResponse: string | undefined,
    updatedAuthPreferences: IUserSecurityAuthParams,
    getSecurityQuestionsStatus: ApiState,
    getSecurityQuestionsResponse: string | undefined,
    securityQuestions: IAuthQuestion[],
    validateAuthAnsApiStatus: ApiState,
    validateAuthAnsApiResponse: string | undefined,
    isValidAnswer: boolean | undefined,
    setAuthQsApiStatus: ApiState,
    setAuthQsApiResponse: string | undefined,
    resetAuthAnsApiStatus: ApiState,
    resetAuthAnsApiResponse: string | undefined,
    changeAuthQuestionStatus: ApiState,
    changeAuthQuestionResponse: string | undefined,
};

export interface IAuthQuestionDialog extends IUserSecurityAuthParams {
    isAuthQstnDlgOpen: boolean,
    setIsAuthQstnDlgOpen: Dispatch<SetStateAction<boolean>>,
    showNotification: (apiStatus: ApiState, resetFunc: Function, responseMsg?: string) => void,
    updateUserAuth: () => void
};

export interface IForgotAuthDialog {
    isForgotAuthOpen: boolean,
    setIsForgotAuthOpen: Dispatch<SetStateAction<boolean>>,
    showNotification: (apiStatus: ApiState, resetFunc: Function, responseMsg?: string) => void,
    authQuestionId?: number | null
};

export interface IAccessLevelsRow {
    row: IAccount,
    editUser: (user: IAccount) => void,
    rolesList: IRole[]
};

export interface IAddUserDialog {
    isAddUserDialogOpen: boolean,
    closeAddUserDialog: () => void,
    _accountList: IAccount[],
    accountList: IAccount[],
    employerId: number,
    user?: IAccount
};

export interface IAccessLevels {
    paginatedAccountList: IAccount[],
    editUser: (user: IAccount) => void
}

export interface IAccountActions {
    editUser: (user: IAccount) => void,
    account: IAccount,
}

export interface IToolsSideNavItem extends INavLink {
    icon?: JSX.Element,
    subMenuItems?: IToolsSideNavItem[]
}

export const countryFormats: Record<number, string> = {
    233: "+# (###) ###-####", 
    234: "+# (###) ###-####", 
    44: "+## #### ### ###",   
    91: "+## #####-#####"
};
