import { combineReducers } from '@reduxjs/toolkit';
import timezonesSlice from './timezone-slice';
import chatbotSlice from './chatbot-slice';

const commonReducer = combineReducers({
    timezones: timezonesSlice.reducer,
    chatbot: chatbotSlice.reducer,
});

export { timezonesSlice };
export default commonReducer;

