import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ManageHistory } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useParams } from 'react-router-dom';
import { JobDetailsPanel } from '../ApplyJob/JobDetailsPanel';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { useEffect, useState } from 'react';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ErrorContainer, GridWithBorder } from './Candidate.styled';
import { IInterviewDetails, ITimeSlot } from '../Interviews/InterviewsModel';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getInterviewRequestDetails } from 'store/slices/employer/interviews/interviews-details-slice';
import { updateAcceptTimeSlots } from 'store/slices/employer/interviews/interviews-actions-slice';
import { isEmpty } from 'lodash';
import InterviewRequestTimeSlots from '../Interviews/Components/InterviewRequestTimeSlots';

const CandidateRequestTime = () => {
  const { requestIdentifier } = useParams();
  const dispatch = useAppDispatch();
  const isSmScreen = IsSmScreen();
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<number[]>([]);
  const [candidateRequestedTimeSlots, setCandidateRequestedTimeSlots] =
    useState<ITimeSlot[]>([]);
  const [interval, setInterval] = useState<number>(60);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState<boolean>(true);
  const {
    interviewRequestDetails,
    getInterviewRequestDetailsResponse,
    getInterviewsRequestDetailsStatus,
  } = useAppSelector((state) => state.employer.interviews.interviewsDetails);
  const { updateAcceptedTimeSlotsStatus, updateAcceptedTimeSlotsResponse } =
    useAppSelector((state) => state.employer.interviews.interviewsActions);

  useEffect(() => {
    if (requestIdentifier) {
      dispatch(getInterviewRequestDetails({ requestIdentifier }));
    }
  }, [dispatch, requestIdentifier]);

  const handleTimeSlotSelection = (index: number) => {
    setSelectedTimeSlots((prev) =>
      prev.includes(index)
        ? prev.filter((slotIndex) => slotIndex !== index)
        : [...prev, index]
    );
  };

  const convert24HourTo12Hour = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const convertedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${convertedHours}:${formattedMinutes} ${ampm}`;
  };

  const interviewTypeIcons: {
    [key in IInterviewDetails['type_of_interview']]: React.ReactNode;
  } = {
    Video: <VideoCameraFrontIcon color="primary" />,
    'In-person': <LocationOnIcon color="primary" />,
    Phone: <PhoneIcon color="primary" />,
  };

  const saveAcceptedtimeSlots = () => {
    dispatch(
      updateAcceptTimeSlots({
        acceptedTimeSlots: selectedTimeSlots,
        requestIdentifier: requestIdentifier!,
        candidateRequestedTimeSlots
      })
    );
  };

  const handleAdditionOfTimeSlot = () => {
    setCandidateRequestedTimeSlots([
      {
        date: '',
        from_time: '',
        timezone: '',
        to_time: '',
      },
    ]);
  };

  useEffect(() => {
    if (!isEmpty(interviewRequestDetails?.requestTimeSlots)) {
      const { from_time, to_time } =
        interviewRequestDetails?.requestTimeSlots[0]!;
      const fromTimeDate = new Date(`1970-01-01T${from_time}:00`);
      const toTimeDate = new Date(`1970-01-01T${to_time}:00`);
      const timeDiff = toTimeDate.getTime() - fromTimeDate.getTime();
      const selectedTimeSlotInterval = timeDiff / (1000 * 60);

      setInterval(selectedTimeSlotInterval);
    }
  }, [interviewRequestDetails?.requestTimeSlots]);

  useEffect(() => {
    if (updateAcceptedTimeSlotsStatus === 'pending') {
      return setIsSaveBtnDisabled(true);
    }

    const isValidCandidateRequestedTimeSlots =
      !isEmpty(candidateRequestedTimeSlots) &&
      candidateRequestedTimeSlots.every(
        (timeSlot) => !Object.values(timeSlot).includes('')
      );

    setIsSaveBtnDisabled(
      !isEmpty(candidateRequestedTimeSlots)
        ? !isValidCandidateRequestedTimeSlots
        : selectedTimeSlots.length === 0
    );
  }, [
    selectedTimeSlots,
    updateAcceptedTimeSlotsStatus,
    candidateRequestedTimeSlots,
  ]);

  return (
    <>
      {getInterviewsRequestDetailsStatus === 'failed' && (
        <ErrorContainer spacing={1}>
          <ErrorOutlineIcon fontSize="large" />
          {getInterviewRequestDetailsResponse === 'Link Expired' && (
            <Typography variant="h5" fontWeight="bold">
              Request Time Slots Link Expired
            </Typography>
          )}
          {getInterviewRequestDetailsResponse === 'Not Found' && (
            <Typography variant="h5" fontWeight="bold">
              Invalid Request Time Slots Link
            </Typography>
          )}
        </ErrorContainer>
      )}

      {getInterviewsRequestDetailsStatus === 'success' && (
        <Grid container padding={1} spacing={1} height="100%">
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <JobDetailsPanel jobCode={interviewRequestDetails?.jobCode} />
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
            <ShPaper variant="outlined">
              <Grid container alignItems="center" justifyContent="center">
                <GridWithBorder
                  item
                  xs={12}
                  sm={8}
                  lg={5}
                  padding={4}
                  showBorder={!isSmScreen}
                  minHeight={500}
                >
                  {updateAcceptedTimeSlotsStatus === 'success' ? (
                    <Stack>
                      <Typography variant="h5">
                        Thank you for selecting the time slots. All the best for
                        your interview
                      </Typography>
                      <Stack
                        display="flex"
                        justifyContent="center"
                        flexDirection="row"
                        marginTop="10rem"
                      ></Stack>
                    </Stack>
                  ) : (
                    <Stack spacing={1}>
                      {updateAcceptedTimeSlotsStatus === 'failed' && (
                        <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                          {updateAcceptedTimeSlotsResponse}
                        </Alert>
                      )}

                      <Typography variant="h5">
                        Please choose an available time slot for the interview
                      </Typography>

                      <ShPaper variant="outlined">
                        <Stack direction="row" spacing={1}>
                          <Stack direction="row" alignItems="center">
                            <PersonOutlineIcon color="primary" />
                          </Stack>
                          <Stack direction="column">
                            <Typography variant="body1" fontWeight={600}>
                              {`${interviewRequestDetails?.candidate.firstName} ${interviewRequestDetails?.candidate.lastName}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </ShPaper>
                      <ShPaper variant="outlined">
                        <Stack direction="column" spacing={1}>
                          <Stack direction="row" spacing={1}>
                            <Stack alignItems="center" direction="row">
                              <ContentPasteSearchIcon color="primary" />
                            </Stack>
                            <Typography variant="body1" fontWeight={600}>
                              Interview Type
                            </Typography>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            {
                              interviewTypeIcons[
                                interviewRequestDetails?.typeOfInterview!
                              ]
                            }
                            <Typography variant="body2" color={'gray'}>
                              {interviewRequestDetails?.typeOfInterview}
                            </Typography>
                          </Stack>
                          {interviewRequestDetails?.address && (
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              marginTop={1}
                            >
                              {interviewRequestDetails?.address}
                            </Typography>
                          )}
                        </Stack>
                      </ShPaper>
                      <ShPaper variant="outlined">
                        <Stack spacing={1}>
                          <Stack direction="row" spacing={1}>
                            <Stack alignItems="center" direction="row">
                              <AccessTimeIcon
                                color="primary"
                                fontSize="medium"
                              />
                            </Stack>
                            <Typography variant="body1" fontWeight={600}>
                              Select from the below times, You can also select
                              multiple times
                            </Typography>
                          </Stack>
                          <Stack spacing={2} width="100%">
                            {interviewRequestDetails?.requestTimeSlots.map(
                              (slot, index) => (
                                <Button
                                  key={index}
                                  fullWidth
                                  variant={
                                    selectedTimeSlots.includes(index)
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  disabled={
                                    updateAcceptedTimeSlotsStatus === 'pending'
                                  }
                                  onClick={() => handleTimeSlotSelection(index)}
                                >
                                  {`${slot.date}: ${convert24HourTo12Hour(
                                    slot.from_time
                                  )} - ${convert24HourTo12Hour(
                                    slot.to_time
                                  )} (${slot.timezone})`}
                                </Button>
                              )
                            )}
                          </Stack>
                        </Stack>
                      </ShPaper>
                      {!isEmpty(candidateRequestedTimeSlots) && (
                        <ShPaper variant="outlined">
                          <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                              <Stack direction="row" alignItems="center">
                                <ManageHistory color="primary" />
                              </Stack>
                              <Stack direction="column">
                                <Typography variant="body1" fontWeight={600}>
                                  Request other time slots
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack>
                              <InterviewRequestTimeSlots
                                interval={interval}
                                isDisabled={
                                  updateAcceptedTimeSlotsStatus === 'pending'
                                }
                                timeSlots={candidateRequestedTimeSlots}
                                updateTimeSlots={(timeSlots: ITimeSlot[]) =>
                                  setCandidateRequestedTimeSlots(timeSlots)
                                }
                              />
                            </Stack>
                          </Stack>
                        </ShPaper>
                      )}
                      {isEmpty(candidateRequestedTimeSlots) && (
                        <ShButton
                          variant="outlined"
                          color="inherit"
                          disabled={false}
                          fullWidth
                          onClick={handleAdditionOfTimeSlot}
                          size="large"
                        >
                          Request other time slots
                        </ShButton>
                      )}
                      <Stack
                        display="flex"
                        justifyContent="center"
                        flexDirection="row"
                      >
                        <ShGreenBtn
                          disableElevation
                          onClick={() => saveAcceptedtimeSlots()}
                          variant="outlined"
                          startIcon={
                            updateAcceptedTimeSlotsStatus === 'pending' ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              ''
                            )
                          }
                          disabled={isSaveBtnDisabled}
                        >
                          {updateAcceptedTimeSlotsStatus === 'pending'
                            ? 'Saving...'
                            : 'Save'}
                        </ShGreenBtn>
                      </Stack>
                    </Stack>
                  )}
                </GridWithBorder>
              </Grid>
            </ShPaper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CandidateRequestTime;
