import { Box, Fade, Stack, Typography } from '@mui/material';
import { useAppSelector } from 'helpers/hooks';
import { ThemeColorDivider } from 'shared/SharedStyles/ShDataDisplay';

export const LoginInfo = () => {

    const { accountLoginInfo } = useAppSelector((state) => state.auth.auth);

    return (
        <>
        <Fade in={true} timeout={800}>
            <Stack paddingY={1} rowGap={1}>
                <ThemeColorDivider />
                <Box className='switch-n-label'>
                    <Typography variant='subtitle2' className='login-info-label'>Last IP Address
                        <Box component='span'>:</Box>
                    </Typography>
                    <Typography variant='subtitle2' className='value'>{accountLoginInfo?.last_sign_in_ip}</Typography>
                </Box>
                <Box className='switch-n-label'>
                    <Typography variant='subtitle2' className='login-info-label'>Last Login at
                        <Box component='span'>:</Box>
                    </Typography>
                    <Typography variant='subtitle2' className='value'>{accountLoginInfo?.last_sign_in_at}</Typography>
                </Box>
                <Box className='switch-n-label'>
                    <Typography variant='subtitle2' className='login-info-label'>Latitude
                        <Box component='span'>:</Box>
                    </Typography>
                    <Typography variant='subtitle2' className='value'>{accountLoginInfo?.lat}</Typography>
                </Box>
                <Box className='switch-n-label'>
                    <Typography variant='subtitle2' className='login-info-label'>Longitude
                        <Box component='span'>:</Box>
                    </Typography>
                    <Typography variant='subtitle2' className='value'>{accountLoginInfo?.long}</Typography>
                </Box>
                <Box className='switch-n-label'>
                    <Typography variant='subtitle2' className='login-info-label'>Device
                        <Box component='span'>:</Box>
                    </Typography>
                    <Typography variant='subtitle2' className='value'>{accountLoginInfo?.last_login_devise}</Typography>
                </Box>
            </Stack>
        </Fade>
        </>
    );
}
