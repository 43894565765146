import { INavLink } from 'shared/SharedModels';

export const CoreSideNavLinks: INavLink[] = [
  {
    redirectTo: '/employer/dashboard',
    label: 'Dashboard',
    feature: 'Dashboard',
  },
  {
    redirectTo: "/employer/job/new",
    label: "Create Job",
    feature: 'Create Job'
  },
  {
    redirectTo: '/employer/jobs',
    label: 'My Jobs',
    feature: 'View Jobs',
  },
  {
    redirectTo: '/employer/interviews',
    label: 'My Interviews',
    feature: 'Interviews',
  },
  {
    redirectTo: '/employer/create-task',
    label: 'Create Task',
    feature: 'CreateTask',
  },
  // {
  //   redirectTo: "/employer/talent-dashboard",
  //   label: "Talent Center",
  //   feature: 'Employee Insights'
  // },
  // Temporary, change to ShResourcesIcon
  {
    redirectTo: '#',
    label: 'Resources',
    feature: 'Resources',
    subNav: [
      {
        feature: 'Templates',
        label: 'Templates',
        redirectTo: '/employer/resources',
      },
      {
        feature: 'Help',
        label: 'Help',
        redirectTo: '/employer/help',
      },
    ],
  },
  {
    redirectTo: '/employer/analytics',
    label: 'My Analytics',
    feature: 'Analytics',
  },
  // Temporary, change to ShCalendarIcon
  {
    redirectTo: '/employer/calendar',
    label: 'My Calendar',
    feature: 'Calendar',
  },
  {
    redirectTo: '/employer/settings/tools/templates/rejection-notification',
    label: 'Tools And Automation',
    feature: 'Tools And Automation',
  },
  {
    redirectTo: '/employer/settings/account/account-information',
    label: 'Settings',
    feature: 'Settings',
  },
];
