import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    Box,
    Fade,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import jobStatesByCountryFallBack from 'Modules/Core/CreateJob/FallbackStatesUsAndCanada';
import { ICompanyInfo, IUpdateCompanyInfoPayload, countryFormats } from 'Modules/Core/SettingsTs/SettingsModel';
import { compressImage } from 'helpers/fileHandlers';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { forwardRef, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { SelectMenuProps, ShFileUploadButton, ShGreenBtn, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { getEmployerDetails } from 'store/slices/employer/employer/employer-details-slice';
import {
    getCompanyInfo, getCompanyLogo, resetLogoUpdate, resetUpdateCompany,
    updateCompanyInfo, updateCompanyLogo
} from 'store/slices/employer/settingsTs/company-info-slice';
import { getJobsStatesByCountry } from 'store/slices/meta-data/jobs-meta-slice';
import isURL from 'validator/lib/isURL';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { ShSwitch } from 'shared/SharedStyles/ShNavigation';
import { ShTooltip } from 'shared/SharedComponents/ShTooltip';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PhoneNumberFormat = forwardRef<HTMLInputElement, any>(
    ({ onChange, value, ...rest }, ref) => { 
        const country_id = Number(rest.country_id) || 233; 
        const format = countryFormats[country_id] || "+###############"; 
        
        return (
            <PatternFormat className="phone-number" id="phone_number" value={value}
                format={format} mask="_" getInputRef={ref}
                onValueChange={(values) => onChange({ target: { value: values.value } })}/>
        );
    }
);

const isValidURL = (url: string): boolean => {
    if (url !== null) {
        return isURL(url, { require_protocol: false });
    } else {
        return false;
    }
};

export const CompanyInfo = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        getCompanyInfoApiStatus, companyInfo, companyLogo, updateCompanyInfoApiStatus, updateCompanyInfoApiResponse,
        updateCompanyLogoApiStatus, getCompanyLogoApiStatus,
        getCompanyLogoApiResponse, updateCompanyLogoApiResponse, getCompanyInfoApiResponse
    } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const { accountAccess, accountCountry, employerId } = useAppSelector((state) => state.auth.auth);
    const { jobStatesByCountry, getJobStatesByCountryStatus, getJobStatesByCountryResponse } = useAppSelector((state) => state.metadata.jobsMeta);
    const [_companyInfo, _setCompanyInfo] = useState<ICompanyInfo>({
        id: 0, company_name: '', website: '', phone_number: '', phone_area: '', masking_name: '',
        formatted_ph_no: '', company_logo: '', company_logo_url: '',  is_eeo_enabled: undefined,  avatar: '',
        calendly_token: '', country: { name: '' }, state: { name_en: '' },
        address: { city: '', country_id: 0, createdAt: '', id: 0, postalcode: '', province_id: 0, updatedAt: ''}
    });
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const careersPageUrl = _companyInfo.masking_name ? `https://${_companyInfo.masking_name}.smoothhiring.com/careers`: "";
    
    const trimFileName = (fileName: string, maxLength: number) => {
        if (fileName?.length > maxLength) {
            return fileName?.substring(0, maxLength) + '...';
        }
        return fileName;
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(careersPageUrl);
    };

    // get company info
    useEffect(() => {
        if (!companyInfo.id) {
            dispatch(getCompanyInfo());
        }

        return () => {
            // Reset update company info, logoAPIvariables on component unmount.
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        }
    }, [companyInfo, dispatch]);

    useEffect(() => {
        if (updateCompanyInfoApiStatus === 'success') {
            dispatch(getCompanyInfo());
            if (employerId) {
                dispatch(getEmployerDetails({ empId: employerId }));
            }
            dispatch(resetUpdateCompany());
        }
    }, [dispatch, employerId, updateCompanyInfoApiStatus]);

    useEffect(() => {
        if (accountCountry?.id && jobStatesByCountry?.length === 0 &&
            getJobStatesByCountryStatus !== 'pending' && getJobStatesByCountryStatus !== 'failed') {
            dispatch(getJobsStatesByCountry({ countryId: accountCountry?.id }));
        }
    }, [accountCountry?.id, accountCountry, dispatch, getJobStatesByCountryStatus, jobStatesByCountry]);

    // get company logo
    useEffect(() => {
        if (companyLogo?.length === 0) {
            dispatch(getCompanyLogo());
        }
    }, [companyLogo, dispatch]);

    // update company information form once data is retrieved
    useEffect(() => {
        if (getCompanyInfoApiStatus === 'success' && companyInfo !== undefined) {
            _setCompanyInfo({
                ...companyInfo,
                about_us: companyInfo.jobs?.companydescription,
                formatted_ph_no: (companyInfo?.phone_area + companyInfo?.phone_number),
                company_logo_url: companyLogo,
                website: companyInfo.website //
            });
        }
    }, [companyInfo, companyLogo, getCompanyInfoApiStatus]);

    // Disable save if any required field is empty or invalid
    useEffect(() => {
        setIsSaveDisabled(() => {
            return _companyInfo?.company_name?.trim()?.length === 0 || _companyInfo?.country.name?.trim()?.length === 0 ||
                !_companyInfo?.address?.province_id || _companyInfo?.address?.city?.trim()?.length === 0 ||
                 _companyInfo?.formatted_ph_no?.trim()?.length === 0 ||
                (_companyInfo?.website !== null && !isValidURL(_companyInfo?.website)) // Check for invalid website
        });
    }, [_companyInfo]);

    useEffect(() => {
        if (accountAccess?.role !== 'admin' && accountAccess?.role !== 'owner') {
            navigate("/employer/dashboard");
        }
    }, [accountAccess?.role, navigate])

    const saveCompanyInfo = () => {
        const payload: IUpdateCompanyInfoPayload = {
            company_name: _companyInfo?.company_name,
            website: _companyInfo?.website,
            province_id: _companyInfo?.address?.province_id,
            city: _companyInfo?.address?.city,
            postal_code: _companyInfo?.address?.postalcode,
            phone_area: _companyInfo?.formatted_ph_no.substring(0, 3),
            phone_number: _companyInfo?.formatted_ph_no.substring(3, _companyInfo?.formatted_ph_no?.length),
            calendly_token: _companyInfo?.calendly_token,
            masking_name: _companyInfo?.masking_name,
            about_us: _companyInfo.about_us,
            is_eeo_enabled: _companyInfo.is_eeo_enabled
        };
        // Updating company info and logo with separate APIs
        dispatch(updateCompanyInfo({ id: _companyInfo.id, payload }));

        // Compress the image and upload.
        if (_companyInfo.company_logo) {
            compressImage(_companyInfo.company_logo).then((cmpFile) => {
                // Pass image as form data with name 'logo'
                let formData = new FormData();
                formData.append("logo", cmpFile);
                dispatch(updateCompanyLogo({ payload: formData }));
            }).catch(err => console.error(err))
        }
    };

    useEffect(() => {
        if (updateCompanyLogoApiStatus === 'success') {
            dispatch(getCompanyLogo());
        }
    }, [dispatch, updateCompanyLogoApiStatus]);

    useEffect(() => {
        if (_companyInfo.website?.trim()) {
            const defaultSubdomain = extractSubdomain(_companyInfo.website);
            _setCompanyInfo((prev) => ({
                ...prev,
                masking_name: defaultSubdomain,
            }));
        }
    }, [_companyInfo.website]);


    const Alerts = () => {
        switch (updateCompanyInfoApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Information...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetUpdateCompany())}>
                    <Typography fontWeight={600}>{updateCompanyInfoApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{updateCompanyInfoApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const AlertsForLogoUpdate = () => {
        switch (updateCompanyLogoApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Logo...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetLogoUpdate())}>
                    <Typography fontWeight={600}>{updateCompanyLogoApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error" onClose={() => dispatch(resetLogoUpdate())}>{updateCompanyLogoApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const extractSubdomain = (website: string): string => {
        return website
            .replace(/^(https?:\/\/)?(www\.)?/, '') // Remove protocol (http, https) and www.
            .split('.')[0]; // Split by '.' and take the first part (domain name)
    };

    return (
        <>
            <Fade in={true} timeout={800}>
                <Stack>
                    <ShPaper variant='outlined' borderRadius={0} headerSection>
                        <Typography variant='subtitle1'> Company Information</Typography>
                        <Typography variant='caption' color='textSecondary'>    
                            Update company information such as website, logo, subdomain, and other related details to keep your account up to date.
                        </Typography>
                    </ShPaper>
                    <ShPaper variant='outlined' noBorderTop>
                        <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} minHeight='350px' maxWidth='1200px'>
                            {/* Alerts placeholder block with min height to maintain height consistency while showing alerts. */}
                            {getCompanyInfoApiStatus === 'failed' &&
                                <Box marginBottom={2}><ShAlert severity="error">{getCompanyInfoApiResponse}</ShAlert></Box>
                            }
                            {updateCompanyInfoApiStatus !== 'idle' && <Box marginBottom={2}>{Alerts()}</Box>}
                            {getCompanyInfoApiStatus === 'pending' ?
                                <LinearProgress /> : <>
                                    <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }} flexWrap='wrap-reverse' rowGap={1}>
                                        <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                                            <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <ShTextFieldV2 label="Company Name" variant="outlined" fullWidth size='small' required
                                                        value={_companyInfo.company_name ?? ''}
                                                        onChange={(e) => _setCompanyInfo({ ..._companyInfo, company_name: e.target.value })} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <ShTextFieldV2 label="Country"
                                                        variant="outlined" fullWidth size='small' required value={_companyInfo.country?.name ?? ''}
                                                        onChange={(e) => _setCompanyInfo({ ..._companyInfo, country: { name: e.target.value } })} disabled
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <ShTextFieldV2
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    required
                                                    select
                                                    label="State"
                                                    value={_companyInfo.address?.province_id ?? 0}
                                                    onChange={(e) => _setCompanyInfo({
                                                        ..._companyInfo,
                                                        address: {
                                                            ..._companyInfo.address,
                                                            province_id: Number(e.target.value) 
                                                        }
                                                    })}
                                                    SelectProps={{ MenuProps: SelectMenuProps }}>
                                                    {/* Loading label while states are getting fetched */}
                                                    {getJobStatesByCountryStatus === 'pending' && <MenuItem>Loading...</MenuItem>}
                                                    {/* Display error if statesAPIfailed to get data */}
                                                    {getJobStatesByCountryStatus === 'failed' && <MenuItem>{getJobStatesByCountryResponse}</MenuItem>}
                                                    {/* Display options list if there are any states available | fallback states for CA & US */}
                                                    {(jobStatesByCountry?.length > 0 ? jobStatesByCountry : jobStatesByCountryFallBack)?.map(s =>
                                                        (<MenuItem key={s.id} value={s.id}>{s.name_en}</MenuItem>)
                                                    )}
                                                </ShTextFieldV2>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <ShTextFieldV2 label="City" variant="outlined" fullWidth size='small' required
                                                        value={_companyInfo.address?.city ?? ''}
                                                        onChange={(e) => _setCompanyInfo({
                                                            ..._companyInfo,
                                                            address: { ..._companyInfo.address, city: e.target.value }
                                                        })} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    {/* Allow user to enter only numeric values */}
                                                    <ShTextFieldV2 label="Zip Code" variant="outlined" fullWidth size='small'
                                                        value={_companyInfo.address?.postalcode ?? ''} onChange={(e) => {
                                                            _setCompanyInfo({
                                                                ..._companyInfo,
                                                                address: { ..._companyInfo.address, postalcode: e.target.value }
                                                            })
                                                        }} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    {/* Custom PhoneNumberFormat component is passed as input component for textfield. 
                                                This masks the numeric phone number from user and displays formatted number as he enters the digits in the field
                                                ex: (987) 654 3210.
                                                The output from this field will be numeric string which is not formatted 
                                                ex: 9876543210. */}
                                                    <ShTextFieldV2 className='phone-num-field' size='small' variant='outlined'
                                                        fullWidth label='Phone Number' id='phone_number'
                                                        required 
                                                        InputProps={{ inputComponent: PhoneNumberFormat as any, inputProps: { country_id: accountCountry?.id } }}
                                                        value={_companyInfo.formatted_ph_no ?? ''}
                                                        onChange={(e) => _setCompanyInfo({ ..._companyInfo, formatted_ph_no: e.target.value })} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <ShTextFieldV2
                                                        label="Company Website"
                                                        variant="outlined"
                                                        id="cw"
                                                        fullWidth
                                                        size="small"
                                                        value={_companyInfo.website ?? ''}
                                                        error={
                                                            _companyInfo?.website?.trim() !== '' &&
                                                            !isValidURL(_companyInfo?.website)
                                                        }
                                                        helperText={
                                                            _companyInfo?.website?.trim() !== '' &&
                                                            !isValidURL(_companyInfo?.website)
                                                                ? 'Invalid website URL'
                                                                : ''
                                                        }
                                                        onChange={(e) => {
                                                            const updatedWebsite = e.target.value;
                                                            _setCompanyInfo({
                                                                ..._companyInfo,
                                                                website: updatedWebsite,
                                                                masking_name: extractSubdomain(updatedWebsite), 
                                                            });
                                                        }}                                                        
                                                    />
                                                </Grid>

                                                {/* Subdomain Field */}
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <ShTextFieldV2
                                                        label="Subdomain"
                                                        variant="outlined"
                                                        id="csd"
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                    {_companyInfo.masking_name ?? ''}.smoothhiring.com
                                                                </span>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <ShTextFieldV2 isResizable
                                                        label="Company Description" id='cd' variant="outlined" multiline
                                                        fullWidth size='small' value={_companyInfo.about_us ?? ''} rows={4}
                                                        onChange={(e) => _setCompanyInfo({ ..._companyInfo, about_us: e.target.value })} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Stack direction="row" pl={2} alignItems="center">
                                                <FormControlLabel
                                                 label={
                                                <Stack direction="row" alignItems="center">
                                                <Typography variant="body2" ml={1}>
                                                 Enable Equal Employment Opportunity (EEO) Questionnaire
                                                </Typography>
                                                <ShTooltip 
                                                 title="An Equal Employment Opportunity (EEO) questionnaire is a valuable tool that employers use to collect essential demographic information from job applicants and employees. This form typically includes questions about race, ethnicity, gender, disability status, veteran status, and other protected characteristics. By utilizing this information, employers can promote a more inclusive and diverse workplace, ensuring equal opportunities for all individuals in the hiring process." 
                                                 placement="right"
        >
                                                <InfoOutlinedIcon fontSize="small" color="disabled" sx={{ ml: 0.5, verticalAlign: "middle" }} />
                                                 </ShTooltip>
                                                </Stack>
                                                }
                                                 control={
                                                 <ShSwitch
                                                 size="medium"
                                                 checked={_companyInfo.is_eeo_enabled === true}
                                                 onChange={(e) => _setCompanyInfo({ ..._companyInfo, is_eeo_enabled: e.target.checked })}
                                                />
                                                 }
                                                />
                                                </Stack>


                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <ShGreenBtn  disableElevation  variant='contained'  onClick={saveCompanyInfo} disabled={isSaveDisabled || updateCompanyInfoApiStatus === 'pending'}
                                                    startIcon={
                                                        updateCompanyInfoApiStatus === 'pending' 
                                                        ? <CircularProgress size={20} color="inherit" /> 
                                                        : <SaveIcon />
                                                    }
                                                    >
                                                    {updateCompanyInfoApiStatus === 'pending' ? 'Saving...' : 'Save Changes'}
                                                    </ShGreenBtn>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={4} xl={4} marginBottom={3} >
                                            <Stack spacing={2}>
                                                <ShPaper variant='outlined' height='calc(100% -  35px)' borderColor={PrimaryThemeColor} borderStyle="dashed">
                                                    <Stack rowGap={1} width='100%'>
                                                        <Stack>
                                                            {updateCompanyLogoApiStatus !== 'idle' && <Box marginBottom={2}>
                                                                {AlertsForLogoUpdate()}</Box>}
                                                            {(_companyInfo.avatar === undefined && _companyInfo?.company_logo === undefined) ?
                                                                <Typography variant='body2'>(250px in 250px)</Typography> :
                                                                getCompanyLogoApiStatus === 'pending' ?
                                                                    <Skeleton width='100%' height='100%' animation='wave' /> :
                                                                    getCompanyLogoApiStatus === 'success' && _companyInfo?.company_logo_url ?
                                                                        <img src={_companyInfo?.company_logo_url} alt="Company Logo" /> :
                                                                        <>
                                                                            {getCompanyLogoApiStatus === 'failed' &&
                                                                                <ShAlert severity="error">
                                                                                    {getCompanyLogoApiResponse}
                                                                                </ShAlert>}
                                                                        </>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                </ShPaper>
                                                <ShFileUploadButton className="file-select-btn" component="label" variant="outlined"
                                                    sx={{
                                                        padding: '8px 16px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '8px',
                                                        textTransform: 'none'
                                                    }}>
                                                    <input accept="image/*" type="file" hidden onChange={(e) =>
                                                        e.target.files !== null
                                                            ? _setCompanyInfo({
                                                                ..._companyInfo,
                                                                company_logo_url: e.target.files[0]
                                                                    ? URL.createObjectURL(e.target.files[0])
                                                                    : _companyInfo?.company_logo_url,
                                                                company_logo: e.target.files[0],
                                                            })
                                                            : ''
                                                    }
                                                    />
                                                    <CloudUploadIcon />
                                                    Upload Logo
                                                    {_companyInfo?.company_logo && (
                                                        <Typography variant="body2" fontStyle="italic" sx={{ marginLeft: '8px' }}>
                                                            : {trimFileName(_companyInfo?.company_logo?.name, 20)}
                                                        </Typography>
                                                    )}
                                                </ShFileUploadButton>
                                                <Grid item>
                                                    <ShTextFieldV2
                                                        label="Careers Page"
                                                        variant="outlined"
                                                        id="csd"
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                    <a href={careersPageUrl} 
                                                                    target="_blank" 
                                                                    rel="noopener noreferrer" 
                                                                    style={{ textDecoration: 'none', color: '#417ee3' }}>
                                                                        {_companyInfo.masking_name ?? ''}.smoothhiring.com/careers
                                                                    </a>
                                                                </span>
                                                            ),
                                                            endAdornment: (
                                                                <>
                                                                    <IconButton size="small" onClick={handleCopyToClipboard}>
                                                                        <ContentCopyIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton size="small" component="a" href={careersPageUrl} target="_blank" rel="noopener noreferrer">
                                                                        <OpenInNewIcon fontSize="small" />
                                                                    </IconButton>
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </>}
                        </Stack>
                    </ShPaper>
                </Stack>
            </Fade>
        </>
    );
};



