import React from 'react';
import { useEffect, useState, useRef } from "react";
import { Dialog, 
  DialogTitle, 
  DialogContent, 
  Box, 
  Typography, 
  MenuItem, 
  FormControl, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  Fade,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { ShTextFieldV2, ShDatePickerV2, ShBlueBtn, ShInputBase } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { AdditionalQuestionPreviewDialogProps } from './AdditionalQuestionsModel';


export const AdditionalQuestionPreviewDialog: React.FC<AdditionalQuestionPreviewDialogProps> = ({ open, onClose, selectedForm }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileSelect = () => {
    const files = fileInputRef.current?.files;
    if (files && files.length > 0) {
      setUploadedFile(files[0]);
    }
  };

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Additional Question's Preview</Typography>
      </DialogTitle>
      <DialogContent>
        <Box flex={1}>
          <ShPaper variant="outlined" headerSection>
            <Typography>Preview</Typography>
          </ShPaper>
          <ShPaper variant="outlined" noBorderTop borderRadius={0}>
            <Fade in={true} timeout={800}>
              <Box>
                {selectedForm?.questions?.length ? (
                  selectedForm.questions.map((question: any, index: number) => (
                    <Box key={question.id} mb={3}>
                      <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                        {index + 1}. {question.question}
                        {question.required === "required" && (
                          <Typography component="span" color="error" ml={0.5}>
                            *
                          </Typography>
                        )}
                      </Typography>

                      {question.type === "textfield" && (
                        <ShTextFieldV2
                          size="small"
                          fullWidth
                          placeholder="Enter your response"
                          variant="outlined"
                        />
                      )}

                      {question.type === "dropdown" && (
                        <ShTextFieldV2
                          size="small"
                          select
                          fullWidth
                          placeholder="Select an option"
                          variant="outlined"
                        >
                          {question.options.map((option: any, optionIdx: number) => (
                            <MenuItem key={optionIdx} value={option.label}>
                              <Typography variant="subtitle2">
                                {option.label}{" "}
                                {option.autoReject && (
                                  <ShChip
                                    label="Auto Reject"
                                    color="error"
                                    customSize="xs"
                                    sx={{ ml: 1 }}
                                  />
                                )}
                              </Typography>
                            </MenuItem>
                          ))}
                        </ShTextFieldV2>
                      )}

                      {question.type === "radio" && (
                        <FormControl component="fieldset" size="small">
                          <RadioGroup>
                            {question.options.map((option: any, optionIdx: number) => (
                              <FormControlLabel
                                key={optionIdx}
                                value={option.label}
                                control={<Radio />}
                                label={
                                  <Typography variant="subtitle2">
                                    {option.label}{" "}
                                    {option.autoReject && (
                                      <ShChip
                                        label="Auto Reject"
                                        color="error"
                                        customSize="xs"
                                        size="small"
                                        sx={{ ml: 1 }}
                                      />
                                    )}
                                  </Typography>
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                      {question.type === "date" && (
                        <FormControl component="fieldset" size="small">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <ShDatePickerV2
                            format="MM/DD/YYYY"
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                placeholder: "MM/DD/YYYY",
                                variant: "outlined",
                              },
                            }}
                          />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                      {question.type === "noOldDate" && (
                        <FormControl component="fieldset" size="small">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <ShDatePickerV2
                            format="MM/DD/YYYY"
                            shouldDisableDate={(date) => dayjs(date as Dayjs).isBefore(dayjs(), 'day')}
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                placeholder: "MM/DD/YYYY",
                                variant: "outlined",
                              },
                            }}
                          />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                      {question.type === "fileUpload" && (
                        <FormControl component="fieldset" size="small">
                          <ShInputBase
                            type="file"
                            hidden
                            ref={fileInputRef}
                            onChange={handleFileSelect}
                          />
                          {uploadedFile && (
                            <Typography sx={{ marginTop: 1 }}>Selected: {uploadedFile.name}</Typography>
                          )}
                        </FormControl>
                      )}
                      {question.type === "number" && (
                          <ShTextFieldV2
                            type="number"
                            size="small"
                            fullWidth
                            variant="outlined"
                            placeholder="Enter a number"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2">No additional question forms selected.</Typography>
                )}
              </Box>
            </Fade>
          </ShPaper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalQuestionPreviewDialog;
