import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants";
import { Link as RouterLink } from 'react-router-dom';
import { ShMuiLink } from "shared/SharedStyles/ShNavigation";
import { CircleBox, DASHBOARD_ICON_BG_COLOR, DashboardFeatureTile } from "../../Dashboard.styled";
import { CareersPageToolsFeatureTiles } from "../../DashboardConstants";
import { useEffect } from "react";
import { getCompanyInfo } from "store/slices/employer/settingsTs/company-info-slice";

export const DashboardCareersPageTileWidget = () => {
    const dispatch = useAppDispatch();
    const { accountAccess } = useAppSelector((state) => state.auth.auth);
    const { companyInfo } = useAppSelector(state => state.employer.employerSettings.companyInfo);

    useEffect(() => {
        if (!companyInfo || !companyInfo.id) {
            dispatch(getCompanyInfo()); 
        } 
    }, [companyInfo, dispatch]);

    return (
        <>
            <Stack direction="row" columnGap={1} rowGap={2} flexWrap="wrap">
                {CareersPageToolsFeatureTiles?.map((tile) => {
                    const isCareersPage = tile.featureName === 'View Careers Page';
                    const redirectUrl = isCareersPage 
                        ? `https://${companyInfo?.masking_name ?? ''}.smoothhiring.com/careers`
                        : tile.featureRedirectUrl;

                    // Determine the link destination
                    const linkProps = isCareersPage
                        ? { href: redirectUrl }
                        : { to: redirectUrl };

                    return (
                        RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                            ?.[tile.featureName]?.visibility !== 'disabled' && (
                                <DashboardFeatureTile key={tile?.featureRedirectUrl}>
                                    <ShMuiLink
                                        {...linkProps}
                                        display="flex"
                                        alignItems="center"
                                        columnGap={1}
                                        noUnderline
                                        paddingLeft={2}
                                        paddingRight={1}
                                        paddingY={1}
                                        width="100%"
                                        noBlueHighlight
                                        component={isCareersPage ? "a" : RouterLink}
                                        target={isCareersPage ? "_blank" : undefined}
                                        rel={isCareersPage ? "noopener noreferrer" : undefined}
                                    >
                                        <CircleBox color={DASHBOARD_ICON_BG_COLOR}>
                                            {tile.tileIcon}
                                        </CircleBox>
                                        <Stack>
                                            <Stack direction="row" columnGap={1} alignItems="center">
                                                {tile.chip}
                                                <Typography variant="body2">
                                                    {tile.tileLabel}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="caption" color="gray">
                                                {tile.caption}
                                            </Typography>
                                        </Stack>
                                    </ShMuiLink>
                                </DashboardFeatureTile>
                            )
                    );
                })}
            </Stack>
        </>
    );
};

