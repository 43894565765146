import { Box, Fade, Stack, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TabPanelProps } from 'shared/SharedModels';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { TCareersPageUrlFragments } from '../../SettingsModel';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { getCompanyInfo } from "store/slices/employer/settingsTs/company-info-slice";
import { CareersPageImageCustomization } from "./CareersPageImageCustomization";
import { CareersPageBranding } from "./CareersPageBranding";


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' minHeight={300} hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const CareersPageNav = () => {
    const dispatch = useAppDispatch();
    const hashInSecurityUrl = useLocation().hash.substring(1) as TCareersPageUrlFragments;
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<number>(0);
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);

    useEffect(() => {
        if (!companyInfo.id && !companyInfo) {
            dispatch(getCompanyInfo());
        }
    }, [companyInfo, dispatch]);


    // Set tab value according to the url fragment if any.
    useEffect(() => {
        if (hashInSecurityUrl) {
            switch (hashInSecurityUrl) {
                case 'career-page-logos':
                    setTabValue(0);
                    break;
                case 'career-page-branding':
                    setTabValue(1);
                    break;
                default:
                    break;
            }
        } else {
            navigate('/employer/settings/account/customize-careers-page#career-page-logos');
        }
    }, [hashInSecurityUrl, navigate]);

    const setCareersPageTabValue = (newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/employer/settings/account/customize-careers-page#career-page-logos');
                break;
            case 1:
                navigate('/employer/settings/account/customize-careers-page#career-page-branding');
                break;
            default:
                break;
        }
    }

    return (
        <>
        <Fade in={true} timeout={800}>
            <Stack spacing={1}>
                <ShTabs aria-label="Career Pages tabs" variant="scrollable" scrollButtons='auto' highlightVariant="underline"
                    allowScrollButtonsMobile value={tabValue} onChange={(evt, newValue) => setCareersPageTabValue(newValue)}>
                    <Tab label='Banners and Images' />
                    <Tab label='Customize Branding' />
                </ShTabs>
                <TabPanel value={tabValue} index={0}>
                    <CareersPageImageCustomization />
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    <CareersPageBranding />
                </TabPanel>
               
            </Stack>
        </Fade>
        </>
    );
}
