import { Analytics } from "Modules/Core/Analytics/Analytics";
import { ApplicantsWrapper } from "Modules/Core/Applicants";
import { ApplicantProfileWrapper } from "Modules/Core/Applicants/ApplicantProfile/ApplicantProfileWrapper";
import { ApplicantWrapper } from "Modules/Core/Applicants/ApplicantProfile/ApplicantWrapper";
import { IncompleteApplicants } from "Modules/Core/Applicants/IncompleteApplicants";
import { AccessController } from "Modules/Core/AuthV2/AccessController";
import { AuthV2 } from "Modules/Core/AuthV2/AuthV2";
import { Calendar } from "Modules/Core/Calendar/Calendar";
import { Dashboard } from "Modules/Core/Dashboard/Dashboard";
import { EmployerAiInterviewQuestionsPage } from "Modules/Core/EmployerResources/EmployerAITools/EmployerAiInterviewQuestionsPage";
import EmployerAiJobDescriptionPage from "Modules/Core/EmployerResources/EmployerAITools/EmployerAiJobDescriptionPage";
import EmployerJobDescriptionPage from "Modules/Core/EmployerResources/EmployerJobDescriptionTemplates/EmployerJobDescriptionPage";
import EmployerJobDescriptionTemplateHome from "Modules/Core/EmployerResources/EmployerJobDescriptionTemplates/EmployerJobDescriptionTemplateHome";
import EmployerOfferTemplateHome from "Modules/Core/EmployerResources/EmployerOfferTemplates/EmployerOfferTemplateHome";
import EmployerOfferTemplateSinglePage from "Modules/Core/EmployerResources/EmployerOfferTemplates/EmployerOfferTemplateSinglePage";
import EmployerPolicyPage from "Modules/Core/EmployerResources/EmployerPolicyTemplates/EmployerPolicyPage";
import EmployerPolicyTemplateHome from "Modules/Core/EmployerResources/EmployerPolicyTemplates/EmployerPolicyTemplateHome";
import EmployerRejectionTemplateHome from "Modules/Core/EmployerResources/EmployerRejectionTemplates/EmployerRejectionTemplateHome";
import EmployerRejectionTemplateSinglePage from "Modules/Core/EmployerResources/EmployerRejectionTemplates/EmployerRejectionTemplateSinglePage";
import { EmployerResourcesHome } from "Modules/Core/EmployerResources/EmployerResourceHome";
import { EmployerResourcesWrapper } from "Modules/Core/EmployerResources/EmployerResourcesWrapper";
import { Interviews } from "Modules/Core/Interviews/Interviews";
import { JobsWrapper } from "Modules/Core/JobsTs/JobsWrapper";
import AppLayout from "Modules/Core/Layouts/AppLayout/AppLayout";
import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { Unauthorized } from "Modules/Core/RedirectPages/Unauthorized";
import { Navigate, Route, Routes } from "react-router-dom";
import { getEmployerResourcesRedirect } from "shared/utils";
import { HelpRouter } from "../HelpRouter";
import { CreateJobRouter } from "./CreateJobRouter";
import { SettingsRouter } from "./SettingsRouter";
import CreateTask from "Modules/Core/CreateTask/CreateTask";
import { ApplicantsPipeline } from "Modules/Core/Applicants/ApplicantPipeline/ApplicantsPipeline";
import { ApplicantPipelineWrapper } from "Modules/Core/Applicants/ApplicantPipeline/ApplicantPipelineWrapper";

export const EmployerRouter = () => {

    return (<>
        <Routes>
            <Route element={<AuthV2 appContext="employer" />}>
                {/* Role based access controller */}
                <Route element={<AccessController />}>
                    <Route path="/unauthorized" element={<Unauthorized />}></Route>
                    {/* Main app layout */}
                    <Route path="/" element={<AppLayout />}>
                        {/* Error 404 page */}
                        <Route path="*" element={<ErrorPage />} />

                        <Route path="/" element={<Navigate replace to="/employer/dashboard" />} />
                        {/* Dashboard */}
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* Calendar */}
                        <Route path="/calendar" element={<Calendar />} />

                        {/* Analytics */}
                        <Route path="/analytics" element={<Analytics />} />

                        {/* <Route path="/talent-dashboard" element={<TalentDashboard />} /> */}

                        {/* Help Pages */}
                        <Route path="/help/*" element={<HelpRouter />} />

                        {/* Employer Resources */}
                        <Route path="/resources" element={<EmployerResourcesWrapper />} >
                            <Route path="/resources" element={<EmployerResourcesHome />} />
                            <Route path={getEmployerResourcesRedirect('aiJobDescription')} element={<EmployerAiJobDescriptionPage />} />
                            <Route path={getEmployerResourcesRedirect('aiInterviewKit')} element={<EmployerAiInterviewQuestionsPage />} />
                            <Route path={getEmployerResourcesRedirect('offerLetterTemplates')} element={<EmployerOfferTemplateHome />} />
                            <Route path={getEmployerResourcesRedirect('offerLetterTemplatesDetails')} element={<EmployerOfferTemplateSinglePage />} />
                            <Route path={getEmployerResourcesRedirect('rejectionLetterTemplate')} element={<EmployerRejectionTemplateHome />} />
                            <Route path={getEmployerResourcesRedirect('rejectionLetterTemplatesDetails')} element={<EmployerRejectionTemplateSinglePage />} />
                            <Route path={getEmployerResourcesRedirect('jobTemplatesHome')} element={<EmployerJobDescriptionTemplateHome />} />
                            <Route path={getEmployerResourcesRedirect('jobTemplateDetails')} element={<EmployerJobDescriptionPage />} />
                            <Route path={getEmployerResourcesRedirect('policyTemplatesHome')} element={<EmployerPolicyTemplateHome />} />
                            <Route path={getEmployerResourcesRedirect('policyTemplateDetails')} element={<EmployerPolicyPage />} />
                        </Route>

                        {/* Jobs List */}
                        {["/jobs/draft", "/jobs/active", "/jobs/expired", "/jobs/template"].map((path, key) =>
                            (<Route path={path} element={<JobsWrapper />} key={key} />))}
                        <Route path="/jobs" element={<Navigate replace to="/employer/jobs/active" />} />

                        {/* Interviews */}
                        {["/interviews/requested", "/interviews/accepted", "/interviews/scheduled", "/interviews/completed", "/interviews/cancelled"].map((path, key) =>
                            (<Route path={path} element={<Interviews />} key={key} />))}
                        <Route path="/interviews" element={<Navigate replace to="/employer/interviews/scheduled" />} />

                        {/* Job Creation */}
                        <Route path="/job/*" element={<CreateJobRouter />} />

                        {/* Creation of Task */}
                        <Route path="/create-task" element={<CreateTask />} />

                        {/* Settings */}
                        <Route path="/settings/*" element={<SettingsRouter />} />

                        {/* Applicants */}
                        <Route path="/applicants/:jobId" element={<ApplicantsWrapper />} />

                        <Route path ="/" element={<ApplicantPipelineWrapper/>}>
                            
                            <Route path="/applicants-pipeline/:jobId" element={<ApplicantsPipeline />}>
                                {/* Nested Route for Applicant Profile */}
                                <Route path="applicant/:applicantId/details" element={<ApplicantProfileWrapper />} />
                            </Route>
                        </Route>

                        {/* Incomplete Applicants */}
                        <Route path="/incomplete-applicants/:jobId" element={<IncompleteApplicants />} />

                        {/* Applicant */}
                        <Route path="/job/:jobId/applicant" element={<ApplicantWrapper />} >
                            <Route path="/job/:jobId/applicant/:applicantId"
                                element={<Navigate replace to="/employer/job/:jobId/applicant/:applicantId/details" />} />
                            <Route path="/job/:jobId/applicant/:applicantId/details" element={<ApplicantProfileWrapper />} />
                            {/* <Route path="/job/:jobId/applicant/:applicantId/resume" element={<ApplicantResume />} />
                            <Route path="/job/:jobId/applicant/:applicantId/comments" element={<ApplicantComments />} />
                            <Route path="/job/:jobId/applicant/:applicantId/questions" element={<ApplicantQuestions />} />
                            <Route path="/job/:jobId/applicant/:applicantId/stage" element={<ApplicantStage />} /> */}
                        </Route>
                        {/* Error 404 page */}
                        <Route path="*" element={<ErrorPage />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    </>)
}