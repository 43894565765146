import { Stack, Typography } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const FormTemplateHeader = () => {
  return (
    <ShPaper variant="outlined" borderRadius={0} headerSection>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={1}>
          <Typography variant="subtitle1" gutterBottom paddingLeft={2}>
            Create Additional Questions
          </Typography>
          <Typography variant="body2" color="textSecondary" paddingLeft={2}>
            Get more information about job seeker details and background.
          </Typography>
          <Typography variant="body2" color="textSecondary" paddingLeft={2}>
            Add questions and specify response options. You can also auto-reject
            applicants based on their responses; reject applicants automatically
            if they do not meet a critical job requirement. This helps you
            streamline and save time.
          </Typography>
        </Stack>
      </Stack>
    </ShPaper>
  );
};
