import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { ShOnHover, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const StyledNotificationContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  borderLeft: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}));

export const StyledNotificationItemContent = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'unread',
})<{ unread: boolean }>(({ theme, unread }) => {
  const muiTheme = theme as Theme;

  return {
    borderLeft: unread ? `4px solid ${muiTheme.palette.primary.main}` : 'none',
    backgroundColor: unread
      ? muiTheme.palette.action.hover
      : muiTheme.palette.background.paper,
    padding: muiTheme.spacing(2),
    borderRadius: muiTheme.shape.borderRadius,
    boxShadow: unread ? muiTheme.shadows[2] : 'none',
    border: !unread ? `1px solid ${muiTheme.palette.divider}` : 'none',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      ...ShOnHover(true, muiTheme as Theme),
    },
  };
});

export const StyledNotificationText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
}));

export const StyledHeaderContainer = styled(Box)(({theme}) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: WhiteColor,
  zIndex: 1,
  padding: 1
}));
