import { Fade, Stack, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";

export const AwardsSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.awards?.length) return null;

    return (
        <Fade in={true} timeout={800}>
            <Stack spacing={2}>
                {candidate.awards.length > 0 && (
                    <Stack mt={2} mb={1}>
                        <Typography fontSize={18}>AWARDS</Typography>
                        <Stack direction="row" flexWrap="wrap" gap={1} mt={1}>
                            {candidate.awards.map((award, index) => (
                                <Typography variant="body2">
                                    {award}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                )}  
            </Stack>
        </Fade>
    );
}
