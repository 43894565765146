import { Box, Stack, Typography } from "@mui/material";
import { ApplicantComment } from "Modules/Core/Applicants/ApplicantsList/ApplicantComment";
import { useAppSelector } from "helpers/hooks";
import moment from "moment";
import { ShAvatar } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { getInitialsFromName, stringToColor } from "shared/utils";

export const ApplicantComments = () => {


    const { comments } = useAppSelector((state) => state.employer.applicants.applicantComments);
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { accountId } = useAppSelector((state) => state.auth.auth);

    return (
        <>
            <Stack>
                <ShPaper variant="outlined" headerSection borderRadius={0}>
                    <Typography variant='body2'>Comments</Typography>
                </ShPaper>
                <ShPaper variant="outlined" noBorderTop>
                    <Stack rowGap={2}>
                        {comments?.length === 0 && <ShAlert severity="info">No Comments found</ShAlert>}
                        {comments?.map(comment => {
                            const intervieweeInitials = getInitialsFromName(comment.user_name || "SK", true, true); // Get initials from name
                            const avatarBackgroundColor = stringToColor(intervieweeInitials); // Generate background color

                            return (
                                <Stack key={comment.id} justifyContent="center">
                                    <ShPaper variant="outlined">
                                        <Stack flexDirection="row" alignItems="center" columnGap={2} marginBottom={0.5} justifyContent='space-between'>
                                            <Stack direction='row' alignItems="center" spacing={2}>
                                                <ShAvatar height={40} width={40} backgroundColor={avatarBackgroundColor}>
                                                    <Typography variant="body1">
                                                        {intervieweeInitials}
                                                    </Typography>
                                                </ShAvatar>
                                                <Stack>
                                                    <Typography variant="body2" fontWeight="bold">{comment.user_name}</Typography>
                                                </Stack>
                                            </Stack>
                                            <Stack flexDirection="row" alignItems="center" columnGap={1} marginBottom={0.5}>
                                                <Typography color='gray' variant="body2">
                                                    {moment(comment.updated_at).tz(moment.tz.guess()).format("MMM DD, YYYY h:mm A")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack flexDirection="row" alignItems="center" columnGap={1} marginBottom={0.5}>
                                            <Typography variant="body2" style={{ wordBreak: 'break-word' }}>{comment.body}</Typography>
                                        </Stack>
                                    </ShPaper>
                                </Stack>
                            );
                        })}

                        {applicantDetails && (
                            <Box width="fit-content">
                                <ApplicantComment
                                    applicantId={applicantDetails.candidateEmployerJobId}
                                    isAddition={comments?.find(comment => comment.user_id === accountId) === undefined}
                                    label="Add Comment"
                                />
                            </Box>

                        )}
                    </Stack>
                </ShPaper>
            </Stack>
        </>
    );
};