import { Alert, Box, Fade, IconButton, LinearProgress, Pagination, Stack, Typography } from "@mui/material";
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchApplicantsList } from 'store/slices/employer/applicants/applicants-list-slice';
import { ApplicantsSortCols, FilterType, IApplicantsApiParams, IApplicantsFilter } from '../ApplicantsModel';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import moment from 'moment';
import { renderMatchChip } from '../ApplicantsList/ApplicantsListView';
import { ResumeScoreGauge } from "../ApplicantHelpers";
import { getInitialsFromName, stringToColor, toTitleCase } from "shared/utils";
import { ShAvatar, ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ApplicantsPipelineToolBar } from "./ApplicantsPipelineToolBar";
import { ISortParamsBase, SortOrder } from "shared/SharedModels";
import { DefaultPageSize } from "shared/constants";
import { getDotPropertiesAndMessage } from "Modules/Core/Dashboard/DashboardConstants";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { updateBookmarkCandidateStatus } from "store/slices/employer/applicants/applicant-actions-slice";

/*
    WORK IN PROGRESS
*/
export const ApplicantsPipeline = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // eslint-disable-next-line 
    const { applicantsList, getApplicantsListStatus, paginationParams, getApplicantsListResponse } = useAppSelector((state) => state.employer.applicants.applicantsList);
    // eslint-disable-next-line 
    const [searchQuery, setSearchQuery] = useState('');
    const { applicantId } = useParams();
    const { jobId } = useParams(); 
    const [selectedFilters, setSelectedFilters] = useState<IApplicantsFilter>({ stages: [], fit: [], keywords: [], rating: [] });
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const [sortParams, setSortParams] = useState<ISortParamsBase<ApplicantsSortCols>>({ orderBy: 'created_at', order: 'desc' });
    const [searchKey, setSearchKey] = useState<string>("");
    const [bookmarks, setBookmarks] = useState<{ [key: string]: boolean }>({});
    const listRef = useRef<HTMLDivElement | null>(null);
    
    useEffect(() => {
        // Initialize the bookmarks state with applicants that are already bookmarked
        const initialBookmarks: { [key: string]: boolean } = {};
        applicantsList.forEach(applicant => {
            if (applicant.isBookmarked) {
                initialBookmarks[applicant.candidateEmployerJobId] = true;
            }
        });
        setBookmarks(initialBookmarks);
    }, [applicantsList]);

    
    const toggleBookmark = async (applicantId: number) => {
        setBookmarks(prev => ({
          ...prev,
          [applicantId]: !prev[applicantId], 
        }));
    
        dispatch(updateBookmarkCandidateStatus({ candidateEmployerId: applicantId }));
    };

    const getApplicants = useCallback((pageNo?: number, pageSize?: number, filters?: IApplicantsFilter,
        sortParams?: ISortParamsBase<ApplicantsSortCols>, search?: string) => {
        const params: IApplicantsApiParams = {
            jobId: parseInt(jobId ?? ''),
            pgNo: search ? 1 : pageNo ?? 1,
            pgSize: pageSize ?? DefaultPageSize,
            sortCol: sortParams?.orderBy ?? 'created_at',
            sortDir: sortParams?.order ?? 'desc',
            search: search ?? '',
            stages: filters?.stages?.map(stg => stg.value).join(','),
            ratings: filters?.rating?.map(stg => stg.value).join(','),
            finalMatches: filters?.fit?.map(stg => stg.value).join(','),
            keywords: filters?.keywords?.map(stg => stg.value).join(','),
        };
        if (isNaN(parseInt(jobId ?? ''))) {
            // If the job id is not a number then navigate to wild card route.
            navigate('/404');
        } else {
            dispatch(fetchApplicantsList(params));
        }
    }, [dispatch, jobId, navigate]);

    useEffect(() => {
        if (!applicantsList || applicantsList.length === 0) {
            getApplicants(); 
        }
    }, [getApplicants, applicantsList]);
    
    
    useEffect(() => {
        if (applicantsList?.length > 0 && !applicantId) {
            navigate(`/employer/applicants-pipeline/${jobId}/applicant/${applicantsList[0].candidateEmployerJobId}/details`);
        }
    }, [applicantsList, applicantId, jobId, navigate]);
    

    const totalPages = paginationParams?.totalPages || 1; 

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (paginationParams) {
            const updatedParams: IApplicantsApiParams = {
                jobId: parseInt(jobId ?? ''),
                pgNo: value,  
                pgSize: paginationParams.requestedPageSize,
                sortCol: 'created_at', 
                sortDir: 'desc', 
                search: searchQuery,  
                stages: '', 
                ratings: '',
                finalMatches: '',
                keywords: '',
            };
    
            dispatch(fetchApplicantsList(updatedParams));
        }
    };
    
    const applyFilters = useCallback((filters: IApplicantsFilter, doNotSaveFilters?: boolean) => {
        setSelectedFilters(filters);
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, undefined, filters, sortParams, searchKey);

        if (!doNotSaveFilters) {
            // Add filters into session storage.
            sessionStorage.setItem(jobId + '', JSON.stringify(filters));
        }
    }, [getApplicants, jobId, searchKey, sortParams]);

    const removeFilter = (filterType: FilterType, val: string) => {
        const tmpStages = [...selectedFilters[filterType]];
        const indx = tmpStages.map(ts => ts.value).indexOf(val);
        tmpStages.splice(indx, 1);
        const temp = { ...selectedFilters, [filterType]: tmpStages }
        setSelectedFilters(temp);
        sessionStorage.setItem(jobId + '', JSON.stringify(temp));
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, paginationParams.requestedPageSize, temp, sortParams, searchKey);
    };


    const searchInApplicants = (searchKey: string) => {
        getApplicants(paginationParams.requestedPageNumber, paginationParams.requestedPageSize, selectedFilters, sortParams, searchKey);
        setSearchKey(searchKey);
    };

    const sortList = (sortBy: ApplicantsSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        /*
           Call get applicants function with page number and size from pagination params.
       */
        getApplicants(paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize,
            selectedFilters,
            { order: order, orderBy: sortBy },
            searchKey);
    };

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [applicantId]);

    return (
        <> 
        <Fade in={true} timeout={800}>
            <Stack height='100%'>
                <Box overflow='auto' padding={0.5}>  
                    <ShPaper variant="outlined" >
                        <ApplicantsPipelineToolBar applicantList={applicantsList} applyFilters={applyFilters} selectedFilters={selectedFilters} 
                        removeFilter={removeFilter} searchInApplicants={searchInApplicants } searchKey={searchKey}  sortList={sortList} sortParams={sortParams}/>
                    {applicantsList?.length !== 0 ? 
                        <Stack direction='row' spacing={2} pt={2}>
                                <Stack direction={isSmScreen ? 'column' : 'row'}>
                                    <Stack rowGap={0.5} overflow='auto' maxHeight='100vh'>
                                        {getApplicantsListStatus === 'failed' && <Alert severity='error'>{getApplicantsListResponse}</Alert>}
                                        {getApplicantsListStatus === 'pending' ? (
                                            <Box marginTop={1} display='flex' justifyContent='center'>
                                                <LinearProgress color='primary' />
                                            </Box>
                                        ) : (
                                            applicantsList.map((applicant) => {
                                                const intervieweeInitials = getInitialsFromName(applicant.fullName || applicant.email || "SK", true, true);
                                                const avatarBackgroundColor = stringToColor(intervieweeInitials); 
                                                const { color } = getDotPropertiesAndMessage(applicant);

                                                return (
                                                    <Stack minWidth={isXsScreen ? 200 : 400}  maxWidth={isSmScreen ? '100%' : 400} key={applicant.id}>
                                                        <ShPaper variant='outlined' borderStyle='solid' width='100%' cursor="pointer"
                                                            borderColor={applicant.candidateEmployerJobId.toString() === applicantId ? ShGreen : 'none'}
                                                            onClick={() => navigate(`/employer/applicants-pipeline/${jobId}/applicant/${applicant.candidateEmployerJobId}/details`)}>
                                                            <Stack direction="row" spacing={2} padding={2} alignItems="center">
                                                                {/* Left Side: Applicant's Full Name and Information */}
                                                                <Stack direction="column" flex={1}>
                                                                    <Stack pb={2}>
                                                                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                                            <Stack direction='row' spacing={1} alignItems='center' pb={1}>
                                                                                <ShAvatar height={45} width={45} backgroundColor={avatarBackgroundColor}>
                                                                                    <Typography variant="h6">
                                                                                        {intervieweeInitials}
                                                                                    </Typography>
                                                                                </ShAvatar>
                                                                                {applicant.fullName && (
                                                                                    <Typography variant="body2" maxWidth={200}>
                                                                                        {applicant.fullName}
                                                                                    </Typography>
                                                                                )}
                                                                            </Stack>
                                                                            <Stack direction='row' spacing={1} alignItems='center'>
                                                                                <ShChip customSize='small' size='small' variant="outlined" borderRadius="25px"
                                                                                textColor={color}label={toTitleCase(applicant.jobStage)} hideBoxShadow/>
                                                                                {renderMatchChip(applicant?.matchType)}
                                                                            </Stack>
                                                                        </Stack>
                                                                        <Typography variant="caption" maxWidth={200} color='gray'>
                                                                            {applicant.email}
                                                                        </Typography>
                                                                    </Stack>

                                                                    {/* Application Date */}
                                                                    <Stack direction="row" alignItems="center" columnGap={1}>
                                                                        <Typography variant="body2" color="text.secondary">
                                                                            {moment(applicant.applicationCompletedAt).format('MMMM Do YYYY, h:mm A')}
                                                                        </Typography>
                                                                    </Stack>

                                                                    {/* Resume Score as Rating (Stars) */}
                                                                    <Stack direction="row" alignItems="center" columnGap={1} marginTop={1} justifyContent='space-between'>
                                                                        <ResumeScoreGauge score={applicant?.resumeScore} />

                                                                        {/* Bookmark Icon */}
                                                                        <IconButton onClick={()=>toggleBookmark(applicant.candidateEmployerJobId)}>
                                                                            {(bookmarks[applicant.candidateEmployerJobId]) ? (
                                                                            <BookmarkIcon color="warning"/>
                                                                            ) : (
                                                                            <BookmarkBorderIcon color='disabled' />
                                                                            )}
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Stack>
                                                            </Stack>
                                                        </ShPaper>
                                                    </Stack>
                                                );
                                            })
                                        )}

                                        {applicantsList && totalPages > 1 && (
                                            <Box display="flex" justifyContent="center" margin={2}>
                                                <Pagination
                                                    count={totalPages}
                                                    page={paginationParams?.requestedPageNumber || 1} 
                                                    onChange={handlePageChange}
                                                    color="primary"
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                </Stack>

                            <ShPaper variant='outlined' width='100%'>
                                <Outlet />
                            </ShPaper>
                        </Stack>
                    :
                    <Stack p={1} alignSelf='center' justifyContent='center' alignItems='center'>
                        <ShPaper variant='outlined' headerSection>
                            <Typography textAlign='center'> No Applicants Currently! Check back later.</Typography>
                        </ShPaper>
                    </Stack>
                    }
                </ShPaper>
                </Box>
            </Stack>
        </Fade>
        </>
    );
}
