import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Divider, Fade, Grid, IconButton, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import { compressImage } from 'helpers/fileHandlers';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShFileUploadButton, ShGreenBtn, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { getEmployerDetails } from 'store/slices/employer/employer/employer-details-slice';
import {
    getCompanyBanner,
    getCompanyInfo,
    getCompanyLogo,
    resetBannerUpdate,
    resetLogoUpdate,
    resetUpdateCompany,
    updateCompanyBanner,
    updateCompanyLogo,
} from 'store/slices/employer/settingsTs/company-info-slice';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ICareersPageDisplayInfo } from './CareersPageModel';

export const CareersPageImageCustomization = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { 
        getCompanyInfoApiStatus, companyInfo, companyBanner, getCompanyBannerApiResponse, getCompanyBannerApiStatus, companyLogo, updateCompanyInfoApiStatus, updateCompanyInfoApiResponse,
        updateCompanyLogoApiStatus, updateCompanyBannerApiStatus, updateCompanyBannerApiResponse, getCompanyLogoApiStatus,
        getCompanyLogoApiResponse, updateCompanyLogoApiResponse, getCompanyInfoApiResponse
    } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const { accountAccess, employerId } = useAppSelector((state) => state.auth.auth);
    const [_companyInfo, _setCompanyInfo] = useState<ICareersPageDisplayInfo>({
        avatar: '',
        website: '',
        company_logo: '',
        company_logo_url: '',
        company_banner: '',
        company_banner_url: '',
        masking_name: ''
    });
    
    
    const careersPageUrl = _companyInfo.masking_name ? `https://${_companyInfo.masking_name}.smoothhiring.com/careers`: "";
    
    const trimFileName = (fileName: string, maxLength: number) => {
        if (fileName?.length > maxLength) {
            return fileName?.substring(0, maxLength) + '...';
        }
        return fileName;
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(careersPageUrl);
    };

    // get company info
    useEffect(() => {
        if (!companyInfo.id && !companyInfo) {
            dispatch(getCompanyInfo());
        }

        return () => {
            // Reset update company info, logoAPIvariables on component unmount.
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        }
    }, [companyInfo, dispatch]);

    useEffect(() => {
        if (updateCompanyInfoApiStatus === 'success') {
            dispatch(getCompanyInfo());
            if (employerId) {
                dispatch(getEmployerDetails({ empId: employerId }));
            }
            dispatch(resetUpdateCompany());
        }
    }, [dispatch, employerId, updateCompanyInfoApiStatus]);
    
    // get company logo
    useEffect(() => {
        if (companyLogo?.length === 0) {
            dispatch(getCompanyLogo());
        }
    }, [companyLogo, dispatch]);

     // get company banner
     useEffect(() => {
        if (companyBanner?.length === 0 && companyInfo?.id !== 0) {
            dispatch(getCompanyBanner({ empId: companyInfo.id })); 
        }
    }, [companyBanner, dispatch, companyInfo]);

    // update company information form once data is retrieved
    useEffect(() => {
        if (getCompanyInfoApiStatus === 'success' && companyInfo !== undefined) {
            _setCompanyInfo({
                ...companyInfo,
                company_logo_url: companyLogo,
                company_banner_url: companyBanner,
                website: companyInfo.website
            });
        }
    }, [companyInfo, companyLogo, companyBanner, getCompanyInfoApiStatus]);

    useEffect(() => {
        if (accountAccess?.role !== 'admin' && accountAccess?.role !== 'owner') {
            navigate("/employer/dashboard");
        }
    }, [accountAccess?.role, navigate])

    const saveCompanyInfo = () => {
   
        // Compress the logo image and upload.
        if (_companyInfo.company_logo) {
            compressImage(_companyInfo.company_logo).then((cmpFile) => {
                // Pass image as form data with name 'logo'
                let formData = new FormData();
                formData.append("logo", cmpFile);
                dispatch(updateCompanyLogo({ payload: formData }));
            }).catch(err => console.error(err))
        }

        // Compress the banner image and upload.
        if (_companyInfo.company_banner) {
            compressImage(_companyInfo.company_banner, true).then((cmpFile) => {
                // Pass image as form data with name 'banner'
                let formData = new FormData();
                formData.append("banner", cmpFile);
                dispatch(updateCompanyBanner({ payload: formData }));
            }).catch(err => console.error(err))
        }
    };

    useEffect(() => {
        if (updateCompanyLogoApiStatus === 'success') {
            dispatch(getCompanyLogo());
        }
    }, [dispatch, updateCompanyLogoApiStatus]);

    useEffect(() => {
        if (_companyInfo.website?.trim()) {
            const defaultSubdomain = extractSubdomain(_companyInfo.website);
            _setCompanyInfo((prev) => ({
                ...prev,
                masking_name: defaultSubdomain,
            }));
        }
    }, [_companyInfo.website]);


    const Alerts = () => {
        switch (updateCompanyInfoApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Information...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetUpdateCompany())}>
                    <Typography fontWeight={600}>{updateCompanyInfoApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{updateCompanyInfoApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const AlertsForLogoUpdate = () => {
        switch (updateCompanyLogoApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Logo...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetLogoUpdate())}>
                    <Typography fontWeight={600}>{updateCompanyLogoApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error" onClose={() => dispatch(resetLogoUpdate())}>{updateCompanyLogoApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const AlertsForBannerUpdate = () => {
        switch (updateCompanyBannerApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Logo...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetLogoUpdate())}>
                    <Typography fontWeight={600}>{updateCompanyBannerApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error" onClose={() => dispatch(resetBannerUpdate())}>{updateCompanyBannerApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    const extractSubdomain = (website: string): string => {
        return website
            .replace(/^(https?:\/\/)?(www\.)?/, '') // Remove protocol (http, https) and www.
            .split('.')[0]; // Split by '.' and take the first part (domain name)
    };

    return (
        <>
            <Fade in={true} timeout={800}>
                <Stack spacing={1}>
                    <ShPaper variant='outlined' borderRadius={0} headerSection>
                        <Typography variant='subtitle1'> Customize Careers Page</Typography>
                        <Typography variant='caption' color='textSecondary'>    
                            Update careers page such as website, logo, subdomain, and other related details to keep your account up to date.
                        </Typography>
                    </ShPaper>
                    <ShPaper variant='outlined' noBorderTop>
                        <Stack direction='row' rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} minHeight='350px' maxWidth='1200px'>
                            {/* Alerts placeholder block with min height to maintain height consistency while showing alerts. */}
                            {getCompanyInfoApiStatus === 'failed' &&
                                <Box marginBottom={2}><ShAlert severity="error">{getCompanyInfoApiResponse}</ShAlert></Box>
                            }
                            {updateCompanyInfoApiStatus !== 'idle' && <Box marginBottom={2}>{Alerts()}</Box>}
                            {getCompanyInfoApiStatus === 'pending' ?
                                <LinearProgress /> :
                                    <>
                                        <Stack spacing={2}>
                                            <Grid container spacing={2} alignItems="stretch">

                                                <Grid item xs={12} sm={6} md={12}>
                                                    <ShTextFieldV2 label="Careers Page" variant="outlined" id="csd" size="small"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                    <a href={careersPageUrl} 
                                                                    target="_blank" 
                                                                    rel="noopener noreferrer" 
                                                                    style={{ textDecoration: 'none', color: '#417ee3' }}>
                                                                        {_companyInfo.masking_name ?? ''}.smoothhiring.com/careers
                                                                    </a>
                                                                </span>
                                                            ),
                                                            endAdornment: (
                                                                <>
                                                                    <IconButton size="small" onClick={handleCopyToClipboard}>
                                                                        <ContentCopyIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton size="small" component="a" href={careersPageUrl} target="_blank" rel="noopener noreferrer">
                                                                        <OpenInNewIcon fontSize="small" />
                                                                    </IconButton>
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                    
                                                </Grid>

                                                {/* Company Logo */}
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <Stack height="100%" spacing={2}>
                                                        <Typography variant='body2'> Upload Logo</Typography>
                                                        <Divider/>
                                                        <ShPaper variant="outlined" height="100%" borderColor={PrimaryThemeColor}  borderStyle="dashed" >
                                                            <Stack rowGap={1} alignItems="center">
                                                                {updateCompanyLogoApiStatus !== 'idle' && (
                                                                    <Box marginBottom={2}>{AlertsForLogoUpdate()}</Box>
                                                                )}
                                                                {(_companyInfo.avatar === undefined && _companyInfo?.company_logo === undefined) ? (
                                                                    <Typography variant="body2">(250px x 250px)</Typography>
                                                                ) : getCompanyLogoApiStatus === "pending" ? (
                                                                    <Skeleton width="100%" height="100%" animation="wave" />
                                                                ) : getCompanyLogoApiStatus === "success" && _companyInfo?.company_logo_url ? (
                                                                    <img 
                                                                        src={_companyInfo?.company_logo_url} 
                                                                        alt="Company Logo" 
                                                                        style={{ width: '250px', height: 'auto' }} 
                                                                    />
                                                                ) : getCompanyLogoApiStatus === "failed" && (
                                                                    <ShAlert severity="error">{getCompanyLogoApiResponse}</ShAlert>
                                                                )}
                                                            </Stack>
                                                        </ShPaper>
                                                        <ShFileUploadButton className="file-select-btn" component="label" variant="outlined">
                                                            <input 
                                                                accept="image/*" 
                                                                type="file" 
                                                                hidden 
                                                                onChange={(e) =>
                                                                    e.target.files !== null
                                                                        ? _setCompanyInfo({
                                                                            ..._companyInfo,
                                                                            company_logo_url: e.target.files[0]
                                                                                ? URL.createObjectURL(e.target.files[0])
                                                                                : _companyInfo?.company_logo_url,
                                                                            company_logo: e.target.files[0],
                                                                        })
                                                                        : ''
                                                                }
                                                            />
                                                            <CloudUploadIcon />
                                                            Upload Logo
                                                            {_companyInfo?.company_logo && (
                                                                <Typography variant="body2" fontStyle="italic" sx={{ marginLeft: '8px' }}>
                                                                    : {trimFileName(_companyInfo?.company_logo?.name, 20)}
                                                                </Typography>
                                                            )}
                                                        </ShFileUploadButton>
                                                    </Stack>
                                                </Grid>

                                                {/* Company Banner */}
                                                <Grid item xs={12} sm={6} md={8}>
                                                    <Stack height="100%" spacing={2}>
                                                        <Typography variant='body2'> Upload Banner</Typography>
                                                        <Divider/>
                                                        <ShPaper variant="outlined" height="100%"  borderColor={PrimaryThemeColor}  borderStyle="dashed"  >
                                                            <Stack rowGap={1} alignItems="center">
                                                                {updateCompanyBannerApiStatus !== 'idle' && (
                                                                    <Box marginBottom={2}>{AlertsForBannerUpdate()}</Box>
                                                                )}
                                                                {(_companyInfo.avatar === undefined && _companyInfo?.company_banner === undefined) ? (
                                                                    <Typography variant="body2">(1000px x 250px)</Typography>
                                                                ) : getCompanyBannerApiStatus === "pending" ? (
                                                                    <Skeleton width="100%" height="100%" animation="wave" />
                                                                ) : getCompanyBannerApiStatus === "success" && _companyInfo?.company_banner_url ? (
                                                                    <img 
                                                                        src={_companyInfo?.company_banner_url} 
                                                                        alt="Company Banner" 
                                                                        style={{ width: '100%', height: 'auto' }} 
                                                                    />
                                                                ) : getCompanyBannerApiStatus === "failed" && (
                                                                    <ShAlert severity="error">{getCompanyBannerApiResponse}</ShAlert>
                                                                )}
                                                            </Stack>
                                                        </ShPaper>
                                                        <ShFileUploadButton component="label" variant="outlined">
                                                            <input 
                                                                accept="image/*" 
                                                                type="file" 
                                                                hidden 
                                                                onChange={(e) => {
                                                                    if (e.target.files !== null) {
                                                                        const file = e.target.files[0];
                                                                        if (file) {
                                                                            if (file.size > 1024 * 1024) { 
                                                                                alert("File size exceeds 1MB. Please upload a smaller file.");
                                                                                return;
                                                                            }

                                                                            _setCompanyInfo({
                                                                                ..._companyInfo,
                                                                                company_banner_url: URL.createObjectURL(file),
                                                                                company_banner: file,
                                                                            });
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <CloudUploadIcon />
                                                            Upload Banner
                                                            {_companyInfo?.company_banner && (
                                                                <Typography variant="body2" fontStyle="italic" sx={{ marginLeft: '8px' }}>
                                                                    : {trimFileName(_companyInfo?.company_banner?.name, 15)}
                                                                </Typography>
                                                            )}
                                                        </ShFileUploadButton>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </>
                            }
                        </Stack>
                    </ShPaper>
                    <ShGreenBtn disableElevation variant='contained'  onClick={saveCompanyInfo} disabled={updateCompanyInfoApiStatus === 'pending'}
                        startIcon={
                            updateCompanyInfoApiStatus === 'pending' 
                            ? <CircularProgress size={20} color="inherit" /> 
                            : <SaveIcon />
                        }
                        >
                        {updateCompanyInfoApiStatus === 'pending' ? 'Saving...' : 'Save Changes'}
                    </ShGreenBtn>
                </Stack>
            </Fade>
        </>
    );
};



