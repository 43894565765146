import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConstructionIcon from '@mui/icons-material/Construction';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, IconButton, ListSubheader, Stack, Tooltip, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DefaultRoleForRAC, RoleAccessControlMap } from 'Modules/Core/AuthV2/AuthConstants';
import { IsSmScreen, useAppSelector } from 'helpers/hooks';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ISideNav } from 'shared/SharedModels';
import { ShListItemButton } from 'shared/SharedStyles/ShDataDisplay';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ToolsSideNavItems } from '../SettingsConstants';
import { IToolsSideNavItem } from '../SettingsModel';

export const ToolsAndAutomationSideNav = ({ toggleDrawer, isMiniDrawer, toggleMiniDrawer }: ISideNav) => {

    const { accountAccess } = useAppSelector((state) => state.auth.auth);
    const isSmScreen = IsSmScreen();
    const getDefaultSubMenuToggleState = (navItems: IToolsSideNavItem[]): { [key: string]: boolean } => {
    const toggleState: { [key: string]: boolean } = {};
    const setSubMenusOpen = (items: IToolsSideNavItem[]) => {
            items.forEach(item => {
                if (item.subMenuItems) {
                    toggleState[item.label] = true; // Set all menus & submenus
                    setSubMenusOpen(item.subMenuItems); // Recurse for nested submenus
                }
            });
        };
        setSubMenusOpen(navItems);
        return toggleState;
    };
    const [toolsSideNavToggleMap, setToolsSideNavToggleMap] = useState<{ [key: string]: boolean }>(() =>
        getDefaultSubMenuToggleState(ToolsSideNavItems)
    );

    // Toggle a specific item's open/close state
    const toggleSubMenu = (label: string) => {
        setToolsSideNavToggleMap((prev) => ({
            ...prev,
            [label]: !prev[label],
        }));
    };


    const LabelWithDynamicTooltip = (item: IToolsSideNavItem) => {
        return (<>
            {isMiniDrawer ?
                <Tooltip placement='right-end' title={item.label} arrow>
                    <Stack direction='row'>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {item.subMenuItems &&
                            (toolsSideNavToggleMap[item.label] ? <ExpandLess /> : <ExpandMore />)}
                    </Stack>
                </Tooltip> :
                <>
                    <Stack direction='row' justifyContent='space-between' width={'100%'} alignItems='center'>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={<Typography variant='body2' marginLeft={1}>{item.label}</Typography>} />
                        {item.subMenuItems &&
                            (toolsSideNavToggleMap[item.label] ? <ExpandLess /> : <ExpandMore />)}
                    </Stack>

                </>}
        </>)
    };

    // Recursive function to render menu and submenus
    const renderNavItems = (navItems: IToolsSideNavItem[], level: number = 0) => {
        return navItems.filter(item => RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]?.featureMap
            ?.[item.feature]?.visibility !== 'hidden').map((item) => (
                <Box key={item.label} paddingLeft={isSmScreen ? 1 : 0}>
                    {item.subMenuItems ?
                        <ShListItemButton
                            sx={{ pl: isMiniDrawer ? 0 : level * 2 }} // Increase padding based on depth level
                            onClick={() => item.subMenuItems ? toggleSubMenu(item.label) : toggleDrawer}
                        >
                            {LabelWithDynamicTooltip(item)}
                        </ShListItemButton> : <>
                            <ShListItemButton sx={{ pl: isMiniDrawer ? 0 : level * 2 }} >
                                <ShMuiLink component={RouterLink} to={item.redirectTo} aria-label={item.label} key={item.label}
                                    noBlueHighlight noUnderline width={'100%'}>
                                    {LabelWithDynamicTooltip(item)}
                                </ShMuiLink>
                            </ShListItemButton>
                        </>}
                    {item.subMenuItems && (
                        <Collapse in={toolsSideNavToggleMap[item.label]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {renderNavItems(item.subMenuItems, level + 1)}
                            </List>
                        </Collapse>
                    )}
                </Box>
            ));
    };

    const RenderSubHeader = () => {
        if (isSmScreen) {
            return (<ListSubheader component="div" id="nested-list-subheader">
                Tools & Automation
            </ListSubheader>)
        } else {
            return (<>
                {isMiniDrawer ? <IconButton onClick={toggleMiniDrawer}><ConstructionIcon /><ChevronRightIcon /></IconButton> :
                    <ListSubheader component="div" id="nested-list-subheader">
                        Tools & Automation <IconButton onClick={toggleMiniDrawer}><ChevronLeftIcon /></IconButton>
                    </ListSubheader>}
            </>)
        }
    }

    return <List component="nav" subheader={RenderSubHeader()}>{renderNavItems(ToolsSideNavItems)}</List>;
};
