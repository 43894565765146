import { Stack, Toolbar, Typography } from "@mui/material";
import { ContactInfo } from 'shared/SharedComponents/ContactInfo';
import { DesktopOnlyStack } from 'shared/SharedStyles/ShLayouts';
import { ShAppBar } from 'shared/SharedStyles/ShSurfaces';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CandidateFooter = () => {

    return (
        <ShAppBar position="sticky">
            <Toolbar>
                <Stack width='100%' direction='row' justifyContent='right' alignItems='center'>
                    <Stack direction='row' alignItems='center'>
                        <DesktopOnlyStack direction='row' columnGap={1}> 
                            <InfoOutlinedIcon fontSize="small" color="action" />
                            <Typography variant="body2"> 
                                For technical support only—please do not send resumes or job applications.
                            </Typography>
                            <ContactInfo showDivider dividerOrientation='vertical' /> 
                        </DesktopOnlyStack>
                    </Stack>
                </Stack>
            </Toolbar>
        </ShAppBar>
    );
};