import { SiInstagram } from "react-icons/si";
import { FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

export const fontFamilies = [
    // System Fonts (Web Safe)
    'Arial', 'Verdana', 'Times New Roman', 'Georgia', 'Courier New', 'Tahoma', 
    'Trebuchet MS', 'Lucida Console', 'Impact', 'Comic Sans MS', 'Segoe UI', 
    'Helvetica', 'Gill Sans', 'Palatino', 'Garamond', 'Franklin Gothic Medium', 
    'Century Gothic', 'Candara', 'Bookman', 'Arial Black',

    // Google Fonts 
    'Roboto', 'Montserrat', 'Open Sans', 'Lato', 'Poppins', 'Raleway', 
    'Merriweather', 'Playfair Display', 'Source Sans Pro', 'Ubuntu', 'Nunito', 
    'Quicksand', 'Work Sans', 'Oswald', 'Fira Sans', 'Droid Sans', 'Cairo', 
    'Hind', 'Titillium Web', 'Barlow', 'Jost', 'Manrope', 'Exo', 'Overpass', 
    'Josefin Sans', 'Zilla Slab', 'Cabin', 'Chivo', 'Heebo', 'Mulish', 'Mukta', 
    'Tajawal', 'Spectral', 'Karla', 'Libre Franklin', 'IBM Plex Sans', 'Noto Sans', 
    'Space Grotesk', 'DM Sans', 'Inter', 'PT Sans', 'PT Serif', 'Vollkorn', 
    'Red Hat Display', 'Asap', 'Maven Pro', 'Signika', 'Teko', 'Bai Jamjuree', 
    'Catamaran', 'Fjalla One', 'Arvo', 'Anton', 'Barlow Condensed', 'Bebas Neue', 
    'Chakra Petch', 'Cormorant', 'Crimson Text', 'EB Garamond', 'Hammersmith One', 
    'Josefin Slab', 'Khand', 'Merriweather Sans', 'Nanum Gothic', 'Neuton', 
    'Oxygen', 'Ropa Sans', 'Saira', 'Sarabun', 'Satisfy', 'Sora', 'Tinos', 'Varela Round', 
    'Yanone Kaffeesatz', 'ZCOOL XiaoWei'
];

export const headingSizes = [
    { label: 'H1', value: 'h1' },
    { label: 'H2', value: 'h2' },
    { label: 'H3', value: 'h3' },
    { label: 'H4', value: 'h4' },
    { label: 'H5', value: 'h5' },
    { label: 'H6', value: 'h6' },
];

export const headingFontWeights = [
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' },
    { label: '900', value: '900' },
];

export  const iconMap: { [key in 'facebook' | 'twitter' | 'linkedin' | 'instagram']: JSX.Element } = {
    facebook: <FacebookIcon size={32}/>,
    twitter: <TwitterIcon size={32} />,
    linkedin: <LinkedinIcon size={32} />,
    instagram: <SiInstagram size={32} />,
};