import React, { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  CircularProgress,
  Typography,
  Chip,
  Rating,
} from '@mui/material';
import { InterviewDialogStyled } from 'Modules/Core/Interviews/Interviews.styled';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getInterviewDetails } from 'store/slices/employer/interviews/interviews-details-slice';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { getEmployerDetails } from 'store/slices/employer/employer/employer-details-slice';
import { isEmpty } from 'lodash';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import ViewDateTimeDetails from './Components/ViewDateTimeDetails';
import {
  SupportAgent,
  CheckCircle,
  Cancel,
  Schedule,
  DateRange,
  Feedback,
  VideoCameraFront,
  ContentPasteSearch,
  LocationOn,
  Phone,
  Person,
  WorkOutline,
  PersonOutline,
  People,
} from '@mui/icons-material';
import {
  IInterviewDetails,
  InterviewStatus,
  StatusLookup,
} from './InterviewsModel';

const ratingLookup: { [index: number]: string } = {
  1: 'Very Poor',
  2: 'Poor',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

const interviewTypeIcons: {
  [key in IInterviewDetails['type_of_interview']]: React.ReactNode;
} = {
  Video: <VideoCameraFront color="primary" />,
  'In-person': <LocationOn color="primary" />,
  Phone: <Phone color="primary" />,
};

const statusIconLookup: Record<InterviewStatus, StatusLookup> = {
  Requested: { icon: <SupportAgent color="info" />, color: 'info' },
  Completed: { icon: <CheckCircle color="success" />, color: 'success' },
  Accepted: { icon: <SupportAgent color="info" />, color: 'info' },
  Cancelled: { icon: <Cancel color="error" />, color: 'error' },
  Scheduled: { icon: <Schedule color="secondary" />, color: 'secondary' },
};

interface ViewInterviewDetailsPopupProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  interviewId: number | undefined;
  applicantName: string;
  employerJobName: string;
}

const ViewInterviewDetailsPopup: React.FC<ViewInterviewDetailsPopupProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  interviewId,
  applicantName,
  employerJobName,
}) => {
  const dispatch = useAppDispatch();
  const isSmScreen = IsSmScreen();
  const { interviewDetails, getInterviewDetailsStatus } = useAppSelector(
    (state) => state.employer.interviews.interviewsDetails
  );
  const { employerDetails } = useAppSelector(
    (state) => state.employer.employerDetails
  );

  useEffect(() => {
    if (interviewId) {
      dispatch(getInterviewDetails({ id: interviewId }));
    }
  }, [dispatch, interviewId]);

  useEffect(() => {
    if (interviewDetails?.employer_id && isEmpty(employerDetails)) {
      dispatch(getEmployerDetails({ empId: interviewDetails?.employer_id }));
    }
  }, [dispatch, interviewDetails?.employer_id, employerDetails]);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <InterviewDialogStyled
      open={isDialogOpen}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="interview-details-title"
      fullScreen={isSmScreen}
    >
      <DialogTitle
        id="interview-details-title"
        textAlign="center"
        fontWeight={600}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          columnGap={1}
        >
          <Box>{`Interview Details for ${applicantName}`}</Box>
          <IconButton onClick={closeDialog}>
            <ClearIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        {getInterviewDetailsStatus === 'pending' ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : interviewDetails ? (
          <Stack spacing={1}>
            <ShPaper variant="outlined">
              
                <Stack direction="row" spacing={1}>
               
                  <Stack direction="row" alignItems="center">
                    <PersonOutline color="primary" />
                  </Stack>
                  <Stack direction="row" spacing={5} alignItems="center" justifyContent="space-between" width="50%"> 
                  <Stack direction="column">
                    <Typography variant="body1" color="black" fontWeight={600}>
                    Candidate Name
                    </Typography>
                    <Typography variant="body2" color={'gray'}>
            {applicantName}
          </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" spacing={1}>
                <Stack direction="row" alignItems="center">
          <WorkOutline color="primary" />
        </Stack>
                  
                  <Stack direction="column">
                    <Typography variant="body1" color="black" fontWeight={600}>
                    Job Title
                    </Typography>
                    <Typography variant="body2" color={'gray'}>
            {employerJobName}
          </Typography>
          </Stack>
                  </Stack>
                </Stack>
             
            </ShPaper>

            <ShPaper variant="outlined">
              <Stack spacing={1}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="body1" color="black" fontWeight={600}>
                    Status
                  </Typography>
                  <Chip
                    icon={statusIconLookup[interviewDetails?.status].icon}
                    label={interviewDetails.status}
                    size="small"
                    color={statusIconLookup[interviewDetails?.status].color}
                    variant="outlined"
                  />
                </Stack>

                {interviewDetails.interview_rating && (
                  <Stack>
                    <Typography variant="body2" color="black" fontWeight={600}>
                      Interview Rating
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Rating
                        value={
                          Number(
                            Object.keys(ratingLookup).find(
                              (key) =>
                                ratingLookup[Number(key)] ===
                                interviewDetails.interview_rating
                            )
                          ) || 0
                        }
                        size="medium"
                        readOnly
                      />
                      {interviewDetails.interview_rating !== null && (
                        <Typography variant="body2" color={'gray'}>
                          {interviewDetails.interview_rating}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </ShPaper>

            {interviewDetails.status === 'Completed' && (
              <ViewDateTimeDetails
                date={interviewDetails.schedule_date}
                from_time={interviewDetails.from_time}
                timezone={interviewDetails.timezone}
                to_time={interviewDetails.to_time}
                updated_at={interviewDetails.updated_at}
              />
            )}

            {interviewDetails.status === 'Requested' && (
              <ShPaper variant="outlined">
                <Stack gap={1}>
                  <Stack direction="row" spacing={1}>
                    <Stack direction="row" alignItems="center">
                      <DateRange color="primary" />
                    </Stack>
                    <Typography variant="body1" color="black" fontWeight={600}>
                      Requested times
                    </Typography>
                  </Stack>
                  <Stack gap={1}>
                    {interviewDetails.request_time_slots?.map(
                      (timeSlot, index) => (
                        <ViewDateTimeDetails
                          key={index}
                          date={timeSlot.date}
                          from_time={timeSlot.from_time}
                          timezone={timeSlot.timezone}
                          to_time={timeSlot.to_time}
                        />
                      )
                    )}
                  </Stack>
                </Stack>
              </ShPaper>
            )}

            {interviewDetails.status === 'Completed' && (
              <ShPaper variant="outlined">
                <Stack spacing={2}>
                  <Box>
                    <Stack direction="row" spacing={1}>
                      <Stack alignItems="center" direction="row">
                        <Feedback color="primary" />
                      </Stack>
                      <Typography
                        variant="body1"
                        color="black"
                        fontWeight={600}
                      >
                        Feedback
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <Person color="primary" />
                      </Stack>
                      <Stack direction="column">
                        <Typography variant="body2" color={'gray'}>
                          {`${employerDetails?.first_name} ${employerDetails?.last_name}`}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          fontWeight={200}
                          fontSize={10}
                        >
                          Feedback given by
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack>
                      {interviewDetails.feedback ? (
                        interviewDetails.feedback
                          .split('\n')
                          .map((line, index) => (
                            <Typography
                              variant="body2"
                              color={'gray'}
                              key={index}
                            >
                              {line}
                            </Typography>
                          ))
                      ) : (
                        <Typography variant="body2" color={'gray'}>
                          No feedback provided
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </ShPaper>
            )}

            <ShPaper variant="outlined">
              <Stack direction="column">
                <Stack direction="row" spacing={1}>
                  <Stack alignItems="center" direction="row">
                    <ContentPasteSearch color="primary" />
                  </Stack>
                  <Typography variant="body1" color="black" fontWeight={600}>
                    Interview Type
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {interviewTypeIcons[interviewDetails.type_of_interview]}
                  <Typography variant="body2" color={'gray'}>
                    {interviewDetails.type_of_interview}
                  </Typography>
                </Stack>
              </Stack>
              {interviewDetails.type_of_interview === 'Video' && (
                <Typography variant="body2" color="textSecondary" marginTop={1}>
                  {interviewDetails.zoom_meeting ? (
                    <>Source: Zoom</>
                  ) : (
                    <>Source: Other Video Link</>
                  )}
                </Typography>
              )}
              {interviewDetails.type_of_interview === 'Phone' && (
                <Typography variant="body2" color="textSecondary" marginTop={1}>
                  {interviewDetails.additional_interview_info ||
                    'No phone number provided'}
                </Typography>
              )}
              {interviewDetails.type_of_interview === 'In-person' && (
                <Typography variant="body2" color="textSecondary" marginTop={1}>
                  Address:{' '}
                  {interviewDetails.additional_interview_info
                    ? interviewDetails.additional_interview_info
                    : 'No address provided'}
                </Typography>
              )}
            </ShPaper>

            {interviewDetails.status === 'Completed' && (
              <ShPaper>
                <Stack direction="row" spacing={1}>
                  <Stack alignItems="center" direction="row">
                    <People color="primary" />
                  </Stack>
                  <Typography variant="body1" color="black" fontWeight={600}>
                    Attendees
                  </Typography>
                </Stack>

                <Stack
                  marginTop={1}
                  direction="row"
                  columnGap={1}
                  flexWrap="wrap"
                  rowGap={1}
                >
                  {interviewDetails.additional_attendees_email &&
                  interviewDetails.additional_attendees_email.length > 0 ? (
                    interviewDetails.additional_attendees_email.map(
                      (email, index) => (
                        <Chip
                          key={index}
                          label={
                            <Typography color="textSecondary" fontSize={14}>
                              {email}
                            </Typography>
                          }
                          size="small"
                          icon={<Person color="primary" fontSize="small" />}
                        />
                      )
                    )
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No attendees available
                    </Typography>
                  )}
                </Stack>
              </ShPaper>
            )}
          </Stack>
        ) : (
          <Typography>No interview details available.</Typography>
        )}
      </DialogContent>

      <DialogActions>
        <ShGreenBtn
          variant="contained"
          disableElevation
          size="small"
          onClick={closeDialog}
          className="self-right"
        >
          Close
        </ShGreenBtn>
      </DialogActions>
    </InterviewDialogStyled>
  );
};

export default ViewInterviewDetailsPopup;
