import { Typography, Stack, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { SUBSCRIPTION_EMPLOYER_PRICING_GROUP } from "../SettingsTs/AccountSettings/Subscriptions/SubscriptionsConstants";
import { getCompanyInfo } from "store/slices/employer/settingsTs/company-info-slice";
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BannerContainer, BannerButton } from "./Banner.styled";

export const SubscriptionBanner = () => {
    const dispatch = useAppDispatch();
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const { companyInfo } = useAppSelector(state => state.employer.employerSettings.companyInfo);
    const [isLoading, setIsLoading] = useState(true); 

    useEffect(() => {
        if (!companyInfo || !companyInfo.id) {
            dispatch(getCompanyInfo()).finally(() => setIsLoading(false)); 
        } else {
            setIsLoading(false);
        }
    }, [companyInfo, dispatch]);

    if (isLoading || !companyInfo || !employerDetails) {
        return null;
    }

    return (
        <>
            {employerDetails?.employer_pricing_group_id === SUBSCRIPTION_EMPLOYER_PRICING_GROUP &&
                !companyInfo.subscription_plan && (
                    <Stack pt={1} pb={1} pl={1} pr={1}>
                        <BannerContainer>
                            <Typography fontSize={14} textAlign="center">
                            ⚡ You're One Step Away from Hiring Top Talent! Post your job to 100's of job boards. Don't miss out!
                            </Typography>
                            <Box pl={2}>
                                <BannerButton variant="contained" color="secondary" size="small" endIcon={<ChevronRightIcon />}
                                    component={RouterLink} to={`/employer/settings/account/subscriptions#view-subscriptions`}>
                                    View Plans
                                </BannerButton>
                            </Box>
                        </BannerContainer>
                    </Stack>
                )}
        </>
    );
};
