import { Stack, Typography } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const CustomHiringPipelineHeader = () => {
  return (
    <ShPaper variant="outlined" borderRadius={0} headerSection>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={1}>
          <Typography variant="subtitle1" gutterBottom paddingLeft={2}>
            Create Custom Hiring Pipelines (Coming Soon!)
          </Typography>
          <Typography variant="body2" color="textSecondary" paddingLeft={2}>
            Create your own custom hiring pipeline.
          </Typography>
        </Stack>
      </Stack>
    </ShPaper>
  );
};
