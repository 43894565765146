import { IBreadcrumbDictionary } from "store/slices/app/app-model";

export const CreateJobBreadcrumbRegex = /\/employer\/job\/\d+\/(title|details|preview|bundles|payment|payment\/success|payment\/failed)/;

export const JobsBreadcrumbRegex = /\/employer\/jobs\/(active|expired|draft)/;

export const ApplicantsBreadcrumbRegex = /\/employer\/applicants\/\d+/;

export const ApplicantDetailsBreadcrumbRegex = /\/employer\/job\/\d+\/applicant\/\d+\/details(#(profile|resume|comments|questions|stage))?/;

export const IncompleteApplicantsBreadcrumbRegex = /\/employer\/incomplete-applicants\/\d+/;

export const DefaultBreadcrumb: IBreadcrumbDictionary = {
    pathname: '/employer/dashboard',
    breadcrumbs: [
        {
            displayName: 'Home',
            href: '/employer/dashboard',
            isActive: true
        }
    ]
};

export const BreadcrumbDictionary: IBreadcrumbDictionary[] = [
    {
        pathname: '/employer/dashboard',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/job/new/title',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Job Creation',
                href: '',
                isActive: false
            },
            {
                displayName: 'Job Details',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/jobs/active',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Jobs',
                href: '',
                isActive: false
            },
            {
                displayName: 'Active',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/jobs/draft',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Jobs',
                href: '',
                isActive: false
            },
            {
                displayName: 'Draft',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/jobs/expired',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Jobs',
                href: '',
                isActive: false
            },
            {
                displayName: 'Expired',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/interviews/requested',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Interviews',
                href: '',
                isActive: false
            },
            {
                displayName: 'Requested',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/interviews/scheduled',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Interviews',
                href: '',
                isActive: false
            },
            {
                displayName: 'Scheduled',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/interviews/completed',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Interviews',
                href: '',
                isActive: false
            },
            {
                displayName: 'Completed',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/interviews/cancelled',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Interviews',
                href: '',
                isActive: false
            },
            {
                displayName: 'Cancelled',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/calendar',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Calendar',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources/ai-interview-kit',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Interview Kit Creator',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources/ai-job-description-generator',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Job Description Generator',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources/job-description-templates',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Job Description Templates',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources/offer-letter-templates',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Offer Letter Templates',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/resources/rejection-letter-templates',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Rejection Letter Templates',
                href: '',
                isActive: false
            }
        ]
    },

    {
        pathname: '/employer/resources/interview-letter-templates',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Interview Letter Templates',
                href: '',
                isActive: false
            }
        ]
    },

    {
        pathname: '/employer/resources/policy-templates',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Resources',
                href: '/employer/resources',
                isActive: true
            },
            {
                displayName: 'Policy Templates Library',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/account-information',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Account Information',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/company-information',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Company Information',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/access-levels',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Access Levels',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/security',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Security',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/branding',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Branding',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/settings/account/integrations',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Settings',
                href: '',
                isActive: false
            },
            {
                displayName: 'Integrations',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/help',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Help',
                href: '',
                isActive: false
            }
        ]
    },
    {
        pathname: '/employer/create-task',
        breadcrumbs: [
            {
                displayName: 'Home',
                href: '/employer/dashboard',
                isActive: true
            },
            {
                displayName: 'Create Task',
                href: '',
                isActive: false
            }
        ]
    }
];