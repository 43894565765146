import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import {
  CreateTaskPayload,
  GetAllTasksResponse,
  GetUnreadTaskCountResponse,
  InitialCreateTaskState,
} from './create-task-model';
import { AxiosResponse } from 'axios';

const InitialNotificationStates: InitialCreateTaskState = {
  isNotificationEnabled: false,
  createTaskApiStatus: 'idle',
  getUnreadTaskCountApiStatus: 'idle',
  getAllTasksApiStatus: 'idle',
};

export const createTask = createAsyncThunk<
  { message: string },
  CreateTaskPayload,
  { rejectValue: { message: string } }
>('createTask', async (payload, { rejectWithValue }) => {
  const response = await httpAdapterInstance
    .post(EmployerApiEndpoints.CREATE_TASK, payload)
    .then((response) => response?.data)
    .catch((error) => {
      throw rejectWithValue(error.response.data);
    });
  return response;
});

export const getUnreadTaskCount = createAsyncThunk<
  GetUnreadTaskCountResponse,
  void,
  { rejectValue: IBaseResponse }
>('getUnreadTaskCount', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetUnreadTaskCountResponse> =
      await httpAdapterInstance.get(EmployerApiEndpoints.UNREAD_TASK_COUNT);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllTasks = createAsyncThunk<
  GetAllTasksResponse,
  void,
  { rejectValue: IBaseResponse }
>('getAllTasks', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetAllTasksResponse> =
      await httpAdapterInstance.get(EmployerApiEndpoints.ALL_TASKS);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const createTaskSlice = createSlice({
  name: 'notifications',
  initialState: InitialNotificationStates,
  reducers: {
    clearCreateTaskStatus: (state) => {
      state.createTaskApiStatus = 'idle';
    },
    toggleNotification: (state) => {
      state.isNotificationEnabled = !state.isNotificationEnabled;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTask.pending, (state) => {
      state.createTaskApiStatus = 'pending';
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.createTaskApiStatus = 'success';
      state.createTaskResponse = action.payload.message;
    });
    builder.addCase(createTask.rejected, (state, action) => {
      state.createTaskApiStatus = 'failed';
      state.createTaskResponse = action?.payload?.message;
    });

    //unread-task-count
    builder.addCase(getUnreadTaskCount.pending, (state) => {
      state.getUnreadTaskCountApiStatus = 'pending';
    });
    builder.addCase(getUnreadTaskCount.fulfilled, (state, action) => {
      state.getUnreadTaskCountApiStatus = 'success';
      state.getUnreadTaskCountResponse = action.payload.count;
    });
    builder.addCase(getUnreadTaskCount.rejected, (state, action) => {
      state.getUnreadTaskCountApiStatus = 'failed';
    });

    //get-all-tasks
    builder.addCase(getAllTasks.pending, (state) => {
      state.getAllTasksApiStatus = 'pending';
    });
    builder.addCase(getAllTasks.fulfilled, (state, action) => {
      state.getAllTasksApiStatus = 'success';
      state.getAllTasksResponse = action.payload.data;
    });
    builder.addCase(getAllTasks.rejected, (state, action) => {
      state.getAllTasksApiStatus = 'failed';
    });
  },
});

export const { clearCreateTaskStatus, toggleNotification } =
  createTaskSlice.actions;
export default createTaskSlice;
