import { useState } from "react";
import { Tab, Box, Fade, Stack } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { DashboardQuickActionTilesWidget } from "./DashboardQuickActionTilesWidget";
import { DashboardToolsTilesWidget } from "./DashboardToolsTilesWidget";
import { Home, Build } from '@mui/icons-material';
import { DashboardCareersPageTileWidget } from "./DashboardCareersPageTilesWidget";
import WebIcon from '@mui/icons-material/Web';
import { ShTabs } from "shared/SharedStyles/ShNavigation";

export const DashboardQuickActionTiles = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ShPaper height="100%" marginBottom="10px" variant="outlined" borderRadius={15}>
      <Stack spacing={2}>
        <ShTabs highlightVariant="underline" value={activeTab} onChange={handleTabChange} variant="scrollable">
          <Tab
            icon={<Home color='primary'/>}
            iconPosition="start"
            label="Get Started"
          />
          <Tab
            icon={<Build  color='primary' />}
            iconPosition="start"
            label="Automation & Tools"
          />
          <Tab
            icon={<WebIcon  color='primary' />}
            iconPosition="start"
            label="Careers page"
          />
        </ShTabs>
        <Box>
          <Fade in={activeTab === 0} timeout={1000}>
            <Box>
              {activeTab === 0 && <DashboardQuickActionTilesWidget />}
            </Box>
          </Fade>
          <Fade in={activeTab === 1} timeout={1000}>
            <Box>
              {activeTab === 1 && <DashboardToolsTilesWidget />}
            </Box>
          </Fade>
          <Fade in={activeTab === 2} timeout={1000}>
            <Box>
              {activeTab === 2 && <DashboardCareersPageTileWidget />}
            </Box>
          </Fade>
        </Box>
      </Stack>
    </ShPaper>
  );
};
