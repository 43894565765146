import { Box, Grid, Stack, styled } from '@mui/material';
import {
  DarkModeBackground,
  ShLightGradientToLeft,
} from 'shared/SharedStyles/styleConstants';

export const CandidateWrapperBox = styled(Box)(({ theme }) => ({
  flex: 1,
  background:
    theme.palette.mode === 'light' ? ShLightGradientToLeft : DarkModeBackground,
}));

export const GridWithBorder = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})<{ showBorder?: boolean }>(({ showBorder }) => ({
  border: showBorder ? '1px solid rgb(223, 218, 218)' : 'none',
  borderRadius: showBorder ? '12px' : '0',
}));

export const ErrorContainer = styled(Stack)((theme) => ({
  backgroundColor: '#f8d7da',
  color: '#721c24',
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}));
