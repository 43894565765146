import { Divider, Stack } from "@mui/material"
import { CandidateWrapperBox } from "Modules/Core/Candidate/Candidate.styled"
import { Notification } from "Modules/Core/Notification"
import { Outlet } from "react-router-dom"
import { ContactInfo } from "shared/SharedComponents/ContactInfo"
import { MobileOnlyStack } from "shared/SharedStyles/ShLayouts"
import { CandidateTopNav } from "./CandidateTopNav"
import { CandidateFooter } from "./CandidateFooter"

export const CandidateLayoutWrapper = () => {
    return (<>
        <Notification />
        <Stack height='100vh'>
            <CandidateTopNav />
            <CandidateWrapperBox>
                <Outlet />
            </CandidateWrapperBox>
            {/* Contact info */}
            <MobileOnlyStack padding={2} rowGap={1}>
                <Divider />
                <ContactInfo showDivider={false} />
            </MobileOnlyStack>
            <CandidateFooter />
        </Stack>
    </>)
}