import { Typography, Grid, Stack, Divider, Badge, Fade, FormControlLabel, Box, LinearProgress } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import CheckIcon from '@mui/icons-material/Check';
import { ShGradientButton } from 'shared/SharedStyles/ShInputs';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star'; // Star icon
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IBundle } from 'Modules/Core/CreateJob/CreateJob/CreateJobModel';
import { allSubscriptionBundles } from './SubscriptionsConstants';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { ShSwitch } from 'shared/SharedStyles/ShNavigation';
import { purchaseSubscription } from 'store/slices/employer/create-job/create-job-payments-slice';
import SubscriptionsCrossTable from './SubscriptionsCrossTable';

export const Subscriptions = () => {
    const dispatch = useAppDispatch();
    const { employerId } = useAppSelector((state) => state.auth.auth);
    const [isYearly, setIsYearly] = useState(false);
    const {purchaseJobStatus, purchaseUrl } = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const [shouldNavigate, setShouldNavigate] = useState(false);

    const getPricingLabel = (bundle: IBundle) => {
        if (bundle.base_price > 0 && bundle.yearlyPrice) {
            const price = isYearly ? bundle.yearlyPrice / 12 : bundle.base_price;
            return `$${price.toFixed(2)} ${isYearly ? '/mo' : '/mo'}`;
        }
    
        return null;
    };

    const handleToggle = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsYearly(event.target.checked);
    };

   const handlePlanSelect = async (planCode: string, isYearly: boolean) => {
        if (employerId) {
            await dispatch(purchaseSubscription({ employerId, planCode, isYearly }));
        }
        setShouldNavigate(true);
    };

    useEffect(() => {
        if (shouldNavigate && purchaseUrl) {
            window.location.href = purchaseUrl;
        }
    }, [shouldNavigate, purchaseUrl, purchaseJobStatus]);


  return (
    <>
    <Fade in={true} timeout={800}>
        <ShPaper variant='outlined'>
            {purchaseJobStatus === 'pending' && <LinearProgress/>}
            <ShContainer margin='auto' maxWidth='xl'>
                <Stack direction="row" alignItems="center" spacing={1} pb={2} justifyContent="center">
                    <LoyaltyIcon color="primary" />
                    <Typography variant="h6" gutterBottom>Subscription Plans</Typography>
                </Stack>

                    <Stack pb={2} spacing={2} alignItems="center" justifyContent="center">
                        <Stack direction='row' spacing={2}>
                            <Typography variant='body1' color={!isYearly ? '#28A745' : 'black'}> Monthly </Typography>
                            <FormControlLabel
                                control={<ShSwitch checked={isYearly} onChange={handleToggle} />}
                                label=""
                            />
                            <Typography variant='body1' color={isYearly ? '#28A745' : 'black'}> Yearly </Typography>
                        </Stack>
                        <ShChip label="Save 20% with yearly" bgColor="#28A745" textColor="#FFFFFF" />
                    </Stack>

                    <Stack direction='row' justifyContent='center'>
                        <Stack >
                            <Divider/>
                            <Grid container columnGap={2} pt={3} justifyContent='center' spacing={1}> 
                                {allSubscriptionBundles.map((bundle) => {
                                    const combinedFeatures = [
                                        ...(bundle.features_included?.split(',').map(feature => ({ feature: feature.trim(), included: true })) || []),
                                        ...(bundle.features_excluded?.split(',').map(feature => ({ feature: feature.trim(), included: false })) || [])
                                    ];

                                    return (
                                        <Grid item xs={12} md={2.5} key={bundle.id} rowGap={1} columnGap={1}>
                                            <Badge
                                                color="primary"
                                                badgeContent={
                                                    bundle.is_featured ? (
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <StarIcon color='inherit' fontSize="small" />
                                                            <Typography variant="caption" fontWeight="bold">
                                                                RECOMMENDED
                                                            </Typography>
                                                        </Stack>
                                                    ) : null
                                                }
                                                invisible={!bundle.is_featured}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                sx={{ 
                                                    "& .MuiBadge-badge": 
                                                        { fontSize: 12, height: 25, minWidth: 150, borderRadius:35, ml:10} 
                                                }}>
                                                <ShPaper 
                                                    minHeight={575}
                                                    sx={{display: 'flex', flexDirection: 'column'}}
                                                    variant='outlined'   
                                                    borderRadius={15}>
                                                    <Stack spacing={2} flexGrow={1} textAlign='center' alignItems='center'>
                                                        <Typography fontSize={34} component="div" gutterBottom>
                                                            {bundle.name}
                                                        </Typography>
                                                        {bundle.base_price > 0 &&
                                                            <Typography variant="h4" sx={{fontSize:'18px'}} fontWeight='bold'>
                                                                {getPricingLabel(bundle)}
                                                            </Typography>
                                                        }
                                                         {/* <Typography variant="h4" fontWeight="bold">
                                                           ${isYearly ? (bundle.yearlyPrice ? bundle.yearlyPrice / 12 : 0).toFixed(2): bundle.base_price}
                                                            <Typography variant="body1" component="span">
                                                                / month
                                                            </Typography>
                                                        </Typography> */}
                                                        <Box>
                                                            <ShChip
                                                                label={`$${isYearly ? (bundle.yearlyPrice) + ' billed annually' : (bundle.base_price * 12).toFixed(2) + '/yearly'}`}
                                                                bgColor={isYearly ? '#28A745' : '#007BFF'} 
                                                                textColor="#FFFFFF"
                                                                size="small" 
                                                                borderRadius="20px"
                                                                sx={{ fontWeight: 'bold', padding: '6px 12px' }}
                                                            />
                                                        </Box>
                                                
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                                        {bundle.description}
                                                    </Typography>
                                                    </Stack>
                                                    <Stack alignItems='center' pt={1}>
                                                        <ShChip color='primary' bgColor='#417ee3' textColor='#FFFFFF' label='Features' maxWidth='150px' size='small' borderRadius='0px'/>
                                                        <ShPaper variant='outlined' borderRadius={15} sx={{ border: '1px solid #417ee3',}}>
                                                            <Grid container spacing={2}>
                                                            {combinedFeatures.slice(0, 5).map((item, index) => (
                                                                    <Grid item xs={12} key={index}>
                                                                        <Stack direction="row" alignItems="center">
                                                                            {item.included ? (
                                                                                <CheckIcon color="success" />
                                                                            ) : (
                                                                                <CloseOutlinedIcon color="error" />
                                                                            )}
                                                                            <Typography ml={1} fontSize={12} textAlign='left'>
                                                                                {item.feature}
                                                                            </Typography>
                                                                        </Stack>
                                                                        <Divider orientation='vertical' flexItem />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </ShPaper>
                                                    </Stack>
                                                    <Stack pt={2} color='white'>
                                                        <ShGradientButton
                                                            onClick={() => handlePlanSelect(bundle.code, isYearly)} 
                                                            textColor="white" color="primary" size="small" variant="contained" fullWidth borderRadius={25} startIcon={<AddShoppingCartIcon />}>
                                                            Choose {isYearly ? 'Yearly':'Monthly'} Plan
                                                        </ShGradientButton>
                                                    </Stack>
                                                </ShPaper>
                                            </Badge>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </ShContainer>

            <SubscriptionsCrossTable/>
        </ShPaper>
    </Fade> 
    </>
  );
};
