import { Typography, Stack, Grid, Box } from "@mui/material";
import { EEOQutionaareData } from "Modules/Core/CreateJob/EEO/EEOConfig";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchCandidateProfileEEOQuestionsAnswers } from "store/slices/employer/applicants/applicant-profile-slice";

export const ApplicantEEO = () => {
    const { applicantDetails, eeoQuestionAndAnswers } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const dispatch = useAppDispatch();
  
    useEffect(() => {
      if (applicantDetails?.candidateId) {
        dispatch(fetchCandidateProfileEEOQuestionsAnswers({ candidateId: applicantDetails.candidateId }));
      }
    }, [dispatch, applicantDetails?.candidateId]);
  
    // If no responses are available
    if (!eeoQuestionAndAnswers || Object.keys(eeoQuestionAndAnswers).length === 0) {
      return <Typography variant="body2">No responses recorded for EEO questions.</Typography>;
    }
  
    return (

      <Stack>
        <ShPaper variant="outlined" headerSection borderRadius={0}>
          <Typography variant="body2" gutterBottom>
             Equal Employment Opportunity (EEO) Responses
          </Typography>
        </ShPaper>        
        <ShPaper variant="outlined" noBorderTop borderRadius={0}>
          <Stack spacing={3}>
            {Object.entries(eeoQuestionAndAnswers).map(([idx, answer], index) => {
              // Find the corresponding question based on idx
              const question = EEOQutionaareData.find((q) => q.idx === parseInt(idx));

              return (
                <ShPaper key={index} variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {question?.label}
                      </Typography>
                      <Box sx={{ mt: 1 }}>
                      <ShChip
                            label={answer || "No response provided"}
                            color={answer ? "primary" : "default"}
                            size="small"
                            />
                      </Box>
                    </Grid>
                  </Grid>
                </ShPaper>
              );
            })}
          </Stack>
        </ShPaper>
    </Stack>
    );
  };