import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from '@mui/icons-material/Schedule';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {
    Alert,
    Box,
    CardHeader,
    Checkbox,
    CircularProgress,
    Fade,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Skeleton,
    Stack,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ShCareersPageButton, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { toTitleCase, truncateText } from "shared/utils";
import { getCareerPageDetails, getCareersPageCompanyBanner, getEmployerPostedJobs } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { createCustomCareersPageEmployerTheme } from './CareersPageCustomTheme';
import { ICareerPage } from '../EmployerSubDomainLayout/EmployerSubDomainModel';
import { iconMap } from 'Modules/Core/SettingsTs/AccountSettings/CareersPage/CareersPageConstants';

export const CareerPagesHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerPostedJobs, companyLogo, getCompanyLogoApiStatus, getCompanyLogoApiResponse, employerByMask, getEmployerPostedJobsStatus, getEmployerPostedJobsResponse, companyBanner, getCompanyBannerApiStatus, getCompanyBannerApiResponse} = useAppSelector(state => state.employer.employerSubDomain);
    const { careerPage} = useAppSelector(state => state.employer.employerSubDomain);
    const {jobDetailsInInfo} = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const [careerPageDetails, setCareerPageDetails] = useState<ICareerPage>({
        employerId: 0,
        typography: undefined, 
        heading_size: undefined,
        heading_font_weight: undefined, 
        primary_color: undefined, 
        secondary_color: undefined, 
        tertiary_color: undefined, 
        cta_text: '', 
        cta_link: '', 
        seo_title: '', 
        seo_description: '', 
        social_links: {
            linkedin: '', 
            facebook: '', 
            twitter: '',
            instagram: '', 
        },
    });    
    const [theme, setTheme] = useState(createCustomCareersPageEmployerTheme(careerPageDetails));

    useEffect(() => {
        if(careerPage){
            if(careerPage){
                setCareerPageDetails(careerPage)
            }
            setTheme(createCustomCareersPageEmployerTheme(careerPage));
        }
    }, [careerPage]); 

    useEffect(() => {
        document.title = careerPageDetails.seo_title || `${employerByMask?.company_name} Careers Page`;
    
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute("content", careerPageDetails.seo_description || "");
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = careerPageDetails.seo_description || "";
            document.head.appendChild(meta);
        }
    
        if (companyLogo) {
            let favicon = document.querySelector("link[rel='icon']") || document.createElement("link");
            favicon.setAttribute("rel", "icon");
            favicon.setAttribute("type", "image/png");
            favicon.setAttribute("href", companyLogo); 
            document.head.appendChild(favicon);
        }
    }, [careerPageDetails.seo_title,  careerPageDetails.seo_description,  employerByMask?.company_name, companyLogo]);
    
    const [searchInput, setSearchInput] = useState(''); 
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOption, setSortOption] = useState('recent');
    const [selectedCities, setSelectedCities] = useState<string[]>([]);
    const [openCityFilter, setOpenCityFilter] = useState(false);
    const [defaultJobId, setDefaultJobId] = useState<string>('');
    const [pageSize] = useState(8);
    const filteredJobs = employerPostedJobs?.filter(job =>
        job.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCities.length > 0 ? selectedCities.includes(job.city) : true)
    ) || [];
    const sortedJobs = [...filteredJobs].sort((a, b) => {
        if (sortOption === 'recent') {
            return new Date(b.starts_at).getTime() - new Date(a.starts_at).getTime();
        } else if (sortOption === 'oldest') {
            return new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime();
        } else if (sortOption === 'city') {
            return a.city.localeCompare(b.city);
        }
        return 0;
    });
    const cities = [...new Set(employerPostedJobs?.map(job => job.city).filter(city => city.trim() !== '') || [])];
    const handleCityToggle = (city: string) => {
        setSelectedCities(prevSelectedCities =>
            prevSelectedCities.includes(city)
                ? prevSelectedCities.filter(c => c !== city)
                : [...prevSelectedCities, city]
        );
    };
    const jobsToDisplay = sortedJobs.length > 0 ? sortedJobs : employerPostedJobs || [];
    const totalPages = Math.ceil(jobsToDisplay.length / pageSize);
    const paginatedJobs = jobsToDisplay.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const { jobId } = useParams(); 
    const jobExists = employerPostedJobs?.some((job) => job.id.toString() === jobId);
    const initialNavigationDone = useRef(false);


    const handleSearch = () => {
        setSearchQuery(searchInput);
    };

    useEffect(() => {
        if (jobId !== undefined && !isNaN(parseInt(jobId))) {
            dispatch(getJobDetails({ jobId: parseInt(jobId) }));
        }
    }, [dispatch, jobId]);

    useEffect(() => {
        if (employerPostedJobs && employerPostedJobs?.length > 0 && !jobId) {
            const sortedJobs = [...employerPostedJobs].sort((a, b) => {
                return new Date(b.starts_at).getTime() - new Date(a.starts_at).getTime();
            });
            
            setDefaultJobId(sortedJobs[0]?.id.toString()); 
        
            if (defaultJobId) {
                dispatch(getJobDetails({ jobId: parseInt(defaultJobId) }));
                navigate(`${defaultJobId}`);
            }
        }
    }, [employerPostedJobs, isSmScreen, navigate, dispatch, jobId]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Get jobs list on page load.
    useEffect(() => {
        if (employerByMask?.id) {
            dispatch(getEmployerPostedJobs({ employerId: employerByMask?.id }))
        }
    }, [dispatch, employerByMask?.id]);

    useEffect(() => {
        if (employerByMask?.id) {
            dispatch(getCareerPageDetails({ employerId: employerByMask?.id }))
           
        }
    }, [dispatch, employerByMask?.id]);

      useEffect(() => {
          if (!isSmScreen && employerPostedJobs && employerPostedJobs.length > 0 && !initialNavigationDone.current) {
              const firstJobId = employerPostedJobs[0]?.id.toString();
              dispatch(getJobDetails({ jobId: parseInt(firstJobId) }));
              navigate(`/careers/${firstJobId}`);
              initialNavigationDone.current = true; 
          }
      }, [employerPostedJobs, isSmScreen, dispatch, navigate]);

       // get company banner
     useEffect(() => {
        if (employerByMask?.id && companyBanner?.length === 0 &&  employerByMask?.id !== 0) {
            dispatch(getCareersPageCompanyBanner({ empId: employerByMask?.id })); 
        }
    }, [companyBanner, dispatch, employerByMask]);

    return (
        <>
            <ThemeProvider theme={theme}>
                <Fade in={true} timeout={800}>
                        <Stack height='100%'>
                            <Box overflow='auto' padding={1}>  
                                {companyBanner &&
                                    <Box width='100%' padding={2} justifyContent='center' overflow='hidden' sx={{ 
                                        height: { xs: '150px', sm: '200px', md: '250px' }, 
                                    }}>
                                        {(companyBanner === undefined) ?
                                            <Typography variant='body2'>(250px in 250px)</Typography> :
                                            getCompanyBannerApiStatus === 'pending' ?
                                                <Skeleton width='25%' height='100%' animation='wave' /> :
                                                getCompanyBannerApiStatus === 'success' && companyBanner ?
                                                    <a href={`https://${employerByMask?.website}`}>
                                                        <img src={companyBanner} alt="Company Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                    </a>
                                                    :
                                                    <>
                                                        {getCompanyBannerApiStatus === 'failed' &&
                                                            <ShAlert severity="error">
                                                                {getCompanyBannerApiResponse}
                                                            </ShAlert>}
                                                    </>
                                            }
                                    </Box>
                                }

                                <ShContainer maxWidth='xl' margin='auto'>
                                    <Box marginY={1}>
                                        <Box alignItems="center" justifyContent='center'>
                                            <ShPaper variant="outlined">
                                                <Stack direction={isSmScreen ? 'column' : 'row'} justifyContent='space-between' spacing={2}>
                                                    <Stack flex={1} maxWidth={400} direction='row' spacing={2}>
                                                        <ShTextFieldV2 size="small" label="Search for Jobs" variant="outlined" fullWidth
                                                            value={searchInput} 
                                                            onChange={(e) => setSearchInput(e.target.value)}/>
                                                        <ShCareersPageButton bgColor={careerPageDetails.primary_color || ShGreen}  onClick={handleSearch}>Search </ShCareersPageButton>
                                                    </Stack>
                                                    <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2}>
                                                        <Box marginTop={2} maxWidth={400} minWidth={250}>
                                                            <FormControl id="city-filter-label" variant="outlined" fullWidth size="small">
                                                                <InputLabel>Filter by City</InputLabel>
                                                                <Select
                                                                    labelId="city-filter-label"
                                                                    multiple
                                                                    open={openCityFilter}
                                                                    onOpen={() => setOpenCityFilter(true)}
                                                                    onClose={() => setOpenCityFilter(false)}
                                                                    value={selectedCities}
                                                                    input={
                                                                        <OutlinedInput
                                                                            startAdornment={
                                                                                <InputAdornment position="start">
                                                                                    <TuneOutlinedIcon />
                                                                                </InputAdornment>
                                                                            }
                                                                            label="Filter by City"
                                                                        />
                                                                    }
                                                                    renderValue={(selected) => (
                                                                        <Box>
                                                                            {selected.join(', ').length === 2 ? 'Filter by City' : truncateText(selected.join(', '), 20)}
                                                                        </Box>
                                                                    )}>
                                                                    {cities.length > 0 ? (
                                                                        cities.map(city => (
                                                                            <MenuItem
                                                                                key={city}
                                                                                value={city}
                                                                                onClick={() => handleCityToggle(city)}
                                                                            >
                                                                                <Checkbox
                                                                                    checked={selectedCities.includes(city)}
                                                                                />
                                                                                <Typography variant="body2">
                                                                                    {city}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))
                                                                    ) : (
                                                                        <MenuItem value="" disabled>No Cities Available</MenuItem>
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <Box flex={1} maxWidth={250} minWidth={150}>
                                                            <FormControl variant="outlined" fullWidth size="small">
                                                                <InputLabel> Sort By</InputLabel>
                                                                <Select
                                                                    input={
                                                                        <OutlinedInput
                                                                            startAdornment={
                                                                                <InputAdornment position="start">
                                                                                    <SwapVertOutlinedIcon />
                                                                                </InputAdornment>
                                                                            }
                                                                            label="Sort By"
                                                                        />
                                                                    }
                                                                    value={sortOption} onChange={(e) => setSortOption(e.target.value)} label="Sort By" >
                                                                    <MenuItem value="recent">Recent</MenuItem>
                                                                    <MenuItem value="oldest">Oldest</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>

                                                        <Stack direction="row" spacing={2} alignItems="center">
                                                            {['facebook', 'twitter', 'linkedin', 'instagram'].map((key) => {
                                                                let link = careerPageDetails.social_links?.[key]; 

                                                                // Ensure the link starts with http:// or https://
                                                                if (link && !link.startsWith('http://') && !link.startsWith('https://')) {
                                                                    link = `https://${link}`;
                                                                }

                                                                return link ? (
                                                                    <a key={key} href={link} target="_blank" rel="noopener noreferrer">
                                                                        {iconMap[key as 'facebook' | 'twitter' | 'linkedin' | 'instagram']}
                                                                    </a>
                                                                ) : null;
                                                            })}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            </ShPaper>
                                        </Box>
                                        {employerPostedJobs ? 
                                            <Stack direction={isSmScreen ? 'column' : 'row'} >
                                                <Stack marginTop={1} rowGap={2} >
                                                    <Typography textAlign='center' variant="subtitle1" paddingTop={1}> {filteredJobs?.length} Jobs found</Typography>
                                                    {getEmployerPostedJobsStatus === 'failed' && <Alert severity='error'>{getEmployerPostedJobsResponse}</Alert>}
                                                    {getEmployerPostedJobsStatus === 'pending' ?
                                                        <Box marginTop={2} width='100%' display='flex' justifyContent='center'><CircularProgress size={100} color='success' /></Box> :
                                                        paginatedJobs?.map(job =>
                                                            <Stack minWidth={isXsScreen ? 200 : 450} maxWidth={isSmScreen ? '100%' : 500} key={job.id}>
                                                                <ShPaper borderColor={careerPageDetails.secondary_color || ShGreen} borderStyle={jobId?.toString() === job.id.toString() ? 'solid' : ''}
                                                                    variant='outlined' key={job.id} sx={{ width: '100%' }} transElevateOnHover onClick={() => navigate(`/careers/${job.id}`)}>
                                                                    <CardHeader
                                                                        action={
                                                                            <ShCareersPageButton variant='contained' size='small' bgColor={careerPageDetails.secondary_color || ShGreen}
                                                                            href={`https://app.smoothhiring.com/candidate/apply-job/${jobDetailsInInfo?.code}`} endIcon= {<ArrowForwardIcon fontSize='small'/>}>
                                                                                Apply Now
                                                                            </ShCareersPageButton>
                                                                        }
                                                                        title={
                                                                            <Typography variant="body2" paddingBottom={1} maxWidth={200}>
                                                                                {job.name}
                                                                            </Typography>
                                                                        }
                                                                        subheader={
                                                                            <>
                                                                                <Stack direction='row' alignItems='center' columnGap={1}>
                                                                                    <ScheduleIcon fontSize='small' />
                                                                                    <Typography variant="body2" color="text.secondary" alignItems='center' justifyContent='center'>
                                                                                        {toTitleCase(moment(job.starts_at).fromNow())}
                                                                                    </Typography>
                                                                                </Stack>
                                                                                <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                                                                    <PlaceIcon fontSize='small' />
                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                        {job.city && job.state ? `${job.city}, ${job.state}` : "Remote"}
                                                                                    </Typography>
                                                                                </Stack>
                                                                            </>
                                                                        }
                                                                    />
                                                                </ShPaper>
                                                            </Stack>
                                                        )}
                                                    {!employerPostedJobs &&
                                                        <ShPaper variant="outlined">
                                                            No Jobs Currently.
                                                        </ShPaper>
                                                    }
                                                    {employerPostedJobs && totalPages > 1 && (
                                                        <Box display="flex" justifyContent="center" margin={2}>
                                                            <Pagination
                                                                count={totalPages}
                                                                page={currentPage}
                                                                onChange={(e, value) => setCurrentPage(value)}
                                                                color="primary"
                                                            />
                                                        </Box>
                                                    )}
                                                </Stack>
                                                {!isSmScreen &&
                                                    <Stack paddingTop={1} rowGap={2} width='100%'>
                                                            {jobExists ? (
                                                                <Outlet />
                                                            ) : (
                                                                <Box display="flex" alignItems="center" justifyContent="center" height="100vh" textAlign="center">
                                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                                        <WarningAmberIcon sx={{ color: 'orange', fontSize: 25 }} />
                                                                        <Typography>Whoops, Job does not exist or is expired</Typography>
                                                                    </Stack>
                                                                </Box>
                                                            )}
                                                    </Stack>
                                                }
                                            </Stack>
                                        :
                                        <Stack p={1} alignSelf='center' justifyContent='center' alignItems='center'>
                                            <ShPaper variant='outlined' headerSection >
                                                {(companyLogo === undefined) ?
                                                        <Typography variant='body2'>(250px in 250px)</Typography> :
                                                        getCompanyLogoApiStatus === 'pending' ?
                                                            <Skeleton width='25%' height='100%' animation='wave' /> :
                                                            getCompanyLogoApiStatus === 'success' && companyLogo ?
                                                                <a href={`https://${employerByMask?.website}`}>
                                                                    <img src={companyLogo} alt="Company Logo" style={{ width: '100%', height: 'auto'}} />
                                                                </a>
                                                                :
                                                                <>
                                                                    {getCompanyLogoApiStatus === 'failed' &&
                                                                        <ShAlert severity="error">
                                                                            {getCompanyLogoApiResponse}
                                                                        </ShAlert>}
                                                                </>
                                                    }
                                                    <Typography textAlign='center'> No Jobs Currently! Check back later.</Typography>
                                            </ShPaper>
                                        </Stack>
                                        }
                                    </Box>
                                </ShContainer>
                            </Box>
                        </Stack>
                </Fade>
            </ThemeProvider>
        </>
    );
}