/* eslint-disable */
import { useState } from "react";
import { Stack } from "@mui/material";
import { CustomHiringPipelineHeader } from "./CustomHiringPipelineHeader";

const initialPipelines = [
    {
        id: "pipeline_1",
        name: "Default Pipeline",
        stages: [
            { label: "New", value: "new" },
            { label: "Not Qualified", value: "not_qualified" },
            { label: "Phone Screening", value: "phone_screening" },
            { label: "Interview", value: "interview" },
            { label: "Final Interview", value: "second_interview" },
            { label: "Job Offered", value: "offer" },
            { label: "Rejected", value: "rejected" },
            { label: "Hired", value: "hired" },
        ],
    },
];

export const CustomHiringPipeline = () => {
    const [pipelines, setPipelines] = useState(initialPipelines);
    const [open, setOpen] = useState(false);
    const [newPipelineName, setNewPipelineName] = useState("");
    const [newStageName, setNewStageName] = useState("");
    const [selectedPipelineId, setSelectedPipelineId] = useState<string | null>(null);
    const [stageDialogOpen, setStageDialogOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false); // To handle alert visibility

    const handleAddPipeline = () => {
        if (newPipelineName.trim()) {
            setPipelines([
                ...pipelines,
                {
                    id: `pipeline_${pipelines.length + 1}`,
                    name: newPipelineName,
                    stages: [],
                },
            ]);
            setNewPipelineName("");
            setOpen(false);
        }
    };

    const handleAddStage = () => {
        if (newStageName.trim() && selectedPipelineId) {
            // Check for duplicate stages
            const pipeline = pipelines.find(p => p.id === selectedPipelineId);
            const stageExists = pipeline?.stages.some(
                (stage) => stage.label.toLowerCase() === newStageName.toLowerCase()
            );

            if (stageExists) {
                setAlertOpen(true); // Show the alert if stage already exists
                return; // Don't add the stage if it already exists
            }

            // If no duplicate, proceed to add the stage
            setPipelines(
                pipelines.map((pipeline) =>
                    pipeline.id === selectedPipelineId
                        ? {
                              ...pipeline,
                              stages: [
                                  ...pipeline.stages,
                                  { label: newStageName, value: newStageName.toLowerCase().replace(/\s+/g, "_") },
                              ],
                          }
                        : pipeline
                )
            );
            setNewStageName("");
            setStageDialogOpen(false);
        }
    };

    const handleDeleteStage = (pipelineId: string, stageValue: string) => {
        setPipelines(
            pipelines.map((pipeline) =>
                pipeline.id === pipelineId
                    ? {
                          ...pipeline,
                          stages: pipeline.stages.filter((stage) => stage.value !== stageValue),
                      }
                    : pipeline
            )
        );
    };

    const handleDragEnd = (pipelineId: string, result: any) => {
        if (!result.destination) return;
        const updatedPipelines = pipelines.map((pipeline) => {
            if (pipeline.id === pipelineId) {
                const reorderedStages = Array.from(pipeline.stages);
                const [movedStage] = reorderedStages.splice(result.source.index, 1);
                reorderedStages.splice(result.destination.index, 0, movedStage);
                return { ...pipeline, stages: reorderedStages };
            }
            return pipeline;
        });
        setPipelines(updatedPipelines);
    };

    return (
        <Stack>
            <CustomHiringPipelineHeader/>
            {/* <Stack spacing={1}>
                {pipelines.map((pipeline) => (
                    <>
                        <ShPaper key={pipeline.id} variant="outlined" borderRadius={2}>
                            <Stack direction='row' spacing={2} alignItems='center' pb={2}>
                                <Typography variant="body2" gutterBottom>{pipeline.name}</Typography>
                                <Chip
                                    icon={<AddIcon />}
                                    size='small'
                                    label="Add Stage"
                                    color="primary"
                                    clickable
                                    variant="outlined"
                                    onClick={() => {
                                        setSelectedPipelineId(pipeline.id);
                                        setStageDialogOpen(true);
                                    }}
                                />
                            </Stack>
                            <Divider flexItem/>
                            <Stack pt={1}>
                            <DragDropContext onDragEnd={(result) => handleDragEnd(pipeline.id, result)}>
                                <Droppable droppableId={pipeline.id} direction="horizontal">
                                    {(provided) => (
                                        <Grid container spacing={2} ref={provided.innerRef}
                                            {...provided.droppableProps} direction="row">
                                            {pipeline.stages.map((stage, index) => (
                                                <Grid item xs={2} key={stage.value}>
                                                    <Draggable draggableId={stage.value} index={index}>
                                                        {(provided) => (
                                                            <ShPaper
                                                                borderColor={shBlue}
                                                                borderStyle="dotted"
                                                                variant="outlined"
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}>
                                                                <Stack
                                                                    spacing={1}
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <DragIndicator color="primary" fontSize="small" />
                                                                    <Typography variant="body2">{stage.label}</Typography>
                                                                    <IconButton
                                                                        onClick={() => handleDeleteStage(pipeline.id, stage.value)}
                                                                    >
                                                                        <Delete fontSize="small" color="error" />
                                                                    </IconButton>
                                                                </Stack>
                                                            </ShPaper>
                                                        )}
                                                    </Draggable>
                                                </Grid>
                                            ))}
                                            {provided.placeholder}
                                        </Grid>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            </Stack>
                        </ShPaper>
                    </>
                ))}
                <Box height={175} flexDirection="column" display="flex" alignItems="center" justifyContent="center" border="2px dashed #74C05A" onClick={() => setOpen(true)}>
                    <Typography variant="subtitle2">Add a Hiring Pipeline</Typography>
                    <IconButton onClick={() => setOpen(true)} color="success" size='large'>
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Stack>
            <ShDialog open={open} onClose={() => setOpen(false)}>
                <ShPaper variant="outlined" headerSection borderRadius={0}>
                    <Typography> Add New Pipeline </Typography>
                </ShPaper>
                <DialogContent>
                    <ShTextFieldV2
                        fullWidth
                        label="Pipeline Name"
                        variant="outlined"
                        value={newPipelineName}
                        onChange={(e) => setNewPipelineName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <ShButton onClick={() => setOpen(false)}>Cancel</ShButton>
                    <ShButton onClick={handleAddPipeline} variant="contained">
                        Add
                    </ShButton>
                </DialogActions>
            </ShDialog>
            <ShDialog open={stageDialogOpen} onClose={() => setStageDialogOpen(false)}>
                <Stack spacing={2}>
                    <ShPaper variant="outlined" headerSection>
                        <Typography variant="body1"> Add New Stage </Typography>
                    </ShPaper>
                    <DialogContent>
                        <ShTextFieldV2
                            fullWidth
                            label="Stage Name"
                            variant="outlined"
                            value={newStageName}
                            onChange={(e) => setNewStageName(e.target.value)}
                        />
                        {alertOpen && (
                            <Alert severity="error" sx={{ marginTop: 2 }}>
                                Stage already exists!
                            </Alert>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ShButton onClick={() => setStageDialogOpen(false)}>Cancel</ShButton>
                        <ShButton onClick={handleAddStage} variant="contained">
                            Add
                        </ShButton>
                    </DialogActions>
                </Stack>
            </ShDialog> */}
        </Stack>
    );
};
