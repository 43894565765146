import { useCallback, useEffect, useState } from "react";
import { Stack, Typography, CircularProgress, Alert, LinearProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { fetchAndGenerateCandidateAiSummary, resetAiCandidateSummary } from "store/slices/employer/applicants/applicants-list-slice";
import { ApplicantsAiCandidateSummary } from "./ApplicantsAiSummaryCard";

export const ApplicantAiSummary = () => {
    const dispatch = useAppDispatch();
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { aiCandidateSummary, generateCandidateAiSummaryStatus } = useAppSelector((state) => state.employer.applicants.applicantsList);
    const [alertMessage, setAlertMessage] = useState("");
    const [ellipsis, setEllipsis] = useState("...");
    const [showAlert, setShowAlert] = useState(true);
   
    const handleGenerateSummary = useCallback(() => {
        if (!applicantDetails?.resumeId || !applicantDetails?.candidateEmployerJobId) {
            setAlertMessage("Missing required parameters.");
            return;
        }

        dispatch(
            fetchAndGenerateCandidateAiSummary({
                resume_id: applicantDetails.resumeId,
                candidate_id: applicantDetails.candidateId,
                candidate_employerjobs_id: applicantDetails.candidateEmployerJobId
            })
        );
    }, [applicantDetails, dispatch]); 

    useEffect(() => {
        if (generateCandidateAiSummaryStatus === "pending") {
            setAlertMessage("Generating Smart View...");
        } else if (generateCandidateAiSummaryStatus === "success") {
            setAlertMessage("Smart View generated successfully!");
        } else if (generateCandidateAiSummaryStatus === "failed") {
            setAlertMessage("Error generating Smart View. Please try again later.");
        }
    }, [generateCandidateAiSummaryStatus, dispatch, handleGenerateSummary]); // Add dispatch and handleGenerateSummary as dependencies

    useEffect(() => {
        if (applicantDetails && applicantDetails.resumeId && applicantDetails.candidateEmployerJobId) {
            handleGenerateSummary();
        }
        return () => {
            dispatch(resetAiCandidateSummary());
        };
    }, [applicantDetails, dispatch, handleGenerateSummary]); 

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined;
        if (generateCandidateAiSummaryStatus === "pending") {
            interval = setInterval(() => {
                setEllipsis((prev) => {
                    if (prev === "...") return ".";
                    if (prev === "..") return "...";
                    return "..";
                });
            }, 500); 
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval); 
    }, [generateCandidateAiSummaryStatus]);

    useEffect(() => {
        if (generateCandidateAiSummaryStatus === 'success') {
            const timer = setTimeout(() => setShowAlert(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [generateCandidateAiSummaryStatus]);

    return (
        <Stack spacing={2}>
            {showAlert && alertMessage && <Alert severity={generateCandidateAiSummaryStatus === 'success' ? 'success' : 'error'}>{alertMessage}</Alert>}
            <ShPaper variant="outlined" headerSection borderRadius={0}>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ShChip customSize="xs" bgColor={ShGreen} textColor="white" label="NEW" />
                        <Typography variant="body2">Smart Summary</Typography>
                    </Stack>
                    {!aiCandidateSummary &&
                        <ShButton
                            variant="contained"
                            size="small"
                            onClick={handleGenerateSummary}
                            disabled={generateCandidateAiSummaryStatus === "pending"}>
                            {generateCandidateAiSummaryStatus === "pending" ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                "Generate Smart View"
                            )}
                        </ShButton>
                    }
                </Stack>
            </ShPaper>
            {generateCandidateAiSummaryStatus === "pending" && (
                <Stack spacing={1} direction='row' alignItems='center' justifyContent="center" marginTop={2}>
                    <Typography variant="body1">Loading{ellipsis}</Typography>
                    <LinearProgress color="primary" sx={{ width: "100%" }} />
                </Stack>
            )}
            {aiCandidateSummary && <ApplicantsAiCandidateSummary candidate={aiCandidateSummary} />}
        </Stack>
    );
};
