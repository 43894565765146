import dayjs from "dayjs";
import { IGeneratedTimeInterval, InterviewStatus } from "./InterviewsModel";
import { lighten } from "@mui/material";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { WhiteColor, PrimaryWordpressThemeColor } from "shared/SharedStyles/styleConstants";
import DuoIcon from '@mui/icons-material/Duo';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import { CheckCircle, Cancel, AccessTime, HourglassEmpty, ThumbUp } from '@mui/icons-material';

/*
* Creates to and from times based on an interval
*/
export const generateTimeIntervals = (stepMinutes: number): IGeneratedTimeInterval[] => {
    const intervals: IGeneratedTimeInterval[] = [];
    let start = dayjs().startOf('day');
    const end = dayjs().endOf('day');
    if (stepMinutes) {
        while (start <= end) {
            const startTime = start.format('h:mm A');
            const endTime = start.add(stepMinutes, 'minute').format('h:mm A');

            const interval: IGeneratedTimeInterval = {
                startTime: start.format('HH:mm'),
                endTime: start.add(stepMinutes, 'minute').format('HH:mm'),
                timeInterval: `${startTime} - ${endTime}`
            }
            intervals.push(interval);
            start = start.add(stepMinutes, 'minute');
        }
    }
    return intervals;
};

// Get the  interval to the current time
// export const getNoonInterval = (intervals: string[]) => {
//     const noon = dayjs().startOf('day').add(12, 'hour').format('h:mm A');
//     const closestInterval = intervals.find(interval => interval.startsWith(noon)) || intervals[0];
//     return closestInterval;
// };

export const getScheduledInterval = (intervals: string[]) => {
    const scheduledTime = dayjs().hour(12).minute(15).format('h:mm A');
    const closestInterval = intervals.find(interval => interval.startsWith(scheduledTime)) || intervals[0];
    return closestInterval;
}

export const getInterviewTypeChip = (interviewsState: String) => {
    switch (interviewsState) {
        case 'In-person':
            return (
                <ShChip
                    icon={<PersonIcon style={{ color: `${WhiteColor}` }} />}
                    size='small'
                    label="In-person"
                    textColor={WhiteColor}
                    bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                />
            );
        case 'Phone':
            return (
                <ShChip
                    icon={<LocalPhoneIcon style={{ color: `${WhiteColor}` }} />}
                    size='small'
                    label="Phone"
                    textColor={WhiteColor}
                    bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                />
            );
        case 'Video':
            return (
                <ShChip
                    icon={<DuoIcon style={{ color: `${WhiteColor}` }} />}
                    size='small'
                    label="Video"
                    textColor={WhiteColor}
                    bgColor={lighten(PrimaryWordpressThemeColor, 0.1)}
                />
            );

    }
};

export const getInterviewStatusChip = (status: InterviewStatus) => {
    switch (status) {
        case 'Scheduled':
            return (
                <ShChip
                    borderRadius='15px'
                    icon={<AccessTime style={{ color: `${WhiteColor}` }} />}
                    label="Scheduled"
                    size="small"
                    customSize="small"
                    style={{
                        color: WhiteColor,
                        backgroundColor: lighten(PrimaryWordpressThemeColor, 0.2),
                    }}
                />
            );
        case 'Cancelled':
            return (
                <ShChip
                    borderRadius='15px'
                    icon={<Cancel style={{ color: `${WhiteColor}` }} />}
                    label="Cancelled"
                    size="small"
                    style={{
                        color: WhiteColor,
                        backgroundColor: lighten('#f44336', 0.2), 
                    }}
                />
            );
        case 'Completed':
            return (
                <ShChip
                    borderRadius='15px'
                    icon={<CheckCircle style={{ color: `${WhiteColor}` }} />}
                    label="Completed"
                    size="small"
                    customSize="small"
                    style={{
                        color: WhiteColor,
                        backgroundColor: lighten('#4caf50', 0.2), 
                    }}
                />
            );
        case 'Requested':
            return (
                <ShChip
                    borderRadius='15px'
                    icon={<HourglassEmpty style={{ color: `${WhiteColor}` }} />}
                    label="Requested"
                    size="small"
                    customSize="small"
                    style={{
                        color: WhiteColor,
                        backgroundColor: lighten('#ff9800', 0.2), 
                    }}
                />
            );
        case 'Accepted':
            return (
                <ShChip
                    borderRadius='15px'
                    icon={<ThumbUp style={{ color: `${WhiteColor}` }} />}
                    label="Accepted"
                    size="small"
                    customSize="small"
                    style={{
                        color: WhiteColor,
                        backgroundColor: lighten('#2196f3', 0.2), 
                    }}
                />
            );
        default:
            return null;
    }
};