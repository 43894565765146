import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateGoogleCalendarEventPayload, ICreateGoogleCalendarResponse, IGoogleAuthPayload, IGoogleCalendarSlice } from 'Modules/Core/Interviews/InterviewsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const googleCalendarInitialState: IGoogleCalendarSlice = {};

export const createGoogleCalendarEvent = createAsyncThunk<ICreateGoogleCalendarResponse, { payload: ICreateGoogleCalendarEventPayload }, { rejectValue: IBaseResponse }>(
    "createGoogleCalendarEvent",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.GOOGLE_CALENDAR_EVENT}`, payload)
            .then((response: AxiosResponse<ICreateGoogleCalendarResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const googleUserAuth = createAsyncThunk<ICreateGoogleCalendarResponse, { payload: IGoogleAuthPayload }, { rejectValue: IBaseResponse }>(
    "googleUserAuth",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.GOOGLE_CALENDAR_AUTH}`, payload)
            .then((response: AxiosResponse<ICreateGoogleCalendarResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const deleteGoogleCalendarEvent = createAsyncThunk<IBaseResponse, { eventId: number }, { rejectValue: IBaseResponse }>(
    "deleteGoogleCalendarEvent",
    async ({ eventId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${EmployerApiEndpoints.GOOGLE_CALENDAR_EVENT}/${eventId}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateGoogleCalendar = createAsyncThunk<IBaseResponse, { payload: ICreateGoogleCalendarEventPayload, eventId: number }, { rejectValue: IBaseResponse }>(
    "updateGoogleCalendar",
    async ({ payload, eventId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.GOOGLE_CALENDAR_EVENT}/${eventId}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const googleCalendarSlice = createSlice({
    name: 'googleCalendar',
    initialState: googleCalendarInitialState,
    reducers: {
        resetGoogleUserAuth: (state) => {
            state.googleUserAuthStatus = 'idle';
            state.googleUserAuthResponse = undefined;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return googleCalendarInitialState;
        });
        // get interviews summary
        builder.addCase(createGoogleCalendarEvent.pending, (state) => {
            state.createGoogleCalendarEventStatus = 'pending'
        });
        builder.addCase(createGoogleCalendarEvent.fulfilled, (state, action) => {
            state.createGoogleCalendarEventStatus = 'success';
            state.createGoogleCalendarEventResponse = action?.payload;
        });
        builder.addCase(createGoogleCalendarEvent.rejected, (state, action) => {
            state.createGoogleCalendarEventStatus = 'failed';
            // state.createGoogleCalendarEventResponse = action?.payload ?? DefaultAPIErrorMsg;
        });
        // get interview details
        builder.addCase(googleUserAuth.pending, (state) => {
            state.googleUserAuthStatus = 'pending';
        });
        builder.addCase(googleUserAuth.fulfilled, (state, action) => {
            state.googleUserAuthStatus = 'success';
            state.googleUserAuthResponse = action.payload;
        });
        builder.addCase(googleUserAuth.rejected, (state, action) => {
            state.googleUserAuthStatus = 'failed';
            state.googleUserAuthResponse = action.payload?.message;
        });
        // get interview request details
        builder.addCase(deleteGoogleCalendarEvent.pending, (state) => {
            state.deleteGoogleCalendarEventStatus = 'pending';
        });
        builder.addCase(deleteGoogleCalendarEvent.fulfilled, (state, action) => {
            state.deleteGoogleCalendarEventStatus = 'success';
        });
        builder.addCase(deleteGoogleCalendarEvent.rejected, (state, action) => {
            state.deleteGoogleCalendarEventStatus = 'failed';
            state.deleteGoogleCalendarEventResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGoogleUserAuth } = googleCalendarSlice.actions;

export default googleCalendarSlice;
