import { Box, Typography, Stack, Divider } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import CommonQuestionSidebar from "../CommonQuestionSidebar";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { motion } from "framer-motion";

export const ContactSupport = () => {
  const isMdScreen = IsMdScreen();
  const isXsScreen = IsXsScreen();
  const paddingValue = isXsScreen ? 2 : 4;

  return (
    <Stack padding={paddingValue} direction="row" alignItems="flex-start">
      <ShContainer margin="auto" sx={{ paddingTop: 0, marginTop: 0 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <ResourceTypography variant="h5" fontWeight="bold" gutterBottom>
            Contact Support
          </ResourceTypography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Need assistance with our ATS platform? Our support team is here to
            help. Fill out the form below or use one of the alternative contact
            methods.
          </Typography>

          <Box my={3}>
            <ShButton
              variant="contained"
              href="https://smoothhiring.com/contact-us/"
              size="large"
              disableElevation
            >
              View Support Form
            </ShButton>
          </Box>

          <Divider sx={{ my: 3 }} />

          <ResourceTypography variant="h6" fontWeight="bold" gutterBottom>
            Other Contact Methods
          </ResourceTypography>
          <Stack spacing={1.5} paddingBottom={3}>
            <Typography variant="body1">
              <strong>Email:</strong> help@smoothhiring.com
            </Typography>
            <Typography variant="body1">
              <strong>Phone:</strong> +1 877 789 8767
            </Typography>
            <Typography variant="body1">
              <strong>Support Hours:</strong> Mon - Fri, 9 AM to 6 PM (EST)
            </Typography>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <ResourceTypography variant="h6" fontWeight="bold" gutterBottom>
            Frequently Asked Questions
          </ResourceTypography>
          <Typography variant="body1" paragraph>
            Before reaching out, check our{" "}
            <a
              href="https://smoothhiring.com/frequently-asked-questions/"
              style={{
                color: "#0073e6",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              FAQ page
            </a>{" "}
            for answers to common questions.
          </Typography>
        </motion.div>
      </ShContainer>

      {!isMdScreen && <CommonQuestionSidebar />}
    </Stack>
  );
};

export default ContactSupport;
