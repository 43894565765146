import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Pagination,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import Menu from '@mui/material/Menu';
import { IJobsFooter, TExportJobsFormat } from 'Modules/Core/JobsTs/JobsModel';
import { CSVFileIcon, EmailShareIcon, ExcelFileIcon } from 'assets/Icons';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ChangeEvent, useEffect, useState } from "react";
import { ShButton, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { FooterWrapper } from 'shared/SharedStyles/ShLayouts';
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { EmailRegEx, PageSizes } from 'shared/constants';
import { exportJobs, resetShareJobs, shareJobs } from 'store/slices/employer/jobs/jobs-list-actions-slice';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { useNotification } from 'Modules/Core/Notification';

export const JobsFooter = ({ paginationParams, getJobs, isSmScreen, selectedJobs, hasMoreButton, careerPage, jobsType }: IJobsFooter) => {
    const notification = useNotification();
    const [pgntnAnchorEl, setPgntnAnchorEl] = useState<HTMLElement | null>(null);
    const isPageSizeOpen = Boolean(pgntnAnchorEl);
    const [moreActionsMenuEl, setMoreActionsMenuEl] = useState<HTMLElement | null>(null);
    const isMoreActionsOpen = Boolean(moreActionsMenuEl);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [shareEmail, setShareEmail] = useState<string>("");
    const [isEmailInValid, setIsEmailInValid] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { downloadAsCSVStatus, downloadAsExcelStatus, shareJobStatus, shareJobResponse } = useAppSelector(state => state.employer.employerJobs.jobsListActions);
    const [exportAllPagesCsv, setExportAllPagesCSV] = useState<boolean>(false);
    const [exportAllPagesExcel, setExportAllPagesExcel] = useState<boolean>(false);

    const validateEmail = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInValid(!reg.test(e?.target.value ?? ''));
        setShareEmail(e?.target.value ?? '');
    };

    const handleExport = (exportFormat: TExportJobsFormat, exportAll: boolean) => {
        dispatch(exportJobs({
            exportFormat: exportFormat, type: jobsType ?? 'active', pageNo: exportAll ? 0 : paginationParams.requestedPageNumber,
            pageSize: exportAll ? paginationParams.totalPages * paginationParams.requestedPageSize : paginationParams.requestedPageSize
        }));
    }

    const handleShareJob = () => {
      dispatch(
        shareJobs({
          email: shareEmail,
          pageNo: paginationParams.requestedPageNumber,
          pageSize: paginationParams.count,
          type: jobsType!,
        })
      );
    };

    useEffect(() => {
      if (shareJobStatus === 'success') {
        setIsDialogOpen(false);
      }
    }, [shareJobStatus]);

    useEffect(() => {
      if (shareJobStatus === 'success') {
        notification.displayNotification({
          open: true,
          type: 'success',
          message: shareJobResponse ?? 'Jobs list sent successfully.',
        });
      }
    }, [shareJobResponse, shareJobStatus, notification]);

    useEffect(() => {
      if (shareJobStatus === 'success') {
        dispatch(resetShareJobs());
      }
    }, [shareJobStatus, dispatch]);

    return (<>
        <FooterWrapper justifyContent={hasMoreButton ? "space-between" : "flex-end"}>
            {hasMoreButton && <>
                <ShButton startIcon={<ExpandLessIcon />} aria-label='More Actions' color='primary' size='small'
                    disableElevation variant='contained' onClick={e => setMoreActionsMenuEl(e.currentTarget)}>More</ShButton>
                <Menu id="more-actions-menu" aria-label='More actions menu' anchorEl={moreActionsMenuEl}
                    open={isMoreActionsOpen} onClose={() => setMoreActionsMenuEl(null)}>
                    {/* Disable option while api is fetching data and display loading label. */}
                    {/* Show delete all option if jobs are selected */}
                    {/* {selectedJobs?.length > 0 &&
                        <MenuItem onClick={() => { }}>
                            <ListItemIcon><DeleteIcon color='error' /></ListItemIcon>
                            <ListItemText>Delete selected Jobs</ListItemText>
                        </MenuItem>} */}
                    <MenuItem disabled={downloadAsExcelStatus === 'pending'} onClick={() => handleExport('xls', exportAllPagesExcel)}>
                        <ListItemIcon><ExcelFileIcon /></ListItemIcon>
                        <ListItemText>{downloadAsExcelStatus === 'pending' ? 'Downloading...' : 'Export as Excel'}</ListItemText>
                        <Stack direction='row' marginLeft={3} alignItems='center' >
                            <Typography variant='caption'>Page</Typography>
                            <Switch onClick={(e) => e.stopPropagation()} onChange={() => setExportAllPagesExcel(!exportAllPagesExcel)} />
                            <Typography variant='caption'>All</Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem disabled={downloadAsCSVStatus === 'pending'} onClick={() => handleExport('csv', exportAllPagesCsv)}>
                        <ListItemIcon><CSVFileIcon /></ListItemIcon>
                        <ListItemText>{downloadAsCSVStatus === 'pending' ? 'Downloading...' : 'Export as CSV'}</ListItemText>
                        <Stack direction='row' marginLeft={3} alignItems='center' >
                            <Typography variant='caption'>Page</Typography>
                            <Switch onClick={(e) => e.stopPropagation()} onChange={() => setExportAllPagesCSV(!exportAllPagesCsv)} />
                            <Typography variant='caption'>All</Typography>
                        </Stack>
                    </MenuItem>
                    {jobsType !== 'template' && <MenuItem onClick={() => { setMoreActionsMenuEl(null); setIsDialogOpen(true); }}>
                        <ListItemIcon><EmailShareIcon /></ListItemIcon>
                        <ListItemText>Email</ListItemText>
                    </MenuItem>}
                </Menu>
            </>}
            <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' columnGap={{ xs: 0, sm: 1, md: 1 }} flexWrap='wrap'>
                {/* Number of records in a given page and total records information. */}
                <Typography variant='caption' fontWeight='bold'>
                    {(paginationParams.requestedPageNumber - 1) * paginationParams.requestedPageSize + 1}-
                    {((paginationParams.requestedPageNumber - 1) * paginationParams.requestedPageSize) +
                        paginationParams.count}&nbsp;of&nbsp;{paginationParams.totalElements}
                </Typography>
                {/* MUI pagination. Changes sibling and boundary counts based on screen size to best fit the page numbers in smaller screens. */}
                <Pagination className='desktop-pagination' color={careerPage ? undefined : 'primary'} showFirstButton showLastButton
                    siblingCount={isSmScreen ? 0 : 0} boundaryCount={isSmScreen ? 0 : 2}
                    count={paginationParams.totalPages} page={paginationParams.requestedPageNumber}
                    onChange={(e, pgNo) => getJobs(pgNo, paginationParams.requestedPageSize)}
                />
                {/* MUI pagination is replaced with simple prev and next stack of buttons for mobile screens. */}
                <Stack className='mobile-pagination' flexDirection='row'>
                    <IconButton disabled={paginationParams.firstPage}
                        onClick={() => getJobs(paginationParams.requestedPageNumber - 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton disabled={paginationParams.lastPage}
                        onClick={() => getJobs(paginationParams.requestedPageNumber + 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
                {/* Page size select menu. */}
                <ShButton size='small' variant='contained' disableElevation onClick={e => setPgntnAnchorEl(e.currentTarget)}
                    color={careerPage ? 'inherit' : 'primary'} endIcon={<ExpandMoreIcon />}>{paginationParams.requestedPageSize}</ShButton>
                <Menu id="page-size-menu" aria-label='Page size' anchorEl={pgntnAnchorEl}
                    open={isPageSizeOpen} onClose={() => setPgntnAnchorEl(null)}>
                    {PageSizes.map(size => (
                        <StyledMenuItem key={size} className={paginationParams.requestedPageSize === size ? "selected-item" : ""}
                            onClick={() => {
                                getJobs(1, size);
                                setPgntnAnchorEl(null);
                            }}>{size}</StyledMenuItem>
                    ))}
                </Menu>
            </Stack>
        </FooterWrapper>
        {/* Share to Email Dialog */}
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} aria-labelledby="title" aria-describedby="subtext">
            <DialogTitle id='title' fontWeight='bold'>Email Jobs List</DialogTitle>
            <DialogContent>
                {shareJobStatus === 'pending' && (
                  <ShAlert stickyAlert marginBottom={2} severity="info">
                    sending email...
                  </ShAlert>
                )}
                <DialogContentText id="subtext">Enter the Email ID to share the Jobs details
                    <Typography variant='caption' id='hint' display='flex' alignItems='center' columnGap={0.5}
                        marginBottom={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                        <InfoOutlinedIcon fontSize='inherit' /> Every Job's details will be shared by default if none selected from the list.</Typography>
                </DialogContentText>
                <ShTextFieldV2 id="share-email-id" label="Enter email address(es) of the recipients" variant="outlined" margin='dense' fullWidth
                    value={shareEmail} onChange={validateEmail} />
            </DialogContent>
            <DialogActions>
                <Stack flexDirection='row' justifyContent='space-between' width='100%' padding={2}>
                    <ShButton variant='outlined' size='small' onClick={() => setIsDialogOpen(false)}>Cancel</ShButton>
                    <ShButton variant='contained' size='small' disableElevation onClick={handleShareJob}
                        disabled={shareEmail?.trim().length === 0 || isEmailInValid || shareJobStatus === 'pending'}>Email jobs list</ShButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
};