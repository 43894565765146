import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { Task } from 'store/slices/employer/create-task/create-task-model';
import { format } from 'date-fns';
import {
  Stack,
  Typography,
  Divider,
  List,
  ListItem,
  LinearProgress,
  Box,
  Drawer,
  IconButton,
} from '@mui/material';
import {
  getAllTasks,
  getUnreadTaskCount,
  toggleNotification,
} from 'store/slices/employer/create-task/task-slice';
import {
  StyledHeaderContainer,
  StyledNotificationContainer,
  StyledNotificationItemContent,
  StyledNotificationText,
} from './NotificationPanelStyles';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { ClearIcon } from '@mui/x-date-pickers/icons';

const NotificationPanel = () => {
  const dispatch = useAppDispatch();
  const { getAllTasksResponse, isNotificationEnabled, getAllTasksApiStatus } =
    useAppSelector((state) => state.employer.createTask);

  useEffect(() => {
    if (isNotificationEnabled) {
      dispatch(getAllTasks());
    }
  }, [dispatch, isNotificationEnabled]);

  useEffect(() => {
    if (getAllTasksApiStatus === 'success') {
      dispatch(getUnreadTaskCount());
    }
  }, [dispatch, getAllTasksApiStatus]);

  const formatDueDate = (dueDate: string) => {
    return format(new Date(dueDate), 'MM/dd/yyyy');
  };

  const renderDescription = (description: string) => {
    const descriptionLines = description.split('\n');
    return (
      <List dense>
        {descriptionLines.map((line, index) => (
          <ListItem key={index} disableGutters>
            <StyledNotificationText variant="body2" color="text.secondary">
              {line}
            </StyledNotificationText>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Drawer
      open={isNotificationEnabled}
      onClose={() => dispatch(toggleNotification())}
      anchor="right"
    >
      <StyledNotificationContainer width={400}>
        <StyledHeaderContainer>
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h6" gutterBottom pl={1}>
              Notifications
            </Typography>
            <IconButton
              className="close-btn"
              onClick={() => dispatch(toggleNotification())}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
          <Divider />
          {getAllTasksApiStatus === 'pending' && <LinearProgress />}
        </StyledHeaderContainer>

        <Stack marginTop={1} gap={1} overflow="scroll" padding={1}>
          {getAllTasksResponse?.length ? (
            getAllTasksResponse.map((task: Task, index: number) => (
              <StyledNotificationItemContent
                unread={!task.task_is_read}
                key={index}
              >
                <Stack spacing={1}>
                  <StyledNotificationText
                    variant="subtitle1"
                    fontWeight={task.task_is_read ? 'normal' : 'bold'}
                    color={task.task_is_read ? 'text.primary' : 'primary.main'}
                  >
                    {task.task_name}
                  </StyledNotificationText>
                  {renderDescription(task.task_description)}

                  <Box>
                    <ShChip
                      label={`Due: ${formatDueDate(task.task_due_date)}`}
                      color="primary"
                      size="small"
                      borderRadius="15px"
                      customSize="small"
                    />
                  </Box>
                </Stack>
              </StyledNotificationItemContent>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No new notifications
            </Typography>
          )}
        </Stack>
      </StyledNotificationContainer>
    </Drawer>
  );
};

export default NotificationPanel;
