import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateJobDetailsState } from 'Modules/Core/CreateJob/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { ICompensation, IJobDetails } from '../jobs/jobs-slice-model';

const initialJobDetailsState: ICreateJobDetailsState = {
    createOrUpdateJobReqResponse: '',
    createOrUpdateJobReqStatus: 'idle'
};

export const createOrUpdateJobRequirements = createAsyncThunk<IBaseResponse, { payload: IJobDetails, jobId: number },
    { rejectValue: IBaseResponse }>(
        "createOrUpdateJobRequirements",
        async ({ payload, jobId }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .put(`${EmployerApiEndpoints.JOBS}/${jobId}/requirements`, payload)
                .then((response: AxiosResponse<IBaseResponse>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const getAdditionalCompensations = createAsyncThunk<IBaseResponse<ICompensation[]>, void, { rejectValue: IBaseResponse }>(
    "getAdditionalCompensations",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.ADDITIONAL_COMPENSATION}`)
            .then((response: AxiosResponse<IBaseResponse<ICompensation[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const createJobDetailsSlice = createSlice({
    name: 'createJobDetailsSlice',
    initialState: initialJobDetailsState,
    reducers: {
        resetJobDetailsState: () => initialJobDetailsState,
        resetCreateOrUpdateJobRequirements: (state) => { state.createOrUpdateJobReqStatus = 'idle'; state.createOrUpdateJobReqResponse = '' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobDetailsState;
        });
        // create or update job req
        builder.addCase(createOrUpdateJobRequirements.pending, (state) => {
            state.createOrUpdateJobReqStatus = 'pending'
        });
        builder.addCase(createOrUpdateJobRequirements.fulfilled, (state, action) => {
            state.createOrUpdateJobReqStatus = 'success';
            state.createOrUpdateJobReqResponse = action.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(createOrUpdateJobRequirements.rejected, (state, action) => {
            state.createOrUpdateJobReqStatus = 'failed';
            state.createOrUpdateJobReqResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get additional compensations
        builder.addCase(getAdditionalCompensations.pending, (state) => {
            state.getAdditionalCompensationsStatus = 'pending'
        });
        builder.addCase(getAdditionalCompensations.fulfilled, (state, action) => {
            state.getAdditionalCompensationsStatus = 'success';
            state.additionalCompensations = action.payload.data;
            state.getAdditionalCompensationsResponse = action.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getAdditionalCompensations.rejected, (state, action) => {
            state.getAdditionalCompensationsStatus = 'failed';
            state.getAdditionalCompensationsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetJobDetailsState, resetCreateOrUpdateJobRequirements } = createJobDetailsSlice.actions;
export default createJobDetailsSlice;
