import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { MenuProps } from '../Interviews.styled';
import { IGeneratedTimeInterval, ITimeSlot } from '../InterviewsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { getTimezones } from 'store/slices/common/timezone-slice';
import { generateTimeIntervals } from '../InterviewsUtilities';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface BaseInterviewTimeSlotProps {
  timeSlot?: ITimeSlot;
  isDisabled: boolean;
}

interface EnabledInterviewTimeSlotProps extends BaseInterviewTimeSlotProps {
  isDisabled: false;
  interval: number;
  setTime: (date: { from_time: string; to_time: string }) => void;
  setDate: (date: string) => void;
  setTimezone: (timezone: string) => void;
}

interface DisabledInterviewTimeSlotProps extends BaseInterviewTimeSlotProps {
  isDisabled: true;
  interval?: number;
  setTime?: (date: { from_time: string; to_time: string }) => void;
  setDate?: (date: string) => void;
  setTimezone?: (timezone: string) => void;
}

type InterviewTimeSlotProps =
  | EnabledInterviewTimeSlotProps
  | DisabledInterviewTimeSlotProps;

const InterviewTimeSlot: React.FC<InterviewTimeSlotProps> = ({
  setTimezone,
  setDate,
  setTime,
  timeSlot,
  isDisabled,
  interval,
}) => {
  const dispatch = useAppDispatch();
  const { timezones } = useAppSelector((state) => state.common.timezones);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<string>('');
  const [timeIntervals, setTimeIntervals] = useState<IGeneratedTimeInterval[]>(
    []
  );

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  useEffect(() => {
    const { from_time, to_time } = timeSlot || {};

    if (from_time && to_time) {
      const timeInterval =
        timeIntervals.find(
          (tI) => tI.startTime === from_time && tI.endTime === to_time
        )?.timeInterval || '';
      setSelectedTimeInterval(timeInterval);
    } else {
      setSelectedTimeInterval('');
    }
  }, [timeSlot, timeIntervals]);

  useEffect(() => {
    const intervals = generateTimeIntervals(interval ?? 30);

    setSelectedTimeInterval('');
    setTimeIntervals(intervals);
  }, [interval]);

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      columnGap={3}
      rowGap={2}
      maxWidth={600}
    >
      <Box width={170}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            value={timeSlot?.date ? dayjs(timeSlot.date) : null}
            onChange={(newValue: Dayjs | null) => {
              !isDisabled &&
                setDate(newValue ? newValue.format('YYYY-MM-DD') : '');
            }}
            slotProps={{ textField: { size: 'small' } }}
            disablePast
            disabled={isDisabled}
          />
        </LocalizationProvider>
      </Box>
      <Box minWidth={210}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          disabled={!interval}
        >
          <InputLabel>Select Interview time</InputLabel>
          <Select
            size="small"
            value={selectedTimeInterval}
            disabled={isDisabled || !interval}
            onChange={(event) => {
              const interval = event.target.value;
              setSelectedTimeInterval(interval);
              const { startTime, endTime } =
                timeIntervals.find((tI) => tI.timeInterval === interval) ?? {};
              !isDisabled &&
                setTime({
                  to_time: endTime ?? '',
                  from_time: startTime ?? '',
                });
            }}
            MenuProps={MenuProps}
            label="Select Interview time"
            renderValue={(selected) => selected}
          >
            {timeIntervals.map((interval, index) => (
              <MenuItem color="grey" key={index} value={interval.timeInterval}>
                {interval.timeInterval}
              </MenuItem>
            ))}
          </Select>
          {!interval && (
            <FormHelperText>
              Enter custom duration to select time interval
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box minWidth={120}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Timezone</InputLabel>
          <Select
            size="small"
            value={timeSlot?.timezone}
            disabled={isDisabled}
            onChange={(event) => !isDisabled && setTimezone(event.target.value)}
            MenuProps={MenuProps}
            label="Timezone"
            renderValue={(selected) => selected}
          >
            {timezones.map((timezone, index) => (
              <MenuItem color="grey" key={index} value={timezone.abbr}>
                {timezone.abbr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Stack>
  );
};

export default InterviewTimeSlot;
