import { IconButton, Stack, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ITimeSlot, InterviewRequestTimeSlotsProps } from '../InterviewsModel';
import InterviewTimeSlot from './InterviewTimeSlot';
import CancelIcon from '@mui/icons-material/Cancel';
import { StyledInterviewTimeSlotContainer } from '../Interviews.styled';

const InterviewRequestTimeSlots: React.FC<InterviewRequestTimeSlotsProps> = ({
  interval,
  isDisabled,
  timeSlots,
  updateTimeSlots,
}) => {
  const addTimeSlot = () => {
    updateTimeSlots([
      ...timeSlots,
      {
        date: '',
        from_time: '',
        timezone: '',
        to_time: '',
      },
    ]);
  };

  const updateTimeSlot = (index: number, updatedSlot: Partial<ITimeSlot>) => {
    updateTimeSlots(
      timeSlots.map((slot, idx) =>
        idx === index ? { ...slot, ...updatedSlot } : slot
      )
    );
  };

  const removeTimeSlot = (index: number) => {
    updateTimeSlots(timeSlots.filter((_, idx) => idx !== index));
  };

  return (
    <Stack gap={2}>
      {timeSlots.map((timeSlot, index) => (
        <StyledInterviewTimeSlotContainer
          key={index}
          direction="row"
          gap={1}
          padding={2}
          justifyContent="space-between"
        >
          <InterviewTimeSlot
            isDisabled={isDisabled}
            interval={interval}
            timeSlot={timeSlot}
            setTimezone={(timezone) => updateTimeSlot(index, { timezone })}
            setDate={(date) => updateTimeSlot(index, { date })}
            setTime={({ to_time, from_time }) => {
              updateTimeSlot(index, { to_time, from_time });
            }}
          />
          <Stack alignItems="center" justifyContent="center">
            <IconButton
              size="small"
              color="error"
              onClick={() => removeTimeSlot(index)}
            >
              <Tooltip title="Remove" placement="bottom">
                <CancelIcon fontSize="medium" />
              </Tooltip>
            </IconButton>
          </Stack>
        </StyledInterviewTimeSlotContainer>
      ))}
      <Tooltip title="Add another time request">
        <ShButton
          variant="outlined"
          color="inherit"
          startIcon={<AddIcon />}
          fullWidth
          onClick={addTimeSlot}
        >
          Add one more available time
        </ShButton>
      </Tooltip>
    </Stack>
  );
};

export default InterviewRequestTimeSlots;
