import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IShFeatureTile } from "shared/SharedModels";

export const CandidateDashboardTiles: Partial<IShFeatureTile>[] = [
    {
        tileIcon: <AssessmentIcon color='success' fontSize='large' />,
        tileLabel: 'Personal Career report',
        caption: 'Learn about your unique traits',
        featureRedirectUrl: '/candidate/report'
    },
    //  To enabled after apis are implemented
    {
        tileIcon: <BuildIcon color='success' fontSize='large' />,
        tileLabel: 'Career tools',
        caption: "Learn which career you're best suited for",
        featureRedirectUrl: '/candidate/career-tools'
    },
    {
        tileIcon: <NotificationsIcon color='success' fontSize='small' />,
        tileLabel: 'Job notification settings',
        caption: 'Update job notification settings',
        featureRedirectUrl: '/candidate/settings'
    },
    {
        tileIcon: <AccountCircleIcon color='success' fontSize='large' />,
        tileLabel: 'Your SmoothHiring profile',
        caption: 'Update your email and password',
        featureRedirectUrl: '/candidate/profile'
    },
    {
        tileIcon: <AccountCircleIcon color='success' fontSize='large' />,
        tileLabel: 'Candidate history',
        caption: 'View your job application history',
        featureRedirectUrl: '/candidate/history'
    }
]