
import { IBaseJson, ILabelValueBase } from 'shared/SharedModels';
import { utils, writeFile } from 'xlsx';

export const exportToExcel = (data: IBaseJson[], fileName: string, headers: ILabelValueBase[]) => {
    /*
        Format the data according to the headers and column order before adding to the worksheet.
    */
    const formattedRows: (string | number)[][] = [];
    data.forEach(d => {
        const row: (string | number)[] = [];
        headers.forEach(hd => {
            row.push(d[hd.value]);
        });
        formattedRows.push(row);
    });
    const worksheet = utils.aoa_to_sheet([headers.map(hd => hd.label), ...formattedRows]);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    writeFile(workbook, `${fileName}.xlsx`);
}

export const exportToCSV = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const exportToExcelFromBlob = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const compressImage = (file: File, noCompress: boolean = false): Promise<File> => {
    return new Promise((resolve, reject) => {
        // Maximum file size (1MB in bytes)
        const maxSize = 1 * 1024 * 1024; // 1MB

        // If noCompress is true, just check file size and convert to PNG or JPG if necessary
        if (noCompress) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const mimeType = fileExtension === 'png' ? 'image/png' : 'image/jpeg';

            // Create a FileReader to load the image
            const reader = new FileReader();
            reader.onload = function (event) {
                const img = new Image();
                img.src = event?.target?.result as string;
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const width = img.width;
                    const height = img.height;

                    canvas.width = width;
                    canvas.height = height;
                    ctx?.drawImage(img, 0, 0, width, height);
                    const quality = mimeType === 'image/jpeg' ? 0.8 : 1;
                    canvas.toBlob((blob) => {
                        if (blob) {
                            const convertedFile = new File([blob], `converted-image.${mimeType === 'image/jpeg' ? 'jpg' : 'png'}`, { type: mimeType });
                    
                            if (convertedFile.size <= maxSize) {
                                resolve(convertedFile);
                            } else {
                                reject(new Error('Converted file exceeds 1MB'));
                            }
                        } else {
                            reject(new Error('Failed to generate blob'));
                        }
                    }, mimeType, quality);
                    
                };
            };
            reader.readAsDataURL(file);
            return;
        }

        // Maximum width for the compressed image.
        const maxWidth = 250;

        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.src = event?.target?.result as string;
            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }

                canvas.width = width;
                canvas.height = height;
                ctx?.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a compressed data URL (JPEG format).
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                let mimeType = 'image/jpeg';  // Default to JPEG
                if (fileExtension === 'png') {
                    mimeType = 'image/png';
                }

                const compressedDataURL = canvas.toDataURL(mimeType, 1); // 1 for maximum quality

                // Create a Blob from the data URL
                const blob = dataURLtoBlob(compressedDataURL);

                // Create a File object with the Blob
                const compressedFile = new File([blob], `compressed-image.${fileExtension}`, { type: mimeType });

                // Check if the compressed file is within the size limit
                if (compressedFile.size <= maxSize) {
                    resolve(compressedFile);
                } else {
                    reject(new Error('Compressed file exceeds 1MB'));
                }
            };
        };
        reader.readAsDataURL(file);
    });
}



// Function to convert data URL to Blob
const dataURLtoBlob = (dataURL: string) => {
    const parts = dataURL.split(',');
    const contentType = parts[0].split(':')[1];
    const byteString = atob(parts[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: contentType });
}

export const downloadPdfFromUrl = async (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const fileToBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            // @ts-ignore
            const base64String = reader?.result?.split(',')[1];
            resolve(base64String);
        };

        reader.onerror = error => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
}

export const base64ToFile = (base64String: string, fileName: string) => {
    // Split the Base64 string to get mime type and data
    const parts = base64String.split(';base64,');
    const contentType = parts[0].split(':')[1];

    // Extract file extension from the fileName
    const extension = fileName.split('.').pop();

    // Use the file extension to determine the correct MIME type
    const type = extension ? `image/${extension}` : contentType;

    // Decode the Base64 data
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    // Create Blob from Uint8Array
    const blob = new Blob([uInt8Array], { type });

    // Create File from Blob
    const file = new File([blob], fileName, { type });

    return file;
}

export const downloadPdf = (data: any, fileName: string) => {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};