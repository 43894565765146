import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {
  ICreateZoomMeetPayload,
  IInterviewState,
  InterviewSortCols,
  InterviewType,
} from 'Modules/Core/Interviews/InterviewsModel';
import {
  ILabelValueBase,
  ITableHeadersBase,
  ITableHeadersOptions,
} from 'shared/SharedModels';

export const InterviewHeaders: (ITableHeadersBase<InterviewSortCols> &
  ITableHeadersOptions)[] = [
  { columnName: 'alphabetical', label: 'Title', order: 1, isSortable: true },
  { columnName: 'recent', label: 'Date', order: 1, isSortable: true },
];

export const VideoInterviewTypes: ILabelValueBase[] = [
  { label: 'Zoom', value: 'zoom' },
  { label: 'Other', value: 'other' },
];

// export const TimeZones: ILabelValueBase[] = [
//     { label: 'Eastern Standard Time', value: 'EST' },
//     { label: 'Central Standard Time', value: 'CST' },
//     { label: 'Pacific Standard Time', value: 'PST' },
//     { label: 'Pacific Daylight Time', value: 'PDT' },
//     { label: 'Atlantic Standard Time', value: 'AST' },
//     { label: 'Hawaii-Aleutian Standard Time', value: 'HAST' },
//     { label: 'Hawaii-Aleutian Daylight Time', value: 'HADT' },
//     { label: 'Newfoundland Standard Time', value: 'NST' },
//     { label: 'Newfoundland Daylight Time', value: 'NDT' },
//     { label: 'Atlantic Daylight Time', value: 'ADT' },
//     { label: 'Eastern Daylight Time', value: 'EDT' },
//     { label: 'Central Daylight Time', value: 'CDT' },
//     { label: 'Mountain Standard Time', value: 'MST' },
//     { label: 'Mountain Daylight Time', value: 'MDT' },
//     { label: 'Alaska Daylight Time', value: 'AKDT' }
// ];

export const InterviewDurations: ILabelValueBase<string, number>[] = [
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '60 Minutes', value: 60 },
  { label: '90 Minutes', value: 90 },
];

export const CreateInterviewPayload: IInterviewState = {
  application_id: undefined,
  from_time: '',
  additional_interview_info: '',
  note: '',
  schedule_date: '',
  to_time: '',
  type_of_interview: '',
  timezone: '',
  additional_attendees_email: [],
  request_time_slots: [
    {
      date: '',
      from_time: '',
      timezone: '',
      to_time: '',
    },
  ],
  status: 'Requested',
};

export const CreateZoomMeetPayload: ICreateZoomMeetPayload = {
  agenda: '',
  from_time: '',
  password: '',
  schedule_date: '',
  topic: '',
};

export const InterviewModes: InterviewType[] = ['In-person', 'Phone', 'Video'];

export const InterviewModeIconMap = {
  'In-person': <BusinessOutlinedIcon color="primary" />,
  Phone: <LocalPhoneOutlinedIcon color="primary" />,
  Video: <VideocamOutlinedIcon color="primary" />,
};
