import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { DashboardContentWrapper } from "Modules/Core/Admin/AdminDashboard/AdminDashboard.styled";
import { useState } from "react";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import {
  deleteCandidateByEmail,
  resetDeleteCandidateByEmail,
} from "store/slices/admin/candidate/admin-candidate-slice";
export const DeleteAccount = () => {
  const dispatch = useAppDispatch();
  const { deleteCandidateByEmailResponse, deleteCandidateByEmailStatus } =
    useAppSelector((state) => state.admin.adminCandidate);
  const [candidateEmail, setCandidateEmail] = useState<string>("");
  return (
    <DashboardContentWrapper>
      {(deleteCandidateByEmailStatus === "failed" ||
        deleteCandidateByEmailStatus === "success") && (
        <ShAlert
          severity={
            deleteCandidateByEmailStatus === "failed" ? "error" : "success"
          }
          onClose={() => dispatch(resetDeleteCandidateByEmail())}
        >
          {deleteCandidateByEmailResponse}
        </ShAlert>
      )}
      <Stack direction="row" padding={2} columnGap={1}>
        <TextField
          id="candidate_email"
          label="Enter Candidate Email Address"
          variant="outlined"
          value={candidateEmail}
          onChange={(e) => setCandidateEmail(e.target.value)}
          sx={{ width: '23%' }}
        />
        <ShButton
          disabled={
            !candidateEmail || deleteCandidateByEmailStatus === "pending"
          }
          onClick={() =>
            dispatch(deleteCandidateByEmail({ email: candidateEmail }))
          }
        >
          {deleteCandidateByEmailStatus === "pending"
            ? "Deleting..."
            : "Delete"}
        </ShButton>
      </Stack>
    </DashboardContentWrapper>
  );
};