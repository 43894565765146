import { Box, Fade, Stack, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TabPanelProps } from 'shared/SharedModels';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { TSubscriptionUrlFragments } from '../../SettingsModel';
import { Subscriptions } from "./Subscriptions";
import { ManageSubscriptions } from "./ManageSubscriptions";
import PaymentHistory from "./PaymentHistory";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { getCompanyInfo, resetUpdateCompany, resetLogoUpdate } from "store/slices/employer/settingsTs/company-info-slice";


const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' minHeight={300} hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const SubscriptionsNav = () => {
    const dispatch = useAppDispatch();
    const hashInSecurityUrl = useLocation().hash.substring(1) as TSubscriptionUrlFragments;
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<number>(0);
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);

    useEffect(() => {
        if (!companyInfo.id) {
            dispatch(getCompanyInfo());
        }

        return () => {
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        };
    }, [companyInfo, dispatch]);


    // Set tab value according to the url fragment if any.
    useEffect(() => {
        if (hashInSecurityUrl) {
            switch (hashInSecurityUrl) {
                case 'manage-subscriptions':
                    setTabValue(0);
                    break;
                case 'view-subscriptions':
                    setTabValue(1);
                    break;
                case 'billing-payment-history':
                    setTabValue(2);
                    break;
                default:
                    break;
            }
        } else {
            navigate('/employer/settings/account/subscriptions#manage-subscriptions');
        }
    }, [hashInSecurityUrl, navigate]);

    const setSubscriptionTabValue = (newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/employer/settings/account/subscriptions#manage-subscriptions');
                break;
            case 1:
                navigate('/employer/settings/account/subscriptions#view-subscriptions');
                break;
            case 2:
                navigate('/employer/settings/account/subscriptions#billing-payment-history');
                break;
            default:
                break;
        }
    }

    return (
        <>
        <Fade in={true} timeout={800}>
            <Stack spacing={1}>
                <ShTabs aria-label="Subscription tabs" variant="scrollable" scrollButtons='auto' highlightVariant="underline"
                    allowScrollButtonsMobile value={tabValue} onChange={(evt, newValue) => setSubscriptionTabValue(newValue)}>
                    
                    <Tab label='Manage your Subscription' />
                    <Tab label='View Subscriptions' />
                    <Tab label='Billing Payment History' />
                </ShTabs>
                <TabPanel value={tabValue} index={0}>
                    <ManageSubscriptions />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Subscriptions />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <PaymentHistory />
                </TabPanel>
            </Stack>
        </Fade>
        </>
    );
}
