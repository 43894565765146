import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Box, Button, Container, Divider, Grid, IconButton,
    InputAdornment, Paper, Stack, Tab, TextField, Typography
} from "@mui/material";
import { IUpdateApplicantPassword, IUpdateApplicantProfile } from "Modules/Core/Applicants/ApplicantsModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiState, TabPanelProps } from "shared/SharedModels";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { DefaultAPIErrorMsg } from "shared/constants";
import { updateAccountDetails } from "store/slices/auth-v2/auth-v2-slice";
import {
    resetUpdateApplicantProfile, updateApplicantPassword,
    updateApplicantProfile
} from "store/slices/employer/applicants/applicant-profile-slice";
import { resetChangePassword } from "store/slices/employer/settingsTs/security-slice";
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const CandidateProfile = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<number>(0);
    const { accountInfo } = useAppSelector((state) => state.employer.employerSettings.accInfo);

    const { updateApplicantProfileApiStatus, updateApplicantProfileApiResponse,
        updatePasswordApiStatus, updatePasswordApiResponse } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const [applicantProfile, setApplicantProfile] = useState<IUpdateApplicantProfile>(() => {
        return {
            first_name: accountInfo?.first_name ?? '',
            middle_name: accountInfo?.middle_name ?? '',
            last_name: accountInfo.last_name ?? '',
            applicantId: accountInfo?.id
        }
    });

    const [applicantPassword, setApplicantPassword] = useState<IUpdateApplicantPassword>(() => {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        }
    })
    const [showOldPass, setShowOldPass] = useState<boolean>(false);
    const [showNewPass, setShowNewPass] = useState<boolean>(false);
    const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);

    useEffect(() => {
        if (updateApplicantProfileApiStatus === 'success') {
            dispatch(updateAccountDetails({ firstName: applicantProfile.first_name, middle: applicantProfile.middle_name, lastName: applicantProfile.last_name }));
        }
    }, [applicantProfile.first_name, applicantProfile.last_name, applicantProfile.middle_name, dispatch, updateApplicantProfileApiStatus]);

    // reset on unmount
    useEffect(() => {
        return () => {
            dispatch(resetUpdateApplicantProfile());
        }
    }, [dispatch]);

    const handleSubmit = () => {
        const applicantProfilePayload = { ...applicantProfile }
        if (!applicantProfilePayload.middle_name) {
            applicantProfilePayload.middle_name = undefined;
        }
        dispatch(updateApplicantProfile(applicantProfilePayload))
    }
    const showAlerts = (apiState?: ApiState, msg?: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            case 'success':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="success" onClose={() => {
                            dispatch(resetUpdateApplicantProfile());
                            dispatch(resetChangePassword())
                        }}>
                            <Typography fontWeight={600}>{msg}</Typography>
                        </ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    return (<>
        <Container maxWidth='sm'>
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Profile</Typography>
                <Paper variant="outlined">
                    <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                        <ShTabs aria-label="Security tabs" variant="scrollable" scrollButtons='auto'
                            allowScrollButtonsMobile value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                            <Tab label='Profile details' />
                            <Tab label='Change password' disabled={accountInfo?.signup_source !== undefined && accountInfo?.signup_source !== null} />
                        </ShTabs >
                        <TabPanel value={tabValue} index={0}>
                            <Stack rowGap={2} marginTop={2}>
                                <Divider />
                                {showAlerts(updateApplicantProfileApiStatus, updateApplicantProfileApiResponse)}
                                <Grid container spacing={{ xs: 3, sm: 3, md: 3, lg: 4 }} >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField id="firstName" required label="First Name" variant="outlined"
                                            value={applicantProfile.first_name} size="small" onChange={(e) => {
                                                setApplicantProfile({ ...applicantProfile, first_name: e.target.value })
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField id="middleName" label="Middle Name" variant="outlined"
                                            value={applicantProfile.middle_name} size="small" onChange={(e) => {
                                                setApplicantProfile({ ...applicantProfile, middle_name: e.target.value })
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField id="lastName" required label="Last Name" variant="outlined"
                                            value={applicantProfile.last_name} size="small" onChange={(e) => {
                                                setApplicantProfile({ ...applicantProfile, last_name: e.target.value })
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField id="emailAddress" label="Email Address" variant="outlined"
                                            value={accountInfo.email} size="small" disabled={true} />
                                    </Grid>
                                </Grid>
                                <Stack direction='row' justifyContent='space-between' mt={2}>
                                    <Button variant="outlined" size="small"
                                        onClick={() => navigate(CandidateLoginRedirect)}>Back</Button>
                                    <ShGreenBtn variant="contained" color="primary" size="small" disableElevation
                                        disabled={applicantProfile.first_name.length === 0 || applicantProfile.last_name.length === 0}
                                        onClick={() => {
                                            handleSubmit();
                                        }}>
                                        {updateApplicantProfileApiStatus === 'pending' ? 'Saving...' : 'Save'}
                                    </ShGreenBtn>
                                </Stack>
                            </Stack>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Stack rowGap={2} marginTop={2}>
                                <Divider />
                                {showAlerts(updatePasswordApiStatus, updatePasswordApiResponse)}
                                <Stack rowGap={2}>
                                    <TextField label="Current Password" variant="outlined" fullWidth size='small'
                                        required type={showOldPass ? 'text' : 'password'}
                                        value={applicantPassword.oldPassword}
                                        onChange={(e) => { setApplicantPassword({ ...applicantPassword, oldPassword: e.target.value }) }}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position='end'> <IconButton edge='end'
                                                onClick={() => setShowOldPass(!showOldPass)}>
                                                {showOldPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                                            </InputAdornment>)
                                        }} />
                                    <TextField label="New Password" variant="outlined" fullWidth size='small'
                                        required type={showNewPass ? 'text' : 'password'}
                                        value={applicantPassword.newPassword}
                                        onChange={(e) => { setApplicantPassword({ ...applicantPassword, newPassword: e.target.value }) }}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position='end'> <IconButton edge='end'
                                                onClick={() => setShowNewPass(!showNewPass)}>
                                                {showNewPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                                            </InputAdornment>)
                                        }} />
                                    <TextField label="Confirm Password" variant="outlined" fullWidth size='small'
                                        required type={showConfirmPass ? 'text' : 'password'}
                                        value={applicantPassword.confirmPassword}
                                        onChange={(e) => { setApplicantPassword({ ...applicantPassword, confirmPassword: e.target.value }) }}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position='end'> <IconButton edge='end'
                                                onClick={() => setShowConfirmPass(!showConfirmPass)}>
                                                {showConfirmPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                                            </InputAdornment>)
                                        }}
                                        helperText={(applicantPassword.confirmPassword.length !== 0
                                            && applicantPassword.newPassword.length !== 0
                                            && applicantPassword.newPassword !== applicantPassword.confirmPassword)
                                            ? 'New password and confirm password should match' : ''} />
                                </Stack>
                                <Stack direction='row' justifyContent='space-between' mt={2}>
                                    <Button variant="outlined" size="small" onClick={() => navigate(CandidateLoginRedirect)}>Back</Button>
                                    <ShGreenBtn variant="contained" color="primary" size="small" disableElevation
                                        disabled={applicantPassword.oldPassword.length === 0 || applicantPassword.newPassword.length === 0
                                            || applicantPassword.confirmPassword.length === 0 ||
                                            applicantPassword.newPassword !== applicantPassword.confirmPassword
                                            || applicantPassword.oldPassword === applicantPassword.newPassword}
                                        onClick={() => dispatch(updateApplicantPassword(applicantPassword))}>
                                        {updatePasswordApiStatus === 'pending' ? 'Saving...' : 'Save'}
                                    </ShGreenBtn>
                                </Stack>
                            </Stack>
                        </TabPanel>
                    </Box>
                </Paper>
            </Box>
        </Container>
    </>)
}