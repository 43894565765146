import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IExportJobsParams, IJobsActionsState, IShareJobsParams } from 'Modules/Core/JobsTs/JobsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { exportToCSV, exportToExcelFromBlob } from 'helpers/fileHandlers';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialJobsActionsState: IJobsActionsState = {
    downloadAsCSVStatus: 'idle',
    downloadAsExcelStatus: 'idle'
};

export const deleteJobById = createAsyncThunk<IBaseResponse, { jobId: number }, { rejectValue: IBaseResponse }>(
    "deleteJobById",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${EmployerApiEndpoints.JOB}/${jobId}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const stopHireById = createAsyncThunk<IBaseResponse, { jobId: number }, { rejectValue: IBaseResponse }>(
    "stopHireById",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.STOP_HIRING}/${jobId}/stopHiring`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const rePostJob = createAsyncThunk<(IBaseResponse & { id: number }), { jobId: string }, { rejectValue: IBaseResponse }>(
    "rePostJob",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOBS}/${jobId}/copy`)
            .then((response: AxiosResponse<(IBaseResponse & { id: number })>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

//download jobs as csv
export const exportJobs = createAsyncThunk<IBaseResponse, IExportJobsParams, { rejectValue: IBaseResponse }>(
    'exportJobs',
    async ({ exportFormat, type, pageNo, pageSize }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EXPORT_JOBS}?exportFormat=${exportFormat}&type=${type}&pageNo=${pageNo}&pageSize=${pageSize}`, { responseType: 'arraybuffer' })
            .then((response: AxiosResponse) => response.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const shareJobs = createAsyncThunk<IBaseResponse, IShareJobsParams, { rejectValue: IBaseResponse }>(
    'shareJobs',
    async ({ email, type, pageNo, pageSize }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.SHARE_JOBS}?type=${type}&pageNo=${pageNo}&pageSize=${pageSize}&email=${email}`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const publishJobToCareersPage = createAsyncThunk<IBaseResponse & { id: number },{ jobId: number; isPublished: boolean },{ rejectValue: IBaseResponse }>(
  "publishJobToCareersPage",
  async ({ jobId, isPublished }, { rejectWithValue }) => {
    return await httpAdapterInstance
      .put(`${EmployerApiEndpoints.JOBS}/${jobId}/publish-to-careers-page`, { isPublished })
      .then((response: AxiosResponse<IBaseResponse & { id: number }>) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response?.data);
      });
  }
);

//download jobs as excel
const jobsListActionsSlice = createSlice({
    name: 'jobsListActions',
    initialState: initialJobsActionsState,
    reducers: {
        resetJobDelete: (state) => { state.deleteJobResponse = ''; state.deleteJobStatus = 'idle' },
        resetStopHire: (state) => { state.stopHireJobResponse = ''; state.stopHireJobStatus = 'idle' },
        resetShareJobs: (state) => { state.shareJobResponse = ''; state.shareJobStatus = 'idle' },
        resetRePostJob: (state) => {
            state.rePostJobResponse = '';
            state.rePostJobStatus = 'idle';
            state.copiedJobId = undefined;
            state.prevJobId = undefined;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobsActionsState;
        });
        // delete job
        builder.addCase(deleteJobById.pending, (state) => {
            state.deleteJobStatus = 'pending'
        });
        builder.addCase(deleteJobById.fulfilled, (state, action) => {
            state.deleteJobStatus = 'success';
            state.deleteJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(deleteJobById.rejected, (state, action) => {
            state.deleteJobStatus = 'failed';
            state.stopHireJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // stop hiring for a job
        builder.addCase(stopHireById.pending, (state) => {
            state.stopHireJobStatus = 'pending'
        });
        builder.addCase(stopHireById.fulfilled, (state, action) => {
            state.stopHireJobStatus = 'success';
            state.stopHireJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(stopHireById.rejected, (state, action) => {
            state.stopHireJobStatus = 'failed';
            state.stopHireJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // re-post a job
        builder.addCase(rePostJob.pending, (state) => {
            state.rePostJobStatus = 'pending'
        });
        builder.addCase(rePostJob.fulfilled, (state, action) => {
            state.rePostJobStatus = 'success';
            state.copiedJobId = action.payload.id;
            state.prevJobId = parseInt(action.meta.arg.jobId);
            state.rePostJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(rePostJob.rejected, (state, action) => {
            state.rePostJobStatus = 'failed';
            state.rePostJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(exportJobs.pending, (state, action) => {

            if (action?.meta.arg.exportFormat === 'csv') {
                state.downloadAsCSVStatus = 'pending';
            } else {
                state.downloadAsExcelStatus = 'pending';
            }
        });
        builder.addCase(exportJobs.fulfilled, (state, action) => {
            if (action?.meta.arg.exportFormat === 'csv') {
                state.downloadAsCSVStatus = 'success';
                exportToCSV(action?.payload, 'Jobs');
            } else {
                state.downloadAsExcelStatus = 'success';
                exportToExcelFromBlob(action?.payload, 'Jobs');
            }
        });
        builder.addCase(exportJobs.rejected, (state, action) => {
            if (action?.meta.arg.exportFormat === 'csv') {
                state.downloadAsCSVStatus = 'failed';
                state.downloadAsCSVResponse = action?.payload?.message ?? 'Error occurred while downloading';
            } else {
                state.downloadAsExcelStatus = 'failed';
                state.downloadAsExcelResponse = action?.payload?.message ?? 'Error occurred while downloading';
            }
        });

        builder.addCase(shareJobs.pending, (state) => {
            state.shareJobStatus = 'pending';
        });
        builder.addCase(shareJobs.fulfilled, (state, action) => {
            state.shareJobStatus = 'success';
            state.shareJobResponse = action?.payload?.message;
        });
        builder.addCase(shareJobs.rejected, (state, action) => {
            state.shareJobStatus = 'failed';
            state.shareJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // publish job to careers page (job will stay past 30 days period)
        builder.addCase(publishJobToCareersPage.pending, (state) => {
            state.publishJobToCareersPageStatus = 'pending';
        });
        builder.addCase(publishJobToCareersPage.fulfilled, (state, action) => {
            state.publishJobToCareersPageStatus = 'success';
            state.publishJobToCareersPageResponse = action?.payload?.message;
        });
        builder.addCase(publishJobToCareersPage.rejected, (state, action) => {
            state.publishJobToCareersPageStatus = 'failed';
            state.publishJobToCareersPageResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetJobDelete, resetStopHire, resetRePostJob, resetShareJobs } = jobsListActionsSlice.actions;
export default jobsListActionsSlice;
