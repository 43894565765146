import { useAppSelector } from "helpers/hooks";
import { sessionExpiryObservable } from "helpers/rxjsHelpers";
import { getAppContextInLocalStorage } from "helpers/TokenHelper";
import { useEffect } from "react";
import { Navigate, Outlet, useNavigate, useParams } from "react-router-dom";
import { TAppContext } from "store/slices/auth-v2/auth-v2-model";
import { AdminLogoutRedirect, CandidateLogoutRedirect, CandidateSurveyLogoutRedirect, EmployerLogoutRedirect } from "./AuthConstants";

export const AuthV2 = ({ appContext }: ({ appContext: TAppContext })) => {

    const navigate = useNavigate();
    const { isAccountLoggedIn } = useAppSelector(state => state.auth.auth);
    const { postingSource } = useAppSelector((state) => state.candidate.applyJobHome);
    // const { navigatedFrom } = useAppSelector((state) => state.app.moduleSwitch);
    // const location = useLocation();
    // const currentPath = location.pathname + location.search;
    // const [accountTypePath, setAccountTypePath] = useState<TAccountType | null>(null);
    const { jobCode } = useParams();

    // Update current path
    // useEffect(() => {
    //     if (currentPath) {
    //         setAccountTypePath(currentPath.split('/')[1] as TAccountType ?? null);
    //     }
    // }, [currentPath]);

    // Subscribing to rxjs observable to listen for session expired event emitted from httpAdapterInstance.
    useEffect(() => {
        const sessionExpirySubscription = sessionExpiryObservable().subscribe((isSessionExpired) => {
            if (isSessionExpired) {
                navigate('/session-expired', { state: { expiredIn: getAppContextInLocalStorage(), applyJobUrl: window.location.pathname } });
            }
        });

        return () => {
            if (sessionExpirySubscription) {
                sessionExpirySubscription.unsubscribe();
            }
        };
    }, [navigate]);

    // To be implemented properly in future
    // if (isAccountLoggedIn && accountTypePath !== null) {
    //     switch (accountAccess?.dashboard) {
    //         case 'employer':
    //             if (accountTypePath !== 'employer') {
    //                 if (navigatedFrom === 'admin') {
    //                     return <Navigate to={EmployerLoginRedirect} />
    //                 } else {
    //                     return <Navigate to={UnauthorizedRedirect} />
    //                 }
    //             }
    //             break;
    //         case 'admin':
    //             if (accountTypePath !== 'admin') {
    //                 return <Navigate to={UnauthorizedRedirect} />
    //             }
    //             break;
    //         case 'candidate':
    //             if (accountTypePath !== 'candidate') {
    //                 return <Navigate to={UnauthorizedRedirect} />
    //             }
    //             break;
    //     }
    // }

    const getRedirectAfterLogout = () => {
        switch (appContext) {
            case 'employer':
                return <Navigate to={EmployerLogoutRedirect} />;
            case 'admin':
                return <Navigate to={AdminLogoutRedirect} />;
            case 'candidate_apply_job':
                return <Navigate to={`${CandidateLogoutRedirect}/${jobCode}${postingSource ? ('?ps=' + postingSource) : ''}`} />;
            case 'candidate_survey':
                return <Navigate to={CandidateSurveyLogoutRedirect} />;
        }
    }

    return (<>
        {isAccountLoggedIn ? <Outlet /> : getRedirectAfterLogout()}
    </>)
}