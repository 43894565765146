import React from 'react';
import {
  QueryStatsOutlined as QueryStatsOutlinedIcon,
  CalendarMonth as CalendarMonthIcon,
  LibraryBooks as LibraryBooksIcon,
  Construction as ConstructionIcon,
  FolderSpecialOutlined as FolderSpecialOutlinedIcon,

  TaskAlt as TaskAltIcon,
  HelpOutline as HelpOutlineIcon,
  Article as ArticleIcon,
} from '@mui/icons-material';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import {
  ShDashboardIcon,
  ShCreateJobIcon,
  ShMyJobsIcon,
  ShMyInterviewsIcon,
  ShSettingsIcon,
} from 'assets/Icons';
import { useTheme } from '@mui/material';
import { TFeatures } from 'Modules/Core/AuthV2/AuthModel';
import {
  ShIconPrimary,
  ShIconSecondary,
} from 'shared/SharedStyles/styleConstants';

type IconConfig = {
  icon: React.ElementType;
  isShIcon?: boolean;
  scale?: number;
};

const featureIconMap: Partial<Record<TFeatures, IconConfig>> = {
  Dashboard: {
    icon: ShDashboardIcon,
    isShIcon: true,
  },
  Create: {
    icon: ShCreateJobIcon,
    isShIcon: true,
  },
  'View Jobs': {
    icon: ShMyJobsIcon,
    isShIcon: true,
  },
  Interviews: {
    icon: ShMyInterviewsIcon,
    isShIcon: true,
  },
  Settings: {
    icon: ShSettingsIcon,
    isShIcon: true,
  },
  Analytics: {
    icon: QueryStatsOutlinedIcon,
    scale: 1.3,
  },
  Calendar: {
    icon: CalendarMonthIcon,
    scale: 1.3,
  },
  Resources: {
    icon: LibraryBooksIcon,
    scale: 1.2,
  },
  'Tools And Automation': {
    icon: ConstructionIcon,
    scale: 1.2,
  },
  'Employee Insights': {
    icon: FolderSpecialOutlinedIcon,
    scale: 1.2,
  },

  'Create Job': {
    icon: WorkOutlineOutlinedIcon,
    scale: 1.3,
  },
  'CreateTask': {
    icon: TaskAltIcon,
    scale: 1.3,
  },
  Help: {
    icon: HelpOutlineIcon,
    scale: 1.3,
  },
  Templates: {
    icon: ArticleIcon,
    scale: 1.3,
  },
};

interface SideNavIconProps {
  feature: TFeatures;
  isDisabled?: boolean;
}

const SideNavIcon: React.FC<SideNavIconProps> = ({
  feature,
  isDisabled = false,
}) => {
  const theme = useTheme();
  const {
    icon: IconComponent,
    isShIcon,
    scale,
  } = featureIconMap[feature] || {};

  if (!IconComponent) return null;

  const iconColor = isDisabled ? theme.palette.grey[500] : ShIconPrimary;
  const iconSecondaryColor = isDisabled
    ? theme.palette.grey[500]
    : ShIconSecondary;

  if (isShIcon) {
    return (
      <IconComponent
        primaryColor={iconColor}
        secondaryColor={iconSecondaryColor}
      />
    );
  }

  return (
    <IconComponent
      style={{
        color: iconColor,
        transform: scale ? `scale(${scale})` : undefined,
      }}
    />
  );
};

export default SideNavIcon;
