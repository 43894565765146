import { Divider, Stack } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { CandidateSummaryProps } from "./ApplicantCandidateAiSummaryModels";
import ExperienceSection from "./AISummarySections/ExperienceSections";
import { SkillsSection } from "./AISummarySections/SkillsSection";
import { ProjectsSection } from "./AISummarySections/ProjectsSection";
import { ProgrammingLanguages } from "./AISummarySections/ProgrammingLanguages";
import { CertificationsSection } from "./AISummarySections/CertificationsSection";
import { AwardsSection } from "./AISummarySections/AwardsSection";
import { EducationSection } from "./AISummarySections/EducationSection";
import { SummarySection } from "./AISummarySections/SummarySection";
import { SmartProfile } from "./AISummarySections/SmartProfile";


export const ApplicantsAiCandidateSummary: React.FC<CandidateSummaryProps> = ({ candidate }) => {
  return (
    <Stack spacing={2} pt={1}>
        {/* Header: Avatar + Name + Email */}
        <ShPaper variant="outlined">
            <SmartProfile candidate={candidate} />
        </ShPaper>
        <ShPaper variant="outlined">
            <Stack spacing={1}>
                {/* Summary */}
                <SummarySection candidate={candidate} />

                {/* Education Section */}
                <EducationSection candidate={candidate} />

                <Divider sx={{ my: 2 }} />

                {/* Experience Section */}
                <ExperienceSection candidate={candidate} />

                {/* Skills Section */}
                <SkillsSection candidate={candidate} />

                {/* Projects Section */}
                <ProjectsSection candidate={candidate} />

                {/* Programming Languages */}
                <ProgrammingLanguages candidate={candidate} />

                {/* Certifications */}
                <CertificationsSection candidate={candidate} />

                {/* Awards */}
                <AwardsSection candidate={candidate} />

            </Stack>
        </ShPaper>
    </Stack>
  );
};
