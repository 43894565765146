import { Fade, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShAvatar } from "shared/SharedStyles/ShDataDisplay";
import { shBlue } from "shared/SharedStyles/styleConstants";
import { getInitialsFromName, stringToColor } from "shared/utils";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Stack } from "@mui/material";
import { Link } from "@mui/material";
import { LinkedIn, Language } from "@mui/icons-material";

const ensureHttp = (url?: string) => {
    if (!url) return undefined;
    return /^https?:\/\//i.test(url) ? url : `https://${url}`;
};

export const SmartProfile: React.FC<CandidateSummaryProps> = ({ candidate }) => {
    
    const initials = getInitialsFromName(candidate?.full_name || candidate?.email);
    const avatarBgColor = stringToColor(initials);
    return (
    <Fade in={true} timeout={800}>
        <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction='row' alignItems='center' spacing={2} justifyContent='left'>
                <ShAvatar width={60} height={60} backgroundColor={avatarBgColor}>
                    <Typography variant="h6">{initials}</Typography>
                </ShAvatar>
                <Stack justifyContent='left'  pl={1}>
                    <Typography variant="h6">{candidate.full_name}</Typography>
                    <Stack spacing={1}>
                        {candidate.current_job_title && (
                            <Typography variant="body2">
                                {candidate.current_job_title} @ {candidate.current_employer}
                            </Typography>
                        )}
                        <Stack direction="row" spacing={2} alignItems="center">
                            {candidate.location && (
                                <Typography variant="body2" color="text.secondary">
                                <LocationOnIcon fontSize="small" style={{ verticalAlign: "middle", marginRight: 4 }} />
                                {candidate.location}
                                </Typography>
                            )}
                            
                            {candidate.email && (
                                <Typography variant="body2" color="text.secondary">
                                    <EmailIcon fontSize="small" style={{ verticalAlign: "middle", marginRight: 4 }} />
                                    {candidate.email}
                                </Typography>
                            )}
                            
                            {candidate.phone_number && (
                                <Typography variant="body2" color="text.secondary">
                                <PhoneIcon fontSize="small" style={{ verticalAlign: "middle", marginRight: 4 }} />
                                {candidate.phone_number}
                                </Typography>
                            )}
                        </Stack>
                        <Stack direction='row' pt={1}>
                            {candidate.tags && candidate.tags.map((tag, index) => (
                                <Typography variant="body2" key={index} color ={shBlue} ml={1}>
                                    #{tag}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                    {candidate.linkedin_profile && (
                        <Link href={ensureHttp(candidate.linkedin_profile)} target="_blank" rel="noopener" color="primary" display="flex" alignItems="center" gap={0.5}>
                            <LinkedIn fontSize="medium" /> LinkedIn Profile
                        </Link>
                    )}
                    {candidate.website_link && (
                        <Link href={ensureHttp(candidate.website_link)} target="_blank" rel="noopener" color="primary" display="flex" alignItems="center" gap={0.5}>
                            <Language fontSize="medium" /> Portfolio
                        </Link>
                    )}
            </Stack>
        </Stack>
    </Fade>
    );
}
