import { useEffect, useState } from "react";
import { Box, DialogContent, Divider, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { RootState } from "shared/SharedModels";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { Star as StarIcon } from "@mui/icons-material";
import { addRemoveOrUpdateEmployerRejectCriteria, getEmployerRejectCriteria } from "store/slices/employer/employer/employer-details-slice";
import { IAutoRejectCriteria, fitOptions } from "Modules/Core/SettingsTs/ToolsAndAutomation/Automation/AutoReject/CriteriaModel";
import { PrimaryThemeColor, PrimaryWordpressThemeColor } from "shared/SharedStyles/styleConstants";
import { ShButton } from "shared/SharedStyles/ShInputs";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Link, useParams } from "react-router-dom";
import { autoRejectApplicants } from "store/slices/employer/applicants/applicants-list-slice";
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { CircleBox } from "Modules/Core/Dashboard/Dashboard.styled";
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export const ApplicantListAutoReject = () => {
    const {
        employerRejectCriteria,
        getAutoRejectCriteraStatus,
        employerDetails,
        updateAutoRejectCriteraStatus,
    } = useSelector((state: RootState) => state.employer.employerDetails);
    const { jobId } = useParams();
    const dispatch = useAppDispatch();
    const [selectedCriteriaId, setSelectedCriteriaId] = useState<number | null>(null); 
    const [selectedCriteria, setSelectedCriteria] = useState<IAutoRejectCriteria>();
    const [openDialog, setOpenDialog] = useState(false);
    const { getBatchAutoRejectStatus } = useAppSelector((state) => state.employer.applicants.applicantsList);

    useEffect(() => {
        if (employerDetails?.id) {
            dispatch(getEmployerRejectCriteria({ empId: employerDetails?.id }));
        }
    }, [dispatch, employerDetails?.id]);

    const handleCriteriaClick = (criteriaId: number) => {
        if (employerDetails?.id) {
            // Update selected criteria ID
            setSelectedCriteriaId(criteriaId);
    
            // Map over the criteria to update the preferred status
            const updatedCriteria = employerRejectCriteria?.map((criteria) =>
                criteria.id === criteriaId
                    ? { ...criteria, isPreferred: true }
                    : { ...criteria, isPreferred: false }
            );
    
            // Extract the preferred criteria (the one where isPreferred is true)
            const preferredCriteria = updatedCriteria?.find((criteria) => criteria.isPreferred);
            setSelectedCriteria(preferredCriteria)
            // Dispatch with a single object of type IAutoRejectCriteria
            if (preferredCriteria) {
                dispatch(
                    addRemoveOrUpdateEmployerRejectCriteria({
                        empId: employerDetails.id,
                        criteria: preferredCriteria,
                        action: "update"
                    })
                );
            }
        }
    };

    const handleBatchRejectClick = () => {
        setOpenDialog(true);
    };

    const cancelBatchReject = () => {
        setOpenDialog(false);
    };

    const confirmBatchReject = () => {
        // Ensure that the jobId and selectedCriteria are available before dispatching the action
        if (jobId && selectedCriteria) {
            dispatch(
                autoRejectApplicants({
                    jobId: jobId,
                    criteria: selectedCriteria,
                })
            ).then(() => {
                // Close dialog after successful rejection
                setOpenDialog(false);
            }).catch((error) => {
                console.error("Batch reject failed", error);
            });
        }
    };


    return (
        <ShPaper variant="outlined">
            <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='center' display='flex' paddingBottom={1}>
                <Stack direction='row' alignItems='center'>
                    <CircleBox size={30} color={PrimaryThemeColor}>
                        <Typography color='white' variant="subtitle2">1</Typography>
                    </CircleBox>
                    <Typography variant="body1" paddingLeft={1}>
                        Select Auto-Reject Criteria
                    </Typography>
                </Stack>
                <Stack direction='row' spacing={1} maxHeight={35}>
                    <ShButton disabled={!selectedCriteriaId}  color='error' variant="contained" size='small' onClick={handleBatchRejectClick}>
                        <DoNotDisturbIcon fontSize="small" sx={{ marginRight: 1 }} />
                        Batch Reject (Without Sending Email)
                    </ShButton>
                    {/* <ShButton disabled={true}  color='error' variant="contained" size='small' onClick={handleBatchRejectClick}>
                        <DoNotDisturbIcon fontSize="small" sx={{ marginRight: 1 }} />
                        Batch Reject and send email (Coming Soon!)
                    </ShButton> */}
                    {!selectedCriteria &&
                        <ShAlert severity="warning"> No Criteria Selected</ShAlert> 
                    }
                </Stack>
            </Stack>
            <Divider />
            <Grid container spacing={2} padding={2}>
                {getAutoRejectCriteraStatus === "pending" || updateAutoRejectCriteraStatus === "pending" || getBatchAutoRejectStatus === "pending" ? (
                    <Box width="100%" padding={2}>
                        <LinearProgress />
                    </Box>
                ) : employerRejectCriteria && employerRejectCriteria.length > 0 ? (
                    employerRejectCriteria?.map((criteria: IAutoRejectCriteria) => (
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} key={criteria.id}>
                            <ShPaper
                                transElevateOnHover
                                variant="outlined"
                                sx={{
                                    border: criteria.id === selectedCriteriaId ? `2px solid ${PrimaryWordpressThemeColor}` : "",
                                    cursor: "pointer",
                                }}
                                onClick={() => handleCriteriaClick(criteria.id)}>
                                <Stack
                                    direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
                                    justifyContent="space-between"
                                    spacing={2}>
                                    <Box maxWidth={175}>
                                        <Typography variant="subtitle2">{criteria.criteriaName}</Typography>
                                    </Box>
                                    {criteria.isPreferred && (
                                        <Box maxWidth={150}>
                                            <ShChip
                                                size="small"
                                                label="Preferred"
                                                color="success"
                                                textColor="white"
                                                borderRadius="15px"
                                                icon={<StarIcon color="success" />}
                                            />
                                        </Box>
                                    )}
                                </Stack>
                                <Typography variant="body2" color="textSecondary">
                                    {criteria.criteriaDesc}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>Resume Score Threshold:</strong> {criteria.resumeScoreThreshold}%
                                </Typography>
                                {criteria.selectedFits.length > 0 &&
                                    <Typography variant="body2" color="textSecondary">
                                        <strong>Reject a Job Fit:</strong> {criteria.selectedFits.join(", ")}
                                    </Typography>
                                }
                                <Typography variant="caption" color="textSecondary">
                                    Created on: {criteria.dateCreated}
                                </Typography>
                            </ShPaper>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Box textAlign="center">
                            <Typography variant="body2" color="textSecondary">
                                No criteria found. You can add a new criteria by {" "}
                                <Link to="/employer/settings/automation/auto-reject/criteria" color="primary">
                                    clicking here
                                </Link>.
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>

            <ShDialog open={openDialog} onClose={cancelBatchReject}>
                <Stack spacing={2}>
                <ShAlert severity="warning">
                    <Typography variant="body1">
                        Confirm Batch Reject!
                    </Typography>
                </ShAlert>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>Are you sure you want to proceed with batch rejection?</Typography>
                    <ShPaper variant="outlined">
                        <Typography gutterBottom>Using preset: <strong>{selectedCriteria?.criteriaName} </strong></Typography>
                        <Typography gutterBottom> Rejecting candidates below resume score: <strong>{selectedCriteria?.resumeScoreThreshold}%</strong></Typography>
                       {selectedCriteria && selectedCriteria?.selectedFits.length > 0 &&
                            <Typography gutterBottom>
                                Rejecting:{" "}
                                <strong>
                                    {selectedCriteria?.selectedFits
                                    ?.map((fit) => {
                                        // Take only the first element of a split value
                                        const firstFit = fit.split(",")[0];
                                        // Find the matching label
                                        return fitOptions.find((opt) => opt.value.includes(firstFit))?.label || firstFit;
                                    })
                                    .join(" | ")}
                                </strong>{" "}
                                fits
                            </Typography>
                        }
                    </ShPaper>
                </DialogContent>
                <ShPaper variant="outlined" headerSection>
                <Stack direction='row' justifyContent='space-between'>
                    <ShButton size='small' onClick={cancelBatchReject} color="primary" startIcon={<CancelIcon />}>
                        Cancel
                    </ShButton>
                    <ShButton size='small' variant="contained" onClick={confirmBatchReject} color="error" startIcon={<CheckCircleIcon />}>
                        Confirm
                    </ShButton>
                </Stack>
                </ShPaper>
                </Stack>
            </ShDialog>

        </ShPaper>
    );
};
