import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance, { feedServer } from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import {
  ApiState,
  IApprovedJobResponse,
  IBaseResponse,
  IJobInfoResponse,
  JobReviewsStates,
} from "shared/SharedModels";

interface InitialJobsState {
  pendingJobs: Array<IJobInfoResponse>;
  approvedJobs: Array<IJobInfoResponse>;
  rejectedJobs: Array<IJobInfoResponse>;
  removedJobs: Array<IJobInfoResponse>;
  employerJob: IJobInfoResponse | object;
  isFetchingJobs: ApiState;
  approveJobApiStatus: ApiState;
  feedGenerationApiStatus: ApiState;
  getFullFingerPrintImageStatus: ApiState;
  getFullFingerPrintImageResponse: string;
  fullFingerPrintImage: string;
  getAdminBundlesStatus: ApiState;
  getAdminBundlesResponse: string;
  jobBundles:Array<IJobInfoResponse>;
}

const initialJobsState: InitialJobsState = {
  isFetchingJobs: "idle",
  pendingJobs: [],
  approvedJobs: [],
  rejectedJobs: [],
  removedJobs: [],
  employerJob: {},
  approveJobApiStatus: "idle",
  feedGenerationApiStatus: "idle",
  getFullFingerPrintImageStatus: "idle",
  getFullFingerPrintImageResponse: "",
  fullFingerPrintImage: "",
  getAdminBundlesStatus: "idle",
  getAdminBundlesResponse: "",
  jobBundles:[],
};

export const getAdminJobs = createAsyncThunk<
  IBaseResponse<IJobInfoResponse[]>,
  { state: string; pageNo: number; pageSize: number }
>(
  "getAdminJobs",
  async (
    { state = "pending", pageNo = 1, pageSize = 20 },
    { rejectWithValue }
  ) => {
    const response = await httpAdapterInstance
      .get(
        `${AdminApiEndpoints.ADMIN_JOB_REVIEWS}?state=${state}&pageNo=${pageNo}&pageSize=${pageSize}`
      )
      .then((response) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

export const getEmployerJob = createAsyncThunk<
  IBaseResponse<IJobInfoResponse>,
  { jobId: string }
>("getEmployerJob", async ({ jobId }, { rejectWithValue }) => {
  const response = await httpAdapterInstance
    .get(`${AdminApiEndpoints.ADMIN_EMPLOYER_JOBS}/${jobId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error.response.data);
    });
  return response;
});

export const approveJob = createAsyncThunk<
  IBaseResponse<IApprovedJobResponse>,
  { jobId: number }
>("approveJob", async ({ jobId }, { rejectWithValue }) => {
  const response = await httpAdapterInstance
    .put(`${AdminApiEndpoints.ADMIN_JOB_REVIEWS}/${jobId}`)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error.response.data);
    });
  return response;
});

export const getJobBenchmarkImage = createAsyncThunk<
  IBaseResponse<string>,
  { jobId: string }
>("getJobBenchmarkImage", async ({ jobId }, { rejectWithValue }) => {
  const response = await httpAdapterInstance
    .get(`${AdminApiEndpoints.ADMIN_EMPLOYER_JOBS_BENCHMARK}/${jobId}/full`)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error.response.data);
    });
  return response;
});

export const getAdminBundles = createAsyncThunk<
IBaseResponse<IJobInfoResponse[]>,
{ jobId: string }
>("getAdminBundles", async ({ jobId }, { rejectWithValue }) => {
const response = await httpAdapterInstance
  .get(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${jobId}/job/bundles`)
  .then((response) => response.data)
  .catch((error) => {
    throw rejectWithValue(error.response.data);
  });
return response;
});


export const generateFeeds = createAsyncThunk<void>(
  "generateFeeds",
  async (_, { rejectWithValue }) => {
    const response = await feedServer
      .get(AdminApiEndpoints.ADMIN_GENERATE_FEEDS)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

const jobReviewsSlice = createSlice({
  name: "jobReviewsSlice",
  initialState: initialJobsState,
  reducers: {
    resetAdminJobs: (state) => {
      state.isFetchingJobs = "idle";
      state.pendingJobs = [];
      state.approvedJobs = [];
      state.rejectedJobs = [];
      state.removedJobs = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminJobs.pending, (state) => {
      state.isFetchingJobs = "pending";
    });
    builder.addCase(getAdminJobs.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { state: jobStatus } = action.meta.arg;

      switch (jobStatus) {
        case JobReviewsStates.approved:
          state.approvedJobs = data;
          break;
        case JobReviewsStates.pending:
          state.pendingJobs = data;
          break;
        case JobReviewsStates.rejected:
          state.rejectedJobs = data;
          break;
        case JobReviewsStates.removed:
          state.removedJobs = data;
          break;
      }
      state.isFetchingJobs = "success";
    });
    builder.addCase(getAdminJobs.rejected, (state) => {
      state.pendingJobs = [];
      state.isFetchingJobs = "failed";
    });
    builder.addCase(getEmployerJob.pending, (state) => {
      state.isFetchingJobs = "pending";
    });
    builder.addCase(getEmployerJob.fulfilled, (state, action) => {
      state.employerJob = action.payload;
      state.isFetchingJobs = "success";
    });
    builder.addCase(getEmployerJob.rejected, (state) => {
      state.employerJob = {};
      state.isFetchingJobs = "failed";
    });
    builder.addCase(getJobBenchmarkImage.pending, (state) => {
      state.getFullFingerPrintImageStatus = "pending";
    });
    builder.addCase(getJobBenchmarkImage.fulfilled, (state, action) => {
      state.getFullFingerPrintImageStatus = "success";
      state.fullFingerPrintImage = String(action.payload);
    });
    builder.addCase(getJobBenchmarkImage.rejected, (state, action) => {
      state.getFullFingerPrintImageStatus = "failed";
      state.getFullFingerPrintImageResponse = String(action.payload);
    });

    builder.addCase(getAdminBundles.pending, (state) => {
      state.getAdminBundlesStatus = "pending";
    });
    builder.addCase(getAdminBundles.fulfilled, (state, action) => {
      state.getAdminBundlesStatus = "success";
      state.jobBundles = action.payload.data ?? [];
    });
    builder.addCase(getAdminBundles.rejected, (state, action) => {
      state.getAdminBundlesStatus = "failed";
      state.getAdminBundlesResponse = String(action.payload);
    });
  },
});

export const { resetAdminJobs } = jobReviewsSlice.actions;
export default jobReviewsSlice;
