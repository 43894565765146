import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IInterview, IInterviewRequestDetails, IInterviewsDetailsState, IInterviewsSummary } from 'Modules/Core/Interviews/InterviewsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialInterviewsDetailsState: IInterviewsDetailsState = {
    getInterviewsSummaryStatus: 'idle',
    getInterviewsRequestDetailsStatus: 'idle'
};

export const getInterviewsSummary = createAsyncThunk<IBaseResponse<IInterviewsSummary>, void, { rejectValue: IBaseResponse }>(
    "getInterviewsSummary",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.INTERVIEWS_SUMMARY}`)
            .then((response: AxiosResponse<IBaseResponse<IInterviewsSummary>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getInterviewDetails = createAsyncThunk<IBaseResponse<IInterview>, { id: number }, { rejectValue: IBaseResponse }>(
    "getInterviewDetails",
    async ({ id }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.INTERVIEWS}/${id}`)
            .then((response: AxiosResponse<IBaseResponse<IInterview>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getInterviewRequestDetails = createAsyncThunk<IBaseResponse<IInterviewRequestDetails>, { requestIdentifier: string }, { rejectValue: IBaseResponse }>(
    "getInterviewRequestDetails",
    async ({ requestIdentifier }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.INTERVIEWS_REQUEST_DETAILS}/${requestIdentifier}`)
            .then((response: AxiosResponse<IBaseResponse<IInterviewRequestDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const interviewsDetailsSlice = createSlice({
    name: 'interviewsList',
    initialState: initialInterviewsDetailsState,
    reducers: {
        resetGetInterviewDetails: (state) => {
            state.getInterviewDetailsStatus = 'idle';
            state.getInterviewDetailsResponse = '';
            state.interviewDetails = undefined;
        },
        resetGetInterviewSummary: (state) => {
            state.getInterviewsSummaryStatus = 'idle';
            state.getInterviewsSummaryResponse = '';
            state.interviewsSummary = undefined;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialInterviewsDetailsState;
        });
        // get interviews summary
        builder.addCase(getInterviewsSummary.pending, (state) => {
            state.getInterviewsSummaryStatus = 'pending'
        });
        builder.addCase(getInterviewsSummary.fulfilled, (state, action) => {
            state.getInterviewsSummaryStatus = 'success';
            state.interviewsSummary = action?.payload?.data;
        });
        builder.addCase(getInterviewsSummary.rejected, (state, action) => {
            state.getInterviewsSummaryStatus = 'failed';
            state.getInterviewsSummaryResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get interview details
        builder.addCase(getInterviewDetails.pending, (state) => {
            state.getInterviewDetailsStatus = 'pending';
            state.interviewDetails = undefined;
        });
        builder.addCase(getInterviewDetails.fulfilled, (state, action) => {
            state.getInterviewDetailsStatus = 'success';
            state.interviewDetails = action.payload.data;
            state.getInterviewDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getInterviewDetails.rejected, (state, action) => {
            state.getInterviewDetailsStatus = 'failed';
            state.getInterviewDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get interview request details
        builder.addCase(getInterviewRequestDetails.pending, (state) => {
            state.getInterviewsRequestDetailsStatus = 'pending';
            state.interviewRequestDetails = undefined;
        });
        builder.addCase(getInterviewRequestDetails.fulfilled, (state, action) => {
            state.getInterviewsRequestDetailsStatus = 'success';
            state.interviewRequestDetails = action.payload.data;
            state.getInterviewRequestDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getInterviewRequestDetails.rejected, (state, action) => {
            state.getInterviewsRequestDetailsStatus = 'failed';
            state.getInterviewRequestDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetInterviewDetails, resetGetInterviewSummary } = interviewsDetailsSlice.actions;

export default interviewsDetailsSlice;
