export const QUESTION_TYPE = [
  { value: 'textfield', label: 'Short Answer' },
  { value: 'textarea', label: 'Paragraph' },
  { value: 'radio', label: 'Choose' },
  { value: 'yesNo', label: 'Yes/No' },
  { value: 'dropdown', label: 'Select from a list/dropdown' },
  { value: 'date', label: 'Date' },
  { value: 'number', label: 'Number'},
  { value: 'noOldDate', label: 'Date (No old dates)'},
  { value: 'fileUpload', label: 'File Upload'}
];
