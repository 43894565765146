import { Button, Stack } from '@mui/material';
import { InterviewTimeSlotsSelectionProps } from '../InterviewsModel';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';


const InterviewTimeSlotsSelection: React.FC<
  InterviewTimeSlotsSelectionProps
> = ({ timeSlots, selectedTimeSlot, isDisabled, onTimeSlotSelect }) => {
  const convert24HourTo12Hour = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const convertedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${convertedHours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <ShPaper variant="outlined">
      <Stack spacing={2} width="100%">
        {timeSlots.map((slot, index) => (
          <Button
            key={index}
            fullWidth
            variant={selectedTimeSlot === index ? 'contained' : 'outlined'}
            disabled={isDisabled}
            onClick={() => onTimeSlotSelect(index)}
          >
            {`${slot.date}: ${convert24HourTo12Hour(
              slot.from_time
            )} - ${convert24HourTo12Hour(slot.to_time)} (${slot.timezone})`}
          </Button>
        ))}
      </Stack>
    </ShPaper>
  );
};

export default InterviewTimeSlotsSelection;
