import React from "react";
import { Stack, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ShTable, ShTableContainer, ShTableRow } from "shared/SharedStyles/ShDataDisplay";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { PLAN_FEATURES_MAP, featureLabels } from "./SubscriptionsConstants";
import { SubscriptionPlan, PLAN_NAME_MAP, SubscriptionPlanFeatures } from "./SubscriptionsModel";


export const SubscriptionCrossTable: React.FC = () => {
  const plans: SubscriptionPlan[] = Object.keys(PLAN_FEATURES_MAP) as SubscriptionPlan[];

  return (
    <Stack pt={2}>
        <ShContainer maxWidth='xl' margin='auto'>
            <ShTableContainer>
                <ShTable size="small">
                    <TableHead>
                    <ShTableRow cursor="default">
                        <TableCell>
                            <Typography variant="body1">
                                Features
                            </Typography>
                        </TableCell>
                        {plans.map((plan) => (
                        <TableCell key={plan} align="center">
                            <Typography variant="h6">
                                {PLAN_NAME_MAP[plan]}
                            </Typography>
                        </TableCell>
                        ))}
                    </ShTableRow>
                    </TableHead>

                    <TableBody>
                    {Object.entries(featureLabels).map(([featureKey, featureLabel]) => (
                        <TableRow key={featureKey}>
                        <TableCell>
                            <Typography variant="body2">{featureLabel}</Typography>
                        </TableCell>
                        {plans.map((plan) => {
                            const featureValue = PLAN_FEATURES_MAP[plan][featureKey as keyof SubscriptionPlanFeatures];

                            return (
                            <TableCell key={plan} align="center">
                                {typeof featureValue === "boolean" ? (
                                featureValue ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />
                                ) : (
                                featureValue ?? <CancelIcon color="error" />
                                )}
                            </TableCell>
                            );
                        })}
                        </TableRow>
                    ))}
                    </TableBody>
                </ShTable>
            </ShTableContainer>
        </ShContainer>
    </Stack>
  );
};

export default SubscriptionCrossTable;
