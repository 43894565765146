import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { IAccountLoginResponse, TAccountType } from "../auth-v2/auth-v2-model";
import { IModuleSwitchState } from "./app-model";

const InitialModuleSwitchState: IModuleSwitchState = {};

export const enterEmployerAccount = createAsyncThunk<IBaseResponse<IAccountLoginResponse>, { employerId: number, payload: { url: string, id: number } }, { rejectValue: IBaseResponse }>(
    "enterEmployerAccount",
    async ({ employerId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/enter-account`, payload)
            .then((response: AxiosResponse<IBaseResponse<IAccountLoginResponse>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const enterAdminAccount = createAsyncThunk<IBaseResponse<IAccountLoginResponse>, void, { rejectValue: IBaseResponse }>(
    "enterAdminAccount",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.ADMIN_EMPLOYERS_LOGOUT}`)
            .then((response: AxiosResponse<IBaseResponse<IAccountLoginResponse>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const moduleSwitchSlice = createSlice({
    name: "moduleSwitch",
    initialState: InitialModuleSwitchState,
    reducers: {
        setNavigatedFrom: (state, action: PayloadAction<{ navigatedFrom: TAccountType | undefined, navigateBackUrl: string }>) => {
            state.navigatedFrom = action.payload.navigatedFrom;
            state.navigateBackUrl = action.payload.navigateBackUrl;
        },
        resetEnterEmployerAccount: (state) => { state.getEmployerAccountLoginStatus = 'idle'; state.getEmployerAccountLoginResponse = '' },
        resetEnterAdminAccount: (state) => { state.getAdminAccountLoginStatus = 'idle'; state.getAdminAccountLoginResponse = '' }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return InitialModuleSwitchState;
        });
        // enterEmployerAccount
        builder.addCase(enterEmployerAccount.pending, (state) => {
            state.getEmployerAccountLoginStatus = 'pending';
        });
        builder.addCase(enterEmployerAccount.fulfilled, (state, action) => {
            state.getEmployerAccountLoginStatus = 'success';
            state.getEmployerAccountLoginResponse = action?.payload?.message;
            state.employerAccountLogin = action?.payload?.data;
        });
        builder.addCase(enterEmployerAccount.rejected, (state, action) => {
            state.getEmployerAccountLoginStatus = 'failed';
            state.getEmployerAccountLoginResponse = action?.payload?.message;
        });
        // enterAdminAccount
        builder.addCase(enterAdminAccount.pending, (state) => {
            state.getAdminAccountLoginStatus = 'pending';
        });
        builder.addCase(enterAdminAccount.fulfilled, (state, action) => {
            state.getAdminAccountLoginStatus = 'success';
            state.getAdminAccountLoginResponse = action?.payload?.message;
            state.adminAccountLogin = action?.payload?.data;
        });
        builder.addCase(enterAdminAccount.rejected, (state, action) => {
            state.getAdminAccountLoginStatus = 'failed';
            state.getAdminAccountLoginResponse = action?.payload?.message;
        });
    },
});

export const { resetEnterEmployerAccount, resetEnterAdminAccount, setNavigatedFrom } = moduleSwitchSlice.actions;
export default moduleSwitchSlice;
