import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Chip,
  CircularProgress,
  Fade,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  JobRequirementCategoryTooltip,
  MandatoryRequirementCategoryIds,
  RequirementCategoryMap,
  RequirementLevelMap,
} from "Modules/Core/CreateJob/CreateJob/CreateJobConstants";
import { getInvalidCategories } from "Modules/Core/CreateJob/CreateJob/CreateJobHelper";
import {
  IJobDetailActionItems,
  IRequirementCategory,
} from "Modules/Core/CreateJob/CreateJob/CreateJobModel";
import { useNotification } from "Modules/Core/Notification";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ApiState } from "shared/SharedModels";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import {
  ShButton,
  ShGreenBtn,
  ShTextFieldV2,
} from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { DefaultAPIErrorMsg } from "shared/constants";
import {
  areAllElementsInArrayXInArrayY,
  areBothObjectsIdentical,
} from "shared/utils";
import { IAiJobDescriptionAndInterviewKitPayload } from "store/slices/app/app-model";
import { getAiKeywordsPrediction } from "store/slices/app/resources-slice";
import {
  createOrUpdateJobRequirements,
  resetCreateOrUpdateJobRequirements,
} from "store/slices/employer/create-job/create-job-details-slice";
import {
  getRequirements,
  resetCreateOrUpdateJob,
  resetGetRequirements,
  setIsPreviewEnabled,
  updateAllJobDetails,
  updateJobDetails,
  updateJobRequirementsInSlice,
} from "store/slices/employer/create-job/create-job-shared-slices";
import { fetchJobSummary } from "store/slices/employer/jobs/jobs-list-slice";
import {
  IJobDetails,
  IRequirement,
} from "store/slices/employer/jobs/jobs-slice-model";
import { JobDetailsActionsStack } from "./CreateJob/CreateJob.styled";

export const JobDetails = () => {
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const { displayNotification } = useNotification();
  const navigate = useNavigate();
  const isRouteEnabled = useOutletContext();
  const { aiKeywordPredictions, getAiKeywordPredictionStatus } = useAppSelector(
    (state) => state.app.resources
  );
  const { jobsSummary } = useAppSelector(
    (state) => state.employer.employerJobs.jobsList
  );
  const {
    createOrUpdateJobReqResponse,
    createOrUpdateJobReqStatus,
    getAdditionalCompensationsStatus,
    getAdditionalCompensationsResponse,
  } = useAppSelector((state) => state.employer.createJob.createJobDetails);
  const {
    allJobDetails,
    jobTitleInfo,
    jobDetails,
    getRequirementsStatus,
    getRequirementsResponse,
    jobRequirements,
    getRequirementsMetaDataStatus,
    getRequirementsMetaDataResponse,
    requirementsMetaData,
    defaultRequirementLevel,
  } = useAppSelector((state) => state.employer.createJob.createJobShared);
  const { getJobDetailsStatus } = useAppSelector(
    (state) => state.employer.employerJobs.jobInfo
  );
  const [errorKeywordTooltipOpen, setErrorKeywordTooltipOpen] = useState(false);
  const [errorKeywordsEmptyTooltipOpen, setErrorKeywordsEmptyTooltipOpen] =
    useState(false);

  const [_jobDetails, _setJobDetails] = useState<IJobDetails>({
    requirements: [],
    companydescription: "",
    keywords: "",
  });
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [isBackClicked, setIsBackClicked] = useState<boolean>(false);
  /**
   * "_jobDetailsCopy" is a reference object based on which undo functionality is implemented.
   * */
  const [_jobDetailsCopy, _setJobDetailsCopy] = useState<IJobDetails>({
    requirements: [],
    companydescription: "",
    keywords: "",
  });
  const [isShowUndo, setIsShowUndo] = useState<boolean>(false);
  const [keywordChips, setKeywordChips] = useState<string[]>([]);
  const [newKeywordToBeAdded, setNewKeywordToBeAdded] = useState<string>("");
  const [selectedCompensations, setSelectedCompensations] = useState<string[]>(
    []
  );

  // Update job details fetched from API.
  useEffect(() => {
    if (getJobDetailsStatus === "success" && jobDetails) {
      _setJobDetails({ ...jobDetails });
      _setJobDetailsCopy({ ...jobDetails });
    }
  }, [getJobDetailsStatus, jobDetails]);

  // show undo if any changes are made in job details
  useEffect(() => {
    if (
      _jobDetailsCopy &&
      _jobDetailsCopy?.requirements?.length > 0 &&
      _jobDetails.requirements?.length > 0
    ) {
      setIsShowUndo(!areBothObjectsIdentical(_jobDetailsCopy, _jobDetails));
    }
  }, [_jobDetails, _jobDetailsCopy]);

  // Get job details if jobId is present in route params.
  useEffect(() => {
    if (
      isRouteEnabled &&
      jobId !== undefined &&
      !isNaN(parseInt(jobId)) &&
      jobRequirements?.length === 0
    ) {
      dispatch(getRequirements({ jobId: jobId }));
    }
  }, [dispatch, isRouteEnabled, jobId, jobRequirements?.length]);

  const getDefaultRequirement = useCallback(
    (reqCatId: number, uiOrder?: number): IRequirement => {
      return {
        name: "",
        requirement_category_id: reqCatId,
        requirement_level_id: defaultRequirementLevel,
        // Generate random number as id for job requirement to handle new job requirement additions and deletions.
        reqId: Math.floor(Math.random() * 100000 + 1),
        ui_order: uiOrder ? uiOrder : 1,
      };
    },
    [defaultRequirementLevel]
  );

  // Update the retrieved requirements.
  useEffect(() => {
    if (jobRequirements && jobRequirements?.length > 0) {
      /*
                Based on the selected Job role in title page the requirements API will fetch requirements
                configured for that Job. If there are no predefined requirements available for a category,
                an empty record with that category Id and default requirement level id will be created.
            */
      let _jobRequirements: IRequirement[] = [];
      requirementsMetaData?.categories.forEach((rmd) => {
        let jobReqByCat = jobRequirements
          .filter((jr) => jr.requirement_category_id === rmd.id)
          .map((jR, indx) => {
            let newJr = { ...jR };
            newJr.ui_order = indx;
            return newJr;
          });
        if (jobReqByCat?.length) {
          _jobRequirements = _jobRequirements.concat(jobReqByCat);
          _jobRequirements.push(
            getDefaultRequirement(rmd.id, jobReqByCat.length + 1)
          );
        } else {
          _jobRequirements.push(getDefaultRequirement(rmd.id));
        }
      });
      _setJobDetails((prevJobDetails) => {
        return { ...prevJobDetails, requirements: _jobRequirements };
      });
      _setJobDetailsCopy((prevJobDetails) => {
        return { ...prevJobDetails, requirements: _jobRequirements };
      });
    } else {
      // Default records if there are no requirements saved.
      const def: IRequirement[] = [];
      requirementsMetaData?.categories.forEach((rmd) => {
        const defaultRecord: IRequirement = getDefaultRequirement(rmd.id);
        def.push(defaultRecord);
      });
      _setJobDetails((prevJobDetails) => {
        return { ...prevJobDetails, requirements: def };
      });
      _setJobDetailsCopy((prevJobDetails) => {
        return { ...prevJobDetails, requirements: def };
      });
    }
  }, [
    getDefaultRequirement,
    jobRequirements,
    requirementsMetaData?.categories,
  ]);

  const removeReq = (req: IRequirement) => {
    const delIndx = _jobDetails.requirements.findIndex(
      (r) => r.reqId === req.reqId
    );
    let tempReq = [..._jobDetails.requirements];
    tempReq.splice(delIndx, 1);
    _setJobDetails({ ..._jobDetails, requirements: tempReq });
  };

  const addReq = (req: IRequirement) => {
    const addIndx = _jobDetails.requirements.findIndex(
      (r) => r.reqId === req.reqId
    );
    const newReq: IRequirement = {
      name: "",
      requirement_category_id: req.requirement_category_id,
      requirement_level_id: defaultRequirementLevel,
      // Generate random number as id for job requirement to handle new job requirement additions and deletions.
      reqId: Math.floor(Math.random() * 100000 + 1),
      ui_order: req?.ui_order + 1,
    };
    let tempReq = [..._jobDetails.requirements];
    tempReq.splice(addIndx + 1, 0, newReq);
    _setJobDetails({ ..._jobDetails, requirements: tempReq });
  };

  const updateReq = (
    req: IRequirement,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tempReq: IRequirement[] = [];
    _jobDetails.requirements?.forEach((rq) => {
      let newReq = { ...rq };
      if (rq.reqId === req.reqId) {
        newReq.name = e.target.value;
      }
      tempReq.push(newReq);
    });
    _setJobDetails({ ..._jobDetails, requirements: tempReq });
  };

  const updateReqLvl = (req: IRequirement, e: SelectChangeEvent<number>) => {
    const tempReq: IRequirement[] = [];
    _jobDetails.requirements?.forEach((rq) => {
      let newReq = { ...rq };
      if (rq.reqId === req.reqId) {
        newReq.requirement_level_id = e.target.value as number;
      }
      tempReq.push(newReq);
    });
    _setJobDetails({ ..._jobDetails, requirements: tempReq });
  };

  const cOrUJobReq = () => {
    let payload = { ..._jobDetails };
    // Remove empty requirement fields if any.
    payload.requirements = _jobDetails.requirements.filter(
      (r) => r.name?.trim().length > 0
    );
    dispatch(
      createOrUpdateJobRequirements({
        jobId: parseInt(jobId ?? ""),
        payload: payload,
      })
    );
  };

  // Navigate to next page once Job is created or updated successfully.
  useEffect(() => {
    if (isRouteEnabled && createOrUpdateJobReqStatus === "success") {
      displayNotification({
        open: true,
        type: "success",
        message: createOrUpdateJobReqResponse,
      });
      dispatch(setIsPreviewEnabled(true));
      // Update 'allJobDetails' and 'jobRequirements' in shared state.
      const jD = { ..._jobDetails };
      // 'areRequirementsUpdated' boolean to check if the requirements are updated to update the preview rich text.
      jD.areRequirementsUpdated = !areAllElementsInArrayXInArrayY(
        _jobDetails.requirements.map((r) => r.name),
        jobRequirements.map((r) => r.name) ?? []
      );
      dispatch(updateAllJobDetails(jD));
      dispatch(updateJobDetails(jD));
      dispatch(updateJobRequirementsInSlice(jD.requirements));
      dispatch(resetCreateOrUpdateJobRequirements());
      if (jobId) {
        dispatch(getRequirements({ jobId: jobId }));
      }
      if (isBackClicked) {
        navigate(`/employer/job/${jobId}/title`);
      } else {
        navigate(`/employer/job/${jobId}/compensation`);
      }
    }
  }, [
    _jobDetails,
    createOrUpdateJobReqResponse,
    createOrUpdateJobReqStatus,
    dispatch,
    displayNotification,
    isBackClicked,
    isRouteEnabled,
    jobId,
    jobRequirements,
    navigate,
  ]);

  const showFailureAlerts = (apiState: ApiState, msg: string) => {
    switch (apiState) {
      case "failed":
        return (
          <>
            <Box marginBottom={2} width="100%">
              <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
            </Box>
          </>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    if (requirementsMetaData && jobRequirements?.length > 0) {
      dispatch(
        setIsPreviewEnabled(
          getInvalidCategories(jobRequirements, requirementsMetaData)
            ?.length === 0
        )
      );
    }
  }, [dispatch, jobRequirements, requirementsMetaData]);

  // Reset all states on unmount.
  useEffect(() => {
    return () => {
      dispatch(resetGetRequirements());
      dispatch(resetCreateOrUpdateJobRequirements());
      dispatch(resetCreateOrUpdateJob());
    };
  }, [dispatch]);

  const getIsMandatoryRow = (category: IRequirementCategory): boolean => {
    return (
      _jobDetails.requirements?.filter(
        (req) => req.requirement_category_id === category.id
      )?.length === 1
    );
  };

  // Job details action items stack in table cell.
  const JobDetailActionItems = ({
    addReq,
    removeReq,
    requirement,
    isMandatoryRow,
    isAddDisabled,
    isLastRow,
  }: IJobDetailActionItems) => {
    return (
      <>
        <Stack flexDirection="row" justifyContent="flex-end">
          {/* Hide 'Remove' for 1st record since at least one is mandatory */}
          {!isMandatoryRow && (
            <ShTooltip placement="left-end" title="Remove">
              <span>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => removeReq(requirement)}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </span>
            </ShTooltip>
          )}
          {isLastRow && (
            <ShTooltip placement="bottom-end" title="Add another">
              <span>
                <IconButton
                  size="small"
                  onClick={() => addReq(requirement)}
                  disabled={isAddDisabled}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </span>
            </ShTooltip>
          )}
        </Stack>
      </>
    );
  };

  const handleAiKeywordsGeneratorSubmit = async () => {
    if (jobTitleInfo?.name) {
      const payload: IAiJobDescriptionAndInterviewKitPayload = { role: "" };
      payload.role = jobTitleInfo.name;
      dispatch(getAiKeywordsPrediction(payload));
    }
  };

  useEffect(() => {
    if (getAiKeywordPredictionStatus === "success" && aiKeywordPredictions) {
      setKeywordChips((prevKeywordChips) => {
        const updatedKeywords = [
          ...new Set([...prevKeywordChips, ...aiKeywordPredictions]),
        ];

        _setJobDetails((prevJobDetails) => ({
          ...prevJobDetails,
          keywords: updatedKeywords.join(","),
        }));

        return updatedKeywords;
      });
    }
  }, [aiKeywordPredictions, getAiKeywordPredictionStatus]);

  // Disable next button.
  useEffect(() => {
    if (requirementsMetaData) {
      setIsNextDisabled(
        getInvalidCategories(_jobDetails.requirements, requirementsMetaData)
          ?.length > 0
      );
    }
  }, [_jobDetails.requirements, requirementsMetaData]);

  useEffect(() => {
    if (selectedCompensations.length > 0) {
      _setJobDetails((prevJobDetails) => ({
        ...prevJobDetails,
        additional_compensations: selectedCompensations
          .filter((item: string) => item.trim().length > 0)
          .join(","),
      }));
      setSelectedCompensations(selectedCompensations);
    }
  }, [selectedCompensations]);

  const onJobDetailsBack = () => {
    setIsBackClicked(true);
    cOrUJobReq();
  };

  // set keyword chips
  useEffect(() => {
    if (allJobDetails?.keywords) {
      // Filter out any empty strings
      const filteredKeywords = allJobDetails.keywords.filter(
        (keyword) => keyword.trim() !== ""
      );
      setKeywordChips(filteredKeywords);
    }
  }, [allJobDetails?.keywords]);

  const addNewKeyword = () => {
    const isKeywordEmpty = newKeywordToBeAdded.trim().length === 0;
    const isMaxKeywordsReached = keywordChips.length >= 15;
    const isKeywordDuplicate = keywordChips.includes(newKeywordToBeAdded);

    setErrorKeywordsEmptyTooltipOpen(isKeywordEmpty ? true : false);
    setErrorKeywordTooltipOpen(
      isMaxKeywordsReached || isKeywordDuplicate ? true : false
    );

    if (isKeywordEmpty || isMaxKeywordsReached || isKeywordDuplicate) return;

    setKeywordChips((prevChips) => {
      const updatedChips = [...prevChips, newKeywordToBeAdded];
      _setJobDetails({ ..._jobDetails, keywords: updatedChips.join(",") });
      return updatedChips;
    });

    setNewKeywordToBeAdded("");
  };

  const deleteKeywordChip = (keyword: string) => {
    const currChips = [...keywordChips];
    currChips.splice(
      currChips.findIndex((_keyword) => _keyword === keyword),
      1
    );
    setKeywordChips([...currChips]);
    _setJobDetails({ ..._jobDetails, keywords: [...currChips].join(",") });
  };

  useEffect(() => {
    if (jobId) {
      dispatch(fetchJobSummary({ jobId: Number(jobId) }));
    }
  }, [dispatch, jobId]);

  const ShowDetailsEditAlert = () => {
    const _jobId = Number(jobId);
    const _applicantsCount = jobsSummary[_jobId]?.totalApplicantsCount;
    return (
      <>
        {_applicantsCount !== undefined && _applicantsCount > 0 && (
          <ShAlert severity="warning">
            <Typography variant="body2">
              Please Note: Since you've already had people apply to your job, If
              you add new items below (important or must haves), only new
              candidates will be asked those additional questions. Please
              contact your friendly hiring coach if you have any questions.
            </Typography>
          </ShAlert>
        )}
      </>
    );
  };

  return (
    <Fade in={true} timeout={800}>
      <Stack alignContent="center">
        <ShPaper variant="outlined" borderRadius={0} headerSection>
          <Typography variant="subtitle1" gutterBottom>
            {" "}
            Enter job details and compensation
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Fill in the key information for the job position, including
            compensation, job duties, skills, qualifications, and more.
          </Typography>
        </ShPaper>
        <ShPaper variant="outlined" noBorderTop>
          <Box
            height="100%"
            maxHeight="100%"
            overflow="auto"
            paddingX={{ xs: 0, sm: 2, md: 3, lg: 3 }}
          >
            <Stack
              className="elements-stack"
              rowGap={2.5}
              minHeight="350px"
              justifyContent="center"
            >
              {ShowDetailsEditAlert()}
              {showFailureAlerts(
                createOrUpdateJobReqStatus,
                createOrUpdateJobReqResponse
              )}
              {showFailureAlerts(
                getRequirementsMetaDataStatus,
                getRequirementsMetaDataResponse
              )}
              {showFailureAlerts(
                getRequirementsStatus,
                getRequirementsResponse
              )}
              {getAdditionalCompensationsStatus &&
                getAdditionalCompensationsResponse &&
                showFailureAlerts(
                  getAdditionalCompensationsStatus,
                  getAdditionalCompensationsResponse
                )}
              {/* Requirements  */}
              {getRequirementsMetaDataStatus === "pending" ||
              getRequirementsStatus === "pending" ? (
                <ShAlert severity="info">Loading Job requirements...</ShAlert>
              ) : (
                <>
                  {requirementsMetaData?.categories?.map((category) => (
                    <Stack key={category.id} width="100%">
                      <Typography
                        variant="body2"
                        component={"span"}
                        fontWeight={600}
                        gutterBottom
                      >
                        <Stack
                          columnGap={1}
                          flexDirection="row"
                          alignItems="center"
                        >
                          {RequirementCategoryMap[category.name]}
                          <ShTooltip
                            title={JobRequirementCategoryTooltip[category.name]}
                            placement="right"
                          >
                            <HelpOutlineIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </ShTooltip>
                        </Stack>
                      </Typography>
                      <TableContainer component={Paper} variant="outlined">
                        <Table size="small" aria-label="Job details table">
                          <TableBody>
                            {/* Filter the categories based on the requirement category id. Sort the array while rendering */}
                            {_jobDetails?.requirements
                              ?.filter(
                                (req) =>
                                  req.requirement_category_id === category.id
                              )
                              .sort((a, b) => a.ui_order - b.ui_order)
                              .map((r, indx) => (
                                <TableRow key={r.reqId}>
                                  {category.name === "responsibility" ? (
                                    <TableCell>
                                      <Stack
                                        flexDirection="row"
                                        justifyContent="space-between"
                                      >
                                        <ShTextFieldV2
                                          variant="standard"
                                          id={r.name + "_" + indx}
                                          placeholder={
                                            indx === 0
                                              ? "Add at least one Job Duty"
                                              : "Add Another Job Duty"
                                          }
                                          value={r.name}
                                          onChange={(e) => updateReq(r, e)}
                                          fullWidth
                                          error={
                                            MandatoryRequirementCategoryIds.includes(
                                              category.id
                                            ) &&
                                            getIsMandatoryRow(category) &&
                                            !r.name
                                          }
                                        />
                                        {/* Filter the categories based on the requirement category id and check for last record. */}
                                        <JobDetailActionItems
                                          addReq={addReq}
                                          removeReq={removeReq}
                                          requirement={r}
                                          isAddDisabled={!r.name}
                                          isMandatoryRow={getIsMandatoryRow(
                                            category
                                          )}
                                          isLastRow={
                                            indx ===
                                            _jobDetails?.requirements?.filter(
                                              (req) =>
                                                req.requirement_category_id ===
                                                category.id
                                            ).length -
                                              1
                                          }
                                        />
                                      </Stack>
                                    </TableCell>
                                  ) : (
                                    <>
                                      <TableCell>
                                        <ShTextFieldV2
                                          variant="standard"
                                          placeholder={
                                            indx === 0
                                              ? "Add at least one " +
                                                RequirementCategoryMap[
                                                  category.name
                                                ]
                                              : "Add Another " +
                                                RequirementCategoryMap[
                                                  category.name
                                                ]
                                          }
                                          value={r.name}
                                          onChange={(e) => updateReq(r, e)}
                                          fullWidth
                                          error={
                                            MandatoryRequirementCategoryIds.includes(
                                              category.id
                                            ) &&
                                            getIsMandatoryRow(category) &&
                                            !r.name
                                          }
                                        />
                                      </TableCell>
                                      <TableCell className="selection-cell">
                                        <Select
                                          name={`${r.name}_${indx}`}
                                          size="small"
                                          variant="standard"
                                          value={
                                            r.requirement_level_id ??
                                            defaultRequirementLevel
                                          }
                                          onChange={(e) => updateReqLvl(r, e)}
                                        >
                                          {requirementsMetaData?.levels?.map(
                                            (lvl) => (
                                              <MenuItem
                                                key={lvl.id}
                                                value={lvl.id}
                                              >
                                                {RequirementLevelMap[lvl.name]}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        className="action-cell"
                                      >
                                        <JobDetailActionItems
                                          addReq={addReq}
                                          removeReq={removeReq}
                                          requirement={r}
                                          isAddDisabled={!r.name}
                                          isMandatoryRow={getIsMandatoryRow(
                                            category
                                          )}
                                          isLastRow={
                                            indx ===
                                            _jobDetails?.requirements?.filter(
                                              (req) =>
                                                req.requirement_category_id ===
                                                category.id
                                            ).length -
                                              1
                                          }
                                        />
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  ))}
                </>
              )}

              <FormControl fullWidth margin="dense">
                <FormLabel htmlFor="keywords_field">
                  <Typography component={"span"} variant="body2" gutterBottom>
                    <Stack
                      columnGap={1}
                      flexDirection="row"
                      alignItems="center"
                    >
                      Keywords
                      <ShTooltip
                        title="Filter applicants by keywords to find the best match for your job! 
                                                Use keywords to improve resume scoring and focus on candidates who have the most relevant skills. 
                                                You can add new keywords or remove existing ones on your job's Job Details page."
                        placement="right"
                      >
                        <HelpOutlineIcon fontSize="small" color="disabled" />
                      </ShTooltip>
                    </Stack>
                  </Typography>
                </FormLabel>
                <Stack rowGap={1} marginTop={1}>
                  {keywordChips.length < 8 && (
                    <ShAlert severity="info">
                      Adding more keywords will help identify better matching
                      candidates.
                    </ShAlert>
                  )}
                  {errorKeywordTooltipOpen && (
                    <ShAlert severity="warning">
                      Maximum number of keywords (15) reached or added a
                      duplicate keyword.
                    </ShAlert>
                  )}
                  {errorKeywordsEmptyTooltipOpen && (
                    <ShAlert severity="warning">
                      Enter a keyword: Keyword cannot be empty.
                    </ShAlert>
                  )}

                  {getAiKeywordPredictionStatus === "pending" && (
                    <CircularProgress size="1.5rem" />
                  )}
                  <Stack
                    direction="row"
                    columnGap={1}
                    rowGap={1}
                    flexWrap="wrap"
                  >
                    {keywordChips?.map((keyword) => (
                      <Chip
                        key={keyword}
                        label={keyword}
                        variant="outlined"
                        onDelete={() => deleteKeywordChip(keyword)}
                      />
                    ))}
                    <ShTextFieldV2
                      size="small"
                      variant="outlined"
                      id="keyword"
                      label="Add a Keyword"
                      value={newKeywordToBeAdded}
                      onChange={(e) => setNewKeywordToBeAdded(e.target.value)}
                      maxWidth="280px"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          addNewKeyword();
                        }
                      }}
                      disabled={getAiKeywordPredictionStatus === "pending"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box display="flex" alignItems="center" gap={2}>
                              <ShTooltip
                                title="Add Keyword"
                                placement="left-end"
                              >
                                <IconButton edge="end" onClick={addNewKeyword}>
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </ShTooltip>

                              <ShTooltip
                                title="Generate keywords with AI"
                                placement="right-end"
                              >
                                <IconButton
                                  edge="end"
                                  onClick={handleAiKeywordsGeneratorSubmit}
                                  style={{ paddingLeft: 16 }}
                                >
                                  <ShChip
                                    label="New"
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    icon={<AutoAwesomeIcon />}
                                  />
                                </IconButton>
                              </ShTooltip>
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </Stack>

                {/* <TextField id='keywords_field' variant="outlined" size='small' placeholder='Enter keywords to match'
                                value={_jobDetails.keywords ?? ''} onChange={(e) => _setJobDetails({ ..._jobDetails, keywords: e.target.value })} fullWidth /> */}
              </FormControl>
              <JobDetailsActionsStack paddingY={1}>
                <ShButton
                  variant="contained"
                  size="small"
                  disableElevation
                  onClick={onJobDetailsBack}
                >
                  Back
                </ShButton>
                <Stack direction="row" columnGap={1}>
                  {isShowUndo && jobDetails && (
                    <ShButton
                      variant="outlined"
                      onClick={() => _setJobDetails({ ..._jobDetailsCopy })}
                    >
                      Undo
                    </ShButton>
                  )}
                  <ShGreenBtn
                    variant="contained"
                    disableElevation
                    onClick={cOrUJobReq}
                    disabled={
                      isNextDisabled || createOrUpdateJobReqStatus === "pending"
                    }
                  >
                    {createOrUpdateJobReqStatus === "pending"
                      ? "Saving..."
                      : "Next"}
                  </ShGreenBtn>
                </Stack>
              </JobDetailsActionsStack>
            </Stack>
          </Box>
        </ShPaper>
      </Stack>
    </Fade>
  );
};
