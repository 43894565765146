import { PLAN_FEATURES_MAP, featureLabels } from "Modules/Core/SettingsTs/AccountSettings/Subscriptions/SubscriptionsConstants";
import { SubscriptionPlanFeatures, SubscriptionPlan } from "Modules/Core/SettingsTs/AccountSettings/Subscriptions/SubscriptionsModel";
import { ICompanyInfo } from "Modules/Core/SettingsTs/SettingsModel";

interface PermissionResponse {
  hasSubscriptionPermission: boolean;
  dialogMessage?: string;
}

export const hasFeaturePermission = (
  companyInfo: ICompanyInfo,
  featureType: keyof SubscriptionPlanFeatures,
  currentUsage?: number // Pass the current usage of candidates,jobs,career page jobs, etc when checking limits
): PermissionResponse => {
  
  const subscriptionPlan = companyInfo.subscription_plan as SubscriptionPlan;
  if (!subscriptionPlan || !(subscriptionPlan in PLAN_FEATURES_MAP)) {
    return {
      hasSubscriptionPermission: false,
      dialogMessage: "Your company does not have an active subscription plan.",
    };
  }

  const planFeatures = PLAN_FEATURES_MAP[subscriptionPlan];
  const featureLimit = planFeatures[featureType];

  // If feature is a boolean, return basesdfd on aceccess
  if (typeof featureLimit === "boolean") {
    return featureLimit
      ? { hasSubscriptionPermission: true }
      : {
        hasSubscriptionPermission: false,
          dialogMessage: `Your current plan does not include the "${featureLabels[featureType]}" feature. Please upgrade to access this feature.`,
        };
  }

  // If feature is a number or "Unlimited", check the userlimits
  if (typeof featureLimit === "number") {
    if (currentUsage !== undefined && currentUsage >= featureLimit) {
      return {
        hasSubscriptionPermission: false,
        dialogMessage: `You have reached the limit for "${featureLabels[featureType]}". Your plan allows up to ${featureLimit}. Please upgrade to increase this limit.`,
      };
    }
    return { hasSubscriptionPermission: true };
  }

  // If feature is "Unlimited", always allow
  if (featureLimit === "Unlimited") {
    return { hasSubscriptionPermission: true };
  }

  return {
    hasSubscriptionPermission: false,
    dialogMessage: "Invalid feature configuration. Please contact your account manager frida@smoothhiring.com",
  };
};
