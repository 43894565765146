import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TuneIcon from '@mui/icons-material/Tune';
import WorkIcon from '@mui/icons-material/Work';
import { StrongFitIcon, TemplateIcon, UseTemplateJobIcon } from "assets/Icons";
import { CreateJobPath, IJobTitle, IJobTitleTooltips, ITestimonialData, JobDescriptionType, TJobRequirementCategoryTooltip, TRequirementLevelMap, TRequirementTypeMap } from "Modules/Core/CreateJob/CreateJob/CreateJobModel";
import { ILabelValueBase, IShFeatureTile } from "shared/SharedModels";
import { ShAvatar } from "shared/SharedStyles/ShDataDisplay";
import { JobLocationType } from "store/slices/employer/jobs/jobs-slice-model";

export const FullTimeId = 4;
export const JobValidity = 30;
export const MandatoryRequirementCategoryIds = [2, 3, 4];
export const TaxRate = 0.06;

export const DefaultState = 'Remote';

export const CreateJobStageMap: Partial<Record<CreateJobPath, number>> = {
    'title': 0,
    'details': 1,
    'preview': 2
};

export const CreateJobPaths: CreateJobPath[] = ['details', 'preview', 'title', 'bundles', 'payment', 'additional-questions', 'eeoQuestionnaire'];

export const JobLocationTypes: ILabelValueBase<string, JobLocationType>[] = [
    { label: 'Remote', value: 'Fully Remote' },
    { label: 'Onsite', value: 'Onsite' },
    { label: 'Hybrid', value: 'Hybrid' }
];


export const ExperienceLevels: ILabelValueBase[] = [
    { label: 'Internship', value: 'Internship' },
    { label: 'Entry Level', value: 'Entry Level' },
    { label: 'Mid Level', value: 'Mid Level' },
    { label: 'Experienced', value: 'Experienced' },
    { label: 'Associate', value: 'Associate' },
    { label: 'Senior', value: 'Senior' },
    { label: 'Director', value: 'Director' },
    { label: 'Executive', value: 'Executive' }
];

export const RequirementCategoryMap: TRequirementTypeMap = {
    education: 'Education',
    qualification: 'Qualification',
    responsibility: 'Job Duties',
    skill: 'Skill'
};

export const RequirementLevelMap: TRequirementLevelMap = {
    important: 'Important',
    must_have: 'Must have',
    nice_to_have: 'Nice to have'
};

export const JobDescriptionTypes: (ILabelValueBase<string, JobDescriptionType> & { icon: JSX.Element })[] = [
    // Removed at the request of Sri
    {
        label: 'Use SmoothHiring',
        value: 'sh',
        icon: <StrongFitIcon fontSize='small' />
    },
    {
        label: 'Customize this Description',
        value: 'custom',
        icon: <TuneIcon fontSize='small' />
    },
    {
        label: 'Use AI Job Description',
        value: 'ai',
        icon: <AutoAwesomeIcon fontSize='small' />
    }
];

export const Months: ILabelValueBase[] = [
    { label: 'January', value: 'January' },
    { label: 'February', value: 'February' },
    { label: 'March', value: 'March' },
    { label: 'April', value: 'April' },
    { label: 'May', value: 'May' },
    { label: 'June', value: 'June' },
    { label: 'July', value: 'July' },
    { label: 'August', value: 'August' },
    { label: 'September', value: 'September' },
    { label: 'October', value: 'October' },
    { label: 'November', value: 'November' },
    { label: 'December', value: 'December' },
];

export const Years: ILabelValueBase[] = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
    { label: '2026', value: '2026' },
    { label: '2027', value: '2027' },
    { label: '2028', value: '2028' },
    { label: '2029', value: '2029' },
    { label: '2030', value: '2030' },
    { label: '2031', value: '2031' },
    { label: '2032', value: '2032' },
];

export const TestimonialData: ITestimonialData[] = [
    { review: "It’s the best service I have ever used for finding an employee! Love it! I have never experienced as many applicants for a position. We got over 105 applicants in 10 days! Awesome!", name: "Jim Williams - All Property Solutions, North Carolina" },
    { review: "SmoothHiring was like having a head-hunter screening applicants before I saw the resume. I found the process to be worth the investment for this reason alone. I didn't have to waste time scouring through dozens of applications to find the one or two that fit my needs.", name: "Derek Sparta – Sparta Graphics, California" },
];

export const JobRequirementCategoryTooltip: TJobRequirementCategoryTooltip = {
    education: `Select the minimum required education for your position. 
    The more strict your requirements, the fewer candidates you'll attract.`,
    qualification: `Qualifications are a simple way to identify who doesn’t meet your minimum requirements. 
                Answered by candidates with Yes/No questions, examples are legal status,
                 languages as well as industry specific accreditation's/designations, 
                experience and software or tools etc.  We've provided common qualifications based on the role.`,
    responsibility: `What does a day in the life look like for the person holding this job?
                Describe the key responsibilities, and sell the job to your ideal candidate!`,
    skill: `Select the skills needed for your position. The more strict your requirements, the fewer candidates you'll attract.`
};

export const JobTitleTooltips: IJobTitleTooltips = {
    additional_hiring_manager: `This is the person hiring for this role. They will receive notifications of new applicants.`,
    employment_type: `Most applicants have a preference for the type of work they're looking for and their schedules.
     In addition, this sets expectations about compensation for the role. 
     Sorry, but we do not accept work from home, business opportunities, or multi level marketing opportunities.`,
    experience: `Let applicants know what level of experience you're looking for. 
    "Entry Level" is less than one year."Experienced" is more than one year.`,
    hiring_manager: `This is the person hiring for this role. They will receive notifications of new applicants.`,
    job_location: `Specify where the applicant would be working - not necessarily the headquarters.`,
    neighborhood: `You may be hiring in a specific area of town. Make sure to mention where that is.`,
    role: `Choose a job role from the list of common jobs in your industry. Don't see what you're looking for? Pick the closest match. 
    You can also enter your own job title in the Job Title field. Tip: simple and common titles work best, so don't get too creative.`,
    job_title: 'Choose a job title which the job seeker can easily relate to. short and  simple job titles are easy for seraching.',
    "job_location_hybrid": "Employees work on-site and also off-site.",
    "job_location_onsite": "Employees work in-person at the location.",
    "job_location_remote": "Employees work off-site."
}

export const JobTitleInitialValues: IJobTitle = {
    job_title_id: 0,
    name: '',
    hiring_manager_id: null,
    optional_hiring_manager_id: null,
    remote_job_type: null,
    city: '',
    postalcode: '',
    province_id: 0,
    neighbourhood: '',
    experience: '',
    positiontype_id: null,
    description: '',
    is_complete: false,
    is_published_to_careers_page: false

}

export const TemplateJobFeatureTiles: IShFeatureTile[] = [
    {
        tileLabel: 'Create a Template Job',
        featureRedirectUrl: '/employer/job/new/title#templateJob',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <TemplateIcon />
        </ShAvatar>,
        caption: 'Design tailored job templates to streamline your hiring process.',
        featureName: 'Create Template Job'
    },
    {
        tileLabel: 'Use a Template Job',
        featureRedirectUrl: '/employer/jobs/template',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <UseTemplateJobIcon />
        </ShAvatar>,
        caption: 'Use existing templates to simplify job posting and attract top talent.',
        featureName: 'Use Template Job'
    },
    {
        tileLabel: 'Create a Regular Job',
        featureRedirectUrl: '/employer/job/new/title',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <WorkIcon />
        </ShAvatar>,
        caption: 'Create fully customized job posting from scratch.',
        featureName: 'Create Job'
    }
];

export const TemplateJobFeatureTilesDefault: IShFeatureTile[] = [
    {
        tileLabel: 'Repost an existing job',
        featureRedirectUrl: '/employer/jobs/expired',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <TemplateIcon />
        </ShAvatar>,
        featureName: 'Repost an existing job',
        caption: 'Repost an existing job and do not carry the candidates over.'
    },
    {
        tileLabel: 'Create a New Job',
        featureRedirectUrl: '/employer/job/new/title',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <UseTemplateJobIcon />
        </ShAvatar>,
        featureName: 'Create a New Job',
        caption: 'Create fully customized job posting from scratch.'
    },
    {
        tileLabel: 'Continue from saved job',
        featureRedirectUrl: '/employer/jobs/draft',
        tileIcon: <ShAvatar color='success' height={50} width={50} svgSize='30px'>
            <WorkIcon />
        </ShAvatar>,
        caption: 'Create a job posting from draft jobs',
        featureName: 'Continue from saved job'
    }
];

export const JobPositionsTypes = [
    { id: 1, label: 'Contractor (1099)' },
    { id: 2, label: 'Any' },
    { id: 3, label: 'Part-Time' },
    { id: 4, label: 'Full-Time' },
    { id: 5, label: 'Temporary' },
    { id: 6, label: 'Contractor (W2)' },
    { id: 7, label: 'Internship' },
    { id: 8, label: 'Work at Home' },
    { id: 9, label: 'Casual' },
    { id: 10, label: 'Contract' },
];

export const DefaultBenefits = ['Paid time off', 'Childcare', 'Disability and long term insurance', 'Training', 'Healthcare'];