import { Alert, Container, Divider, Paper, Stack, Typography } from "@mui/material";
import { SocialLogin } from "Modules/Core/AuthV2/SocialLogin";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { ShButton, ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { EmailRegEx } from "shared/constants";
import { checkIfAccountExists, logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { IApplyJobEmailValidation } from "./ApplyJobModel";

export const ApplyJobEmailValidation = ({ setCurrentJobApplyStep, candidateEmail, setCandidateEmail }: IApplyJobEmailValidation) => {

    const dispatch = useAppDispatch();
    const { checkIfAccountExistsStatus, checkIfAccountExistsResponseMsg } = useAppSelector((state) => state.auth.auth);
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);

    /**
     * Email validation function with Regular Expression.
     * */
    const validateEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInvalid(!reg.test(e.target.value));
    };

    // logout on this component load
    useEffect(() => {
        dispatch(logoutAccount({ logoutType: 'auto' }));
    }, [dispatch]);

    return (<>
        {checkIfAccountExistsStatus === 'failed' &&
            <Alert severity='error' action={<ShButton
                onClick={() => dispatch(checkIfAccountExists({ email: candidateEmail ?? '' }))}>Retry</ShButton>}>
                {checkIfAccountExistsResponseMsg}</Alert>
        }
        <Container maxWidth='xs'>
            <Paper variant='outlined'>
                <Stack rowGap={3} alignSelf='center' alignItems='center'
                    padding={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    <Stack rowGap={2} alignItems='center'>
                        <ShTextFieldV2 label="Email Address" variant="outlined" fullWidth size='small' required
                            value={candidateEmail} error={isEmailInvalid}
                            onChange={(e) => { setCandidateEmail(e.target.value); validateEmail(e) }}
                            helperText={isEmailInvalid ? 'Please enter a valid email' : ''}
                        ></ShTextFieldV2>
                        <ShGreenBtn size='small' variant='outlined'
                            disabled={checkIfAccountExistsStatus === 'pending' || isEmailInvalid || !candidateEmail}
                            onClick={() => dispatch(checkIfAccountExists({ email: candidateEmail ?? '' }))}>
                            {checkIfAccountExistsStatus === 'pending' ? 'Checking...' : 'Continue'}</ShGreenBtn>
                    </Stack>
                    <Typography variant="caption">Or</Typography>
                    <ShButton variant="outlined" onClick={() => setCurrentJobApplyStep('resume_upload')}>Upload Resume</ShButton>
                    <Divider flexItem orientation="horizontal">
                        <Typography variant="caption">Or continue with</Typography>
                    </Divider>
                    <SocialLogin buttonType="icon" usedIn='candidate_apply_job' />
                </Stack>
            </Paper>
        </Container>
    </>);
}