import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IAccountInfo } from "Modules/Core/SettingsTs/SettingsModel";
import { compressImage } from "helpers/fileHandlers";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import {
  ShFileUploadButton,
  ShGreenBtn,
  ShTextFieldV2,
} from "shared/SharedStyles/ShInputs";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { DefaultAPIErrorMsg, EmailRegEx } from "shared/constants";
import { updateAccountDetails } from "store/slices/auth-v2/auth-v2-slice";
import {
  getAccInfo,
  getUserImage,
  resetUpdateAccInfo,
  updateAccountInfo,
  updateUserImage,
} from "store/slices/employer/settingsTs/acc-info-slice";

export const AccountInfo = () => {
  const dispatch = useAppDispatch();
  const {
    getAccInfoApiStatus,
    getAccInfoApiResponse,
    accountInfo,
    updateAccInfoApiStatus,
    updateAccInfoApiResponse,
    getUserImageApiStatus,
    userImage,
    updateUserImageApiStatus,
    updateUserImageApiResponse,
  } = useAppSelector((state) => state.employer.employerSettings.accInfo);
  const [accInfo, setAccInfo] = useState<IAccountInfo>({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    calendly_token: "",
    applicant_notifications: false,
    timezone: "",
  });
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [_userImage, _setUserImage] = useState<{
    userImage?: File;
    userImageUrl: string;
  }>();
  const { timezones } = useAppSelector((state) => state.common.timezones);

  // get account information
  useEffect(() => {
    if (!accountInfo.id) {
      dispatch(getAccInfo());
    }

    return () => {
      // Reset update account infoAPIvariables on component unmount.
      dispatch(resetUpdateAccInfo());
    };
  }, [accountInfo.id, dispatch]);

  // update account information form after the data is retrieved
  useEffect(() => {
    if (getAccInfoApiStatus === "success" && accountInfo !== undefined) {
      const tempAccInfo = {
        first_name: accountInfo.first_name,
        last_name: accountInfo.last_name,
        email: accountInfo.email,
        calendly_token: "",
        id: accountInfo.id,
        applicant_notifications: accountInfo.applicant_notifications,
        timezone: accountInfo.timezone,
      };
      setAccInfo(tempAccInfo);
    }
  }, [accountInfo, getAccInfoApiStatus]);

  useEffect(() => {
    if (getUserImageApiStatus === "success" && userImage) {
      _setUserImage({ userImageUrl: userImage });
    }
  }, [getUserImageApiStatus, userImage]);

  // This is just a good measure to ensure the acc info view is updated after its been changed
  // otherwise the old name will still show until refresh
  useEffect(() => {
    if (updateAccInfoApiStatus === "success") {
      dispatch(getAccInfo());
      dispatch(getUserImage());
    }
  }, [updateAccInfoApiStatus, dispatch]);

  // Email validation function with Regular Expression.
  const validateEmail = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const reg = new RegExp(EmailRegEx);
    setIsEmailInvalid(!reg.test(e.target.value));
  };

  // Disable save if any required field is empty or email is invalid
  useEffect(() => {
    setIsSaveDisabled(() => {
      return (
        accInfo.first_name.trim()?.length === 0 ||
        accInfo.last_name.trim()?.length === 0 ||
        accInfo.email.trim()?.length === 0 ||
        isEmailInvalid ||
        !accInfo.timezone
      );
    });
  }, [accInfo, isEmailInvalid]);

  const updateAccInfo = () => {
    const { first_name, last_name, applicant_notifications, timezone } =
      accInfo;
    dispatch(
      updateAccountInfo({
        userId: accInfo.id,
        payload: { first_name, last_name, applicant_notifications, timezone },
      })
    );
    if (_userImage?.userImage) {
      compressImage(_userImage?.userImage)
        .then((cmpFile) => {
          // Pass image as form data with name 'logo'
          let formData = new FormData();
          formData.append("logo", cmpFile);
          dispatch(updateUserImage({ payload: formData }));
        })
        .catch((err) => console.error(err));
    }
  };

  const InfoUpdateAlerts = () => {
    switch (updateAccInfoApiStatus) {
      case "pending":
        return <ShAlert severity="info">Updating Information...</ShAlert>;
      case "success":
        return (
          <ShAlert
            severity="success"
            onClose={() => dispatch(resetUpdateAccInfo())}
          >
            <Typography fontWeight={600}>{updateAccInfoApiResponse}</Typography>
          </ShAlert>
        );
      case "failed":
        return <ShAlert severity="error">{updateAccInfoApiResponse}</ShAlert>;
      default:
        return <></>;
    }
  };

  // update first and last name in auth state once account details are updated.
  useEffect(() => {
    if (updateAccInfoApiStatus === "success") {
      const { first_name, last_name } = accInfo;
      dispatch(
        updateAccountDetails({ firstName: first_name, lastName: last_name })
      );
    }
  }, [accInfo, dispatch, updateAccInfoApiStatus]);

  const trimFileName = (fileName: string, maxLength: number) => {
    if (fileName?.length > maxLength) {
      return fileName?.substring(0, maxLength) + "...";
    }
    return fileName;
  };

  return (
    <>
      <Fade in={true} timeout={800}>
        <Stack>
          <ShPaper variant="outlined" borderRadius={0} headerSection>
            <Typography variant="subtitle1"> Account Information</Typography>
            <Typography variant="caption" color="textSecondary">
              Update the following details to keep your account up to date
            </Typography>
          </ShPaper>
          <ShPaper variant="outlined" borderRadius={0} noBorderTop>
            <Stack
              rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
              minHeight="250px"
              marginTop={1}
            >
              {/* Alerts placeholder block with min height to maintain height consistency while showing alerts. */}
              {getAccInfoApiStatus === "failed" ? (
                <Box marginBottom={2} width="100%">
                  <ShAlert severity="error">
                    {getAccInfoApiResponse ?? DefaultAPIErrorMsg}
                  </ShAlert>
                </Box>
              ) : (
                updateAccInfoApiStatus !== "idle" && (
                  <Box marginBottom={2}>{InfoUpdateAlerts()}</Box>
                )
              )}
              {updateUserImageApiStatus === "failed" && (
                <ShAlert severity="error">{updateUserImageApiResponse}</ShAlert>
              )}
              {/*Show loading spinner whileAPIis fetching data*/}
              {getAccInfoApiStatus === "pending" ? (
                <LinearProgress />
              ) : (
                <>
                  <Stack
                    direction="row"
                    columnGap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                    rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                    maxWidth={"1000px"}
                    flexWrap="wrap"
                  >
                    <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} flex={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ShTextFieldV2
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            size="small"
                            required
                            value={accInfo.first_name}
                            onChange={(e) =>
                              setAccInfo({
                                ...accInfo,
                                first_name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ShTextFieldV2
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            required
                            value={accInfo.last_name}
                            onChange={(e) =>
                              setAccInfo({
                                ...accInfo,
                                last_name: e.target.value,
                              })
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ShTextFieldV2
                            fullWidth
                            label="Email Address"
                            variant="outlined"
                            size="small"
                            required
                            disabled
                            value={accInfo.email}
                            onChange={(e) => {
                              setAccInfo({ ...accInfo, email: e.target.value });
                              validateEmail(e);
                            }}
                            error={isEmailInvalid}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <ShTextFieldV2
                            fullWidth
                            size="small"
                            required
                            label="Timezone"
                            value={accInfo.timezone}
                            onChange={(e) =>
                              setAccInfo({
                                ...accInfo,
                                timezone: e.target.value,
                              })
                            }
                            select
                          >
                            {timezones?.map((timezone, index) => (
                              <MenuItem key={index} value={timezone.tz}>
                                {timezone.name} ({timezone.abbr})
                              </MenuItem>
                            ))}
                          </ShTextFieldV2>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Divider orientation="vertical" flexItem/>
                    <Box paddingLeft = {2}
                    >
                      <Stack
                        rowGap={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {_userImage?.userImageUrl && (
                          <img
                            width={200}
                            height={200}
                            src={_userImage?.userImageUrl}
                            alt="User Img"
                          />
                        )}
                        <ShFileUploadButton
                          className="file-select-btn"
                          component="label"
                          variant="outlined"
                          sx={{
                            padding: "8px 16px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            textTransform: "none",
                          }}
                        >
                          <input
                            accept="image/*"
                            type="file"
                            hidden
                            onChange={(e) =>
                              e.target.files !== null
                                ? _setUserImage({
                                    userImage: e.target.files[0],
                                    userImageUrl: URL.createObjectURL(
                                      e.target.files[0]
                                    ),
                                  })
                                : ""
                            }
                          />
                          <CloudUploadIcon />
                          {_userImage?.userImage ? (
                            <Typography
                              variant="body2"
                              fontStyle="italic"
                              ml={"8px"}
                            >
                              {trimFileName(_userImage?.userImage?.name, 20)}
                            </Typography>
                          ) : (
                            "Upload Profile Photo"
                          )}
                        </ShFileUploadButton>
                      </Stack>
                    </Box>
                  </Stack>
                  {/* <TextField label="Calendly Token" variant="outlined" fullWidth size='small'
                                value={accInfo.calendly_token} onChange={(e) => setAccInfo({ ...accInfo, calendly_token: e.target.value })}></TextField> */}
                  <Box pl={2}>
                    <FormControlLabel
                      label={
                        <Typography variant="body2" ml={1}>
                          New Applicant Notifications
                        </Typography>
                      }
                      control={
                        <ShSwitch
                          size="medium"
                          checked={accInfo.applicant_notifications}
                          onChange={(e) =>
                            setAccInfo({
                              ...accInfo,
                              applicant_notifications: e.target.checked,
                            })
                          }
                        />
                      }
                    />
                  </Box>
                  <ShGreenBtn
                    variant="contained"
                    disableElevation
                    onClick={updateAccInfo}
                    disabled={
                      isSaveDisabled || updateAccInfoApiStatus === "pending"
                    }
                    startIcon={
                      updateAccInfoApiStatus === "pending" ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    {updateAccInfoApiStatus === "pending"
                      ? "Saving Changes..."
                      : "Save Changes"}
                  </ShGreenBtn>
                </>
              )}
            </Stack>
          </ShPaper>
        </Stack>
      </Fade>
    </>
  );
};
