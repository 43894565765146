import { Stack } from '@mui/material';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useAppSelector, useAppDispatch } from 'helpers/hooks';
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import {
  resetZoomCreation,
  resetZoomUpdate,
  resetZoomDelete,
} from 'store/slices/employer/applicants/applicant-actions-slice';

const ShowStatus = () => {
  const dispatch = useAppDispatch();
  const {
    zoomMeetCreationStatus,
    zoomMeetCreationResponse,
    zoomMeetUpdateResponse,
    zoomMeetUpdateStatus,
    zoomMeetDeleteStatus,
    zoomMeetDeleteResponse,
  } = useAppSelector((state) => state.employer.applicants.applicantActions);

  const {
    createInterviewScheduleStatus,
    createInterviewScheduleResponse,
    updateInterviewScheduleStatus,
    updateInterviewScheduleResponse,
    updateInterviewRequestResponse,
    updateInterviewRequestStatus,
    scheduleInterviewResponse,
    scheduleInterviewStatus,
    createScheduledInterviewStatus,
    createScheduledInterviewResponse,
  } = useAppSelector((state) => state.employer.interviews.interviewsActions);

  const showAlerts = (
    apiState: ApiState,
    pendingText: string,
    responseText: string,
    callBack?:
      | ActionCreatorWithoutPayload<'applicantActions/resetZoomCreation'>
      | ActionCreatorWithoutPayload<'applicantActions/resetZoomUpdate'>
      | ActionCreatorWithoutPayload<'applicantActions/resetZoomDelete'>
      | undefined
  ) => {
    switch (apiState) {
      case 'pending':
        return (
          <ShAlert stickyAlert marginBottom={2} severity="info">
            {pendingText}
          </ShAlert>
        );
      case 'success':
        return (
          <ShAlert
            stickyAlert
            marginBottom={2}
            severity="success"
            onClose={() => (callBack ? dispatch(callBack()) : '')}
          >
            {responseText}
          </ShAlert>
        );
      case 'failed':
        return (
          <ShAlert stickyAlert marginBottom={2} severity="error">
            {responseText}
          </ShAlert>
        );
      default:
        return <></>;
    }
  };

  return (
    <Stack>
      {showAlerts(
        zoomMeetCreationStatus,
        'Creating Zoom Meet...',
        zoomMeetCreationResponse,
        resetZoomCreation
      )}
      {showAlerts(
        zoomMeetUpdateStatus,
        'Updating Zoom Meet...',
        zoomMeetUpdateResponse,
        resetZoomUpdate
      )}
      {showAlerts(
        zoomMeetDeleteStatus,
        'Deleting Zoom Meet...',
        zoomMeetDeleteResponse,
        resetZoomDelete
      )}
      {showAlerts(
        createInterviewScheduleStatus,
        'Creating Interview schedule...',
        createInterviewScheduleResponse
      )}
      {showAlerts(
        createScheduledInterviewStatus,
        'Creating Scheduled Interview...',
        createScheduledInterviewResponse
      )}
      {showAlerts(
        updateInterviewScheduleStatus,
        'Updating Interview schedule...',
        updateInterviewScheduleResponse
      )}
      {showAlerts(
        updateInterviewRequestStatus,
        'Updating Interview Request...',
        updateInterviewRequestResponse
      )}
      {showAlerts(
        scheduleInterviewStatus,
        'Scheduling interview...',
        scheduleInterviewResponse
      )}
    </Stack>
  );
};

export default ShowStatus;
