import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IEmployerDetails, IEmployerDetailsState } from './employer-details-model';
import { IAutoRejectCriteria } from 'Modules/Core/SettingsTs/ToolsAndAutomation/Automation/AutoReject/CriteriaModel';
import { ILetterTemplate } from 'Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/LettersModel';
import { IForm } from 'Modules/Core/SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplateModel';

const initialEmployerCustomState: IEmployerDetailsState = {};

export const getEmployerDetails = createAsyncThunk<IBaseResponse<IEmployerDetails>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerDetails",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}`)
            .then((response: AxiosResponse<IBaseResponse<IEmployerDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// Thunk to fetch employe letters
export const getEmployerLettersTemplates = createAsyncThunk<IBaseResponse<ILetterTemplate[]>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerLettersTemplates",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/letters-templates`)
            .then((response: AxiosResponse<IBaseResponse<ILetterTemplate[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);


// Thunk to add, remove, or update employer letters
export const addRemoveOrUpdateEmployerLetterTemplates = createAsyncThunk<IBaseResponse, { empId: number, action: string, template: ILetterTemplate }, { rejectValue: IBaseResponse }>(
    "addRemoveOrUpdateEmployerLetterTemplates",
    async ({ empId, action, template }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/letters-templates`, { action, template })
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// Thunk to fetch employer auto reject criteria presets
export const getEmployerRejectCriteria = createAsyncThunk<IBaseResponse<IAutoRejectCriteria[]>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerRejectCriteria",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/rejection-criteria`)
            .then((response: AxiosResponse<IBaseResponse<IAutoRejectCriteria[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);


// Thunk to add, remove, or update auto reject criterias
export const addRemoveOrUpdateEmployerRejectCriteria = createAsyncThunk<IBaseResponse, { empId: number, action: string, criteria: IAutoRejectCriteria }, { rejectValue: IBaseResponse }>(
    "addRemoveOrUpdateEmployerRejectCriteria",
    async ({ empId, action, criteria }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/rejection-criteria`, { action, criteria })
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// Thunk to fetch employer application form templates
export const getEmployerFormsTemplates = createAsyncThunk<IBaseResponse<IForm[]>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerFormsTemplates",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/application-form-templates`)
            .then((response: AxiosResponse<IBaseResponse<IForm[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// Thunk to add, remove, or update employer application form templates
export const addRemoveOrUpdateEmployerFormTemplates = createAsyncThunk<IBaseResponse, { empId: number, action: string, form: IForm }, { rejectValue: IBaseResponse }>(
    "addRemoveOrUpdateEmployerFormTemplates",
    async ({ empId, action, form }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/application-form-templates`, { action, form })
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// New actions for selected job form
export const getSelectedJobForm = createAsyncThunk<IBaseResponse<IForm>, { jobId: string }, { rejectValue: IBaseResponse }>(
    "getSelectedJobForm",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/job-form`)
            .then((response: AxiosResponse<IBaseResponse<IForm>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const saveSelectedJobForm = createAsyncThunk<IBaseResponse, { jobId: string, selectedForm: IForm }, { rejectValue: IBaseResponse }>(
    "saveSelectedJobForm",
    async ({ jobId, selectedForm }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOBS}/${jobId}/job-form`, { jobId, selectedForm })
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);



const employerDetailsSlice = createSlice({
    name: 'employerDetails',
    initialState: initialEmployerCustomState,
    reducers: {
        resetGetEmployerDetails: (state) => {
            state.getEmployerDetailsStatus = 'idle';
            state.getEmployerDetailsResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialEmployerCustomState;
        });
        // get employer details
        builder.addCase(getEmployerDetails.pending, (state) => {
            state.getEmployerDetailsStatus = 'pending'
        });
        builder.addCase(getEmployerDetails.fulfilled, (state, action) => {
            state.getEmployerDetailsStatus = 'success';
            state.employerDetails = action.payload.data;
            if (state.employerDetails.free_trail_job_id) {
                state.employerDetails.free_trail_signup = false;
            }
        });
        builder.addCase(getEmployerDetails.rejected, (state, action) => {
            state.getEmployerDetailsStatus = 'failed';
            state.getEmployerDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Get employer offer letters
        builder.addCase(getEmployerLettersTemplates.pending, (state) => {
            state.getLettersStatus = 'pending';
        });
        builder.addCase(getEmployerLettersTemplates.fulfilled, (state, action) => {
            state.getLettersStatus = 'success';
            state.employerLetters = action.payload.data;
        });
        builder.addCase(getEmployerLettersTemplates.rejected, (state, action) => {
            state.getLettersStatus = 'failed';
            state.getLettersResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Add, remove, or update employer letter templates
        builder.addCase(addRemoveOrUpdateEmployerLetterTemplates.pending, (state) => {
            state.getLettersStatus = 'pending';
        });
        builder.addCase(addRemoveOrUpdateEmployerLetterTemplates.fulfilled, (state, action) => {
            state.getLettersStatus = 'success';
        });
        builder.addCase(addRemoveOrUpdateEmployerLetterTemplates.rejected, (state, action) => {
            state.getLettersStatus = 'failed';
            state.getLettersResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        
        // Get employer auto reject criteria
        builder.addCase(getEmployerRejectCriteria.pending, (state) => {
            state.getAutoRejectCriteraStatus = 'pending';
        });
        builder.addCase(getEmployerRejectCriteria.fulfilled, (state, action) => {
            state.getAutoRejectCriteraStatus = 'success';
            state.employerRejectCriteria = action.payload.data;
        });
        builder.addCase(getEmployerRejectCriteria.rejected, (state, action) => {
            state.getAutoRejectCriteraStatus = 'failed';
            state.getRejectCriteriaResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Add, remove, or update employer reject criteria
        builder.addCase(addRemoveOrUpdateEmployerRejectCriteria.pending, (state) => {
            state.updateAutoRejectCriteraStatus = 'pending';
        });
        builder.addCase(addRemoveOrUpdateEmployerRejectCriteria.fulfilled, (state, action) => {
            state.updateAutoRejectCriteraStatus = 'success';
        });
        builder.addCase(addRemoveOrUpdateEmployerRejectCriteria.rejected, (state, action) => {
            state.updateAutoRejectCriteraStatus = 'failed';
            state.updateRejectCriteriaResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Get employer application form templates
        builder.addCase(getEmployerFormsTemplates.pending, (state) => {
            state.getFormsStatus = 'pending';
        });
        builder.addCase(getEmployerFormsTemplates.fulfilled, (state, action) => {
            state.getFormsStatus = 'success';
            state.employerForms = action.payload.data;
        });
        builder.addCase(getEmployerFormsTemplates.rejected, (state, action) => {
            state.getFormsStatus = 'failed';
            state.getFormsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Add, remove, or update employer form templates
        builder.addCase(addRemoveOrUpdateEmployerFormTemplates.pending, (state) => {
            state.getFormsStatus = 'pending';
        });
        builder.addCase(addRemoveOrUpdateEmployerFormTemplates.fulfilled, (state, action) => {
            state.getFormsStatus = 'success';
        });
        builder.addCase(addRemoveOrUpdateEmployerFormTemplates.rejected, (state, action) => {
            state.getFormsStatus = 'failed';
            state.getFormsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

          // Get selected job form
          builder.addCase(getSelectedJobForm.pending, (state) => {
            state.getSelectedJobFormStatus = "pending";
        });
        builder.addCase(getSelectedJobForm.fulfilled, (state, action) => {
            state.getSelectedJobFormStatus = "success";
            state.selectedJobForm = action.payload.data;
        });
        builder.addCase(getSelectedJobForm.rejected, (state, action) => {
            state.getSelectedJobFormStatus = "failed";
            state.getSelectedJobFormResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // Save selected job form
        builder.addCase(saveSelectedJobForm.pending, (state) => {
            state.saveSelectedJobFormStatus = "pending";
        });
        builder.addCase(saveSelectedJobForm.fulfilled, (state, action) => {
            state.saveSelectedJobFormStatus = "success";
        });
        builder.addCase(saveSelectedJobForm.rejected, (state, action) => {
            state.saveSelectedJobFormStatus = "failed";
            state.saveSelectedJobFormResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    
    }
});

export const { resetGetEmployerDetails } = employerDetailsSlice.actions;
export default employerDetailsSlice;
