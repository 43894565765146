import { Box, Link } from "@mui/material";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from "store/slices/app/breadcrumb-slice";
import { JobType } from "store/slices/employer/jobs/jobs-slice-model";
import { IJob } from "../JobsModel";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShBadge } from "Modules/Core/Dashboard/Dashboard.styled";
import NotificationsIcon from '@mui/icons-material/Notifications'; // Bubble Icon for new applicants

export const ApplicantsCount = ({ job, usedIn, jobsType }: { job: IJob, usedIn: 'Table' | 'List', jobsType: JobType }) => {
    const isXsScreen = IsXsScreen();
    const dispatch = useAppDispatch();
    const { paginationParams } = useAppSelector(state => state.employer.employerJobs.jobsList);

    const gotoApplicants = () => {
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({
            labelReg: {
                [job.id]: `${job.name}`,
                [`jobs_${job.id}_page`]: `${paginationParams.requestedPageNumber}`,
                [`jobs_${job.id}_isExpand`]: 'false',
            }
        }));
    };

    return (
        <>
            {(job.new_stage_count !== undefined && job.new_stage_count > 0) ? (
                <ShTooltip title="Applicants in 'New' stage" placement="top">
                    <Link component={RouterLink} onClick={gotoApplicants} to={`/employer/applicants/${job.id}?stage=new`}>
                        <ShBadge color="primary" badgeContent={job.new_stage_count} bgColor="#FF8C00">
                            <NotificationsIcon sx={{ fontSize: isXsScreen ? 15 : 20 }} />
                        </ShBadge>
                    </Link>
                </ShTooltip>
            ) : (usedIn === 'List' && !isXsScreen) ? (
                <Box height={30} width={30}></Box> 
            ) : (
                <></>
            )}
        </>
    );
};
