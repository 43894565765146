import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { AddAttendeesDialogProps } from "Modules/Core/Interviews/InterviewsModel";
import { IAccount } from "Modules/Core/SettingsTs/SettingsModel";
import { useEffect, useState } from "react";
import { EmailRegEx } from "shared/constants";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import {
  ShButton,
  ShGreenBtn,
  ShTextareaWrapper,
} from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { toTitleCase } from "shared/utils";
import { getAccountList } from "store/slices/employer/settingsTs/access-levels-slice";

const AddAttendeesDialog = ({
  isDialogOpen,
  selectedAttendees,
  setSelectedAttendees,
  onClose,
  title,
  subTitle,
  allowCustomEmails,
}: AddAttendeesDialogProps) => {
  const isSmScreen = IsSmScreen();
  const dispatch = useAppDispatch();
  const [customEmails, setCustomEmails] = useState("");
  const [areEmailsInvalid, setAreEmailsInvalid] = useState<boolean>(false);
  const { accountList, getAccountListApiStatus } = useAppSelector(
    (state) => state.employer.employerSettings.accessLevels
  );
  const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>([]);
  const [customEmailsArray, setCustomEmailsArray] = useState<string[]>([]);

  const onEmailsChange = (value: string) => {
    setCustomEmails(value);
    const emails = value.split(",").map((email) => email.trim());
    const regX = new RegExp(EmailRegEx);
    const isValidEmails = emails.every((email) => regX.test(email));
    setAreEmailsInvalid(!isValidEmails);
  };

  useEffect(() => {
    if (getAccountListApiStatus === "idle") {
      dispatch(getAccountList());
    }
  }, [dispatch, getAccountListApiStatus]);

  useEffect(() => {
    const selectedAccAttendees: IAccount[] = [];
    const selectedCustomAttendees: string[] = [];

    selectedAttendees.forEach((selectedEmail) => {
      const matchingAccount = accountList.find(
        (acc) => acc.account.email === selectedEmail
      );

      if (matchingAccount) {
        selectedAccAttendees.push(matchingAccount);
      } else {
        selectedCustomAttendees.push(selectedEmail);
      }
    });

    setSelectedAccounts(selectedAccAttendees);
    setCustomEmails(selectedCustomAttendees.join(", "));
  }, [selectedAttendees, accountList]);

  useEffect(() => {
    const emails = customEmails
      .split(",")
      .slice(0, 25)
      .map((email) => email.trim())
      .filter((email) => email.length > 0);

    setCustomEmailsArray(emails);
  }, [customEmails]);

  const handleCheckboxChange = (account: IAccount) => {
    setSelectedAccounts((prevSelected) =>
      prevSelected.some((acc) => acc.account_id === account.account_id)
        ? prevSelected.filter((acc) => acc.account_id !== account.account_id)
        : [...prevSelected, account]
    );
  };

  const handleAddAttendees = () => {
    const selectedAccEmails = selectedAccounts.map((acc) => acc.account.email);
    setSelectedAttendees([...selectedAccEmails, ...customEmailsArray]);
    onClose(false);
  };

  const [isEditing, setIsEditing] = useState(false); // New state to track editing mode

  useEffect(() => {
    // When the dialog is opened, determine if we're in editing mode based on the attendees
    if (isDialogOpen) {
      setIsEditing(selectedAttendees.length > 0);
    }
  }, [isDialogOpen, selectedAttendees]);

  return (
    <ShDialog fullScreen={isSmScreen} open={isDialogOpen} onClick={(e) => e.stopPropagation()} aria-labelledby="title">
      <DialogTitle id="title" textAlign="center">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box>{title}</Box>
          <IconButton onClick={() => onClose(false)}>
            <ClearIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap={3}>
          <Stack>
            <Typography variant="body1" gutterBottom>
              {subTitle}
            </Typography>
            {getAccountListApiStatus === "success" && (
              <Stack direction="column" gap={1}>
                {accountList.map((acc) => (
                  <ShPaper key={acc.account_id} variant="outlined" cursor="pointer" noPadding transElevateOnHover onClick={() => handleCheckboxChange(acc)}
                    borderStyle="solid" borderColor={
                      selectedAccounts.some(
                        (selectedAcc) => selectedAcc.account_id === acc.account_id
                      ) ? ShGreen : undefined}>

                    <Stack key={acc.account_id} direction="column" alignItems="flex-start" justifyContent="space-between">
                      <Stack direction="row" alignItems="center" width="100%">
                        <Checkbox
                          checked={selectedAccounts.some(
                            (selectedAcc) =>
                              selectedAcc.account_id === acc.account_id
                          )}
                        />
                        <Stack width='100%' p={0.75}>
                          <Typography fontSize={16}>
                            {acc.fullName ?? "Unknown Name"}
                          </Typography>
                          <Stack direction='row' justifyContent='space-between' >
                            <Typography fontSize={14} color="gray">
                              {acc.account?.email ?? "No Email"}
                            </Typography>
                            <Typography fontSize={14} color="gray">
                              {toTitleCase(acc.role?.name.replace("_", " ") ?? "No Role")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </ShPaper>

                ))}

                {accountList.length === 0 && (
                  <Stack alignItems="center" justifyContent="center" height='200px'>
                    <Typography variant="body2" color="textSecondary" fontSize='16px'>
                      No additional attendees found
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
            {getAccountListApiStatus === "pending" && (
              <Stack alignItems="center">
                <Typography variant="body2" color="textSecondary" alignContent="center">
                  Loading...
                </Typography>
              </Stack>
            )}
            {getAccountListApiStatus === "failed" && (
              <Stack alignItems="center">
                <ShButton onClick={() => dispatch(getAccountList())} variant="contained" color="error">
                  Try again
                </ShButton>
              </Stack>
            )}
          </Stack>
          {allowCustomEmails && (
            <Stack>
              <FormControl fullWidth>
                <FormLabel id="to_emails">
                  <Typography color="black" variant="body1" >
                    Need to add more attendees? Enter their email id(s) below:
                  </Typography>
                </FormLabel>
                <ShTextareaWrapper>
                  <textarea
                    id="to_emails"
                    value={customEmails}
                    onChange={(e) => onEmailsChange(e.target.value)}
                  />
                  {customEmails?.length > 4 && areEmailsInvalid && (
                    <Typography variant="caption" color="red">
                      Please enter valid Emails.
                    </Typography>
                  )}
                  {customEmails.split(",").length > 25 && (
                    <Typography variant="caption" color="red">
                      You can only send up to 25 emails. Please remove some
                      emails.
                    </Typography>
                  )}
                </ShTextareaWrapper>
              </FormControl>
              <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={1}>
                {customEmailsArray.map((email, index) => (
                  <Chip size="small" key={index} label={email} />
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <ShButton onClick={() => onClose(false)} variant="contained">
            Cancel
          </ShButton>
          <ShGreenBtn
            variant="contained"
            size="small"
            onClick={handleAddAttendees}
            disabled={areEmailsInvalid && !!customEmails}
          >
            {isEditing ? "Done" : "Add"}
          </ShGreenBtn>
        </Stack>
      </DialogActions>
    </ShDialog>
  );
};

export default AddAttendeesDialog;