import { Box, CssBaseline, GlobalStyles, PaletteMode, ThemeProvider, autocompleteClasses, createTheme, responsiveFontSizes } from "@mui/material";
import { ShRouter } from "Router";
import { useAppSelector } from "helpers/hooks";
import { BorderColorDark, BorderColorLight, PaperBorderRadius, PrimaryThemeColor, PrimaryWordpressThemeColor, SHMenuBoxShadow, ShGreen, WhiteColor, shBlue } from "shared/SharedStyles/styleConstants";
import CheckIcon from "@mui/icons-material/Check";

const App = () => {
  const { curTheme } = useAppSelector((state) => state.app.theme);
  const theme = responsiveFontSizes(createTheme({
    palette: {
      mode: curTheme as PaletteMode,
      primary: { main: curTheme === 'light' ? PrimaryThemeColor : WhiteColor },
      success: { main: ShGreen }
    },
    typography: {
      fontFamily: "Poppins, Roboto, sans-serif",
    },
    components: {
      MuiAppBar: { defaultProps: { color: 'inherit' } },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            borderColor: curTheme === 'light' ? BorderColorLight : BorderColorDark,
            borderRadius: PaperBorderRadius
          },
          elevation: {
            borderRadius: PaperBorderRadius
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: curTheme === 'light' ? '#ffffff' : '#333333',
            borderRadius: PaperBorderRadius,
            border: '1px solid',
            borderColor: curTheme === 'light' ? BorderColorLight : BorderColorDark,
            boxShadow: SHMenuBoxShadow,
            padding: '4px 0',
          },
          list: {
            padding: 0,
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: curTheme === 'light' ? shBlue : 'white', 
              borderLeft: `3px solid ${PrimaryWordpressThemeColor}`, 
            },
          },
        },
      },

      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              style: {
                backgroundColor: curTheme === 'light' ? '#ffffff' : '#333333',
                borderColor: curTheme === 'light' ? BorderColorLight : BorderColorDark,
                borderRadius: PaperBorderRadius,
                borderStyle: 'solid',
                boxShadow: SHMenuBoxShadow,
              },
            },
          },
        },
      },

      MuiAutocomplete: {
        defaultProps: {
          renderOption: (props, option, state, ownerState) => {
            const { key, ...optionProps } = props;
            const isSelected = state.selected;
            return (
              <Box
                key={key}
                sx={{
                  [`&.${autocompleteClasses.option}`]: {
                    transition: "all 0.2s ease",
                    paddingTop: "4px",
                    justifyContent: "space-between",
                    borderLeft: isSelected ? `3px solid ${PrimaryWordpressThemeColor}` : "none",
                    backgroundColor: isSelected
                      ? curTheme === "light"
                        ? shBlue
                        : "#444444"
                      : "transparent",
                    color: isSelected
                      ? curTheme === "light"
                        ? shBlue
                        : "white"
                      : "inherit",
                    "&:hover": {
                      backgroundColor: isSelected ? curTheme === "light" ? "#b2ebf2" : "#555555"
                        : curTheme === "light"
                        ? "#f0f0f0"
                        : "#444444",
                    },
                  },
                }}
                component="li"
                {...optionProps}>
                {ownerState.getOptionLabel(option)}
                {isSelected && <CheckIcon color='primary' fontSize="small"/>}
              </Box>
            );
          },
          componentsProps: {
            paper: {
              sx: {
                backgroundColor: curTheme === "light" ? "#ffffff" : "#333333",
                borderColor: curTheme === "light" ? BorderColorLight : BorderColorDark,
                borderRadius: PaperBorderRadius,
                borderStyle: "solid",
                boxShadow: SHMenuBoxShadow,
              },
            },
          },
        },
      },
      
    },
  }));

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* Necessary due to bold looking different in safari & other browsers */}
        <GlobalStyles
          styles={{
            '*': {
              fontSynthesis: 'none',
            },
            'strong, b': {
              fontWeight: 0,
            },
          }}
        />
        <CssBaseline />
        <ShRouter />
      </ThemeProvider>
    </>
  );
};

export default App;
