import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { CommonApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';

interface Message {
  sender: "bot" | "user";
  text: string;
  accountId?: string;
}

interface ChatbotState {
  messages: Message[];
  status: "idle" | "loading" | "success" | "failed";
}

interface SendMessageResponse {
    message: string;
}
  
const initialState: ChatbotState = {
  messages: [
    {
      sender: "bot",
      text: "Hi there! Feel free to send us a message, and our support team will get back to you within 8 hours on weekdays (usually sooner!) and within 24-48 hours on weekends.",
    },
  ],
  status: "idle",
};

// Thunk to send user message to backend
export const sendMessageToSupport = createAsyncThunk<SendMessageResponse, { accountId: string; emailBody: string }, { rejectValue: IBaseResponse } >(
  'chatbot/sendMessageToSupport', 
  ({ accountId, emailBody }, { rejectWithValue }) => {
    return httpAdapterInstance
      .post(CommonApiEndpoints.CHATBOT, { accountId, emailBody })  
      .then((response: AxiosResponse<SendMessageResponse>) => {return response.data; })
      .catch((error: any) => {
        return rejectWithValue(error.response?.data || { message: "Failed to send message." });
      });
  }
);


const chatbotSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    addUserMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    addBotResponse: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    clearChat: (state) => {
      state.messages = [
        {
          sender: "bot",
          text: "Hi there! Feel free to send us a message, and our support team will get back to you within 8 hours on weekdays (usually sooner!) and within 24-48 hours on weekends.",
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessageToSupport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendMessageToSupport.fulfilled, (state) => {
        state.status = "success";
        state.messages.push({
          sender: "bot",
          text: "We've sent an email to our support team. We will get back to you within 8 hours on weekdays (often earlier!) and 24-48 hours on weekends.",
        });
      })
      .addCase(sendMessageToSupport.rejected, (state, action) => {
        state.status = "failed";
        state.messages.push({
          sender: "bot",
          text: `Error: ${action.payload}`,
        });
      });
  },
});

export const { addUserMessage, addBotResponse, clearChat } = chatbotSlice.actions;
export default chatbotSlice;
