
import { AppWrapper } from "Modules/Core/AppWrapper";
import { SessionExpired } from "Modules/Core/AuthV2/SessionExpired";
import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { AiInterviewQuestionsPage } from "Modules/Marketing/Resources/AiInterviewQuestionsPage";
import AiJobDescriptionPage from "Modules/Marketing/Resources/AiJobDescriptionPage";
import { ResourcesHome } from "Modules/Marketing/Resources/ResourceHome";
import { ResourcesWrapper } from "Modules/Marketing/Resources/ResourcesWrapper";
import DescriptionTemplateHome from "Modules/Marketing/Resources/Templates/JobDescriptionTemplates/DescriptionTemplateHome";
import DescriptionTemplatePage from "Modules/Marketing/Resources/Templates/JobDescriptionTemplates/DescriptionTemplatePage";
import OfferTemplateHome from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateHome";
import { PolicyTemplateHome } from "Modules/Marketing/Resources/Templates/PolicyTemplates/PolicyTemplateHome";
import { PolicyTemplatePage } from "Modules/Marketing/Resources/Templates/PolicyTemplates/PolicyTemplatePage";
import { ToolsHome } from "Modules/Marketing/Tools/ToolsHome";
import { ToolsWrapper } from "Modules/Marketing/Tools/ToolsWrapper";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { getResourcesRedirect, getToolsRedirect } from "shared/utils";
import { AdminRouter } from "./AdminRouter";
import { AuthRouter } from "./AuthRouter";
import { CandidateRouter } from "./CandidateRouter";
import { EmployerRouter } from "./Employer/EmployerRouter";
import { EmployerSubDomainRouter } from "./EmployerSubDomainRouter";
import { OfferLetterTemplatesRouter } from "./OfferLetterTemplatesRouter";

export const ShRouter = () => {
    return (
        <BrowserRouter>
            <Routes>

                {/* Root routes */}
                <Route path="/" element={<AppWrapper />}>
                    <Route path="/session-expired" element={<SessionExpired />} />

                    {/* Error 404 page */}
                    <Route path="*" element={<ErrorPage />} />

                    {/* Account Authentication */}
                    <Route path="/" element={<Navigate replace to="/accounts/" />} />
                    <Route path="/accounts/*" element={<AuthRouter />} />

                    {/* Admin routes */}
                    <Route path="/admin/*" element={<AdminRouter />} />

                    {/* Employer routes*/}
                    <Route path="/employer/*" element={<EmployerRouter />} />

                    {/* Candidate */}
                    <Route path="/candidate/*" element={<CandidateRouter />} />
                </Route>

                {/* Marketing Resources */}
                <Route path="/" element={<ResourcesWrapper />}>
                    <Route path={getResourcesRedirect('resourcesHome')} element={<ResourcesHome />} />
                    {/* AI Generated Tools */}
                    <Route path={getResourcesRedirect('aiJobDescription')} element={<AiJobDescriptionPage />} />
                    <Route path={getResourcesRedirect('aiInterviewKit')} element={<AiInterviewQuestionsPage />} />
                    {/* Templates (ex. offer templates, job desc templates, policy templates, etc.) */}
                    <Route path={getResourcesRedirect('jobTemplatesHome')} element={<DescriptionTemplateHome />} />
                    <Route path={getResourcesRedirect('jobTemplateDetails')} element={<DescriptionTemplatePage />} />
                    <Route path={getResourcesRedirect('policyTemplatesHome')} element={<PolicyTemplateHome />} />
                    <Route path={getResourcesRedirect('policyTemplateDetails')} element={<PolicyTemplatePage />} />
                    <Route path={getResourcesRedirect('offerLetterTemplates')} element={<OfferTemplateHome />} />
                    <Route path="resources/offer-letter-templates/*" element={<OfferLetterTemplatesRouter />} />
                </Route>

                {/* Tools */}
                <Route path="/" element={<ToolsWrapper />}>
                    <Route path={getToolsRedirect('toolsHome')} element={<ToolsHome />} />
                </Route>
                {/* Employer Career Pages*/}
                <Route path="/jobs/*" element={<EmployerSubDomainRouter />} />
                <Route path="/careers/*" element={<EmployerSubDomainRouter />} />
            </Routes>
        </BrowserRouter>
    );
}