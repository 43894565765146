import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { Calendar } from "Modules/Core/Calendar/Calendar";
import { Stack, Typography } from "@mui/material";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { DashboardTileBordeRadius } from "../DashboardConstants";

export const DashboardCalendar = () => {

    return (
        <Stack height='550px'>
            <ShPaper variant="outlined" style={{ height: '550px', overflow: 'auto' }} borderRadius={DashboardTileBordeRadius}>
                <Stack direction="row" alignItems="center" spacing={1} padding={1}>
                    <CalendarMonthOutlined color="primary" />
                    <Typography>Calendar</Typography>
                </Stack>
                <Calendar/>
            </ShPaper>
        </Stack>
    )
}
