import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICompanyInfo, ICompanyInfoState, IUpdateCompanyInfoPayload } from "Modules/Core/SettingsTs/SettingsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";

const initialCompanyInfoState: ICompanyInfoState = {
    getCompanyInfoApiStatus: 'idle',
    getCompanyInfoApiResponse: '',
    companyInfo: {
        id: 0, company_name: '', subscription_plan: undefined, subscription_status: undefined, website: '', phone_number: '', phone_area: '', formatted_ph_no: '', company_logo: '', is_eeo_enabled: undefined,
        company_logo_url: '', avatar: '', calendly_token: '', country: { name: '' }, state: { name_en: '' }, masking_name: '',
        address: { city: '', country_id: 0, createdAt: '', id: 0, postalcode: '', province_id: 0, updatedAt: '' },
        stripe_subscription_id: "",
    },
    getCompanyLogoApiStatus: 'idle',
    getCompanyLogoApiResponse: '',
    companyLogo: '',
    updateCompanyInfoApiStatus: 'idle',
    updateCompanyInfoApiResponse: '',
    updateCompanyLogoApiStatus: 'idle',
    updateCompanyLogoApiResponse: '',

    getCompanyBannerApiStatus: 'idle',
    getCompanyBannerApiResponse: '',
    companyBanner: '',
    updateCompanyBannerApiStatus: 'idle',
    updateCompanyBannerApiResponse: '',
};

export const getCompanyInfo = createAsyncThunk<IBaseResponse<ICompanyInfo>, void, { rejectValue: IBaseResponse }>(
    "getCompanyInfo",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.GET_COMPANY_INFO}`)
            .then((response: AxiosResponse<IBaseResponse<ICompanyInfo>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getCompanyLogo = createAsyncThunk<IBaseResponse<{ s3Url: string }>, void, { rejectValue: IBaseResponse }>(
    "getCompanyLogo",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS_LOGO}`)
            .then((response: AxiosResponse<IBaseResponse<{ s3Url: string }>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateCompanyInfo = createAsyncThunk<IBaseResponse, { id: number, payload: IUpdateCompanyInfoPayload }, { rejectValue: IBaseResponse }>(
    "updateCompanyInfo",
    async ({ id, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.EMPLOYERS}/${id}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateCompanyLogo = createAsyncThunk<IBaseResponse, { payload: FormData }, { rejectValue: IBaseResponse }>(
    "updateCompanyLogo",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS_LOGO}`, payload.payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateCompanyBanner = createAsyncThunk<IBaseResponse, { payload: FormData }, { rejectValue: IBaseResponse }>(
    "updateBannerLogo",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS_BANNER_LOGO}`, payload.payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getCompanyBanner = createAsyncThunk<IBaseResponse<{ s3Url: string }>,{ empId: number },  { rejectValue: IBaseResponse }>(
  "getCompanyBanner",
  async ({ empId }, { rejectWithValue }) => { 
    return await httpAdapterInstance
      .post(`${EmployerApiEndpoints.EMPLOYERS_GET_BANNER_LOGO}`, { empId })  
      .then((response: AxiosResponse<IBaseResponse<{ s3Url: string }>>) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);

const companyInfoSlice = createSlice({
    name: "companyInfo",
    initialState: initialCompanyInfoState,
    reducers: {
        resetGetCompanyInfo: (state) => { state.getCompanyInfoApiStatus = 'idle'; state.getCompanyInfoApiResponse = '' },
        resetUpdateCompany: (state) => { state.updateCompanyInfoApiStatus = 'idle'; state.updateCompanyInfoApiResponse = '' },
        resetLogoUpdate: (state) => { state.updateCompanyLogoApiStatus = 'idle'; state.updateCompanyLogoApiResponse = '' },
        resetBannerUpdate: (state) => { state.updateCompanyBannerApiStatus = 'idle'; state.updateCompanyBannerApiResponse = '' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialCompanyInfoState;
        });
        //handle company information
        builder.addCase(getCompanyInfo.pending, (state) => {
            state.getCompanyInfoApiStatus = 'pending';
        });
        builder.addCase(getCompanyInfo.fulfilled, (state, action) => {
            state.getCompanyInfoApiStatus = 'success';
            state.getCompanyInfoApiResponse = action?.payload?.message ?? DefaultAPISuccessMsg;
            state.companyInfo = action?.payload?.data;
        });
        builder.addCase(getCompanyInfo.rejected, (state, action) => {
            state.getCompanyInfoApiStatus = 'failed';
            state.getCompanyInfoApiResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        //get company logo
        builder.addCase(getCompanyLogo.pending, (state) => {
            state.getCompanyLogoApiStatus = 'pending';
        });
        builder.addCase(getCompanyLogo.fulfilled, (state, action) => {
            state.getCompanyLogoApiStatus = 'success';
            state.getCompanyLogoApiResponse = action?.payload?.message ?? DefaultAPISuccessMsg;
            state.companyLogo = action?.payload?.data?.s3Url;
        });
        builder.addCase(getCompanyLogo.rejected, (state, action) => {
            state.getCompanyLogoApiStatus = 'failed';
            state.getCompanyLogoApiResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        //update company info
        builder.addCase(updateCompanyInfo.pending, (state) => {
            state.updateCompanyInfoApiStatus = 'pending';
        });
        builder.addCase(updateCompanyInfo.fulfilled, (state, action) => {
            state.updateCompanyInfoApiStatus = 'success';
            state.updateCompanyInfoApiResponse = action?.payload?.message;
        });
        builder.addCase(updateCompanyInfo.rejected, (state, action) => {
            state.updateCompanyInfoApiStatus = 'failed';
            state.updateCompanyInfoApiResponse = action?.payload?.message;
        });
        //update company logo
        builder.addCase(updateCompanyLogo.pending, (state) => {
            state.updateCompanyLogoApiStatus = 'pending';
        });
        builder.addCase(updateCompanyLogo.fulfilled, (state, action) => {
            state.updateCompanyLogoApiStatus = 'success';
            state.updateCompanyLogoApiResponse = action.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(updateCompanyLogo.rejected, (state, action) => {
            state.updateCompanyLogoApiStatus = 'failed';
            state.updateCompanyLogoApiResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });

         //update banner logo
         builder.addCase(updateCompanyBanner.pending, (state) => {
            state.updateCompanyBannerApiStatus = 'pending';
        });
        builder.addCase(updateCompanyBanner.fulfilled, (state, action) => {
            state.updateCompanyBannerApiStatus = 'success';
            state.updateCompanyBannerApiResponse = action.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(updateCompanyBanner.rejected, (state, action) => {
            state.updateCompanyBannerApiStatus = 'failed';
            state.updateCompanyBannerApiResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });

        // get company banner
        builder.addCase(getCompanyBanner.pending, (state) => {
            state.getCompanyBannerApiStatus = 'pending';
        });
        builder.addCase(getCompanyBanner.fulfilled, (state, action) => {
            state.getCompanyBannerApiStatus = 'success';
            state.getCompanyBannerApiResponse = action?.payload?.message ?? DefaultAPISuccessMsg;
            state.companyBanner= action?.payload?.data?.s3Url;
        });
        builder.addCase(getCompanyBanner.rejected, (state, action) => {
            state.getCompanyBannerApiStatus = 'failed';
            state.getCompanyBannerApiResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    },
});

export const { resetGetCompanyInfo, resetUpdateCompany, resetLogoUpdate, resetBannerUpdate } = companyInfoSlice.actions;
export default companyInfoSlice;
