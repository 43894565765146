import { Skeleton, Stack, Toolbar, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { ContactInfo } from "shared/SharedComponents/ContactInfo";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { DesktopOnlyStack } from "shared/SharedStyles/ShLayouts";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { getCareerPageDetails, getCompanyLogo } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";

export const EmployerSubDomainTopNav = () => {
    const dispatch = useAppDispatch();
    const {employerByMask,companyLogo, getCompanyLogoApiStatus, getCompanyLogoApiResponse} = useAppSelector(state => state.employer.employerSubDomain);
    const { careerPage} = useAppSelector(state => state.employer.employerSubDomain);

    useEffect(() => {
        if (employerByMask?.id && !careerPage) {
            dispatch(getCareerPageDetails({ employerId: employerByMask?.id }))
           
        }
    }, [dispatch, employerByMask?.id, careerPage]);

      // get Company Logo from backend 
      useEffect(() => {
        if (!companyLogo && employerByMask?.id ) {
          dispatch(getCompanyLogo({ empId: employerByMask?.id  }));
        }
      }, [dispatch, employerByMask?.id , companyLogo]);

    
    return (
        <ShAppBar position="sticky" sx={{borderBottom: `4px solid ${careerPage?.primary_color || ShGreen}` }}>
            <Toolbar>
                <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                    <Stack direction='row' spacing={2} pl={1} alignItems='center'>                
                        {(companyLogo === undefined) ?
                            <Typography variant='body2'>(250px in 250px)</Typography> :
                            getCompanyLogoApiStatus === 'pending' ?
                                <Skeleton width='25%' height='100%' animation='wave' /> :
                                getCompanyLogoApiStatus === 'success' && companyLogo ?
                                    <a href={`https://${employerByMask?.website}`}>
                                        <img src={companyLogo} alt="Company Logo" style={{ width: '100%', height: 'auto', maxHeight: '75px' }} />
                                    </a>
                                    :
                                    <>
                                        {getCompanyLogoApiStatus === 'failed' &&
                                            <ShAlert severity="error">
                                                {getCompanyLogoApiResponse}
                                            </ShAlert>}
                                    </>
                        }
                        {employerByMask?.company_name && 
                            <Typography variant="h6" color='gray'> Careers at {employerByMask?.company_name}</Typography>
                        }
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        <DesktopOnlyStack direction='row' columnGap={2}>
                            <ContactInfo showDivider dividerOrientation='vertical' />
                        </DesktopOnlyStack>
                    </Stack>
                </Stack>
            </Toolbar>
        </ShAppBar>
    )
}