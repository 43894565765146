export const AuthCodeCountdown = 120;

export const CountryCodes: { code: 'ca' | 'us', label: string, phone: string }[] = [
    { code: 'ca', label: 'Canada', phone: '1' },
    { code: 'us', label: 'United States', phone: '1' }
];

export const CompanySizeOptions = ['0-10', '11-50', '51-100', '101-200', '201-490', '500+'];

export const SignUpPageBenefits = [
    "Advertise jobs easily (Sponsored)",
    "Attract top talent",
    "Utilize AI Tools",
    "Collaborate with your team",
    "Branded Careers Page",
    "Predict the best candidate with behavorial assesments"
];