import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAllApplicantData, IApplicantsState } from "Modules/Core/Applicants/ApplicantsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultPageSize } from "shared/constants";

const initialApplicantsState: IApplicantsState = {
    getApplicantsStatus: "idle",
    applicantsList: [],
    paginationParams: {
      count: 0,
      requestedPageNumber: 1,
      requestedPageSize: DefaultPageSize,
      totalPages: 0,
    },
  };

export const getApplicantsList = createAsyncThunk<
  IBaseResponse<IAllApplicantData[]>,
  {
    employerId: number;
    pageNo?: number;
    pageSize?: number;
    startDate?: string;
    endDate?: string;
  },
  { rejectValue: IBaseResponse }
>(
  "getApplicantsList",
  async ({ employerId, pageNo, pageSize, startDate, endDate }, { rejectWithValue }) => {
    const payload = {
      employerId,
      pageNo: pageNo || 1,
      pageSize: pageSize || DefaultPageSize,
      startDate,
      endDate,
    };

    return await httpAdapterInstance
        .post(`${EmployerApiEndpoints.APPLICANTS}/talentpool`, payload)  
        .then((response: AxiosResponse<IBaseResponse<IAllApplicantData[]>>) => response?.data)
        .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);


const allApplicantsSlice = createSlice({
    name: "applicants",
    initialState: initialApplicantsState,
    reducers: {
      resetApplicantsState: () => initialApplicantsState,
      resetExportApplicantsAsCSV: (state) => {
        state.paginationParams = {
          count: 0,
          requestedPageNumber: 1,
          requestedPageSize: DefaultPageSize,
          totalPages: 0,
        };
      },
    },
    extraReducers: (builder) => {
      // On Store PURGE reset the state
      builder.addCase(PURGE, () => {
        return initialApplicantsState;
      });
      // Fetch applicants list
      builder.addCase(getApplicantsList.pending, (state) => {
        state.getApplicantsStatus = "pending";
      });
      builder.addCase(getApplicantsList.fulfilled, (state, action) => {
        state.getApplicantsStatus = "success";
        state.getApplicantsResponse = action?.payload.message;
        state.applicantsList = action.payload?.data;
        state.paginationParams = action?.payload.stats;
        // Add total records counts in the response
        state.paginationParams.count = action.payload.data?.length;
      });
      builder.addCase(getApplicantsList.rejected, (state, action) => {
        state.getApplicantsStatus = "failed";
        // state.getApplicantsResponse =
        // action?.payload?.message ?? DefaultAPIErrorMsg;
        state.paginationParams = {
          count: 0,
          requestedPageNumber: 1,
          requestedPageSize: DefaultPageSize,
          totalPages: 0,
        };
      });
    },
  });

export const { resetApplicantsState} =
  allApplicantsSlice.actions;
export default allApplicantsSlice;