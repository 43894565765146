import { useState } from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { predefinedQuestions } from "./FormTemplateConstants";
import { IQuestion } from "./FormTemplateModel";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import SparkleIcon from '@mui/icons-material/AutoAwesome';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const groupedQuestions = predefinedQuestions.reduce((acc: { [key: string]: IQuestion[] }, question) => {
  if (!acc[question.section]) {
    acc[question.section] = [];
  }
  acc[question.section].push(question);
  return acc;
}, {});

export const FormTemplatePrefilledOptions = ({ onAddQuestion }: { onAddQuestion: (question: IQuestion) => void }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleAddQuestion = (question: IQuestion) => {
    onAddQuestion(question);
  };

  // Filter questions based on search term
  const filteredQuestions = Object.keys(groupedQuestions).reduce((acc: { [key: string]: IQuestion[] }, section) => {
    const filteredSectionQuestions = groupedQuestions[section].filter(question =>
      question.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredSectionQuestions.length > 0) {
      acc[section] = filteredSectionQuestions;
    }
    return acc;
  }, {});

  return (
    <ShContainer maxWidth='sm'>
      <ShPaper variant="outlined" borderRadius={15} headerSection sx={{ maxHeight: 800}} overflow="auto">
        <Box display="flex" alignItems="center" pb={1}>
            <SparkleIcon fontSize='small' color="primary" sx={{ mr: 1 }} />
            <Typography>Suggestions</Typography>
        </Box>

        <Box pb={1}>
          <ShTextFieldV2 size='small' fullWidth variant="outlined" placeholder="Search questions..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
        </Box>

        <Box>
          <Grid container spacing={2}>
            {Object.keys(filteredQuestions).map(section => (
              <Box key={section} padding={2}>
                <Typography variant="body2" gutterBottom>{section}</Typography>
                <Divider />
                <Grid container spacing={2} paddingTop={1}>
                  {filteredQuestions[section].map((question) => (
                    <Grid item key={question.id}>
                      <ShChip
                        borderRadius="15px"
                        customSize='small'
                        label={
                          <Typography variant="caption" noWrap>
                            {question.question}
                          </Typography>
                        }
                        clickable
                        onClick={() => handleAddQuestion(question)}
                        icon={<AddCircleIcon />}
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </Grid>
        </Box>
      </ShPaper>
    </ShContainer>
  );
};
