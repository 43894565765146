import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Box, IconButton, Stack } from "@mui/material";
import { LeftDrawerStyled, MainContainer, SideNavContainer } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { LeftPanelContainer } from "shared/SharedStyles/ShLayouts";
import { ShIconPrimary } from 'shared/SharedStyles/styleConstants';
import { ToolsAndAutomationSideNav } from "./ToolsAndAutomationSideNav";

export const ToolsAndAutomation = () => {

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isMiniDrawer, setIsMiniDrawer] = useState<boolean>(false);

    const toggleDrawerHandler = () => {
        setIsDrawerOpen((prev) => !prev);
    };

    return (<>
        <Stack direction='row' height={'100%'}>
            <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>

                {isDrawerOpen ? <IconButton onClick={toggleDrawerHandler}>
                    <ConstructionIcon style={{ color: ShIconPrimary }} />
                    <ChevronLeftIcon />
                </IconButton> :
                    <IconButton onClick={toggleDrawerHandler}>
                        <ConstructionIcon style={{ color: ShIconPrimary }} />
                        <ChevronRightIcon />
                    </IconButton>}
            </Box>
            <LeftDrawerStyled anchor="left" open={isDrawerOpen} onClose={toggleDrawerHandler}>
                {/* Side nav wrapper switches the different side nav components based on the route */}
                <ToolsAndAutomationSideNav toggleDrawer={toggleDrawerHandler} />
                {/* Toggle drawer prop is only sent in mobile view to close the drawer overlay while navigating to components. */}
            </LeftDrawerStyled>
            <SideNavContainer className={isMiniDrawer ? 'is-mini-drawer' : ''}>
                <LeftPanelContainer>
                    <ToolsAndAutomationSideNav isMiniDrawer={isMiniDrawer} toggleMiniDrawer={() => setIsMiniDrawer(!isMiniDrawer)} />
                </LeftPanelContainer>
            </SideNavContainer>
            <MainContainer>
                <Box component='div' className="main-outlet-container">
                    <Outlet />
                </Box>
            </MainContainer>
        </Stack>
    </>);
};