import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Checkbox,
  Grid,
  Autocomplete,
  TextField
} from "@mui/material";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "shared/SharedModels";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { MouseEvent, useCallback, useEffect, useState, ReactNode } from "react";
import { EmailRegEx } from "shared/constants";
import { ShReactQuill } from "shared/SharedComponents/ShReactQuill/ShReactQuill";
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import {
  ShButton,
  ShGreenBtn,
  ShBlueBtn,
  ShTextFieldV2,
} from "shared/SharedStyles/ShInputs";
import {
  resetRejectionEmail,
  sendRejectionEmail,
  updateRejectReasonInCandidateEmployerJobsTable,
  getRejectReasonInCandidateEmployerJobsTable,
} from "store/slices/employer/applicants/applicant-actions-slice";
import {
  fetchJobSummary,
  resetFetchJobSummary,
} from "store/slices/employer/jobs/jobs-list-slice";
import { RejectionEmailPayload } from "../ApplicantsConstants";
import {
  IRejectionEmailDialog,
  IRejectionEmailPayload,
} from "../ApplicantsModel";
import { ILetterTemplate } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/LettersModel";
import {
  addRemoveOrUpdateEmployerLetterTemplates,
  getEmployerLettersTemplates,
} from "store/slices/employer/employer/employer-details-slice";
import { sortTemplates } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/LetterHelpers";
import { Star as StarIcon } from "@mui/icons-material";
import { changeApplicantStageInProfile } from "store/slices/employer/applicants/applicant-profile-slice";
import { getJobHiringManagers } from "store/slices/employer/create-job/job-title-slice";
import { Divider } from "@mui/material";

export const RejectionEmailDialog = ({
  isRejectionEmailDialogOpen,
  changeStage,
  setIsRejectionEmailDialogOpen,
  applicant,
  jobId,
}: IRejectionEmailDialog) => {
  const dispatch = useAppDispatch();
  const { employerDetails, employerLetters, getLettersStatus } = useSelector(
    (state: RootState) => state.employer.employerDetails
  );
  const notification = useNotification();
  const isXsScreen = IsXsScreen();
  const { rejectionEmailStatus, rejectionEmailResponse } = useAppSelector(
    (state) => state.employer.applicants.applicantActions
  );
  const { jobsSummary } = useAppSelector(
    (state) => state.employer.employerJobs.jobsList
  );
  const [rejectionEmailPayload, setRejectionEmailPayload] =
    useState<IRejectionEmailPayload>(() => {
      return { ...RejectionEmailPayload, to: applicant.email };
    });

  const { jobHiringManagers } = useAppSelector(
    (state) => state.employer.jobTitle
  );

  const [emailBodyEditorValue, setEmailBodyEditorValue] = useState<string>("");
  const [isSendEmailDisabled, setIsSendEmailDisabled] = useState<boolean>(true);
  const [isToEmailInvalid, setIsToEmailInvalid] = useState<boolean>(false);
  const [isCcEmailInvalid, setIsCcEmailInvalid] = useState<boolean>(false);
  const [isBccEmailInvalid, setIsBccEmailInvalid] = useState<boolean>(false);
  const [templateDesc] = useState("");
  const [ccEmails] = useState<string>("");
  const [isDefaultRejectionChecked, setIsDefaultRejectionChecked] =
    useState(false);
  const [localRejectionLetters, setLocalRejectionLetters] = useState<
    ILetterTemplate[]
  >(employerLetters || []);
  const [selectedLetter, setSelectedLetter] = useState<ILetterTemplate | null>(
    null
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    selectedLetter?.id || ""
  );
  const unfitReasons = useMemo(
    () => [
      { id: "1", reason: "Higher salary expectations" },
      { id: "2", reason: "Insufficient experience" },
      { id: "3", reason: "Missing qualifications" },
      { id: "4", reason: "Overqualified" },
      { id: "5", reason: "Not the right fit" },
      { id: "6", reason: "Inadequate benefits" },
      { id: "7", reason: "A Cultural mismatch" },
      { id: "8", reason: "Not willing to relocate" },
      { id: "9", reason: "Poor References" },
      { id: "10", reason: "Cannot Commute" },
    ],
    []
  );

  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState("");
  const [addedRejectionNote, setAddedRejectionNote] = useState("");

  //   const [open, setOpen] = useState(false);

  useEffect(() => {
    if (employerLetters) {
      const filteredTemplates = employerLetters.filter(
        (template) => template.templateType === "Rejection"
      );
      const sortedTemplates = sortTemplates(filteredTemplates);
      setLocalRejectionLetters(sortedTemplates);
    }
  }, [employerLetters]);

  useEffect(() => {
    if (employerDetails?.id) {
      dispatch(getEmployerLettersTemplates({ empId: employerDetails?.id }));
    }
  }, [dispatch, employerDetails?.id]);

  useEffect(() => {
    if (employerDetails?.id) {
      dispatch(getJobHiringManagers({ empId: employerDetails?.id }));
    }
  }, [dispatch, employerDetails?.id]);

  const _sendRejectionEmail = () => {
    dispatch(
      sendRejectionEmail({
        applicationId: applicant.candidateEmployerJobId,
        jobId: jobId,
        payload: rejectionEmailPayload,
      })
    );
  };
  const _updateRejectionNotes = () => {
    let rejectReasons: string;
    let rejectionNotes: string;
    if (selectedReason === "custom") {
      rejectReasons = customReason;
    } else if (selectedReason === "") {
      rejectReasons = "";
    } else {
      const selectedOption = unfitReasons.find(
        (reason) => reason.id === selectedReason
      );
      rejectReasons = selectedOption ? selectedOption.reason : "";
    }
    rejectionNotes = addedRejectionNote;
    dispatch(
      updateRejectReasonInCandidateEmployerJobsTable({
        applicationId: applicant.candidateEmployerJobId,
        payload: {
          rejectReasons: rejectReasons,
          rejectionNotes: rejectionNotes,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      getRejectReasonInCandidateEmployerJobsTable({
        applicationId: applicant.candidateEmployerJobId,
      })
    ).then((response) => {
      if (response?.payload?.data) {
        const { rejectReason, rejectionNotes } = response.payload.data;
        const matchedReason = unfitReasons.find(
          (reason) => reason.reason === rejectReason
        );
        setSelectedReason(
          matchedReason ? matchedReason.id : rejectReason ?? ""
        );
        setAddedRejectionNote(rejectionNotes ?? "");
      }
    });
  }, [dispatch, applicant.candidateEmployerJobId, unfitReasons]);

  // Get Job summary to pre-populate email template.
  useEffect(() => {
    if (jobId) {
      if (
        !(jobId in jobsSummary) &&
        jobsSummary[jobId]?.getSummaryStatus !== "success"
      ) {
        dispatch(fetchJobSummary({ jobId: jobId }));
      }
    }
  }, [dispatch, jobId, jobsSummary]);

  useEffect(() => {
    if (jobsSummary[jobId]?.employerName && !rejectionEmailPayload.data) {
      setEmailBodyEditorValue(`<p>Dear ${applicant.fullName},</p>
                                <br/>
                                <p>Thank you for applying to ${jobsSummary[jobId].name} at ${jobsSummary[jobId].employerName}. 
                                We wanted to let you know that we have reviewed your resume.
                                Even though you have a great background we found that you would not be a good match for this position.
                                We regret to inform you that you are not shortlisted for the position of ${jobsSummary[jobId].name}.</p>
                                <br/>
                                <p>We wish you all the best in your job search and future professional endeavors.</p>
                                <br/>
                                <p>Regards, <br/>The Team at ${jobsSummary[jobId]?.employerName}</p>`);
      setRejectionEmailPayload((prevPayload) => ({
        ...prevPayload,
        subject:
          prevPayload.subject ||
          `Regarding your application at ${jobsSummary[jobId]?.employerName}`,
      }));
      dispatch(resetFetchJobSummary(jobId));
    }
  }, [
    applicant.fullName,
    dispatch,
    jobId,
    jobsSummary,
    rejectionEmailPayload.data,
  ]);

  // Update editor value in payload as it changes.
  useEffect(() => {
    setRejectionEmailPayload((prev) => {
      return { ...prev, data: emailBodyEditorValue };
    });
  }, [emailBodyEditorValue]);

  const closeRejectEmailDialog = useCallback(
    (e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      if (e) {
        e.stopPropagation();
      }
      setIsRejectionEmailDialogOpen({
        [applicant.candidateEmployerJobId]: false,
      });
      setRejectionEmailPayload({
        ...RejectionEmailPayload,
        to: applicant.email,
      });
      setEmailBodyEditorValue("");
    },
    [
      applicant.candidateEmployerJobId,
      applicant.email,
      setIsRejectionEmailDialogOpen,
    ]
  );

  const sendRejectionEmailWithNotificationFlag = () => {
    changeStage("rejected", applicant?.candidateEmployerJobId, true);
    dispatch(
      changeApplicantStageInProfile({
        applicantId: applicant.candidateEmployerJobId,
        payload: { stage: "rejected", sendEmail: true },
      })
    );
  };

  const DontSendEmailUpdateStatus = () => {
    changeStage("rejected", applicant?.candidateEmployerJobId, false);
    dispatch(
      changeApplicantStageInProfile({
        applicantId: applicant.candidateEmployerJobId,
        payload: { stage: "rejected", sendEmail: false },
      })
    );
  };

  useEffect(() => {
    if (rejectionEmailStatus === "success") {
      notification.displayNotification({
        open: true,
        type: "success",
        message: rejectionEmailResponse ?? "",
      });
      dispatch(resetRejectionEmail());
      closeRejectEmailDialog();
      // dispatch(changeApplicantStageInList({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
      // dispatch(changeApplicantStageInProfile({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
    }
  }, [
    applicant.candidateEmployerJobId,
    closeRejectEmailDialog,
    dispatch,
    notification,
    rejectionEmailResponse,
    rejectionEmailStatus,
  ]);

  // Disable send button.
  useEffect(() => {
    const { to, data, subject, cc, bcc } = rejectionEmailPayload;
    setIsSendEmailDisabled(
      !to ||
        !data ||
        !subject ||
        isToEmailInvalid ||
        (cc?.length > 0 && isCcEmailInvalid) ||
        (bcc?.length > 0 && isBccEmailInvalid)
    );
  }, [
    isBccEmailInvalid,
    isCcEmailInvalid,
    isToEmailInvalid,
    rejectionEmailPayload,
  ]);

  // Email validation function with Regular Expression.
  const validateEmail = (
    e: { target: { value: string } },
    field: "to" | "cc" | "bcc"
  ) => {
    const reg = new RegExp(EmailRegEx);
    setRejectionEmailPayload({
      ...rejectionEmailPayload,
      [field]: e.target.value,
    });
    switch (field) {
      case "to":
        setIsToEmailInvalid(!reg.test(e.target.value));
        break;
      case "cc":
        setIsCcEmailInvalid(!reg.test(e.target.value));
        break;
      case "bcc":
        setIsBccEmailInvalid(!reg.test(e.target.value));
        break;
      default:
        break;
    }
  };

  const handleDefaultRejectionCheckbox = () => {
    const newTemplate: ILetterTemplate = {
      id: Date.now(),
      dateCreated: new Date().toLocaleDateString(),
      templateName: "Rejection Template",
      templateDesc,
      subject: rejectionEmailPayload.subject,
      emailBody: emailBodyEditorValue,
      ccEmails: ccEmails.trim(),
      isPreferred: true,
      templateType: "Rejection",
    };

    if (employerDetails?.id) {
      dispatch(
        addRemoveOrUpdateEmployerLetterTemplates({
          empId: employerDetails?.id,
          action: "add",
          template: newTemplate,
        })
      );
    }
  };

  const handleChange = (
    event: SelectChangeEvent<string | number>,
    child: ReactNode
  ) => {
    const selectedId = event.target.value as string | number; // Cast to expected type
    setSelectedTemplateId(selectedId);
    const selectedTemplate = localRejectionLetters.find(
      (template) => template.id === selectedId
    );
    if (selectedTemplate) {
      handleLetterClick(selectedTemplate);
    } else {
      setEmailBodyEditorValue(`<p>Dear ${applicant.fullName},</p>
                <br/>
                <p>Thank you for applying to ${jobsSummary[jobId].name} at ${jobsSummary[jobId].employerName}. 
                We wanted to let you know that we have reviewed your resume.
                Even though you have a great background we found that you would not be a good match for this position.
                We regret to inform you that you are not shortlisted for the position of ${jobsSummary[jobId].name}.</p>
                <br/>
                <p>We wish you all the best in your job search and future professional endeavors.</p>
                <br/>
                <p>Regards, <br/>The Team at ${jobsSummary[jobId]?.employerName}</p>`);
    }
  };

  const handleLetterClick = (template: ILetterTemplate) => {
    setSelectedLetter(template);

    // Replace placeholders in the email body
    const updatedEmailBody = template.emailBody
      ?.replace(/\[CANDIDATE_NAME\]/g, `${applicant.fullName}`)
      ?.replace(/\[JOB_NAME\]/g, jobsSummary[jobId]?.name || "the position")
      ?.replace(/\[YOUR_NAME\]/g, `${employerDetails?.first_name}`)
      ?.replace(
        /\[COMPANY_NAME\]/g,
        employerDetails?.company_name || "our company"
      );

    // Prefill the rejection email payload and email body editor
    setRejectionEmailPayload((prev) => ({
      ...prev,
      cc: template.ccEmails || prev.cc,
      subject: template.subject || prev.subject,
      data: updatedEmailBody || prev.data,
    }));

    setEmailBodyEditorValue(updatedEmailBody || template.emailBody || "");
  };

  const handleChangeCustomUnfitReason = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (value === "custom") {
      setCustomReason(""); // Reset custom reason input when selected
    }
    setSelectedReason(value);
  };

  const handleRejectionNoteChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddedRejectionNote(event.target.value);
  };

  return (
    <>
      <ShDialog
        open={isRejectionEmailDialogOpen}
        fullScreen={isXsScreen}
        onClose={() => closeRejectEmailDialog()}
        aria-labelledby="title"
        aria-describedby="sub_title"
        onClick={(e) => e.stopPropagation()}
        maxWidth="md"
      >
        <DialogTitle id="title" textAlign="center">
          <Typography variant="h5" fontWeight="bold">
            {`Reject ${applicant.fullName}`}
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ width: "100%" }}>
            {getLettersStatus === "pending" ? (
              <Box width="100%" padding={2}>
                <LinearProgress />
              </Box>
            ) : (
              <FormControl fullWidth variant="outlined">
                <InputLabel id="unfit-reason-label" shrink>
                  Select a Reason
                </InputLabel>
                <Select
                  labelId="unfit-reason-label"
                  value={selectedReason}
                  onChange={handleChangeCustomUnfitReason}
                  displayEmpty
                  label="Select a reason"
                  fullWidth
                  sx={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 1,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em style={{ color: "#aaa" }}>Select a reason</em>;
                    }
                    if (selected === "custom") {
                      return (
                        customReason || (
                          <em style={{ color: "#aaa" }}>Other reason</em>
                        )
                      );
                    }
                    const selectedOption = unfitReasons.find(
                      (reason) => reason.id === selected
                    );
                    return selectedOption ? selectedOption.reason : "None";
                  }}
                >
                  <MenuItem value="">
                    <Typography variant="body2">
                      <em>None</em>
                    </Typography>
                  </MenuItem>
                  {unfitReasons.map((reason) => (
                    <MenuItem key={reason.id} value={reason.id}>
                      <Typography variant="body2">{reason.reason}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem value="custom">
                    <Typography variant="body2" sx={{ color: "blue" }}>
                      <em>Other reason</em>
                    </Typography>
                  </MenuItem>
                </Select>
                {selectedReason === "custom" && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ShTextFieldV2
                      isResizable
                      label="Add Other Reason"
                      id="custom-reason"
                      variant="outlined"
                      multiline
                      fullWidth
                      size="small"
                      value={customReason}
                      rows={4}
                      onChange={(e) => setCustomReason(e.target.value)}
                      sx={{ mt: 2 }}
                    />
                  </Grid>
                )}

                <ShTextFieldV2
                  id="RejectionNotes"
                  label="Rejection Notes"
                  variant="outlined"
                  size="medium"
                  // required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={addedRejectionNote}
                  onChange={handleRejectionNoteChange}
                  sx={{ mt: 3 }}
                  placeholder="Enter rejection notes"
                  multiline
                  rows={6}
                />
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
                    <ShBlueBtn
                      size="small"
                      onClick={() => {
                        if (isDefaultRejectionChecked) {
                          handleDefaultRejectionCheckbox();
                        }
                        DontSendEmailUpdateStatus();
                        closeRejectEmailDialog();
                        _updateRejectionNotes();
                      }}
                    >
                      Reject
                    </ShBlueBtn>
                  </Box>
                </Box>
                <Divider sx={{ mt: 4, mb: 2 }} />
              </FormControl>
            )}
          </Box>
          <Stack rowGap={2} height={"100%"}>
            <Grid container alignItems="center" sx={{ mt: 2 }}>
              {" "}
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    mt: 0,
                  }}
                >
                  Send Rejection Email
                </Typography>
                {applicant.hasReceivedRejectionEmail && (
                  <ShAlert severity="warning">
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography variant="body2" color="inherit">
                        Applicant was already notified by email
                      </Typography>
                    </Box>
                  </ShAlert>
                )}
              </Grid>
            </Grid>
            <ShTextFieldV2
              id="email_to"
              label="To"
              variant="outlined"
              size="small"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={rejectionEmailPayload.to}
              onChange={(e) => validateEmail(e, "to")}
              error={rejectionEmailPayload.to?.length > 0 && isToEmailInvalid}
              helperText={
                rejectionEmailPayload.to?.length > 0 &&
                isToEmailInvalid &&
                "Please enter a valid email"
              }
            />
            <Autocomplete
              id="email_cc"
              options={jobHiringManagers || []}
              getOptionLabel={(option) => option.email}
              renderOption={(props, option) => (
                <li {...props} style={{ padding: "8px 20px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography variant="body1" fontWeight="bold">
                  {option.first_name} {option.last_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {option.email}
                </Typography>
              </li>
              
              
              )}
              onChange={(event, value) => {
                if (value) {
                  validateEmail({ target: { value: value.email } }, "cc");
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cc"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={
                    rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid
                  }
                  helperText={
                    rejectionEmailPayload.cc?.length > 0 &&
                    isCcEmailInvalid &&
                    "Please enter a valid email"
                  }
                />
              )}
            />
            {/* <ShTextFieldV2
              id="email_cc"
              label="Cc"
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={rejectionEmailPayload.cc}
              onChange={(e) => validateEmail(e, "cc")}
              error={rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid}
              helperText={
                rejectionEmailPayload.cc?.length > 0 &&
                isCcEmailInvalid &&
                "Please enter a valid email"
              }
            /> */}
            <ShTextFieldV2
              id="email_bcc"
              label="Bcc"
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={rejectionEmailPayload.bcc}
              onChange={(e) => validateEmail(e, "bcc")}
              error={rejectionEmailPayload.bcc?.length > 0 && isBccEmailInvalid}
              helperText={
                rejectionEmailPayload.bcc?.length > 0 &&
                isBccEmailInvalid &&
                "Please enter a valid email"
              }
            />
            <ShTextFieldV2
              id="email_subject"
              label="Subject"
              variant="outlined"
              size="small"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={rejectionEmailPayload.subject}
              onChange={(e) => {
                setRejectionEmailPayload({
                  ...rejectionEmailPayload,
                  subject: e.target.value,
                });
              }}
            />
            <Box sx={{ width: "100%" }}>
              {getLettersStatus === "pending" ? (
                <Box width="100%" padding={2}>
                  <LinearProgress />
                </Box>
              ) : (
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="template-select-label" shrink>
                    Select Email Template
                  </InputLabel>
                  <Select
                    labelId="template-select-label"
                    value={selectedTemplateId}
                    onChange={handleChange}
                    displayEmpty
                    label="Select Email Template" // Ensure the label is passed here
                    fullWidth
                    sx={{
                      maxHeight: "400px",
                      overflowY: "auto",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 1,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "primary.main",
                      },
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return (
                          <em style={{ color: "#aaa" }}>
                            Select Email Template
                          </em>
                        );
                      }
                      const selectedTemplate = localRejectionLetters.find(
                        (template) => template.id === selected
                      );
                      return selectedTemplate
                        ? selectedTemplate.templateName
                        : "None";
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {localRejectionLetters.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body2" color="textPrimary">
                            {template.templateName}
                          </Typography>
                          {template.isPreferred && (
                            <Typography
                              variant="caption"
                              color="success.main"
                              display="flex"
                              alignItems="center"
                            >
                              <StarIcon
                                fontSize="small"
                                sx={{ marginRight: 0.5 }}
                              />{" "}
                              Preferred
                            </Typography>
                          )}
                          <Typography variant="caption" color="textSecondary">
                            {template.templateDesc}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            <strong>Subject:</strong> {template.subject}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            Created on: {template.dateCreated}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            {jobsSummary[jobId]?.getSummaryStatus === "pending" ? (
              <ShAlert severity="info">Prefilling email template ...</ShAlert>
            ) : (
              <Box flex={1}>
                <ShReactQuill
                  quillEditorValue={emailBodyEditorValue}
                  setQuillEditorValue={setEmailBodyEditorValue}
                />
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Cancel button on the left */}
            <DialogActions>
              <Box sx={{ position: "absolute", bottom: "5px", width: "100%" }}>
                <ShButton
                  size="small"
                  onClick={(e) => closeRejectEmailDialog(e)}
                  sx={{
                    fontWeight: "bold",
                    marginLeft: "-5px",
                  }}
                >
                  Cancel
                </ShButton>
              </Box>
            </DialogActions>

            <FormControl
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={isDefaultRejectionChecked}
                  onChange={(e) =>
                    setIsDefaultRejectionChecked(e.target.checked)
                  }
                  color="primary"
                  sx={{ padding: 0, marginRight: 1 }}
                />
                <Typography variant="body2">
                  Add & make this a default rejection letter
                </Typography>
              </Box>
            </FormControl>

            <Box>
              <ShGreenBtn
                size="small"
                onClick={() => {
                  if (isDefaultRejectionChecked) {
                    handleDefaultRejectionCheckbox();
                  }
                  _sendRejectionEmail();
                  sendRejectionEmailWithNotificationFlag();
                  _updateRejectionNotes();
                }}
                disabled={
                  rejectionEmailStatus === "pending" ||
                  applicant.hasReceivedRejectionEmail ||
                  isSendEmailDisabled
                }
              >
                {rejectionEmailStatus === "pending"
                  ? "Sending Email ..."
                  : "Reject and Send Email"}
              </ShGreenBtn>
            </Box>
          </Box>
        </DialogActions>
      </ShDialog>
    </>
  );
};
