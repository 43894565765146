import { Stack, Typography, Box, Fade } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

export const SummarySection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.summary) return null;

    return (
    <Fade in={true} timeout={800}>
            <Stack spacing={1}>
                <Typography fontSize={18} gutterBottom>SUMMARY</Typography>
                <ShPaper variant="outlined">
                {candidate.summary && (
                            <Box>
                                <Typography fontSize={14}>{candidate.summary}</Typography>
                            </Box>
                    )}
                </ShPaper>
            </Stack>
    </Fade>
    );
}
