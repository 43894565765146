import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AssessmentIcon from '@mui/icons-material/Assessment'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { Box, Container, Divider, Grid, Paper, Stack, Step, StepIconProps, StepLabel, Stepper, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from 'helpers/hooks'
import { useEffect, useState } from 'react'
import { Outlet, Link as RouterLink, useLocation } from 'react-router-dom'
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation'
import { ShPaper } from 'shared/SharedStyles/ShSurfaces'
import { setAppContext } from 'store/slices/auth-v2/auth-v2-slice'
import { ShStepperConnector, ShStepperIconRoot } from './ApplyJob.styled'
import { ApplyJobSteps } from './ApplyJobConstants'
import { IApplyJobStep, TCurrentJobApplyStep } from './ApplyJobModel'
import { JobDetailsPanel } from './JobDetailsPanel'

export const ApplyJobWrapper = () => {
  const dispatch = useAppDispatch();
  const [applyJobCurrentStep, setApplyJobCurrentStep] = useState<number>(0);
  const activeStep = useLocation().pathname.split("/").pop() as TCurrentJobApplyStep;
  const { getJobDetailsByCodeStatus, jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
  const [_applyJobSteps, _setApplyJobSteps] = useState<IApplyJobStep[]>([]);
  const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);

  const ShStepperIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: <AccountCircleIcon />,
      2: <QuestionAnswerIcon />,
      3: <AssessmentIcon />
    };

    return (
      <ShStepperIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}</ShStepperIconRoot>
    );
  }

  // set app context
  useEffect(() => {
    dispatch(setAppContext({ currentAppContext: 'candidate_apply_job' }));
  }, [dispatch]);

   // Hide assessment step for ats job
   useEffect(() => {
    if (getJobDetailsByCodeStatus === 'success') {
      if (jobDetailsByCode?.ats_purchased || candidateDetails?.jobApplicationId) {
        _setApplyJobSteps(ApplyJobSteps.filter(step => step.value !== 2));
      } else {
        _setApplyJobSteps(ApplyJobSteps);
      }
    }
  }, [getJobDetailsByCodeStatus, jobDetailsByCode?.ats_purchased, candidateDetails?.jobApplicationId]);

  // Set stepper value on path change.
  useEffect(() => {
    setApplyJobCurrentStep(ApplyJobSteps.find(step => step.path === activeStep)?.value ?? 0);
  }, [activeStep]);

  return (
      <Grid container padding={1} spacing={1} height="100%">
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
          <JobDetailsPanel />
        </Grid>
        {getJobDetailsByCodeStatus === 'failed' ? (
          <Stack flex={1} alignItems="center" justifyContent="center" height="100%">
            <Container maxWidth="sm">
              <Paper>
                <Box padding={10} textAlign="center">
                  <Typography textAlign="center" variant="body2" fontWeight={600} gutterBottom>
                    This Job does not exist anymore.
                  </Typography>
                  <ShMuiLink alignItems="center" component={RouterLink} to="/candidate/new" noBlueHighlight>
                    Take SmoothHiring Survey
                  </ShMuiLink>
                </Box>
              </Paper>
            </Container>
          </Stack>
        ) : (
          <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
            <ShPaper variant="outlined">
              <Stack
                rowGap={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }}
                paddingTop={{ xs: 2, sm: 3, md: 4, lg: 4 }}
                paddingBottom={1}
              >
                <Stepper activeStep={applyJobCurrentStep} alternativeLabel connector={<ShStepperConnector />}>
                  {_applyJobSteps.map((step) => (
                    <Step key={step.value}>
                      <StepLabel StepIconComponent={ShStepperIcon}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Divider />
                <Outlet />
              </Stack>
            </ShPaper>
          </Grid>
        )}
      </Grid>
  );
}