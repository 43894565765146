import { useEffect, useState } from "react";
import { Stack, Typography, Box, Slider } from "@mui/material";
import { ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ColorPickerProps } from "./CareersPageModel";

export const shades: Record<string, string[]> = {
    primary: ["#ab5810", "#f57f17", "#f79845"],
    secondary: ["#7d5fb2", "#b388ff", "#c29fff"],
    tertiary: ["#7d5fb2", "#b388ff", "#c29fff"],
};


// Function to convert RGB to Hex
const rgbToHex = (r: number, g: number, b: number): string => {
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
};


// Function to adjust the shade (darken or lighten)
const adjustShade = (color: string, shade: number): string => {
    const hex = color.startsWith("#") ? color.slice(1) : color; // remove '#' if present
    const rgb = parseInt(hex, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = rgb & 0xff;

    // Adjust based on the shade value
    const adjustment = (shade - 500) / 500;
    const adjust = (value: number) => Math.min(Math.max(value + adjustment * 100, 0), 255);

    const newR = adjust(r);
    const newG = adjust(g);
    const newB = adjust(b);

    return rgbToHex(newR, newG, newB);
};

// Function to convert Hex to RGB
const hexToRgb = (hex: string) => {
    const hexColor = hex.startsWith("#") ? hex.slice(1) : hex;
    const bigint = parseInt(hexColor, 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
    };
};

// Generate a 4x4 swatch grid of similar colors
const generateSwatch = (baseColor: string) => {
    const baseRgb = hexToRgb(baseColor);
    const swatches = [];
    for (let i = 0; i < 16; i++) {
        const factor = (i % 10) * 10; // Variation factor
        const adjustedColor = rgbToHex(
            Math.min(Math.max(baseRgb.r + factor, 0), 255),
            Math.min(Math.max(baseRgb.g + factor, 0), 255),
            Math.min(Math.max(baseRgb.b + factor, 0), 255)
        );
        swatches.push(adjustedColor);
    }
    return swatches;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({
    label,
    color,
    setColor,
    shadeColors,
    onColorChange,
}) => {
    const [shade, setShade] = useState<number>(500);
    const [inputColor, setInputColor] = useState<string>(color);

    const handleColorChange = (newColor: string) => {
        setInputColor(newColor);
        setColor(newColor);

        if (onColorChange) {
            onColorChange(newColor); // Call the parent's color change function
        }
    };

    useEffect(() => {
        setInputColor(color); 
    }, [color]);

    const adjustedColor = adjustShade(color, shade);
    const swatches = generateSwatch(adjustedColor);

    return (
        <Stack spacing={2}>
            {/* Color Label */}
            <Typography variant="body2">{label}</Typography>

            {/* Color Input Field with Preview */}
            <Stack direction="row" spacing={1} alignItems="center">
                <Box
                    sx={{
                        width: 30,
                        height: 30,
                        bgcolor: color,
                        border: "1px solid #aaa",
                        borderRadius: "4px",
                    }}
                />
                <ShTextFieldV2
                    fullWidth
                    size="small"
                    value={inputColor}
                    onChange={(e) => handleColorChange(e.target.value)}
                />
            </Stack>

            {/* Shade Slider */}
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>Shade:</Typography>
                <Slider
                    value={shade}
                    onChange={(_, newValue) => setShade(newValue as number)}
                    min={100}
                    max={900}
                    step={1}
                    sx={{ color: adjustedColor }}
                />
                <Typography>{shade}</Typography>
            </Stack>

            {/* Color Swatches */}
            <Stack direction="row" spacing={1} flexWrap="wrap" width="calc(100% + 8px)">
                {swatches
                    .filter((value, index, self) => self.indexOf(value) === index) // Filter out duplicates
                    .map((swatch: string, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                width: 40,
                                height: 40,
                                bgcolor: swatch,
                                borderRadius: "4px",
                                cursor: "pointer",
                                border: swatch === color ? "2px solid gray" : "none",
                                transition: "transform 0.2s",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    border: `2px solid ${swatch === color ? "gray" : "black"}`,
                                },
                            }}
                            onClick={() => handleColorChange(swatch)} // Update color when clicked
                        />
                    ))}
            </Stack>
        </Stack>
    );
};
