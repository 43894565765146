import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { ApiStates } from "shared/constants";

const initialSubsState = {
    ftchngSubs: ApiStates.idle,
    employeeSubs: [],
    addOrRemoveSubsRspnse: '',
    addingOrRemovingSubs: ApiStates.idle
};

export const fetchEmployeeSubscriptions = createAsyncThunk(
    "fetchEmployeeSubscriptions",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/subscriptions`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const addEmployeeSubscriptions = createAsyncThunk(
    "addEmployeeSubscriptions",
    async ({ employerId, subsType }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/subscriptions?subscriptionType=${subsType}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const removeEmployeeSubscriptions = createAsyncThunk(
    "removeEmployeeSubscriptions",
    async ({ employerId, subsType }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/subscriptions?subscriptionType=${subsType}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const subsSlice = createSlice({
    name: "subs",
    initialState: initialSubsState,
    reducers: {
        resetAddOrRemoveSubs: (state) => { state.addingOrRemovingSubs = ApiStates.idle; state.addOrRemoveSubsRspnse = '' }
    },
    extraReducers: (builder) => {
        //get employee subs
        builder.addCase(fetchEmployeeSubscriptions.pending, (state, action) => {
            state.ftchngSubs = ApiStates.pending;
        });
        builder.addCase(fetchEmployeeSubscriptions.fulfilled, (state, action) => {
            state.employeeSubs = action?.payload?.data;
            state.ftchngSubs = ApiStates.success;
        });
        builder.addCase(fetchEmployeeSubscriptions.rejected, (state, action) => {
            state.employeeSubs = [];
            state.ftchngSubs = ApiStates.failed;
        });
        //add employee subs
        builder.addCase(addEmployeeSubscriptions.pending, (state, action) => {
            state.addingOrRemovingSubs = ApiStates.pending;
        });
        builder.addCase(addEmployeeSubscriptions.fulfilled, (state, action) => {
            state.addOrRemoveSubsRspnse = action?.payload?.message;
            state.addingOrRemovingSubs = ApiStates.success;
        });
        builder.addCase(addEmployeeSubscriptions.rejected, (state, action) => {
            state.addingOrRemovingSubs = ApiStates.failed;
        });
        //remove employee subs
        builder.addCase(removeEmployeeSubscriptions.pending, (state, action) => {
            state.addingOrRemovingSubs = ApiStates.pending;
        });
        builder.addCase(removeEmployeeSubscriptions.fulfilled, (state, action) => {
            state.addOrRemoveSubsRspnse = action?.payload?.message;
            state.addingOrRemovingSubs = ApiStates.success;
        });
        builder.addCase(removeEmployeeSubscriptions.rejected, (state, action) => {
            state.addingOrRemovingSubs = ApiStates.failed;
        });
    },
});

export const { resetAddOrRemoveSubs } = subsSlice.actions;
export default subsSlice;
