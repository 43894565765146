import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { EmployerDetailsInitial, ExternalIdTypes } from 'Modules/Core/Admin/EmployerProfile/EmpProfileConstants';
import { EmpInfoInputBlock, EmpInfoLabelBlock, ExternalIdBlock, ExternalIdLabel, ExternalIdValue, ExternalIdsBlock, StyledLink, StyledStack, ThemedBtn } from "Modules/Core/Admin/EmployerProfile/EmployerDetails.styled";
import { EmployerLoginRedirect } from 'Modules/Core/AuthV2/AuthConstants';
import { useAppSelector } from 'helpers/hooks';
import moment from 'moment-timezone';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ApiStates } from 'shared/constants';
import { fetchEmployerDetails, fetchExternalIds, fetchPricingGroups, resetEmpDetUpdation, updateEmployerDetails } from 'store/slices/admin/employer/emp-details-slice';
import { enterEmployerAccount, resetEnterEmployerAccount, setNavigatedFrom } from 'store/slices/app/module-switch-slice';
import { loginByResponse } from 'store/slices/auth-v2/auth-v2-slice';

export const EmployerDetails = ({ employerId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUrl = useLocation().pathname;
    const employerDetails = useSelector((state) => state.employer.employer.employerDetails);
    const { accountId } = useAppSelector((state) => state.auth.auth);
    const { getEmployerAccountLoginStatus, employerAccountLogin } = useSelector((state) => state.app.moduleSwitch);
    const pricingGroups = useSelector((state) => state.employer.employer.pricingGroups);
    const externalIds = useSelector((state) => state.employer.employer.externalIds);
    const updatingEmpDetails = useSelector((state) => state.employer.employer.updatingEmpDetails);
    const updtEmpDetlsResposne = useSelector((state) => state.employer.employer.updtEmpDetlsResposne);
    const [empDetails, setEmpDetails] = useState(EmployerDetailsInitial);
    const [extrnlIds, setExternalIds] = useState([]);

    useEffect(() => {
        if (employerId) {
            dispatch(fetchEmployerDetails({ employerId }));
            dispatch(fetchPricingGroups());
            dispatch(fetchExternalIds({ employerId }));
        }
    }, [employerId, dispatch]);

    useEffect(() => {
        if (employerDetails?.id) {
            setEmpDetails(employerDetails);
        }
    }, [employerDetails, employerId, dispatch]);

    useEffect(() => {
        // Updating External IDs labels based on the values from API response
        if (externalIds) {
            const tempArr = [];
            externalIds.forEach(eId => {
                let tempEidObj = { ...eId };
                switch (tempEidObj.type) {
                    case ExternalIdTypes.owner_marketo:
                        tempEidObj['label'] = 'Owner Marketo ID';
                        break;
                    case ExternalIdTypes.sdfc_account:
                        tempEidObj['label'] = 'SFDC Account ID';
                        break;
                    case ExternalIdTypes.sdfc_contact:
                        tempEidObj['label'] = 'SFDC Contact ID';
                        break;
                    default:
                        break;
                }
                tempArr.push(tempEidObj);
            });
            setExternalIds(tempArr);
        }
    }, [externalIds]);

    const updateEmpDetls = () => {
        const empDetls = {
            'company_name': empDetails.company_name,
            'phone_area': empDetails.phone_area,
            'phone_number': empDetails.phone_number,
            'employer_code': empDetails.employer_code,
            'employer_pricing_group_id': empDetails.employer_pricing_group_id,
            'discount_amount': empDetails.discount_amount,
            'discount_rate': empDetails.discount_rate,
            'discount_amount_used': empDetails.discount_amount_used,
            'require_resumes': empDetails.require_resumes,
            'clone_employees': empDetails.clone_employees,
            'has_template_jobs': empDetails.has_template_jobs,
            'sends_candidate_alerts': empDetails.sends_candidate_alerts,
            'new_apply_flow': empDetails.new_apply_flow,
            'display_currently_hiring_jobs_feed': empDetails.display_currently_hiring_jobs_feed,
            'first_name': empDetails.first_name,
            'last_name': empDetails.last_name
        }
        dispatch(updateEmployerDetails({ employerId, empDetls }));
    };

    useEffect(() => {
        if (updatingEmpDetails === ApiStates.success) {
            dispatch(fetchEmployerDetails({ employerId }));
        }

    }, [updatingEmpDetails, employerId, dispatch]);

    const Alerts = () => {
        switch (updatingEmpDetails) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Updating...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetEmpDetUpdation()) }} severity="success">{updtEmpDetlsResposne}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetEmpDetUpdation()) }} severity="error">Error occurred while updating!</ShAlert>);
            default:
                return (<></>);
        }
    };

    const enterEmpAccount = () => {
        dispatch(enterEmployerAccount({ employerId: employerId, payload: { url: currentUrl, id: accountId } }));
    }

    useEffect(() => {
        if (getEmployerAccountLoginStatus === 'success') {
            dispatch(setNavigatedFrom({ navigatedFrom: 'admin', navigateBackUrl: currentUrl }));
            dispatch(loginByResponse({ loginResponse: employerAccountLogin }));
            navigate(EmployerLoginRedirect);
            dispatch(resetEnterEmployerAccount());
        }
    }, [currentUrl, dispatch, employerAccountLogin, getEmployerAccountLoginStatus, navigate]);

    return (<>
        <StyledStack id='employer-profile'>
            <StyledStack>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h6" gutterBottom>
                        Employer
                    </Typography>
                    <Button onClick={enterEmpAccount}>Enter Account</Button>
                </Stack>

                <Stack>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Company Name
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="company_name"
                                value={empDetails.company_name !== null ? empDetails.company_name : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, company_name: e.target.value }) }}
                                placeholder="Company Name"
                                inputProps={{ 'aria-label': 'Company Name' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                First Name
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="first_name"
                                value={empDetails.first_name !== null ? empDetails.first_name : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, first_name: e.target.value }) }}
                                placeholder="First Name"
                                inputProps={{ 'aria-label': 'First Name' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Last Name
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="last_name"
                                value={empDetails.last_name !== null ? empDetails.last_name : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, last_name: e.target.value }) }}
                                placeholder="Last Name"
                                inputProps={{ 'aria-label': 'Last Name' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Phone
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Grid container spacing={{ xs: 0.5, sm: 0.8, md: 1.5, lg: 2, xl: 2 }}>
                                <Grid item xs={4} sm={3} md={2} lg={2} xl={1} >
                                    <InputBase type='number' name="phone_area"
                                        value={empDetails.phone_area !== null ? empDetails.phone_area : 0}
                                        onChange={(e) => { setEmpDetails({ ...empDetails, phone_area: e.target.value }) }}
                                        placeholder="Phone"
                                        inputProps={{ 'aria-label': 'Phone' }}
                                    />
                                </Grid>
                                <Grid item xs={8} sm={9} md={10} lg={10} xl={11}>
                                    <InputBase type='number' name="phone_number"
                                        value={empDetails.phone_number !== null ? empDetails.phone_number : 0}
                                        onChange={(e) => { setEmpDetails({ ...empDetails, phone_number: e.target.value }) }}
                                        placeholder="Phone"
                                        inputProps={{ 'aria-label': 'Phone' }}
                                    />
                                </Grid>
                            </Grid>
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Employer Code
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="employer_code"
                                value={empDetails.employer_code !== null ? empDetails.employer_code : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, employer_code: e.target.value }) }}
                                placeholder="Employer Code"
                                inputProps={{ 'aria-label': 'Employer Code' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Employer Pricing Group
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Select size='small' variant='standard'
                                name='employer_pricing_group_id'
                                value={empDetails.employer_pricing_group_id}
                                onChange={(e) => { setEmpDetails({ ...empDetails, employer_pricing_group_id: e.target.value }) }}>
                                {pricingGroups && pricingGroups?.map(pg =>
                                    (<MenuItem key={pg.id} value={pg.id}>{!pg.is_selectable && 'Inactive: '}{pg.name}</MenuItem>)
                                )}
                            </Select>
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Calendly Token
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="calendly_token"
                                value={empDetails.calendly_token !== null ? empDetails.calendly_token : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, calendly_token: e.target.value }) }}
                                placeholder="Calendly Token"
                                inputProps={{ 'aria-label': 'Calendly Token' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Promo Code
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="promotioncode_id"
                                value={empDetails.promotioncode_id !== null ? empDetails.promotioncode_id : ''}
                                onChange={(e) => { setEmpDetails({ ...empDetails, promotioncode_id: e.target.value }) }}
                                placeholder="Promo Code"
                                inputProps={{ 'aria-label': 'Promo Code' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Discount Amount
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="discount_amount"
                                value={empDetails.discount_amount !== null ? empDetails.discount_amount : 0}
                                onChange={(e) => { setEmpDetails({ ...empDetails, discount_amount: e.target.value }) }}
                                placeholder="Discount Amount"
                                inputProps={{ 'aria-label': 'Discount Amount' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Discount Amount Used
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="discount_amount_used"
                                checked={empDetails.discount_amount_used !== null ? empDetails.discount_amount_used : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, discount_amount_used: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Discount Amount Used' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Discount Rate
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <InputBase name="discount_rate"
                                value={empDetails.discount_rate !== null ? empDetails.discount_rate : 0}
                                onChange={(e) => { setEmpDetails({ ...empDetails, discount_rate: e.target.value }) }}
                                placeholder="Discount Rate"
                                inputProps={{ 'aria-label': 'Discount Rate' }}
                            />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Require Resumes for Applicants
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="require_resumes"
                                checked={empDetails.require_resumes !== null ? empDetails.require_resumes : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, require_resumes: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Require Resumes for Applicants' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Job Customization (Clone Employees)
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="clone_employees"
                                checked={empDetails.clone_employees !== null ? empDetails.clone_employees : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, clone_employees: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Job Customization' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Template Jobs
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="has_template_jobs"
                                checked={empDetails.has_template_jobs !== null ? empDetails.has_template_jobs : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, has_template_jobs: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Templates Jobs' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Sends candidate alerts
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="sends_candidate_alerts"
                                checked={empDetails.sends_candidate_alerts !== null ? empDetails.sends_candidate_alerts : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, sends_candidate_alerts: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Send Candidate Alerts' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                New Apply Flow
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="new_apply_flow"
                                checked={empDetails.new_apply_flow !== null ? empDetails.new_apply_flow : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, new_apply_flow: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'New Apply Flow' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                External ID’s
                            </Typography>
                        </EmpInfoLabelBlock>
                        <ExternalIdsBlock container item xs={7} md={7} lg={7} xl={7}>
                            {extrnlIds.map(eIds => (
                                <ExternalIdBlock container item key={eIds.text}>
                                    <ExternalIdLabel className='label' item xs={6}>
                                        <Typography variant='subtitle1'>
                                            {eIds.label}
                                        </Typography>
                                    </ExternalIdLabel>
                                    <ExternalIdValue className='value' item xs={6}>
                                        <StyledLink href={eIds.link} target="_blank">
                                            {eIds.text}
                                        </StyledLink>
                                    </ExternalIdValue>
                                </ExternalIdBlock>
                            ))}
                        </ExternalIdsBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Created On
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Typography variant="subtitle2">
                                {moment(empDetails.createdAt).format('MMM D, YYYY HH:mm')}
                            </Typography>
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Last Edited
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Typography variant="subtitle2">
                                {moment(empDetails.updatedAt).format('MMM D, YYYY HH:mm')}
                            </Typography>
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoInputBlock item xs={12} md={12} lg={12} xl={12}>
                            <Typography variant="subtitle2">
                                Public-accesible Currently Hiring Jobs Feed (experimental; currently only planned for Canadian Tire):
                            </Typography>
                        </EmpInfoInputBlock>
                    </Grid>
                    <Grid container>
                        <EmpInfoLabelBlock item xs={5} md={5} lg={5} xl={5}>
                            <Typography variant="subtitle2">
                                Display public feed
                            </Typography>
                        </EmpInfoLabelBlock>
                        <EmpInfoInputBlock item xs={7} md={7} lg={7} xl={7}>
                            <Checkbox name="display_currently_hiring_jobs_feed"
                                checked={empDetails.display_currently_hiring_jobs_feed !== null ? empDetails.display_currently_hiring_jobs_feed : false}
                                onChange={(e) => { setEmpDetails({ ...empDetails, display_currently_hiring_jobs_feed: e.target.checked }) }}
                                inputProps={{ 'aria-label': 'Display public feed' }}
                                checkedIcon={<CheckIcon />} />
                        </EmpInfoInputBlock>
                    </Grid>
                </Stack>
            </StyledStack>
            <Box>
                <ThemedBtn variant="contained" disabled={updatingEmpDetails === ApiStates.pending} onClick={updateEmpDetls}>Save Changes</ThemedBtn>
            </Box>
            {Alerts()}
        </StyledStack>
    </>);
};