import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { ExcludedSubdomains } from "shared/constants";
import { getEmployerDetailsByMaskingName } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";
import { EmployerSubDomainOutletWrapper } from "./EmployerSubDomain.styled";
import { EmployerSubDomainTopNav } from "./EmployerSubDomainTopNav";
import { TExcludedSubdomains } from "shared/SharedModels";

export const EmployerSubDomainWrapper = () => {

    const dispatch = useAppDispatch();
    const { employerByMask, getEmployerByMaskingNameStatus } = useAppSelector(state => state.employer.employerSubDomain);
    // get subdomain from url, if resources navigate to resources related pages.
    const subdomain = new URL(window.location.href)?.hostname?.split('.')[0]?.toLowerCase() as TExcludedSubdomains;
    // const subdomain = 'ctwelland' as any;

    useEffect(() => {
        if (!employerByMask && getEmployerByMaskingNameStatus === 'idle' && !ExcludedSubdomains.includes(subdomain)) {
            dispatch(getEmployerDetailsByMaskingName({ maskingName: subdomain }));
        }
    }, [dispatch, employerByMask, getEmployerByMaskingNameStatus, subdomain]);

    return (<>
        <Stack height='100vh'>
            <EmployerSubDomainTopNav />
            <EmployerSubDomainOutletWrapper>
                <Outlet />
            </EmployerSubDomainOutletWrapper>
        </Stack>
    </>);
};