import { SubscriptionPlanFeatures } from "./SubscriptionsModel";

export const entryPlanFeatures: SubscriptionPlanFeatures = {
    activeJobPostings: 3,
    careersPageJobPostings: 20,
    users: 3,
    candidates: 30,
    brandedCareerSite: true,
    jobBoardDistribution: true,
    resumeParsing: true,
    candidateAutomations: false,
    candidateQuestionnaires: false,
    customizablePipelineStages: false,
    eeocOfccpReporting: false,
    emailCalendarIntegration: false,
    interviewSelfScheduling: false,
    realTimeAnalytics: false,
    taskManagement: false,
    advancedQuestionnaires: false,
    assessmentIntegrations: false,
  };
  
  export const starterPlanFeatures: SubscriptionPlanFeatures = {
    activeJobPostings: 10,
    careersPageJobPostings: 'Unlimited',
    users: 10,
    candidates: 'Unlimited',
    brandedCareerSite: true,
    jobBoardDistribution: true,
    resumeParsing: true,
    candidateAutomations: true,
    candidateQuestionnaires: true,
    customizablePipelineStages: true,
    eeocOfccpReporting: true,
    emailCalendarIntegration: true,
    interviewSelfScheduling: true,
    realTimeAnalytics: true,
    taskManagement: true,
    advancedQuestionnaires: false,
    assessmentIntegrations: false,
  };
  
  export const growthPlanFeatures: SubscriptionPlanFeatures = {
    activeJobPostings: 30,
    careersPageJobPostings: 'Unlimited',
    users: 'Unlimited',
    candidates: 'Unlimited',
    brandedCareerSite: true,
    jobBoardDistribution: true,
    resumeParsing: true,
    candidateAutomations: true,
    candidateQuestionnaires: true,
    customizablePipelineStages: true,
    eeocOfccpReporting: true,
    emailCalendarIntegration: true,
    interviewSelfScheduling: true,
    realTimeAnalytics: true,
    taskManagement: true,
    advancedQuestionnaires: false,
    assessmentIntegrations: false,
  };
  
  export const scalePlanFeatures: SubscriptionPlanFeatures = {
    activeJobPostings: 'Unlimited',
    careersPageJobPostings: 'Unlimited',
    users: 'Unlimited',
    candidates: 'Unlimited',
    brandedCareerSite: true,
    jobBoardDistribution: true,
    resumeParsing: true,
    candidateAutomations: true,
    candidateQuestionnaires: true,
    customizablePipelineStages: true,
    eeocOfccpReporting: true,
    emailCalendarIntegration: true,
    interviewSelfScheduling: true,
    realTimeAnalytics: true,
    taskManagement: true,
    advancedQuestionnaires: true,
    assessmentIntegrations: true,
  };