import { WelcomeDialogSubscriptionSignup } from "./WelcomeDialogSubscriptionSignup";

/**
 * Welcome dialog shows up when the Employer is signed up for the first time THROUGH THE SUBSCRIPTION FLOW. 
 * This dialog will only close if the Employer Acknowledges the Welcome prompt */
export const WelcomeDialog = () => {

    // const currentHash = window.location.hash.substring(1) as SubscriptionPlan;
    // if (["entry-plan","starter-plan", "growth-plan", "scale-plan"].includes(currentHash)) {
    //     return <WelcomeDialogSubscriptionSignup />;
    // }else{
    //     return <WelcomeDialogNewSignup />;
    // }
    return <WelcomeDialogSubscriptionSignup />;
}

