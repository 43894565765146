import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "shared/SharedModels";
import dayjs, { Dayjs } from 'dayjs';
import { fetchCandidateProfileAdditionalQuestionAnswers } from "store/slices/employer/applicants/applicant-profile-slice";
import { getSelectedJobForm } from "store/slices/employer/employer/employer-details-slice";
import { Typography, 
  Box, 
  RadioGroup, 
  FormControlLabel, 
  Radio, 
  Stack,
 } from '@mui/material';
 import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShTextFieldV2, ShDatePickerV2, ShBlueBtn, ShInputBase } from "shared/SharedStyles/ShInputs";

export const ApplicantAdditionalQuestions = () => {
  const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const { selectedJobForm } = useSelector((state: RootState) => state.employer.employerDetails);
  const { additionalQuestions } = useSelector((state: RootState) => state.employer.applicants.applicantProfile);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (jobId) {
      dispatch(getSelectedJobForm({ jobId }));
    }
  }, [jobId, dispatch]);


  useEffect(() => {
    if (applicantDetails?.candidateId) {
      dispatch(fetchCandidateProfileAdditionalQuestionAnswers({ candidateId: applicantDetails.candidateId }));
    }
  }, [dispatch, applicantDetails?.candidateId]);

  const handleFileSelect = () => {
    const files = fileInputRef.current?.files;
    if (files && files.length > 0) {
      setUploadedFile(files[0]);
    }
  };

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  return (
    <Stack>
      <ShPaper variant="outlined" headerSection noBorderTop borderRadius={0}>
        <Typography variant='body2'> Additional Questions</Typography>
      </ShPaper>
      <ShPaper variant="outlined" noBorderTop borderRadius={0}>
        <Box>
          {selectedJobForm?.questions?.map((question) => {
            const answer = additionalQuestions?.[Number(question.id)]?.answer;
            const autoReject = additionalQuestions?.[Number(question.id)]?.autoReject;

            return (
              <ShPaper key={question.id} variant="outlined" sx={{ marginBottom: 2, padding: 2 }}>
                <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                  {question.question}
                </Typography>

                {/* Handle textfield questions */}
                {question.type === 'textfield' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <ShTextFieldV2
                      fullWidth
                      label="Answer"
                      value={answer || ''}
                      disabled
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                  </Box>
                ) : null}

                {/* Handle radio button questions */}
                {question.type === 'radio' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <RadioGroup value={answer}>
                      {question.options.map((option) => (
                        <FormControlLabel
                          key={option.label}
                          value={option.label}
                          control={<Radio />}
                          disabled
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                ) : null}

                {/* Handle date questions */}
                {question.type === 'date' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ShDatePickerV2
                        format="MM/DD/YYYY"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                            placeholder: "MM/DD/YYYY",
                            variant: "outlined",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : null}

                {/* Handle today and future date questions */}
                {question.type === 'noOldDate' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ShDatePickerV2
                        format="MM/DD/YYYY"
                        shouldDisableDate={(date) => dayjs(date as Dayjs).isBefore(dayjs(), 'day')}
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                            placeholder: "MM/DD/YYYY",
                            variant: "outlined",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : null}

                {/* Handle file upload questions */}
                {question.type === "fileUpload" && (
                  <Box sx={{ marginTop: 1 }}>
                    <ShInputBase
                      type="file"
                      ref={fileInputRef}
                      hidden
                      onChange={handleFileSelect}
                    />
                    {uploadedFile && (
                      <Typography sx={{ marginTop: 1 }}>{uploadedFile.name}</Typography>
                    )}
                  </Box>
                )}

                {/* Handle number questions */}
                {question.type === 'number' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <ShTextFieldV2
                      type="number"
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="Enter a number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </Box>
                ) : null}

                {/* Display answer for other question types like dropdown */}
                {question.type !== 'textfield' && question.type !== 'radio' && answer && (
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      <strong>Answer:</strong> {answer}
                    </Typography>
                  </Box>
                )}

                {/* Auto reject chip */}
                {autoReject && (
                  <ShChip
                    label="Auto Reject"
                    color="error"
                    sx={{ marginTop: 1, marginLeft: 1 }}
                  />
                )}
              </ShPaper>
            );
          })}
        </Box>
      </ShPaper>
    </Stack>
  );
};
