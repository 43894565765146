import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useAppSelector } from 'helpers/hooks';
import { useLocation, useNavigate } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGradientButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import SendIcon from '@mui/icons-material/Send';

export const SubscriptionPaymentRedirect = () => {

    const { firstName } = useAppSelector((state) => state.auth.auth);
    const location = useLocation();
    const isPaymentSuccess = location.pathname.includes('payment-success');
    const navigate = useNavigate();

    return (
        <Stack rowGap={1}>
            {isPaymentSuccess ? (
                <>
                    <ShContainer maxWidth="sm" margin='auto'>
                        <ShPaper variant="outlined">
                            <Stack textAlign='center'>
                                <Box display="flex" justifyContent="center" mb={3}>
                                    <CheckCircleOutline sx={{ fontSize: 80, color: 'success.main' }} />
                                </Box>
                                <Typography variant="h4" fontWeight={600} gutterBottom>
                                    Payment Successful!
                                </Typography>
                                <Typography variant="h5" color="text.secondary" gutterBottom>
                                    Thank you, {firstName}!
                                </Typography>
                                <Typography variant="body1" color="text.secondary" paragraph>
                                    Your subscription has been successfully activated. You now have access to all premium features, including job postings, candidate management, and detailed analytics.
                                </Typography>
                                <Typography variant="body2" color="text.secondary" paragraph>
                                    Start exploring all the tools available to grow your business.
                                </Typography>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" mt={4}>
                                    <ShGradientButton startIcon={<SendIcon />} variant="contained" color="primary" size="large" onClick={() => navigate(`/employer/job/new/select`)}>
                                    Post a Job
                                    </ShGradientButton>
                                    <ShButton variant="outlined" size="large" onClick={() => navigate(`/employer/settings/account/subscriptions#view-subscriptions`)}>
                                    Explore Features
                                    </ShButton>
                                </Stack>
                            </Stack>
                        </ShPaper>
                    </ShContainer>
                </>
            ) : (
                <>
                    <ShAlert color='error' severity="error">
                        <Typography variant="body2">Payment Failed</Typography>
                    </ShAlert>
                </>
            )}
        </Stack>
    );
};