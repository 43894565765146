export type TemplateType = 'ApplicationConfirmation' | 'Rejection' | 'Offer' | 'Interview' | 'Shortlist' | 'Assessment';

export interface LetterPresetsProps {
    templateType: TemplateType;
}
export interface ILetterTemplate {
    templateType: TemplateType; 
    id: number;
    dateCreated: string;
    templateName: string;
    templateDesc: string;
    subject: string;
    emailBody: string;
    ccEmails: string;
    isPreferred: boolean;
}

export const TEMPLATE_TYPE_LABELS: Record<TemplateType, { label: string; caption: string }> = {
    'ApplicationConfirmation': {
        label: 'Application Confirmation Letter',
        caption: 'A formal letter sent to the candidate confirming that their application has been successfully received. This letter may also outline the next steps in the recruitment process and provide information about when they can expect to hear back from the employer.',
    },
    'Rejection': {
        label: 'Rejection Letter Templates',
        caption: 'A respectful letter informing the candidate that they have not been selected for the position. The letter may include constructive feedback, a thank you for their time, and encouragement to apply for future openings.',
    },
    'Offer': {
        label: 'Offer Letter',
        caption: 'A formal letter sent to a candidate offering them a position within the company. This letter includes the terms of employment, such as salary, benefits, start date, and other pertinent details related to the job offer.',
    },
    'Interview': {
        label: 'Interview Invitation Letter',
        caption: 'A letter sent to candidates inviting them for an interview. It specifies the date, time, and location of the interview, along with any necessary instructions or information the candidate may need to prepare.',
    },
    'Shortlist': {
        label: 'Shortlist Notification Letter',
        caption: 'A letter informing the candidate that they have been shortlisted for further consideration. It provides details about the next stages of the hiring process, such as additional interviews or assessments, and gives the candidate an indication of when they will hear from the employer.',
    },
    'Assessment': {
        label: 'Assessment Invitation Letter',
        caption: 'A letter inviting the candidate to take part in an assessment, which could be a skills test, personality test, or any other evaluation that the employer uses to assess the candidate’s suitability for the role.',
    },
};
