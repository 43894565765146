import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse, IFailureResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";
import { ICandidateSocialSignUpPayload, ISocialLoginState, ISocialLoginToApplyJobNavParams, IUserSocialSignUp, TSocialLoginProvider } from "./auth-v2-model";

const initialSocialLoginState: ISocialLoginState = {};

// get social login redirect url
export const getSocialLoginRedirectUrl = createAsyncThunk<{ redirectUrl: string },
    { provider: TSocialLoginProvider, isSignUp: boolean }, { rejectValue: IBaseResponse }>(
        "getSocialLoginRedirectUrl",
        async ({ provider, isSignUp }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.OAUTH_LOGIN}/${provider}?signUp=${isSignUp}`)
                .then((response: AxiosResponse<{ redirectUrl: string }>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

// get social login redirect url for candidate job apply
export const getCandidateSocialLoginRedirectUrl = createAsyncThunk<{ redirectUrl: string },
    ICandidateSocialSignUpPayload, { rejectValue: IBaseResponse }>(
        "getCandidateSocialLoginRedirectUrl",
        async ({ provider, postingSource, jobCode }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.OAUTH_SIGNUP_CANDIDATE}/${provider}?ps=${postingSource}&jobCode=${jobCode}`)
                .then((response: AxiosResponse<{ redirectUrl: string }>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

// login with social profile 
export const loginWithSocialProfile = createAsyncThunk<IBaseResponse,
    { provider: TSocialLoginProvider, payload: { oauth_code: string, codeVerifier?: string } }, { rejectValue: IBaseResponse }>(
        "loginWithSocialProfile",
        async ({ payload, provider }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.OAUTH_LOGIN}/${provider}`, payload)
                .then((response: AxiosResponse<IBaseResponse>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

// login with candidate social profile
export const candidateSignUpWithSocialProfile = createAsyncThunk<IBaseResponse, ICandidateSocialSignUpPayload,
    { rejectValue: IBaseResponse }>(
        "candidateSignUpWithSocialProfile",
        async ({ payload, provider, jobCode, postingSource }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.OAUTH_SIGNUP_CANDIDATE}/${provider}?ps=${postingSource}&jobCode=${jobCode}`, payload)
                .then((response: AxiosResponse<IBaseResponse>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

// get social login profile
export const getSocialLoginProfile = createAsyncThunk<IUserSocialSignUp,
    { provider: TSocialLoginProvider, payload: { oauth_code: string, codeVerifier?: string; } }, { rejectValue: IFailureResponse }>(
        "getSocialLoginProfile",
        async ({ provider, payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.OAUTH_LOGIN}/${provider}/profile`, payload)
                .then((response: AxiosResponse<IUserSocialSignUp>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const socialLoginSlice = createSlice({
    name: "socialLogin",
    initialState: initialSocialLoginState,
    reducers: {
        resetSocialLogin: (state) => {
            state.getSocialLoginRedirectUrlStatus = 'idle';
            state.employerSocialProfileStatus = 'idle';
            state.employerSocialPayload = undefined;
        },
        resetSocialLoginWithProfile: (state) => {
            state.getSocialLoginProfileStatus = 'idle';
            state.getSocialLoginProfileResponse = '';
            state.getSocialLoginProfileStatusCode = undefined;
        },
        resetCandidateSignUpWithSocialProfile: (state) => {
            state.signUpWithCandidateSocialProfileStatus = 'idle';
            state.signUpWithCandidateSocialProfileResponse = '';
        },
        setSocialLoginToApplyJobNavParams: (state, action: PayloadAction<ISocialLoginToApplyJobNavParams>) => {
            state.socialLoginToApplyJobNavParams = action.payload;
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialSocialLoginState;
        });
        //get social redirect url
        builder.addCase(getSocialLoginRedirectUrl.pending, (state) => {
            state.getSocialLoginRedirectUrlStatus = 'pending';
        });
        builder.addCase(getSocialLoginRedirectUrl.fulfilled, (state, action) => {
            state.getSocialLoginRedirectUrlStatus = 'success';
            state.socialLoginRedirectUrl = action?.payload?.redirectUrl;
        });
        builder.addCase(getSocialLoginRedirectUrl.rejected, (state, action) => {
            state.getSocialLoginRedirectUrlStatus = 'failed';
            state.getSocialLoginRedirectUrlResponse = action.payload?.message;
        });
        //get social redirect url for candidate
        builder.addCase(getCandidateSocialLoginRedirectUrl.pending, (state) => {
            state.getCandidateSocialSignUpRedirectUrlStatus = 'pending';
        });
        builder.addCase(getCandidateSocialLoginRedirectUrl.fulfilled, (state, action) => {
            state.getCandidateSocialSignUpRedirectUrlStatus = 'success';
            state.candidateSocialLoginRedirectUrl = action?.payload?.redirectUrl;
        });
        builder.addCase(getCandidateSocialLoginRedirectUrl.rejected, (state, action) => {
            state.getCandidateSocialSignUpRedirectUrlStatus = 'failed';
            state.getCandidateSocialSignUpRedirectUrlResponse = action.payload?.message;
        });
        //login with social profile 
        builder.addCase(loginWithSocialProfile.pending, (state) => {
            state.employerSocialProfileStatus = 'pending';
        });
        builder.addCase(loginWithSocialProfile.fulfilled, (state, action) => {
            state.employerSocialProfileStatus = 'success';
            state.employerSocialPayload = action?.payload?.data;
        });
        builder.addCase(loginWithSocialProfile.rejected, (state, action) => {
            state.employerSocialProfileStatus = 'failed';
            state.employerSocialProfileResponse = action.payload?.message;
        });
        //signup with candidate social profile 
        builder.addCase(candidateSignUpWithSocialProfile.pending, (state) => {
            state.signUpWithCandidateSocialProfileStatus = 'pending';
        });
        builder.addCase(candidateSignUpWithSocialProfile.fulfilled, (state, action) => {
            state.signUpWithCandidateSocialProfileStatus = 'success';
            state.candidateSignUpPayload = action?.payload?.data;
        });
        builder.addCase(candidateSignUpWithSocialProfile.rejected, (state, action) => {
            state.signUpWithCandidateSocialProfileStatus = 'failed';
            state.signUpWithCandidateSocialProfileResponse = action.payload?.message;
        });
        //get social login profile
        builder.addCase(getSocialLoginProfile.pending, (state) => {
            state.getSocialLoginProfileStatus = 'pending';
        });
        builder.addCase(getSocialLoginProfile.fulfilled, (state, action) => {
            state.getSocialLoginProfileStatus = 'success';
            state.socialSignUpProfile = action?.payload;
            state.socialSignUpProfile.signup_source = action.meta.arg.provider;
        });
        builder.addCase(getSocialLoginProfile.rejected, (state, action) => {
            state.getSocialLoginProfileStatus = 'failed';
            state.getSocialLoginProfileResponse = action.payload?.message ?? DefaultAPIErrorMsg;
            state.getSocialLoginProfileStatusCode = action.payload?.statusCode;
        });
    },
});

export const { resetSocialLogin, resetSocialLoginWithProfile,
    resetCandidateSignUpWithSocialProfile, setSocialLoginToApplyJobNavParams } = socialLoginSlice.actions;
export default socialLoginSlice;
