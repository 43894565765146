import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { addJobToCart, setSelectedAddOnBundles, setTotalJobCost } from 'store/slices/employer/create-job/create-job-payments-slice';
import { JobValidity } from '../CreateJob/CreateJobConstants';
import { IBundle } from '../CreateJob/CreateJobModel';
import { SUBSCRIPTION_EMPLOYER_PRICING_GROUP } from 'Modules/Core/SettingsTs/AccountSettings/Subscriptions/SubscriptionsConstants';
import { BEHAVORIAL_ASSESMENT_BUNDLE_ID, FREE_JOB_POSTING_BUNDLE_ID, JOB_POSTING_PKG_MSG, SUBSCRIPTION_DIRECTIONS_MSG } from './BundlesConstants';
import BundlesCard from './BundlesCard';
import JobBoards from 'assets/Images/job_boards.svg';

export const BundlesSubscription = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { jobId } = useParams();
    const bundlesStackRef = useRef<HTMLDivElement>(null);
    const { allBaseBundles, allAddOnBundles, inventoryBundles} = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const { currentJobCreationFlow } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const [_selectedAddOnBundles, _setSelectedAddOnBundles] = useState<number[]>([]);
    const [_totalJobCost, _setTotalJobCost] = useState<number>(0);
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);

    const handleAddOnBundleSelect = (addonId: number) => {
        _setSelectedAddOnBundles((prevSelected) => {
            let newSelected: number[];
            if (prevSelected.includes(addonId)) {
                newSelected = prevSelected.filter((id) => id !== addonId);
            } else {
                newSelected = [...prevSelected, addonId];
            }

            // Update total cost
            const newTotalCost = allAddOnBundles
                .filter(bundle => newSelected.includes(bundle.id))
                .reduce((acc, bundle) => acc + (bundle.base_price), 0);

            _setTotalJobCost(newTotalCost);

            return newSelected;
        });
    };

    useEffect(() => {
        const newTotalCost = allAddOnBundles
            .filter(bundle => _selectedAddOnBundles.includes(bundle.id))
            .reduce((acc, bundle) => acc + (bundle.base_price || 0), 0);

        _setTotalJobCost(newTotalCost);
    }, [_selectedAddOnBundles, allAddOnBundles]);


    useEffect(() => {
        if (bundlesStackRef.current) {
            bundlesStackRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);


    const getPricingLabel = (bundle: IBundle) => {
        const bundleInInventory = inventoryBundles?.find(ib => ib.bundle_id === bundle.id);
        return (<>
            {
                bundleInInventory ?
                    <>
                        {`1 of ${bundleInInventory?.quantity} credit${bundleInInventory?.quantity > 1 ? 's' : ''}`}
                    </> :
                    bundle.base_price > 0 &&
                    <>
                        ${bundle.base_price}
                    </>
            }
        </>);
    };

    const continueToPayment = () => {
        dispatch(setTotalJobCost(_totalJobCost));
    
        const updatedAddOnBundles = new Set(_selectedAddOnBundles || []);
        updatedAddOnBundles.add(FREE_JOB_POSTING_BUNDLE_ID);
    
        const updatedAddOnBundlesArray = Array.from(updatedAddOnBundles);
    
        dispatch(setSelectedAddOnBundles(updatedAddOnBundlesArray));
    
        if (updatedAddOnBundlesArray.length) {
            dispatch(addJobToCart({ jobId: parseInt(jobId ?? ''), payload: { bundles: updatedAddOnBundlesArray } }));
        }
    };
    

    const onJobBundlesBack = () => {
        if (currentJobCreationFlow === 'useTemplateJob') {
            navigate(`/employer/job/${jobId}/title`);
        } else {
            navigate(`/employer/job/${jobId}/additional-questions`);
        }
    }

    return (
        <>
            <Stack direction='row' >
                <Stack spacing={1}>
                    {/* Vertical Cards for Bundles */}
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        {companyInfo.employer_pricing_group_id !== SUBSCRIPTION_EMPLOYER_PRICING_GROUP &&
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <LocalOfferOutlinedIcon color='primary' />
                                <Typography variant='body1'>Bundles</Typography>
                            </Stack>
                        }
                        <Stack padding={.5}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <ShoppingCartOutlinedIcon color='primary' />
                                <Typography variant="body2" whiteSpace='nowrap' fontWeight={600}>
                                    Total: ${_totalJobCost}
                                </Typography>
                            </Stack>
                        </Stack>

                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center' p={0.5}>
                        <LibraryAddOutlinedIcon color='primary' />
                        <Typography variant='body1' pt={1}> Add ons </Typography>
                    </Stack>
                    <Stack>
                        <ShPaper variant='outlined' headerSection borderRadius={0}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography variant='body1' gutterBottom>
                                    Free Job Posting Boards (Included)
                                </Typography>
                                <Box height="40px" display="flex" alignItems="center">
                                <img src={JobBoards} alt="TopCandidates" style={{ height: '200%', width: 'auto', objectFit: 'contain' }} />
                            </Box>

                            </Stack>
                        </ShPaper>
                        <ShPaper variant='outlined' noBorderTop borderRadius={0}>
                            <Grid container rowGap={2}>
                                {/* 
                                    FREE JOB POSTING Bundle for Subscription
                                */}
                                {allBaseBundles.filter(addon => addon.id === FREE_JOB_POSTING_BUNDLE_ID).map((addon) => (
                                    <BundlesCard
                                        key={addon.id}
                                        addon={addon}
                                        selectedAddOnBundles={_selectedAddOnBundles}
                                        setSelectedAddOnBundles={_setSelectedAddOnBundles}
                                        handleAddOnBundleSelect={handleAddOnBundleSelect}
                                        getPricingLabel={getPricingLabel}
                                        employerDetails={employerDetails}
                                    />
                                ))}
                            </Grid>
                        </ShPaper>
                    </Stack>
                    
                    <Divider/>
                    <Stack>
                        <ShPaper variant='outlined' headerSection borderRadius={0}>
                            <Typography variant='body1' gutterBottom>
                                Candidate Fit (Behavorial Assessment)
                            </Typography>
                        </ShPaper>
                        <ShPaper variant='outlined' noBorderTop borderRadius={0}>
                            <Grid container rowGap={2}>
                                {/* 
                                    Only behavorial assesment 
                                */}
                                {allAddOnBundles.filter(addon => addon.id === BEHAVORIAL_ASSESMENT_BUNDLE_ID).map((addon) => (
                                    <BundlesCard
                                        key={addon.id}
                                        addon={addon}
                                        selectedAddOnBundles={_selectedAddOnBundles}
                                        setSelectedAddOnBundles={_setSelectedAddOnBundles}
                                        handleAddOnBundleSelect={handleAddOnBundleSelect}
                                        getPricingLabel={getPricingLabel}
                                        employerDetails={employerDetails}
                                    />
                                ))}
                            </Grid>
                        </ShPaper>
                    </Stack>
                    <Stack>
                        <ShPaper variant='outlined' headerSection borderRadius={0}>
                            <Typography variant='body1' gutterBottom>
                            {companyInfo.employer_pricing_group_id !== SUBSCRIPTION_EMPLOYER_PRICING_GROUP 
                                ? JOB_POSTING_PKG_MSG : SUBSCRIPTION_DIRECTIONS_MSG}
                            </Typography>
                        </ShPaper>
                        <ShPaper variant='outlined' noBorderTop borderRadius={0}>
                            <Grid container rowGap={2}>
                                {/* 
                                    All addons except behavoiral
                                */}
                                {allAddOnBundles.filter(addon => addon.id !== BEHAVORIAL_ASSESMENT_BUNDLE_ID).map((addon) => (
                                    <BundlesCard
                                        key={addon.id}
                                        addon={addon}
                                        selectedAddOnBundles={_selectedAddOnBundles}
                                        setSelectedAddOnBundles={_setSelectedAddOnBundles}
                                        handleAddOnBundleSelect={handleAddOnBundleSelect}
                                        getPricingLabel={getPricingLabel}
                                        employerDetails={employerDetails}
                                    />
                                ))}
                            </Grid>
                        </ShPaper>
                    </Stack>
                    {!employerDetails?.free_trail_signup &&
                        <Stack flexDirection='row' alignItems='center' columnGap={0.5} m={2}>
                            <InfoOutlinedIcon fontSize="small" color='disabled' />
                            <Typography variant="caption" display='flex' alignItems='center'>
                                Jobs active for {JobValidity} days
                            </Typography>
                        </Stack>
                    }
                    <Stack flexDirection='row' justifyContent='space-between' alignItems='center' columnGap={1} rowGap={1} mb={3} flexWrap='wrap'>
                        <ShButton variant='contained' size='small' disableElevation onClick={onJobBundlesBack}>Back</ShButton>
                        <Typography variant="body2" whiteSpace='nowrap' fontWeight={600}>Total: ${_totalJobCost}</Typography>
                        <ShGreenBtn disableElevation variant='contained' className='continue-btn' onClick={() => {continueToPayment()}}>
                                Continue
                        </ShGreenBtn>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

export default BundlesSubscription;
