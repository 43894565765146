import CreateIcon from '@mui/icons-material/Create';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Chip, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { InterviewIcon } from "assets/Icons";
import FingerPrint from 'assets/Images/HelpImages/FingerPrint.svg';
import { IsSmScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { CommonQuestionSidebar } from "../CommonQuestionSidebar";
import { ApplicantsStagesInfo } from "../HelpConstants";

export const ManagingCandidates = () => {

  const isSmScreen = IsSmScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (

    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
        <ResourceTypography variant="h5" fontWeight="bold"  gutterBottom >Manage your Candidates</ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            With SmoothHiring's ATS platform, managing candidates has never been easier.
            Follow these simple steps to streamline your recruitment process.
          </ResourceTypography>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>View Candidates</ResourceTypography>
          <ResourceTypography variant="body1">
            To manage candidates, navigate to the &nbsp;
            <ShButton size="small" variant="contained" color="primary">My Jobs</ShButton> &nbsp; section.
            Select the job for which you want to manage candidates and click on the row OR the &nbsp; <MoreVertIcon /> &nbsp; icon.
            Then click on the &nbsp; <ShButton color='inherit' variant="text" startIcon={<PeopleIcon color='secondary' />}>View Applicants</ShButton> &nbsp;
            link.
          </ResourceTypography>

          <Box paddingTop={3} paddingBottom={3}>
            <TableContainer component={Paper} elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Owner</TableCell>
                    <TableCell>Hiring Manager</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Start Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Software Engineer</TableCell>
                    <TableCell>John Doe</TableCell>
                    <TableCell>Jane Smith</TableCell>
                    <TableCell>New York</TableCell>
                    <TableCell>NY</TableCell>
                    <TableCell>01/01/2024</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <MoreVertIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Analyzing Candidate Fit</ResourceTypography>
          <ResourceTypography variant="body1">
            Our platform provides insights into each candidate's suitability through metrics such as Strong Fit, Weak Fit, and Distortion.
            This helps you make informed decisions based on predictive analytics and candidate assessments.
            Strong Fit indicates the candidate will work well with your company. Fit means they qualify to work for your company.
            Weak fit suggest they may not be a qualified hire. Distortion means there was an issue with their predictive assessment,
            and the candidate may have completed it incorrectly.
          </ResourceTypography>
          <Grid container spacing={1} wrap="wrap">
            <Grid item xs={3} sm={2} md={1}>
              <Chip variant="outlined" label="Strong Fit" color="success" />
            </Grid>
            <Grid item xs={3} sm={2} md={1}>
              <Chip variant="outlined" label="Weak Fit" color="warning" />
            </Grid>
            <Grid item xs={3} sm={2} md={1}>
              <Chip variant="outlined" label="Distortion" color="error" />
            </Grid>
            <Grid item xs={3} sm={2} md={1}>
              <Chip variant="outlined" label="Fit" color="primary" />
            </Grid>
          </Grid>


          <ResourceTypography paddingTop={2} variant="h6" fontWeight={'bold'} gutterBottom>Change Candidate Stages</ResourceTypography>
          <Box paddingTop={1} paddingBottom={3}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Candidate Stages
            </Typography>
            <Typography variant="body1" paragraph>
              You can change candidate stages by updating the <strong> Stage </strong> column in your applicant view.
              The dropdown will look similar to this button <ShButton variant="outlined" color='primary'>New</ShButton>
              You may then click on it to reveal the possible stages for the candidates.
              Clicking on any interview options will prompt you to schedule a time to interview.
            </Typography>
            <Typography variant="body1" paragraph>
              Candidates can be in various stages throughout the recruitment process. Below are the possible stages:
            </Typography>
            <Grid container spacing={1}>
              {ApplicantsStagesInfo.map((stage) => (
                <Grid item xs={3} key={stage.value}>
                  <Chip label={stage.label} color="info" />
                </Grid>
              ))}
            </Grid>
          </Box>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>View Candidate Fingerprint</ResourceTypography>
          <Typography variant="body1" paragraph>
            You may view a candidate summary by <strong> clicking on the row </strong> of a candidate to see the preview below, which
            shows some basic information of a candidate along with their fingerprint from their predictive assessment.
            You may also view profile to see their Resume, Detailed Fingerprint, Comments, and tailored
            Interview questions to ask this candidate.
          </Typography>
          <Box
            component="img"
            src={FingerPrint}
            alt="TopCandidates"
            sx={{
              width: 'auto',
              height: 'auto',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              display: 'block',
              margin: '0 auto'
            }}
          />
          <ResourceTypography paddingTop={2} variant="h6" fontWeight={'bold'} gutterBottom>Overview of Features</ResourceTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                <FormatListNumberedRtlIcon color='primary' />
                <Stack spacing={2}>
                  <Typography variant="h6" noWrap gutterBottom>View Applicants</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Access and review applications submitted by candidates.
                  </Typography>
                  <ShButton component={RouterLink} to="/employer/jobs/active" size='small'
                    variant="contained" color="secondary" endIcon={<PeopleIcon />}>View Applicants</ShButton>
                </Stack>
              </ShPaper>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                <CreateIcon color='primary' />
                <Stack spacing={2}>
                  <Typography variant="h6" noWrap gutterBottom>Schedule Interviews</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Schedule interviews with shortlisted candidates.
                  </Typography>
                  <Stack spacing={2} direction={isSmScreen ? 'row' : 'column'}>
                    <ShButton component={RouterLink} to="/employer/interviews/scheduled"
                      size='small' variant="contained" color="primary" endIcon={<InterviewIcon />}>Interviews</ShButton>
                    <ShButton component={RouterLink} to="/employer/calendar" size='small'
                      variant="contained" color="info" endIcon={<CalendarIcon />}>Calendar</ShButton>
                  </Stack>
                </Stack>
              </ShPaper>
            </Grid>
          </Grid>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Candidate Evaluation</ResourceTypography>
          <ResourceTypography variant="body1">
            Evaluate candidates based on their application details and predictive analytics scores. Our platform categorizes candidates into Strong Fit, Weak Fit, and other relevant metrics to help you make the best hiring decisions.
          </ResourceTypography>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Interviews and Offers</ResourceTypography>
          <ResourceTypography variant="body1">
            Once you've shortlisted candidates, you can schedule interviews directly through the platform. After the interview process, easily extend job offers to the best candidates.
          </ResourceTypography>
        </ShContainer>

        {!isSmScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};
