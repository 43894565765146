import { Stack, Typography, Box, Fade, Divider } from "@mui/material";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { PrimaryWordpressThemeColor } from "shared/SharedStyles/styleConstants";

export const SkillsSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.skills?.length) return null;

    return (
    <Fade in={true} timeout={800}>
        <Stack spacing={2}>
          {candidate.skills.length > 0 && (
                <Box mt={2} mb={1}> 
                    <Typography fontSize={18}>SKILLS</Typography>
                    <Divider flexItem/>
                    <Stack direction="row" flexWrap="wrap" gap={1} mt={1}>
                        {candidate.skills.map((skill, index) => (
                            <ShChip hideBoxShadow size='small' customSize='small' bgColor={PrimaryWordpressThemeColor} textColor='white' key={index} label={skill}/>
                        ))}
                    </Stack>
                </Box>
            )}
        </Stack>
    </Fade>
    );
}
