import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAreaInformation, IJobTitleState, INewJobCreationResponse, IUseATemplateJobPayload } from 'Modules/Core/CreateJob/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from 'shared/constants';

const initialJobTitleState: IJobTitleState = {
    getJobHiringManagersResponse: '',
    getJobHiringManagersStatus: 'idle',
    jobHiringManagers: [],
    newJobCreationResponse: '',
    newJobCreationStatus: 'idle',
    newJobId: undefined,
    getAreaInformationStatus: 'idle',
    areaInformation: {
        city: '',
        state: '',
        country: '',
    }
};

export const getJobHiringManagers = createAsyncThunk<IBaseResponse<any[]>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getJobHiringManagers",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}/hiring-managers`)
            .then((response: AxiosResponse<IBaseResponse<any[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const createNewJob = createAsyncThunk<INewJobCreationResponse, void, { rejectValue: IBaseResponse }>(
    "createNewJob",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.JOBS}`)
            .then((response: AxiosResponse<INewJobCreationResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const createNewTemplateJob = createAsyncThunk<INewJobCreationResponse, void, { rejectValue: IBaseResponse }>(
    "createNewTemplateJob",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.CREATE_TEMPLATE_JOB}`)
            .then((response: AxiosResponse<INewJobCreationResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getAreaInformationByZipCode = createAsyncThunk<IBaseResponse<IAreaInformation>, { zipCode: string }, { rejectValue: IBaseResponse }>(
    "getAreaInformationByZipCode",
    async ({ zipCode }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.AREA_INFORMATION}/${zipCode}`)
            .then((response: AxiosResponse<IBaseResponse<IAreaInformation>>) => response.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const createJobWithTemplate = createAsyncThunk<IBaseResponse<{ id: number }>, { jobId: number, payload: IUseATemplateJobPayload }, { rejectValue: IBaseResponse }>(
    "createJobWithTemplate",
    async ({ payload, jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.USE_TEMPLATE_JOB}/${jobId}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const jobTitleSlice = createSlice({
    name: 'jobTitle',
    initialState: initialJobTitleState,
    reducers: {
        resetGetJobHiringManagers: (state) => { state.getJobHiringManagersStatus = 'idle'; state.getJobHiringManagersResponse = '' },
        resetCreateNewJob: (state) => { state.newJobCreationStatus = 'idle'; state.newJobCreationResponse = '' },
        setRePostJobId: (state, action: PayloadAction<number>) => { state.rePostJobId = action.payload.toString() },
        resetAreaInfoByZipCode: (state) => {
            state.getAreaInformationStatus = 'idle';
            state.areaInformation = {
                city: '',
                state: '',
                country: '',
            }
        },
        resetUseATemplateJob: (state) => { state.useATemplateJobStatus = 'idle'; state.useATemplateJobResponse = '' }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobTitleState;
        });
        // get hiring managers
        builder.addCase(getJobHiringManagers.pending, (state) => {
            state.getJobHiringManagersStatus = 'pending'
        });
        builder.addCase(getJobHiringManagers.fulfilled, (state, action) => {
            state.getJobHiringManagersStatus = 'success';
            state.jobHiringManagers = action.payload.data;
        });
        builder.addCase(getJobHiringManagers.rejected, (state, action) => {
            state.getJobHiringManagersStatus = 'failed';
            state.getJobHiringManagersResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // create new job
        builder.addCase(createNewJob.pending, (state) => {
            state.newJobCreationStatus = 'pending'
        });
        builder.addCase(createNewJob.fulfilled, (state, action) => {
            state.newJobCreationStatus = 'success';
            state.newJobId = action.payload.newJobId;
        });
        builder.addCase(createNewJob.rejected, (state, action) => {
            state.newJobCreationStatus = 'failed';
            state.newJobCreationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // create new template job
        builder.addCase(createNewTemplateJob.pending, (state) => {
            state.newJobCreationStatus = 'pending'
        });
        builder.addCase(createNewTemplateJob.fulfilled, (state, action) => {
            state.newJobCreationStatus = 'success';
            state.newJobId = action.payload.newJobId;
        });
        builder.addCase(createNewTemplateJob.rejected, (state, action) => {
            state.newJobCreationStatus = 'failed';
            state.newJobCreationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        builder.addCase(getAreaInformationByZipCode.pending, (state, action) => {
            state.getAreaInformationStatus = 'pending';
        });
        builder.addCase(getAreaInformationByZipCode.fulfilled, (state, action) => {
            state.getAreaInformationStatus = 'success';
            state.areaInformation = action.payload.data;
        });
        builder.addCase(getAreaInformationByZipCode.rejected, (state, action) => {
            state.getAreaInformationStatus = 'failed';
            state.areaInformation = { city: '', state: '', country: '' };
        });

        builder.addCase(createJobWithTemplate.pending, (state, action) => {
            state.useATemplateJobStatus = 'pending';
        });
        builder.addCase(createJobWithTemplate.fulfilled, (state, action) => {
            state.useATemplateJobStatus = 'success';
            state.useATemplateJobResponse = action.payload?.message ?? DefaultAPISuccessMsg;
            state.jobIdCreatedWithTemplate = action.payload.data.id;
        });
        builder.addCase(createJobWithTemplate.rejected, (state, action) => {
            state.useATemplateJobStatus = 'failed';
            state.useATemplateJobResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        })
    }
});

export const { resetGetJobHiringManagers, setRePostJobId, resetCreateNewJob, resetAreaInfoByZipCode, resetUseATemplateJob } = jobTitleSlice.actions;
export default jobTitleSlice;
