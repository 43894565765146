import { Alert, Box, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { IApplicantsApiParams } from "Modules/Core/Applicants/ApplicantsModel";
import { IJobsApiParams } from "Modules/Core/JobsTs/JobsModel";
import { useCallback, useEffect, useState } from "react";
import { ShAvatar, ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchDashboardActiveJobsList } from "store/slices/employer/employer/dashboard/dashboard-active-jobs-slice";
import { fetchDashboardApplicantsList } from "store/slices/employer/employer/dashboard/dashboard-applicants-slice";
import { setSelectedJobId } from "store/slices/employer/employer/dashboard/dashboard-recent-activity-selected-job-slice";
import { DashboardMenuProps } from "../Dashboard.styled";
import { DashboardListSize, DashboardTileBordeRadius, getDotPropertiesAndMessage } from "../DashboardConstants";
import { getInitialsFromName, stringToColor, toTitleCase, truncateText } from 'shared/utils';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";

export const DashboardRecentActivityWidget = () => {
    const [selectedJob, setSelectedJob] = useState<number>(0);
    const [selectedJobName, setSelectedJobName] = useState<string>("");
    const navigate = useNavigate();
    const { jobsList: activeJobsList, getJobsListStatus } = useAppSelector(state => state.employer.dashboard.dashboardActiveJobs);
    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForRecentActivity);

    const dispatch = useAppDispatch();
    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        // FOR FETCHING DRAFT JOBS
        dispatch(fetchDashboardActiveJobsList(params));
    }, [dispatch]);

    // Get jobs list on page load.
    useEffect(() => {
        if(activeJobsList.length === 0){
            getJobs();
        }
    }, [dispatch, getJobs, activeJobsList.length]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: 50,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };
        dispatch(fetchDashboardApplicantsList(params));
    }, [dispatch]);

    // Get applicants list when summary panel is toggled and has applicants.
    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);
            setSelectedJobName(selectedJobData?.name ?? '')
            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);

    
    const handleDashboardRecentActivityChange = (event: SelectChangeEvent<string>) => {
        const jobId = parseInt(event.target.value);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }

    };
    

    /*
    * If the applicant is new, then use the completedAtDate when sorting, otherwise use the stageModifiedAt Date
    */
    const sortedApplicantList = [...applicantsData].sort((a, b) => {
        const dateA = (a.jobStage === 'new') ? new Date(a.applicationCompletedAt) : new Date(a.stageModifiedAt);
        const dateB = (b.jobStage === 'new') ? new Date(b.applicationCompletedAt) : new Date(b.stageModifiedAt);
        return dateB.getTime() - dateA.getTime();
    });

    return (
        <Stack height='550px'>
            <ShPaper height='100%' variant='outlined' borderRadius={DashboardTileBordeRadius} overflow='auto'>
                <Stack direction='row' justifyContent='space-between' padding={1}>
                    <Typography variant='subtitle1' sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessAlarmIcon color='primary' sx={{ marginRight: 1 }} />
                        Activity
                    </Typography>
                    <Box width={200}>              
                        <FormControl fullWidth size="small" disabled={activeJobsList.length === 0}>
                            <InputLabel style={{ fontSize: 'small' }}>{activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}</InputLabel>
                            <Select
                            style={{ fontSize: 'small' }}
                            MenuProps={DashboardMenuProps}
                            onChange={handleDashboardRecentActivityChange}
                            value={activeJobsList.length > 0 ? selectedJob.toString() : ''}
                            input={<OutlinedInput label="Select a JobsX" />}>
                            {activeJobsList?.map((job) => (
                                <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                    {truncateText(job.name, 30)}
                                </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Box>
                </Stack>
                {getJobsListStatus === "pending" ?<> <LinearProgress /></> :
                    <Stack>
                        {sortedApplicantList?.length > 0 ?
                            <>
                                <Stack marginBottom={2} justifyContent='center' overflow='auto'>
                                        {sortedApplicantList.map((applicant, index) => {
                                            const { color, message } = getDotPropertiesAndMessage(applicant, selectedJobName);
                                            const initials = getInitialsFromName(applicant?.fullName || applicant?.email, true, true);
                                            const getAvatarBackgroundColor = stringToColor(initials);

                                            return (
                                                <Stack key={index} pb={0.5} pt={0.5}> 
                                                    <ShPaper variant="outlined">
                                                        <Stack direction='row' justifyContent='space-between' pb={1}>
                                                            <ShChip size='small' customSize='small' borderRadius='12px' textColor='white'
                                                                variant="outlined" bgColor={PrimaryThemeColor}
                                                                label={
                                                                    <Typography fontSize='11.5px'> 
                                                                        {new Date(applicant.stageModifiedAt).toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' })}
                                                                    </Typography>
                                                                }/>
                                                                <IconButton onClick={()=> navigate(`/employer/job/${selectedJobId}/applicant/${applicant.candidateEmployerJobId}/details`)}>
                                                                    <OpenInNewIcon fontSize='small' color='primary' />
                                                                </IconButton>
                                                        </Stack>
                                                        <Stack direction='row' spacing={2} alignItems='center'>
                                                            <ShAvatar minWidth={35} height={35} backgroundColor={getAvatarBackgroundColor} >
                                                                <Typography variant='body1'>
                                                                    {(applicant?.firstName ? applicant?.firstName[0] : applicant?.email[0]).toUpperCase()}
                                                                </Typography>
                                                            </ShAvatar>
                                                            <Typography fontSize='12.5px'>
                                                                {message}
                                                            </Typography>
                                                            <ShChip
                                                                customSize='small'
                                                                size='small'
                                                                variant="outlined"
                                                                textColor={color}
                                                                label={toTitleCase(applicant.jobStage)}
                                                                hideBoxShadow
                                                                />
                                                        </Stack>
                                                    </ShPaper>
                                                </Stack>
                                            );
                                        })}
                                </Stack>
                            </>
                            :
                            <Alert severity="info">No Recent Activity</Alert>
                        }
                    </Stack>
                }
            </ShPaper>
        </Stack>
    )
}