import { Stack } from "@mui/material";
import WidgetFactory from "./DashboardWidgetFactory/WidgetFactory";

export const Dashboard = () => {
    return (
        <Stack rowGap={3} padding={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <WidgetFactory />
        </Stack>
    );
};
