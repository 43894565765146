import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import {
  Box,
  Divider,
  Link,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { OrderTableSummaryStyled } from "Modules/Core/CreateJob/CreateJob/CreateJob.styled";
import {
  IBundle,
  PaymentResponse,
} from "Modules/Core/CreateJob/CreateJob/CreateJobModel";
import { useNotification } from "Modules/Core/Notification";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ShBullets, ShCheckmark } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShStack } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import {
  areAllElementsInArrayXInArrayY,
  areSomeElementsInArrayXInArrayY,
} from "shared/utils";
import {
  postSubscriptionWithJobNoAddons,
  purchaseJob,
  purchaseJobWithCoupon,
  resetPurchase,
} from "store/slices/employer/create-job/create-job-payments-slice";
import { resetUseATemplateJob } from "store/slices/employer/create-job/job-title-slice";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";
import JobShareDialog from "../../JobsTs/JobShareDialog";
import {
  SUBSCRIPTION_EMPLOYER_PRICING_GROUP,
  SUBSCRIPTION_PLAN_NAVIGATE,
} from "../../SettingsTs/AccountSettings/Subscriptions/SubscriptionsConstants";
import {
  getCompanyInfo,
  resetLogoUpdate,
  resetUpdateCompany,
} from "store/slices/employer/settingsTs/company-info-slice";
import {
  fetchJobsCounts,
  resetJobsState,
} from "store/slices/employer/jobs/jobs-list-slice";
import { hasFeaturePermission } from "shared/SharedComponents/SubscriptionPermissions";
import { ConfirmationDialog } from "shared/SharedComponents/ConfirmationDialog/ConfirmationDialog";
import { JobValidity } from "../CreateJob/CreateJobConstants";

export const Payment = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const isXsScreen = IsXsScreen();
  const {
    allBundles,
    inventoryBundles,
    totalJobCost,
    selectedAddOnBundles,
    selectedBaseBundle,
    purchaseJobStatus,
    orderId,
    purchaseJobResponse,
    purchaseUrl,
    purchaseJobWithCouponStatus,
    purchaseJobWithCouponResponse,
    discount,
  } = useAppSelector((state) => state.employer.createJob.createJobPayments);
  const { employerDetails } = useAppSelector(
    (state) => state.employer.employerDetails
  );
  const { employerId } = useAppSelector((state) => state.auth.auth);
  const pathSegments = useLocation().pathname.split("/");
  const lastPathSegment = pathSegments[
    pathSegments.length - 1
  ] as PaymentResponse;
  const [isShareJobEnabled, setIsShareJobEnabled] = useState(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const { companyInfo } = useAppSelector(
    (state) => state.employer.employerSettings.companyInfo
  );
  const { activeCount } = useAppSelector(
    (state) => state.employer.employerJobs.jobsList
  );
  const { hasSubscriptionPermission, dialogMessage } = hasFeaturePermission(
    companyInfo,
    "activeJobPostings",
    activeCount
  );
  const [
    isInsufficentPermissionsDialogOpen,
    setInsufficentPermissionsDialogOpen,
  ] = useState(false);

  // Get Jobs counts on load.
  useEffect(() => {
    dispatch(fetchJobsCounts());
    return () => {
      // Reset jobs state on component unmount.
      dispatch(resetJobsState());
    };
  }, [dispatch]);

  const payForJob = () => {
    // For subscription users if they are just using there job posting (unsponsored), if they are on free trial, bypass the subscriptionPermissioncheck
    if (
      !employerDetails?.free_trail_signup &&
      employerDetails?.employer_pricing_group_id ===
        SUBSCRIPTION_EMPLOYER_PRICING_GROUP &&
      jobId
    ) {
      if (hasSubscriptionPermission) {
        dispatch(
          postSubscriptionWithJobNoAddons({
            metadata: {
              employerId: employerDetails.id,
              accountId: employerDetails?.account_id,
              jobId: jobId,
            },
            stripId: 1,
            purchaseMode: "unknown",
          })
        );
      } else {
        setInsufficentPermissionsDialogOpen(true);
      }
    } else {
      if (orderId) {
        dispatch(
          purchaseJob({ jobId: parseInt(jobId ?? ""), orderId: orderId })
        );
      }
    }
  };

  useEffect(() => {
    if (!companyInfo.id) {
      dispatch(getCompanyInfo());
    }

    return () => {
      // Reset update company info, logoAPIvariables on component unmount.
      dispatch(resetUpdateCompany());
      dispatch(resetLogoUpdate());
    };
  }, [companyInfo, dispatch]);

  // Show snackbar notifications when the purchase is either successful or failed.
  useEffect(() => {
    if (purchaseJobStatus === "failed" || purchaseJobStatus === "success") {
      notification.displayNotification({
        open: true,
        type: purchaseJobStatus === "failed" ? "error" : "success",
        message: purchaseJobResponse ?? "",
      });
      dispatch(resetPurchase());
      // Route to Active Jobs once job is successfully posted.
      if (purchaseJobStatus === "success") {
        dispatch(resetUseATemplateJob());
      }
    } else if (purchaseJobStatus === "stripe_payment_required" && purchaseUrl) {
      window.location.href = purchaseUrl;
    }
  }, [
    dispatch,
    navigate,
    notification,
    purchaseJobResponse,
    purchaseJobStatus,
    purchaseUrl,
  ]);

  useEffect(() => {
    if (
      (lastPathSegment === "success" || purchaseJobStatus === "success") &&
      jobId
    ) {
      setIsShareJobEnabled(true);
    }
  }, [lastPathSegment, purchaseJobStatus, jobId]);

  const getPricingLabel = (bundle: IBundle) => {
    const bundleInInventory = inventoryBundles?.find(
      (ib) => ib.bundle_id === bundle.id
    );
    return (
      <>
        {bundleInInventory ? (
          <Typography
            variant={isXsScreen ? "body2" : "body1"}
            textAlign={isXsScreen ? "right" : "unset"}
            gutterBottom={isXsScreen}
            fontWeight={600}
          >
            {`1 of ${bundleInInventory?.quantity} credit${
              bundleInInventory?.quantity > 1 ? "s" : ""
            }`}
          </Typography>
        ) : (
          <Typography
            variant={isXsScreen ? "body2" : "body1"}
            textAlign={isXsScreen ? "right" : "unset"}
            gutterBottom={isXsScreen}
            fontWeight={600}
          >
            ${bundle.base_price}
          </Typography>
        )}
      </>
    );
  };

  useEffect(() => {
    if (employerDetails?.employer_code) {
      setCouponCode(employerDetails.employer_code);
    }
  }, [employerDetails?.employer_code]);

  const activateJobWithCoupon = () => {
    // If subscription member and has free trial, then they shouldnt have a base bundle
    const shouldExcludeBaseBundle =
      employerDetails?.free_trail_signup &&
      employerDetails?.employer_pricing_group_id ===
        SUBSCRIPTION_EMPLOYER_PRICING_GROUP;

    dispatch(
      purchaseJobWithCoupon({
        jobId: parseInt(jobId ?? ""),
        payload: {
          bundles: shouldExcludeBaseBundle
            ? [...selectedAddOnBundles]
            : [selectedBaseBundle, ...selectedAddOnBundles],
          coupon_code: couponCode,
        },
      })
    );
  };

  useEffect(() => {
    if (purchaseJobWithCouponStatus === "success") {
      if (employerId) {
        dispatch(getEmployerDetails({ empId: employerId }));
      }
    }
  }, [dispatch, employerId, purchaseJobWithCouponStatus]);

  return (
    <>
      <Stack
        rowGap={1}
        maxWidth={750}
        maxHeight="100%"
        paddingX={{ xs: 0, sm: 2, md: 3, lg: 3 }}
      >
        <Typography
          align="center"
          variant="body1"
          fontWeight={600}
          gutterBottom={!isXsScreen}
        >
          Order Summary
        </Typography>
        {isXsScreen ? (
          <>
            <ShPaper variant="outlined" noPadding flex={1} overflow="auto">
              {allBundles
                ?.filter((b) =>
                  [selectedBaseBundle, ...selectedAddOnBundles].includes(b.id)
                )
                .map((bundle, indx, arr) => (
                  <Stack paddingY={1} paddingX={2} key={bundle.id}>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      {bundle.name}
                    </Typography>
                    <ShBullets>
                      {bundle.description.split("-").map(
                        (d, i) =>
                          d && (
                            <li key={i}>
                              <Typography variant="caption">{d}</Typography>
                            </li>
                          )
                      )}
                    </ShBullets>
                    {!employerDetails?.free_trail_signup &&
                      purchaseJobWithCouponStatus !== "success" &&
                      getPricingLabel(bundle)}
                    {/* Hide divider for last bundle */}
                    {indx < arr.length - 1 && (
                      <Box mt={1}>
                        <Divider />
                      </Box>
                    )}
                  </Stack>
                ))}
              {!employerDetails?.free_trail_signup &&
                purchaseJobWithCouponStatus !== "success" && (
                  <Box zIndex={1} position="sticky" bottom={0} left={0}>
                    <Divider />
                    <ShStack
                      direction="row"
                      justifyContent="space-between"
                      paddingY={1}
                      paddingX={2}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        Total
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        ${totalJobCost}
                      </Typography>
                    </ShStack>
                  </Box>
                )}
            </ShPaper>
          </>
        ) : (
          <>
            <TableContainer component={Paper} variant="outlined" sx={{ overflow: "visible" }}>
              <OrderTableSummaryStyled
                size="small"
                aria-label="Payment summary table"
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell><Typography variant="body1" fontWeight={"bold"} >Quantity</Typography></TableCell> */}
                    <TableCell>
                      <Typography variant="body1" fontWeight={"bold"}>
                        Bundle
                      </Typography>
                    </TableCell>
                    {!employerDetails?.free_trail_signup &&
                      purchaseJobWithCouponStatus !== "success" && (
                        <TableCell>
                          <Typography variant="body1" fontWeight={"bold"}>
                            Price
                          </Typography>
                        </TableCell>
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allBundles
                    ?.filter((b) =>
                      [selectedBaseBundle, ...selectedAddOnBundles].includes(
                        b.id
                      )
                    )
                    .map((bundle) => (
                      <TableRow className="rowStyle" key={bundle.id}>
                        {/* <TableCell className="no-left-border" align="center">1</TableCell> */}
                        <TableCell className="no-left-border">
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            fontWeight={"bold"}
                          >
                            {bundle.name}
                          </Typography>
                          {/* 
                      Bundle description is being sent as '-' separated string from the API.
                      Split it with '-' and display as a Bullet points.
                    */}
                          <ShCheckmark
                            items={bundle.description.split(/(?=[-~])/)}
                          />
                        </TableCell>
                        {!employerDetails?.free_trail_signup &&
                          purchaseJobWithCouponStatus !== "success" && (
                            <TableCell>
                              {/* 
                    If bundles are already available in inventory show the credits deducted label.
                    If no bundles, then display bundle price.
                  */}
                              {getPricingLabel(bundle)}
                            </TableCell>
                          )}
                      </TableRow>
                    ))}

                  {/* Total rows */}
                  {!employerDetails?.free_trail_signup &&
                    purchaseJobWithCouponStatus !== "success" && (
                      <TableRow>
                        {/* <TableCell rowSpan={3} className="no-border" /> */}
                        <TableCell className="border" align="right">
                          Total
                        </TableCell>
                        <TableCell className="totalAmountCell">
                          ${totalJobCost}
                        </TableCell>
                      </TableRow>
                    )}
                  {discount > 0 && (
                    <>
                      <TableRow>
                        <TableCell className="border" align="right">
                          Discount
                        </TableCell>
                        <TableCell className="discountedAmount">
                          ${totalJobCost * discount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="border" align="right">
                          <strong>Final Price</strong>
                        </TableCell>
                        <TableCell
                          className="finalPrice"
                          style={{ backgroundColor: "beige" }}
                        >
                          ${totalJobCost - totalJobCost * discount}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </OrderTableSummaryStyled>
            </TableContainer>
          </>
        )}
        <Outlet
          context={
            lastPathSegment === "success" || purchaseJobStatus === "success"
          }
        />
        <Stack>
          {!employerDetails?.free_trail_signup &&
            purchaseJobWithCouponStatus !== "success" &&
            lastPathSegment !== "success" &&
            lastPathSegment !== "failed" &&
            (areAllElementsInArrayXInArrayY(
              [selectedBaseBundle, ...selectedAddOnBundles],
              inventoryBundles.map((ib) => ib.bundle_id)
            ) &&
            areSomeElementsInArrayXInArrayY(
              [selectedBaseBundle, ...selectedAddOnBundles],
              inventoryBundles.map((ib) => ib.bundle_id)
            ) ? (
              <ShAlert variant="outlined" severity="info" marginBottom={1}>
                You are about to use credits for this Job.
              </ShAlert>
            ) : totalJobCost > 0 ? (
              <ShAlert variant="outlined" severity="warning" marginBottom={1}>
                Looks like you don't have enough credits for this Job with the
                selected bundles. Proceed to purchase?
              </ShAlert>
            ) : (
              <></>
            ))}
          {employerDetails?.free_trail_signup && (
            <Stack rowGap={1} marginY={1}>
              <Typography variant="body2">
                Your free trial job expires in {JobValidity} days
              </Typography>
              <Stack direction="row" rowGap={1} flexWrap="wrap">
                <Typography variant="body2">Need help? Email:&nbsp;</Typography>
                <Link
                  href="mailto:help@smoothhiring.com"
                  fontSize="small"
                  underline="hover"
                >
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                  >
                    <MailOutlinedIcon fontSize="small" />
                    help@smoothhiring.com
                  </Typography>
                </Link>
                <Typography variant="body2">
                  &nbsp;or call us toll free:&nbsp;
                </Typography>
                <Link href="tel:18777898767" fontSize="small" underline="hover">
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                  >
                    <PhoneOutlinedIcon fontSize="small" />1 (877)
                    789-8767.&nbsp;
                  </Typography>
                </Link>
                <Typography variant="body2">Happy Hiring!</Typography>
              </Stack>
              {/* <ShTextField size="small" name='coupon_code' id="coupon_code" label="Coupon code" maxWidth="200px" autoFocus
            variant="outlined" value={couponCode} onChange={e => setCouponCode(e.target.value)} margin="dense" /> */}
            </Stack>
          )}
          {purchaseJobWithCouponResponse && (
            <Box marginY={1}>
              {
                <ShAlert
                  severity={
                    purchaseJobWithCouponStatus === "success"
                      ? "success"
                      : "error"
                  }
                >
                  {purchaseJobWithCouponResponse}
                </ShAlert>
              }
            </Box>
          )}
          {lastPathSegment === "success" ? (
            <Stack flexDirection="row" justifyContent="flex-end">
              <ShGreenBtn
                className="self-right"
                variant="contained"
                color="primary"
                size="small"
                onClick={() => navigate("/employer/jobs/active")}
              >
                Go to My Jobs
              </ShGreenBtn>
            </Stack>
          ) : (
            <Stack flexDirection="row" justifyContent="space-between">
              {purchaseJobWithCouponStatus !== "success" && (
                <ShButton
                  variant="contained"
                  size="small"
                  disableElevation
                  component={RouterLink}
                  to={`/employer/job/${jobId}/bundles`}
                >
                  Back
                </ShButton>
              )}

              {(employerDetails?.free_trail_signup ||
                (!employerDetails?.free_trail_signup &&
                  purchaseJobWithCouponStatus === "success")) &&
                (purchaseJobWithCouponStatus === "success" ? (
                  <ShGreenBtn
                    className="self-right"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate("/employer/jobs/active")}
                  >
                    Go to My Jobs
                  </ShGreenBtn>
                ) : (
                  <ShGreenBtn
                    className="self-right"
                    size="small"
                    onClick={activateJobWithCoupon}
                    disabled={
                      purchaseJobWithCouponStatus === "pending" || !couponCode
                    }
                  >
                    {purchaseJobWithCouponStatus === "pending"
                      ? "Activating Job..."
                      : "Post Job"}
                  </ShGreenBtn>
                ))}
              {!employerDetails?.free_trail_signup &&
                purchaseJobWithCouponStatus !== "success" && (
                  <ShGreenBtn
                    className="self-right"
                    size="small"
                    onClick={payForJob}
                  >
                    {purchaseJobStatus === "pending"
                      ? "Processing..."
                      : lastPathSegment === "failed"
                      ? "Retry"
                      : "Confirm"}
                  </ShGreenBtn>
                )}
            </Stack>
          )}
        </Stack>
        {isShareJobEnabled && (
          <JobShareDialog
            jobId={parseInt(jobId!)}
            navBtn={{
              path: "/employer/jobs/active",
              title: "Later. Go to My Jobs",
            }}
          />
        )}
        <ConfirmationDialog
          isDialogOpen={isInsufficentPermissionsDialogOpen}
          title="Job Postings Limit Reached "
          contentText={
            dialogMessage ||
            "You do not have permission to post this job. Please upgrade your plan."
          }
          onCancel={() => setInsufficentPermissionsDialogOpen(false)}
          onConfirm={() => {
            setInsufficentPermissionsDialogOpen(false);
            navigate(SUBSCRIPTION_PLAN_NAVIGATE);
          }}
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Upgrade Plan"
        />
      </Stack>
    </>
  );
};
