import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { Link as RouterLink } from "react-router-dom";
import { ShAlert, ShDialog } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { setIsWelcomeAcknowledged } from "store/slices/app/app-info-slice";

/**
 * Welcome dialog shows up when the Employer is signed up for the first time.
 * This dialog will only close if the Employer Acknowledges the Welcome prompt */
export const WelcomeDialogSubscriptionSignup = () => {

    const dispatch = useAppDispatch();
    const isWelcomeAcknowledged = useAppSelector(state => state.app.appInfo.isWelcomeAcknowledged);

    const dispatchWelcomeAcknowledgement = () => {
        dispatch(setIsWelcomeAcknowledged({ _isWelcomeAcknowledged: 'yes' }));
    }

    return (<>
        <ShDialog open={isWelcomeAcknowledged === 'no'}>
            <DialogTitle id='title' textAlign='center' fontWeight={600}>
                Welcome to the Future of Hiring!
                <IconButton className='close-btn' onClick={dispatchWelcomeAcknowledgement}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1" gutterBottom pb={2}> 
                    You're about to change the way you hire forever.
                    It will be Easy, Fast and we'll get the best candidates to the top of your pile.
                </Typography>
                <ShAlert variant='outlined' color='info'>
                    <Typography variant="body2" align="center">
                        Start exploring now or pick the perfect plan for your team’s growth.
                    </Typography>
                </ShAlert>
            </DialogContent>
            <DialogActions>
                <ShButton component={RouterLink} to="/employer/dashboard"
                    onClick={dispatchWelcomeAcknowledgement}>Explore the app!</ShButton>
                <ShGreenBtn component={RouterLink} to="/employer/settings/account/subscriptions#view-subscriptions"
                    onClick={dispatchWelcomeAcknowledgement}>Choose Your Plan</ShGreenBtn>
            </DialogActions>
        </ShDialog>
    </>);
}

