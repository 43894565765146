import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ClearIcon from "@mui/icons-material/Clear";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TableChartIcon from "@mui/icons-material/TableChart";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  IconButton,
  Paper,
  Stack,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants";
import { JobsHeaders } from "Modules/Core/JobsTs/JobsConstants";
import { IJobsToolBar, JobTypes } from "Modules/Core/JobsTs/JobsModel";
import { IsSmScreen, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LayoutTypes } from "shared/SharedModels";
import { ShButton, ShInputBase } from "shared/SharedStyles/ShInputs";
import { ShTabsV2, StyledMenuItem } from "shared/SharedStyles/ShNavigation";
import { ShToolbarPaper } from "shared/SharedStyles/ShSurfaces";
import { PaperVariant } from "shared/SharedStyles/styleConstants";
import { KeyCodes } from "shared/constants";
import { JobType } from "store/slices/employer/jobs/jobs-slice-model";
import { useNavigate } from "react-router-dom";
import { Send } from "@mui/icons-material";

export const JobsToolBar = ({
  layoutType,
  setLayoutType,
  sortList,
  sortParams,
  searchKey,
  jobsType,
  searchInJobs,
  setJobsType,
  activeCount,
  draftCount,
  expiredCount,
  templateCount,
}: IJobsToolBar) => {
  const isSmScreen = IsSmScreen();
  const navigate = useNavigate();
  const [sortByAnchorEl, setSortByAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const isSortByOpen = Boolean(sortByAnchorEl);
  const [_searchKey, _setSearchKey] = useState<string>("");

  const { accountAccess } = useAppSelector((state) => state.auth.auth);
  const { employerDetails } = useAppSelector(
    (state) => state.employer.employerDetails
  );

  const renderPostJobButtonAlways = () => {
    const featureMap =
      RoleAccessControlMap[accountAccess.role ?? "employer"].featureMap;

    return (
      <>
        {featureMap && featureMap["Draft Jobs"] ? (
          featureMap["Draft Jobs"].visibility === "hidden" ? (
            <></>
          ) : featureMap["Draft Jobs"].visibility === "disabled" ? (
            <ShButton variant="contained" disabled startIcon={<Send />}  color="primary">
              Post a New Job
            </ShButton>
          ) : (
            <ShButton  variant="contained" onClick={() => navigate("/employer/job/new")} startIcon={<Send />} color="primary">
              Post a New Job
            </ShButton>
          )
        ) : (
          <ShButton variant="contained" onClick={() => navigate("/employer/job/new")} color="primary" startIcon={<Send />}>
            Post a Job
          </ShButton>
        )}
      </>
    );
  };

  
  useEffect(() => {
    _setSearchKey(searchKey);
  }, [searchKey]);

  return (
    <>
      <ShToolbarPaper elevation={0}>
        <Stack
          className="tools-stack"
          columnGap={1}
          flexWrap={isSmScreen ? "wrap-reverse" : "nowrap"}
          rowGap={1}
          justifyContent="space-between"
        >
          <Stack
            flexDirection="row"
            overflow="auto"
            alignItems="center"
            columnGap={1}
          >
            <ShTabsV2
              aria-label="Job Tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              value={jobsType}
              onChange={(e, nV) => {
                setJobsType(nV as JobType);
              }}
            >
              <Tab
                label={`Active Jobs (${activeCount ?? 0})`}
                component={Link}
                value={JobTypes.Active}
                to={"/employer/jobs/active"}
              />
              {RoleAccessControlMap[accountAccess.role ?? "employer"]
                .featureMap?.["Draft Jobs"]?.visibility !== "hidden" && (
                <Tab
                  label={`Draft Jobs (${draftCount ?? 0})`}
                  component={Link}
                  value={JobTypes.Draft}
                  to={"/employer/jobs/draft"}
                  disabled={
                    RoleAccessControlMap[accountAccess.role ?? "employer"]
                      .featureMap?.["Draft Jobs"]?.visibility === "disabled"
                  }
                />
              )}
              {RoleAccessControlMap[accountAccess.role ?? "employer"]
                .featureMap?.["Expired Jobs"]?.visibility !== "hidden" && (
                <Tab
                  label={`Expired Jobs (${expiredCount ?? 0})`}
                  component={Link}
                  value={JobTypes.Expired}
                  to={"/employer/jobs/expired"}
                  disabled={
                    RoleAccessControlMap[accountAccess.role ?? "employer"]
                      .featureMap?.["Expired Jobs"]?.visibility === "disabled"
                  }
                />
              )}
              {employerDetails?.has_template_jobs &&
                RoleAccessControlMap[accountAccess.role ?? "employer"]
                  .featureMap?.["Template Jobs"]?.visibility !== "hidden" && (
                  <Tab
                    label={`Template Jobs (${templateCount ?? 0})`}
                    component={Link}
                    value={JobTypes.TemplateJobs}
                    to={"/employer/jobs/template"}
                    disabled={
                      RoleAccessControlMap[accountAccess.role ?? "employer"]
                        .featureMap?.["Template Jobs"]?.visibility ===
                      "disabled"
                    }
                  />
                )}
            </ShTabsV2>
            {RoleAccessControlMap[accountAccess.role ?? "employer"]
              .featureMap?.["All Job Count"]?.visibility !== "hidden" && (
              <Tooltip
                arrow
                title={`All Jobs (${
                  activeCount + draftCount + expiredCount || 0
                })`}
              >
                <InfoOutlinedIcon fontSize="small" color="disabled" />
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end" padding={0}>
            {renderPostJobButtonAlways()}
          </Stack>
          <Stack flexDirection="row" columnGap={1}>
            <Paper className="custom-paper" variant={PaperVariant}>
              <ShInputBase
                id="search-jobs"
                placeholder="Title/Location"
                inputProps={{ "aria-label": "Search Jobs" }}
                value={_searchKey}
                onChange={(e) => _setSearchKey(e.target.value)}
                onKeyUp={(e) =>
                  e.code === KeyCodes.Enter && searchInJobs(_searchKey)
                }
              />
           
              {searchKey?.length > 0 && (
                <Tooltip arrow title="Clear search">
                  <IconButton
                    aria-label="clear"
                    color="error"
                    onClick={() => searchInJobs("")}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip arrow title="Search">
                <span>
                  <IconButton
                    disabled={_searchKey?.trim().length === 0}
                    aria-label="search"
                    onClick={() => searchInJobs(_searchKey)}
                  >
                    <SearchIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Paper>
           
            {layoutType === LayoutTypes.List && jobsType !== "template" && (
              <Paper className="custom-paper" variant={PaperVariant}>
                
                <Stack
                  flexDirection="row"
                  columnGap={{ xs: 0, sm: 1, md: 1, lg: 1 }}
                >
                  <ShButton
                    size="small"
                    aria-label={`Sort by ${
                      JobsHeaders.filter((jh) => jh.isSortable).find(
                        (ah) =>
                          sortParams && ah.columnNameInDb === sortParams.orderBy
                      )?.label
                    }
                                in ${
                                  sortParams.order === "asc"
                                    ? "ascending"
                                    : "descending"
                                } order`}
                    onClick={(e) => setSortByAnchorEl(e.currentTarget)}
                  >
                    {
                      JobsHeaders.filter((jh) => jh.isSortable).find(
                        (ah) => ah.columnNameInDb === sortParams.orderBy
                      )?.label
                    }
                  </ShButton>
                  <Menu
                    id="sort-by-menu"
                    aria-label="Sort by menu"
                    anchorEl={sortByAnchorEl}
                    open={isSortByOpen}
                    onClose={() => setSortByAnchorEl(null)}
                  >
                    {JobsHeaders.filter((jh) => jh.isSortable).map((hd) => (
                      <StyledMenuItem
                        key={hd.columnNameInDb}
                        className={
                          hd.columnNameInDb === sortParams.orderBy
                            ? "selected-item"
                            : ""
                        }
                        onClick={() => {
                          sortList(
                            hd.columnNameInDb ?? "name",
                            sortParams.order
                          );
                          setSortByAnchorEl(null);
                        }}
                      >
                        {hd.label}
                      </StyledMenuItem>
                    ))}
                  </Menu>
                  <Tooltip arrow title="Reverse sort direction">
                    <IconButton
                      size="small"
                      aria-label={`Sort in ${
                        sortParams.order === "asc" ? "ascending" : "descending"
                      } order`}
                      onClick={() => {
                        sortList(
                          sortParams.orderBy,
                          sortParams.order === "asc" ? "desc" : "asc"
                        );
                        setSortByAnchorEl(null);
                      }}
                    >
                      {sortParams.order === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Paper>
            )}
            {!isSmScreen && (
              <Paper className="custom-paper" variant={PaperVariant}>
                
                <ToggleButtonGroup
                  exclusive
                  value={layoutType}
                  onChange={(e, newVal) =>
                    newVal !== null && setLayoutType(newVal)
                  }
                  aria-label="toggle layout"
                >
                  <ToggleButton value={LayoutTypes.List} aria-label="List">
                    <Tooltip arrow title="List view">
                      <ViewListIcon />
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton value={LayoutTypes.Table} aria-label="Table">
                    <Tooltip arrow title="Table view">
                      <TableChartIcon />
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Paper>
            )}
          </Stack>
        </Stack>
      </ShToolbarPaper>
    </>
  );
};
