import { IBundle } from "Modules/Core/CreateJob/CreateJob/CreateJobModel";
import { SubscriptionPlanFeatures, SubscriptionPlan } from "./SubscriptionsModel";
import { entryPlanFeatures, starterPlanFeatures, growthPlanFeatures, scalePlanFeatures } from "./SubscriptionFeaturesConstants";

const entry: IBundle = {
  id: 1,
  country_id: 233,
  name: 'Entry',
  level: 2,
  base_price: 49,
  active: true,
  description: 'Basic job postings and features.',
  kind: 'base',
  employer_pricing_group_id: 1,
  code: 'entry-plan',
  order: 2,
  discount: 0,
  is_featured: false,
  currency_symbol: '$',
  yearlyPrice: 470,
  paymentLink: '',
  features_included: '3 Active Job Postings Per Month, 20 Jobs Posted to Careers Page, 3 Users, 30 Candidates Per Job, Career Site'
};

const starter: IBundle = {
  id: 2,
  country_id: 233,
  name: 'Starter',
  level: 2,
  base_price: 149,
  active: true,
  description: 'Enhanced hiring tools to get off the ground.',
  kind: 'base',
  employer_pricing_group_id: 1,
  code: 'starter-plan',
  order: 2,
  discount: 0,
  is_featured: true,
  currency_symbol: '$',
  yearlyPrice: 1430,
  paymentLink: '',
  features_included: '10 Active Job Postings Per Month, Unlimited Jobs Posted to Careers Page, 10 Users, Unlimited Candidates Per Job, Candidate Questionnaires',
};

const growth: IBundle = {
  id: 3,
  country_id: 233,
  name: 'Growth',
  level: 3,
  base_price: 249,
  active: true,
  description: 'Hire better together with a growing team.',
  kind: 'base',
  employer_pricing_group_id: 1,
  code: 'growth-plan',
  order: 3,
  discount: 0,
  is_featured: false,
  currency_symbol: '$',
  yearlyPrice: 2390,
  paymentLink: '',
  features_included: 'Includes all Starter features, 30 Active Job Postings Per Month, Unlimited Jobs Posted to Careers Page, Unlimited Users, Unlimited Candidates Per Job, Candidate Questionnaires',
};

const scale: IBundle = {
  id: 4,
  country_id: 233,
  name: 'Scale',
  level: 4,
  base_price: 429,
  active: true,
  description: 'Ultimate hiring control & flexibility.',
  kind: 'base',
  employer_pricing_group_id: 1,
  code: 'scale-plan',
  order: 4,
  discount: 0,
  is_featured: false,
  currency_symbol: '$',
  yearlyPrice: 4118,
  paymentLink: '',
  features_included: 'Includes all Growth features, Unlimited Active Job Postings Per Month, Unlimited Jobs Posted to Careers Page, Unlimited Users, Unlimited Candidates Per Job',
};



export const allSubscriptionBundles: IBundle[] = [entry, starter, growth, scale];

export const featureLabels: Record<keyof SubscriptionPlanFeatures, string> = {
  activeJobPostings: "Active Job Postings Per Month",
  careersPageJobPostings: "Jobs Posted to Careers Page",
  users: "Users",
  candidates: "Candidates Per Job",
  // candidatePools: "Candidate Pools",
  brandedCareerSite: "Branded Career Site",
  jobBoardDistribution: "Distribute to Job Boards",
  // gdprCompliance: "GDPR Compliance",
  // multiLanguageSupport: "Multi-Language Support",
  resumeParsing: "Resume Parsing",
  // backgroundScreening: "Background Screening Integrations",
  candidateAutomations: "Candidate Automations",
  candidateQuestionnaires: "Candidate Questionnaires",
  customizablePipelineStages: "Customizable Pipeline Stages",
  eeocOfccpReporting: "Equal Employment Opportunities Reporting",
  emailCalendarIntegration: "Email & Calendar Integration",
  // mobileApps: "iOS & Android Mobile Apps",
  interviewSelfScheduling: "Interview Self-Scheduling",
  // liveVideoMeetings: "Live Video Meetings",
  realTimeAnalytics: "Real-Time Analytics & Reporting",
  // slackIntegration: "Slack Integration",
  taskManagement: "Task Management",
  // videoTutorials: "Video Tutorials",
  // weeklyWebinars: "Weekly Webinars",
  // automatedReferenceChecking: "Automated Reference Checking",
  // customizableInterviewGuides: "Customizable Interview Guides",
  // customizableScorecards: "Customizable Scorecards",
  // employeeReferrals: "Employee Referrals",
  // eSignatures: "eSignatures",
  // externalRecruiterSupport: "External Recruiter Support",
  // helloMessenger: "Hello Messenger",
  // multipleLocations: "Multiple Locations",
  // multipleRecruitingPipelines: "Multiple Recruiting Pipelines",
  advancedQuestionnaires: "Advanced Questionnaires",
  assessmentIntegrations: "Assessment Integrations",
  // candidateComparison: "Candidate Comparison",
  // candidateNurtureCampaigns: "Candidate Nurture Campaigns",
  // customRolesPermissions: "Custom Roles & Permissions",
  // departmentList: "Department List",
  // hrisIntegrations: "HRIS Integrations",
  // jobApprovals: "Job Approvals",
  // offerApprovals: "Offer Approvals",
  // offerManagement: "Offer Management",
  // tagLocking: "Tag Locking",
};


export const PLAN_FEATURES_MAP: Record<SubscriptionPlan, SubscriptionPlanFeatures> = {
  "entry-plan-monthly": { ...entryPlanFeatures },
  "starter-plan-monthly": { ...starterPlanFeatures },
  "growth-plan-monthly": { ...growthPlanFeatures },
  "scale-plan-monthly": { ...scalePlanFeatures },

  //Yearly plans
  "entry-plan-yearly": { ...entryPlanFeatures },
  "starter-plan-yearly": { ...starterPlanFeatures },
  "growth-plan-yearly": { ...growthPlanFeatures },
  "scale-plan-yearly": { ...scalePlanFeatures },
};

export const SUBSCRIPTION_EMPLOYER_PRICING_GROUP = 9;
export const SUBSCRIPTION_PLAN_NAVIGATE = "/employer/settings/account/subscriptions#view-subscriptions"