import { Chip, Grid, Stack } from '@mui/material';
import { GridWithBorder } from '../Candidate/Candidate.styled';
import { Typography } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import {
  ShButton,
  ShResizableTextField,
  ShTextFieldV2,
} from 'shared/SharedStyles/ShInputs';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import AddAttendeesDialog from '../Interviews/AddAttendeesDialog';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  clearCreateTaskStatus,
  createTask,
} from 'store/slices/employer/create-task/task-slice';
import { useNotification } from '../Notification';

const CreateTask = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const isSmScreen = IsSmScreen();
  const [isMembersDialogueOpen, setMembersDialogueOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState<Dayjs | null>(dayjs());
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { createTaskApiStatus } = useAppSelector(
    (state) => state.employer.createTask
  );
  const { accountList } = useAppSelector(
    (state) => state.employer.employerSettings.accessLevels
  );

  const isFormValid =
    taskName && description && selectedMembers.length > 0 && dueDate;

  const handleCreateTask = () => {
    if (!isFormValid) {
      setErrorMessage('Please fill all fields.');
      return;
    }

    const accountIds = accountList
      .filter((account) => selectedMembers.includes(account.account.email))
      .map((account) => account.account_id);

    const formattedDueDate = dueDate ? dueDate.format('YYYY-MM-DD') : '';

    dispatch(
      createTask({
        name: taskName,
        description: description,
        assignTo: accountIds,
        dueDate: formattedDueDate,
      })
    );
  };

  useEffect(() => {
    if (createTaskApiStatus === 'failed') {
      setErrorMessage('Failed to create task. Please try again.');
    } else if (createTaskApiStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: 'Task created successfully',
      });
      setTaskName('');
      setDescription('');
      setSelectedMembers([]);
      setDueDate(dayjs());
      setErrorMessage(null);
      dispatch(clearCreateTaskStatus());
    }
  }, [createTaskApiStatus, dispatch, notification]);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <GridWithBorder
        item
        xs={12}
        sm={8}
        lg={5}
        padding={4}
        showBorder={!isSmScreen}
        minHeight={500}
        gap={1}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h6">Create Task</Typography>
        <Typography variant="body1" >
          Create & assign tasks for yourself or someone on your team
        </Typography>
        <Stack gap={4}>
          <Stack gap={1}>
            <ShPaper variant="outlined" borderRadius={10}>
              <Stack gap={1}>
                <Typography variant="body1">Task Name</Typography>
                <ShTextFieldV2
                  size="small"
                  fullWidth
                  variant="outlined"
                  placeholder="Task Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                />
              </Stack>
            </ShPaper>
            <ShPaper variant="outlined" borderRadius={10}>
              <Stack>
                <Typography variant="body1">Description</Typography>
                <ShResizableTextField
                  onClick={(e) => e.stopPropagation()}
                  multiline
                  rows={3}
                  margin="dense"
                  id="paragraph"
                  fullWidth
                  variant="outlined"
                  placeholder="Add description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Stack>
            </ShPaper>
            <ShPaper variant="outlined" borderRadius={10}>
              <Stack gap={1}>
                <Typography variant="body1">
                  Who will work on this task?
                </Typography>
                <ShButton
                  variant="outlined"
                  color="inherit"
                  startIcon={<AddIcon />}
                  fullWidth
                  onClick={() => setMembersDialogueOpen(true)}
                >
                  Assign Task
                </ShButton>
                <Stack
                  marginTop={2}
                  direction="row"
                  columnGap={1}
                  flexWrap="wrap"
                  rowGap={1}
                >
                  {selectedMembers.map((email, index) => (
                    <Chip size="small" key={index} label={email} />
                  ))}
                </Stack>
              </Stack>
            </ShPaper>
            <ShPaper variant="outlined" borderRadius={10}>
              <Stack gap={1}>
                <Typography variant="body1">Due date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dueDate}
                    onChange={(newValue: Dayjs | null) => {
                      setDueDate(newValue);
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                    disablePast
                  />
                </LocalizationProvider>
              </Stack>
            </ShPaper>
          </Stack>
          <Stack alignItems="end" gap={1}>
            <ShButton
              onClick={handleCreateTask}
              variant="contained"
              disabled={!isFormValid || createTaskApiStatus === 'pending'}
            >
              {createTaskApiStatus === 'pending'
                ? 'Creating...'
                : 'Create Task'}
            </ShButton>
            {errorMessage && (
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
          </Stack>
        </Stack>
      </GridWithBorder>
      <AddAttendeesDialog
        isDialogOpen={isMembersDialogueOpen}
        selectedAttendees={selectedMembers}
        setSelectedAttendees={(emails) => {
          setSelectedMembers(emails);
        }}
        onClose={setMembersDialogueOpen}
        title="Add Members"
        subTitle="Select Members"
      />
    </Grid>
  );
};

export default CreateTask;
