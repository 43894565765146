import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { FormTemplates } from "../../SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplates";
import { useEffect, useState } from "react";
import { getCompanyInfo } from "store/slices/employer/settingsTs/company-info-slice";
import { useNotification } from "Modules/Core/Notification";

export const AdditionalQuestionForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();
  // const { isEeoEnabled } = useAppSelector((state) => state.employer.createJob.createJobShared);
  const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
  const { allJobDetails, createOrUpdateJobResponse, createOrUpdateJobStatus,  currentJobCreationFlow } = useAppSelector((state) => state.employer.createJob.createJobShared);
  const notification = useNotification();
  const [isBackClicked, setIsBackClicked] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (createOrUpdateJobStatus === 'success') {
        if (currentJobCreationFlow === 'templateJob') {
          notification.displayNotification({
              open: true,
              type: 'success',
              message: createOrUpdateJobResponse
          });
        }else{
          if (allJobDetails?.workflow_state === 'active') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: createOrUpdateJobResponse
            });
            if (isBackClicked) {
                navigate(`/employer/job/${jobId}/preview`);
            }else{
              setIsNextDisabled(true);
            }
          }
        }
    }
  }, [allJobDetails?.workflow_state, navigate, createOrUpdateJobResponse, createOrUpdateJobStatus, currentJobCreationFlow, notification, jobId, isBackClicked]);

  useEffect(() => {
        if (currentJobCreationFlow === 'templateJob') {
          navigate(`/employer/jobs/template`);
        }else{
          if (allJobDetails?.workflow_state === 'active') {
            if (isBackClicked) {
                navigate(`/employer/job/${jobId}/preview`);
            }else{
              notification.displayNotification({
                open: true,
                type: 'success',
                message: "Additional Questions Successfully Updated"
            });
              setIsNextDisabled(true);
            }
          }
        }
  }, [allJobDetails?.workflow_state, navigate, createOrUpdateJobResponse, createOrUpdateJobStatus, currentJobCreationFlow, notification, jobId, isBackClicked]);

  useEffect(() => {
    if (!companyInfo.id) {
        dispatch(getCompanyInfo());
    }

}, [companyInfo, dispatch]);

  return (
    <Stack spacing={2}>
      <FormTemplates usedInCreateJob={true} />
      {/* Navigation Buttons */}
      <Stack direction="row" justifyContent="space-between">

      <ShButton variant="contained" disableElevation onClick={() => {
        setIsBackClicked(true);
        navigate(`/employer/job/${jobId}/preview`);
      }}>
        Back
      </ShButton>

        {companyInfo.is_eeo_enabled ?
          <ShGreenBtn variant="contained" disableElevation onClick={() => {
            if (isNextDisabled) {
              navigate(`/employer/jobs/active`);
            } else {
              navigate(`/employer/job/${jobId}/eeoQuestionnaire`);
            }
            }}>
            Next
          </ShGreenBtn>
          :
          <ShGreenBtn variant="contained" disableElevation onClick={() => {
            if (isNextDisabled) {
              navigate(`/employer/jobs/active`);
            } else {
              navigate(`/employer/job/${jobId}/bundles`);
            }
          }}>
            {isNextDisabled ? 'Finish & Save' : 'Next'}
          </ShGreenBtn>
        }

      </Stack>
    </Stack>
  );
};
