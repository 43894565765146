import { IQuestion } from "./FormTemplateModel";

interface ISectionedQuestion extends IQuestion {
    section: string;
}

export const predefinedQuestions: ISectionedQuestion[] = [
    // Location Section
    {
        id: '991',
        question: 'Can you relocate?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'required',
        section: 'Location',
    },
    {
        id: '9920',
        question: 'What is your current city and state?',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'Location',
    },
    {
        id: '992',
        question: 'Do you need a visa?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'required',
        section: 'Location',
    },
    {
        id: '993',
        question: 'Are you legally eligible to work in United States?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'required',
        section: 'Location',
    },
    
    // Compensation and Start Date
    {
        id: '994',
        question: 'What is your desired salary?',
        type: 'textfield',
        options: [],
        required: 'required',
        section: 'Compensation and Start Date',
    },
    {
        id: '995',
        question: 'What is your available start date?',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'Compensation and Start Date',
    },
    
    // Current Employment
    {
        id: '996',
        question: 'Reason for leaving your current organization?',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'Current Employment',
    },
    
    // References
    {
        id: '997',
        question: 'Do you have any references?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'References',
    },
    {
        id: '998',
        question: 'Reference Name',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'References',
    },
    {
        id: '999',
        question: 'Reference Contact Information',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'References',
    },
    {
        id: '1000',
        question: 'Relationship to Reference',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'References',
    },
    
    // Questions Specific to the Role
    {
        id: '9911',
        question: 'Why do you want to work for our company?',
        type: 'textfield',
        options: [],
        required: 'required',
        section: 'Questions Specific to the Role',
    },
    {
        id: '9912',
        question: 'How do your skills and experience align with the role\'s requirements?',
        type: 'textfield',
        options: [],
        required: 'required',
        section: 'Questions Specific to the Role',
    },
    {
        id: '9913',
        question: 'Describe a challenge you faced in a previous job and how you handled it.',
        type: 'textfield',
        options: [],
        required: 'required',
        section: 'Questions Specific to the Role',
    },
    {
        id: '9914',
        question: 'Do you have any certifications relevant to this role?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'Questions Specific to the Role',
    },
    
    // Career and Work Preferences
    {
        id: '9915',
        question: 'What are your career goals for the next 5 years?',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'Career and Work Preferences',
    },
    {
        id: '9916',
        question: 'Do you have experience working remotely?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'Career and Work Preferences',
    },
    {
        id: '9917',
        question: 'Are you willing to work overtime if required?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'Career and Work Preferences',
    },
    
    // Other Information
    {
        id: '9918',
        question: 'Do you have a valid driver\'s license?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'Other Information',
    },
    {
        id: '9919',
        question: 'What languages do you speak fluently?',
        type: 'textfield',
        options: [],
        required: 'optional',
        section: 'Other Information',
    },
    {
        id: '9920',
        question: 'Are you comfortable with traveling for work?',
        type: 'radio',
        options: [
            { label: 'Yes', autoReject: false },
            { label: 'No', autoReject: false }
        ],
        required: 'optional',
        section: 'Other Information',
    },
];
