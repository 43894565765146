import ContactPageIcon from "@mui/icons-material/ContactPage";
import DescriptionIcon from "@mui/icons-material/Description";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import InfoIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Collapse,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ApplicantsHeaders } from "Modules/Core/Applicants/ApplicantsConstants";
import { ApplicantComment } from "Modules/Core/Applicants/ApplicantsList/ApplicantComment";
import { ApplicantRating } from "Modules/Core/Applicants/ApplicantsList/ApplicantRating";
import { ApplicantSummary } from "Modules/Core/Applicants/ApplicantsList/ApplicantSummary";
import { StagesToggler } from "Modules/Core/Applicants/ApplicantsList/StagesToggler";
import { getRejectReasonInCandidateEmployerJobsTable, updateBookmark } from "store/slices/employer/applicants/applicant-actions-slice";
import {
  IApplicantRowModel,
  IApplicantsTable,
} from "Modules/Core/Applicants/ApplicantsModel";
import { CircleBox } from "Modules/Core/Dashboard/Dashboard.styled";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import {
  CollapsibleTableRow,
  RowSelectCell,
  ShAvatar,
  ShTable,
  ShTableContainer,
  ShTableRow,
  ThemeColorDivider,
} from "shared/SharedStyles/ShDataDisplay";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShCheckbox } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { getInitialsFromName, stringToColor } from "shared/utils";
import { getApplicantResume } from "store/slices/employer/applicants/applicant-profile-slice";
import { ResumeScoreGauge } from "../ApplicantHelpers";
import { renderMatchChip } from "./ApplicantsListView";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { updateBookmarkCandidateStatus } from "store/slices/employer/applicants/applicant-actions-slice";

// Table Row component
const Row = ({
  applicant,
  selectedApplicants,
  selectApplicant,
  indx,
  aiCandidatesSummary,
  applicantsSummary,
  getApplicantSummary,
  goToApplicantProfile,
  changeStage,
  isAtsPurchased,
  downloadHiringGuide,
  isIncompleteApplicantView,
  getAiCandidateSummary,
}: IApplicantRowModel) => {
  const dispatch = useAppDispatch();
  const [openResumeViewDialog, setResumeViewDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [loadingRejection, setLoadingRejection] = useState(false);
  const {bookmarks} = useAppSelector((state) => state.employer.applicants.applicantActions);
  const { getApplicantResumeStatus, resumeS3Url } = useAppSelector(
    (state) => state.employer.applicants.applicantProfile
  );
  const openResumeDialog = (event: any) => {
    event.stopPropagation();
    dispatch(
      getApplicantResume({ applicantId: applicant.candidateEmployerJobId ?? 0 })
    );
    setResumeViewDialog(true);
  };
  const handleCloseDialog = () => {
    setResumeViewDialog(false);
  };

  useEffect(() => {
    if (applicant.hasReceivedRejectionEmail || applicant.addedRejectionReasons) {
      setLoadingRejection(true);
      dispatch(
        getRejectReasonInCandidateEmployerJobsTable({
          applicationId: applicant.candidateEmployerJobId,
        })
      )
        .then((res) => {
          setRejectionReason(`
            Rejection Reason: ${
              res.payload?.data?.rejectReason || "No reason provided"
            }<br />
            Notes: ${res.payload?.data?.rejectionNotes || "No notes provided"}
          `);
        })
        .finally(() => setLoadingRejection(false));
    }
  }, [
    applicant.hasReceivedRejectionEmail,
    applicant.addedRejectionReasons,
    applicant.candidateEmployerJobId,
    dispatch,
  ]);
  const toggleBookmark = async (event: React.MouseEvent, applicantId: number) => {
    event.stopPropagation();
    const isBookmarked = !(bookmarks[applicantId] ?? false);
    dispatch(updateBookmark({ applicantId, isBookmarked }));
    dispatch(updateBookmarkCandidateStatus({ candidateEmployerId: applicantId }));
  };

  useEffect(() => {
    dispatch(updateBookmark({
      applicantId: applicant.candidateEmployerJobId,
      isBookmarked: applicant.isBookmarked || false,
    }));
  }, [applicant, dispatch]);
  
  const initials = getInitialsFromName(applicant?.fullName || applicant?.email, true, true);
  const avatarBgColor = stringToColor(initials);

  //   const { aiCandidateSummary} = useAppSelector((state) => state.employer.applicants.applicantsList);

  //   useEffect(() => {
  //     if (applicant.isExpanded && applicant.resumeId) {
  //         dispatch(
  //             fetchAndGenerateCandidateAiSummary({
  //                 resume_id: applicant.resumeId,
  //                 candidate_id: applicant.candidateId,
  //                 candidate_employerjobs_id: applicant.candidateEmployerJobId
  //             })
  //         );
  //     }
  //     return () => {
  //       dispatch(resetAiCandidateSummary());
  //   };
  // }, [applicant, dispatch]); 

  return (
    <>
      <ShTableRow onClick={(e) => {
          if (!isIncompleteApplicantView) {
            getApplicantSummary(applicant.candidateEmployerJobId);
          }
          getAiCandidateSummary(applicant);
        }}
        cursor={isIncompleteApplicantView ? "default" : "pointer"}
        id={`a_t_v_r_${applicant.candidateEmployerJobId}`}
      >
        <RowSelectCell onClick={(e) => e.stopPropagation()}>
          <ShCheckbox
            id={"chk_" + indx}
            checked={selectedApplicants?.includes(
              applicant.candidateEmployerJobId
            )}
            size="small"
            onChange={() => selectApplicant(applicant.candidateEmployerJobId)}
          />
        </RowSelectCell>
        <TableCell>
          <Stack direction="row" spacing={{ lg: 3, md: 2, sm: 1, xs: 0.5 }} alignItems="center">
            <ShAvatar width={40} height={40} backgroundColor={avatarBgColor} className="avatar" alt={applicant.fullName} src={`user-avatar-to-be-loaded-here`}>
              <Typography variant="body1">{initials}</Typography>
            </ShAvatar>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              <Stack>
                <Typography variant="body2">{applicant?.fullName || applicant?.firstName}</Typography>
                <Typography variant="caption">{applicant?.email}</Typography>
              </Stack>
              {!isIncompleteApplicantView &&
              <IconButton onClick={(event) => toggleBookmark(event, applicant.candidateEmployerJobId)}>
                  {(bookmarks[applicant.candidateEmployerJobId]) ? (
                  <BookmarkIcon color="warning"/>
                  ) : (
                  <BookmarkBorderIcon color='disabled' />
                  )}
              </IconButton>}
            </Stack>
            {!isIncompleteApplicantView && (
              <Tooltip
                title="View Applicant's details"
                onClick={(e) => e.stopPropagation()}
              >
                <Box
                  component="span"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToApplicantProfile(applicant);
                  }}
                >
                  <ContactPageIcon
                    fontSize="small"
                    color="primary"
                    className="on-hover"
                  />
                </Box>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          {applicant.applicationCompletedAt && (
            <Typography variant="body2">
              {moment(applicant.applicationCompletedAt).format("MMM DD, YYYY")}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <ApplicantRating
            className="dsk-only-inline-flex"
            applicant={applicant}
            usedIn="list"
          />
        </TableCell>
        {/* Show this cell only for non ats jobs */}
        {!isAtsPurchased && (
          <TableCell>
            <Typography component={"div"} variant="body2">
              {renderMatchChip(applicant.matchType)}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <ResumeScoreGauge score={applicant.resumeScore || 0} />
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {!isIncompleteApplicantView && (
              <StagesToggler
                applicant={applicant}
                usedIn="list"
                changeStage={changeStage}
              />
            )}
            <Stack direction="row" spacing={1} alignItems="center">
              {applicant.addedRejectionReasons && (
                <>
                  <ShTooltip
                    title={
                      loadingRejection ? (
                        "Fetching..."
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{ __html: rejectionReason }}
                        />
                      )
                    }
                    placement="top">
                    <InfoIcon
                      color="primary"
                      style={{
                        fontSize: "19px",
                        cursor: !loadingRejection ? "pointer" : "default",
                      }}
                    />
                  </ShTooltip>
                </>
              )}
              {applicant.hasReceivedRejectionEmail && (
                <>
                  <ShTooltip title="A rejection email was sent to the candidate" placement="left-end">
                    <IconButton onClick={(e) => e.stopPropagation()} style={{ cursor: "default", padding: 4 }}>
                      <MarkEmailReadIcon
                        color="primary"
                        style={{
                          fontSize: "19px",
                          marginTop: "-4px",
                          marginLeft: "4px",
                        }}
                      />
                    </IconButton>
                  </ShTooltip>
                </>
              )}
            </Stack>
          </Stack>
        </TableCell>

        <TableCell>
          <ApplicantComment applicantId={applicant.candidateEmployerJobId} />
        </TableCell>
        <TableCell>
          <IconButton onClick={openResumeDialog} size="small" sx={{ width: 20, height: 20, padding: 0 }}>
              <CircleBox color="#e8f5e9">
                <ShTooltip title="View resume" placement="top">
                  <DescriptionIcon
                    style={{ color: ShGreen, cursor: "pointer" }}
                  />
                </ShTooltip>
              </CircleBox>
          </IconButton>
        </TableCell>
      </ShTableRow>
      <CollapsibleTableRow>
        <TableCell colSpan={8}>
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "pending" && <LinearProgress />}
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "success" && (
            <Collapse in={applicant.isExpanded} timeout="auto" unmountOnExit>
              <ApplicantSummary
                aiCandidateSummary={aiCandidatesSummary?.[applicant.candidateEmployerJobId]}
                changeStage={changeStage}
                applicant={applicant}
                key={applicant.candidateEmployerJobId}
                goToApplicantProfile={goToApplicantProfile}
                isAtsPurchased={isAtsPurchased}
                summary={applicantsSummary[applicant.candidateEmployerJobId]}
                applicantId={applicant.candidateEmployerJobId}
                downloadHiringGuide={downloadHiringGuide}
              />
              <ThemeColorDivider />
            </Collapse>
          )}
        </TableCell>
      </CollapsibleTableRow>
      <ShDialog
        open={openResumeViewDialog}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <ShPaper variant="outlined" headerSection>
          <DialogTitle>Applicant Resume</DialogTitle>
          <Typography> </Typography>
        </ShPaper>
        <DialogContent>
          {getApplicantResumeStatus === "pending" && <LinearProgress />}
          <Box flex={1} display="flex" flexDirection="column" height={"80vh"} justifyContent="center" alignItems="center">
            {resumeS3Url && getApplicantResumeStatus ==='success' ? (
              <iframe title="applicant resume" src={resumeS3Url} width="100%"height="100%"></iframe>
            ) : (
              <Box>
                <ShPaper variant="outlined">
                  <Typography variant="body1" color="textSecondary" justifyContent='center'>
                    Applicant did not submit a resume. 
                  </Typography>
                </ShPaper>
              </Box>
            )}
          </Box>
        </DialogContent>

      </ShDialog>
    </>
  );
};

export const ApplicantsTableView = ({
  applicantsList,
  applicantsSummary,
  aiCandidatesSummary,
  isAtsPurchased,
  selectedApplicants,
  selectApplicant,
  sortList,
  sortParams,
  getApplicantSummary,
  getAiCandidateSummary,
  goToApplicantProfile,
  changeStage,
  downloadHiringGuide,
  isIncompleteApplicantView,
  selectAll,
  isAllApplicantsSelected,
}: IApplicantsTable) => {
  const location = useLocation();
  const { getApplicantsListStatus } = useAppSelector(
    (state) => state.employer.applicants.applicantsList
  );

  useEffect(() => {
    if (getApplicantsListStatus === "success") {
      const searchParams = new URLSearchParams(location.search);
      const applicantIdString = searchParams.get("applicantId");
      const applicantId = Number(applicantIdString) || undefined;
      const currRow = document.getElementById(`a_t_v_r_${applicantId}`);
      currRow?.setAttribute("tabindex", "0");
      currRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      currRow?.focus();
    }
  }, [getApplicantsListStatus, location.search]);

  return (
    <>
      <Fade in={true} timeout={800}>
        <ShTableContainer>
          <ShTable size="small">
            <TableHead>
              <ShTableRow cursor="pointer">
                {/* Select all checkbox */}
                <TableCell className="select-header">
                  <ShCheckbox
                    checked={
                      isAllApplicantsSelected
                        ? isAllApplicantsSelected()
                        : false
                    }
                    indeterminate={
                      selectedApplicants?.length > 0 &&
                      selectedApplicants?.length < applicantsList?.length
                    }
                    size="small"
                    onChange={() => selectAll && selectAll()}
                  />
                </TableCell>
                {/* Sortable constant table headers */}
                {ApplicantsHeaders.map((headCell) => (
                  <TableCell
                    key={headCell.columnName}
                    sortDirection={
                      sortParams.orderBy === headCell.columnName
                        ? sortParams.order
                        : false
                    }
                  >
                    {headCell.columnName === "rating" ? ( // Apply tooltip and sorting label for rating col
                      <Typography
                        variant="body2"
                        display="flex"
                        alignItems="center"
                      >
                        <TableSortLabel
                          active={sortParams.orderBy === headCell.columnName}
                          direction={
                            sortParams.orderBy === headCell.columnName
                              ? sortParams.order
                              : "asc"
                          }
                          onClick={() => {
                            sortList(
                              headCell.columnName,
                              sortParams.order === "asc" ? "desc" : "asc"
                            );
                          }}
                        >
                          {headCell.label}
                          &nbsp;
                          <Tooltip
                            title="Recruiters can rate their candidates based on interviews, qualifications, etc."
                            placement="top"
                          >
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="disabled"
                            />
                          </Tooltip>
                        </TableSortLabel>
                      </Typography>
                    ) : (
                      <TableSortLabel
                        active={sortParams.orderBy === headCell.columnName}
                        direction={
                          sortParams.orderBy === headCell.columnName
                            ? sortParams.order
                            : "asc"
                        }
                        onClick={() => {
                          sortList(
                            headCell.columnName,
                            sortParams.order === "asc" ? "desc" : "asc"
                          );
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                {/* Show this column only for non ats jobs */}
                {!isAtsPurchased && (
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body2">Job Fit &nbsp;</Typography>
                      <Tooltip
                        title="This is your candidates match with the job's skills and their personality fit based on assessment."
                        placement="right"
                      >
                        <InfoOutlinedIcon fontSize="small" color="disabled" />
                      </Tooltip>
                    </Stack>
                  </TableCell>
                )}
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    display="flex"
                  >
                    <Typography variant="body2">Resume Score</Typography>
                    <ShTooltip
                      title="A resume score is a numerical or qualitative evaluation based on job requirements. This score aims to assess how well the resume matches the job description. If scores are lower than expected, consider updating your job description with more specific keywords."
                      placement="right"
                      showArrowIcon
                      arrowLink="/employer/help/optimize-job-posting"
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        color="disabled"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </ShTooltip>
                  </Stack>
                </TableCell>

                {!isIncompleteApplicantView && <TableCell>Stage</TableCell>}

                <TableCell></TableCell>
                <TableCell></TableCell>
                {isIncompleteApplicantView && <TableCell></TableCell>}
              </ShTableRow>
            </TableHead>
            <TableBody>
              {applicantsList.map((aplcnt, index) => (
                // Row component holds both applicant and collapsible summary rows.
                <Row
                  key={index}
                  indx={index}
                  applicant={aplcnt}
                  goToApplicantProfile={goToApplicantProfile}
                  applicantsSummary={applicantsSummary}
                  aiCandidatesSummary={aiCandidatesSummary}
                  selectedApplicants={selectedApplicants}
                  getApplicantSummary={getApplicantSummary}
                  isAtsPurchased={isAtsPurchased}
                  selectApplicant={(applcntId: number) =>
                    selectApplicant(applcntId)
                  }
                  downloadHiringGuide={downloadHiringGuide}
                  changeStage={changeStage}
                  isIncompleteApplicantView={isIncompleteApplicantView}
                  getAiCandidateSummary={getAiCandidateSummary}
                />
              ))}
            </TableBody>
          </ShTable>
        </ShTableContainer>
      </Fade>
    </>
  );
};
