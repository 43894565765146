import { createTheme } from "@mui/material";
import { ICareerPage } from "../EmployerSubDomainLayout/EmployerSubDomainModel";
import { PrimaryThemeColor, ShGreen } from "shared/SharedStyles/styleConstants";

// Function to create a dynamic theme based on the careerPage data
export const createCustomCareersPageEmployerTheme = (careerPage: ICareerPage) => {
    const {
        primary_color = PrimaryThemeColor, 
        secondary_color = ShGreen, 
        tertiary_color = '#4CAF50', 
        typography = 'Times New Roman',
        heading_font_weight = '400'
    } = careerPage || {};

    return createTheme({
        palette: {
            primary: {
                main: primary_color,
            },
            secondary: {
                main: secondary_color,
            },
            background: {
                default: tertiary_color,
            },
        },
        typography: {
            fontFamily: typography,
            h6: {
                fontWeight: heading_font_weight,
            }
        },
    });
};
