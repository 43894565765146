import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useAppSelector } from 'helpers/hooks';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { RoleAccessControlMap } from "../../AuthV2/AuthConstants";
import { TemplateJobFeatureTiles } from '../CreateJob/CreateJobConstants';
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";

export const TemplateJobSelect = () => {

    const { accountAccess } = useAppSelector((state) => state.auth.auth);
    const navigate = useNavigate(); 
    
    return (<>
    <ShDialog open={true} maxWidth='xl'>
            <ShPaper variant="outlined" headerSection>
                <Typography variant="h6" textAlign='center'>What type of Job do you want to create?</Typography>
            </ShPaper>
        <DialogContent>
            <Stack rowGap={{ xs: 2, sm: 2, md: 3, lg: 4 }} alignItems='center'>
                <Stack direction='row' flexWrap='wrap' columnGap={{ xs: 2, sm: 2, md: 3, lg: 4 }}
                    rowGap={{ xs: 2, sm: 2, md: 3, lg: 4 }} justifyContent='center'>
                    {TemplateJobFeatureTiles?.map((tile) => (
                        RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                            ?.[tile.featureName]?.visibility !== 'hidden' &&
                        <ShPaper key={tile?.featureRedirectUrl} noPadding transElevateOnHover variant="outlined">
                            <ShMuiLink noUnderline component={RouterLink} to={tile.featureRedirectUrl}
                                minWidth={275} maxWidth={300} display='flex' alignItems='center'
                                columnGap={1} noBlueHighlight padding={2} width='100%'>
                                {tile.tileIcon}
                                <Stack>
                                    <Typography variant="body2" fontWeight="bold">
                                        {tile.tileLabel}
                                    </Typography>
                                    {tile.caption &&
                                        <Typography variant="caption" color='gray'>
                                            {tile.caption}
                                        </Typography>}
                                </Stack>
                            </ShMuiLink>
                        </ShPaper>
                    ))}
                </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <ShButton onClick={()=> navigate(-1)} color="primary">
                Cancel
            </ShButton>
        </DialogActions>
        </ShDialog>
    </>);
}