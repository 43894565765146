import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Autocomplete, Box, Fade, Grid, Stack, Typography } from "@mui/material";
import { ExperienceLevels, FullTimeId, JobLocationTypes, JobTitleInitialValues, JobTitleTooltips } from "Modules/Core/CreateJob/CreateJob/CreateJobConstants";
import { IJobTitle, IUseATemplateJobPayload, TJobCreationFLow } from "Modules/Core/CreateJob/CreateJob/CreateJobModel";
import { useNotification } from "Modules/Core/Notification";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ApiState } from "shared/SharedModels";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { AutocompleteLoaders, AutocompletePopoverProps, ShGreenBtn, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { DummyBlock } from 'shared/SharedStyles/ShLayouts';
import { DefaultAPIErrorMsg, LovNoMatchesText } from "shared/constants";
import {
    resetCreateOrUpdateJob, setCurrentJobCreationFlow, setIsBundlesEnabled, setIsDetailsEnabled,
    setIsJobIdWithTemplateCreated, updateJob, updateJobRequirementsInSlice, updateTemplateJob
} from "store/slices/employer/create-job/create-job-shared-slices";
import {
    createJobWithTemplate, createNewJob, createNewTemplateJob, getAreaInformationByZipCode, resetAreaInfoByZipCode,
    resetCreateNewJob, resetGetJobHiringManagers, resetUseATemplateJob
} from "store/slices/employer/create-job/job-title-slice";
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';
// import { getCompanyInfo, resetUpdateCompany, updateCompanyInfo } from 'store/slices/employer/settingsTs/company-info-slice';
import { resetGetJobRoles, resetGetJobsStatesByCountry } from 'store/slices/meta-data/jobs-meta-slice';
// import { ICompanyInfo, IUpdateCompanyInfoPayload } from '../SettingsTs/SettingsModel';
import { JobLocationType } from 'store/slices/employer/jobs/jobs-slice-model';
import jobStatesByCountryFallBack from './FallbackStatesUsAndCanada';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ShSwitch } from 'shared/SharedStyles/ShNavigation';

export const JobTitle = () => {
    const [isValidZipCode, setIsValidZipCode] = useState<boolean>(true);
    const [isCityAndStateDisabled, setIsCityAndStateDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { jobId } = useParams();
    const notification = useNotification();
    const navigate = useNavigate();
    const hashInCreateJobUrl = useLocation().hash.substring(1) as TJobCreationFLow;
    const { accountCountry } = useAppSelector((state) => state.auth.auth);
    const { getJobDetailsStatus } = useAppSelector((state) => state.employer.employerJobs.jobInfo);
    const { jobTitleInfo, isDetailsEnabled, createOrUpdateJobResponse, createOrUpdateJobStatus, currentJobCreationFlow, isBundlesEnabled,
        requirementsMetaData, isJobIdWithTemplateCreated } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { getJobHiringManagersResponse, getJobHiringManagersStatus, jobHiringManagers, newJobCreationStatus,
        newJobCreationResponse, newJobId, areaInformation, rePostJobId, useATemplateJobStatus,
        getAreaInformationStatus, jobIdCreatedWithTemplate } = useAppSelector((state) => state.employer.jobTitle);
    const { getJobTitlesResponse, getJobTitlesStatus, getJobStatesByCountryResponse, getJobStatesByCountryStatus, jobStatesByCountry,
        jobTitles } = useAppSelector(state => state.metadata.jobsMeta);
    // const { getCompanyInfoApiStatus, companyInfo, companyLogo, updateCompanyInfoApiStatus } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const postalCodeRef = useRef<string | null>(null);
    const [jobInfo, setJobInfo] = useState<IJobTitle>({ ...JobTitleInitialValues });

    // about us
    // const [_companyInfo, _setCompanyInfo] = useState<ICompanyInfo>({
    //     id: 0, company_name: '', website: '', phone_number: '', phone_area: '', masking_name: '',
    //     formatted_ph_no: '', company_logo: '', company_logo_url: '', avatar: '',
    //     calendly_token: '', country: { name: '' }, state: { name_en: '' },
    //     address: { city: '', country_id: 0, createdAt: '', id: 0, postalcode: '', province_id: 0, updatedAt: '' }
    // });

    // get company info
    // useEffect(() => {
    //     if (!companyInfo.id || updateCompanyInfoApiStatus === 'success') {
    //         dispatch(getCompanyInfo());
    //         dispatch(resetUpdateCompany());
    //     }
    // }, [companyInfo.id, dispatch, updateCompanyInfoApiStatus]);

    // useEffect(() => {
    //     if (companyInfo !== undefined) {
    //         _setCompanyInfo({
    //             ...companyInfo,
    //             about_us: companyInfo.jobs?.companydescription,
    //             formatted_ph_no: (companyInfo?.phone_area + companyInfo?.phone_number),
    //             company_logo_url: companyLogo,
    //             website: companyInfo.website //
    //         });
    //     }
    // }, [companyInfo, companyLogo, getCompanyInfoApiStatus]);

    useEffect(() => {
        if (hashInCreateJobUrl === 'templateJob') {
            dispatch(setCurrentJobCreationFlow('templateJob'));
        } else if (hashInCreateJobUrl === 'useTemplateJob') {
            dispatch(setCurrentJobCreationFlow('useTemplateJob'));
        }
    }, [dispatch, hashInCreateJobUrl]);

    // Set Job details if already exists. if not initialize it.
    useEffect(() => {
        if (jobTitleInfo) {
            setJobInfo({ ...jobTitleInfo });
        } else {
            setJobInfo({ ...JobTitleInitialValues });
        }
    }, [jobTitleInfo]);

    const handleHoursPerWeek = (e: { target: { value: string }; stopPropagation: () => void; }) => {
        if (e.target.value?.length <= 3) {
            setJobInfo({
                ...jobInfo, hours_per_week: e.target.value ? parseInt(e.target.value) : undefined
            });
        }
    }

    const cOrUJob = () => {
        const isRequiredFieldsNotFilled = getIsRequiredFieldsNotFilled();
        if (isRequiredFieldsNotFilled) {
            return setIsNextDisabled(true);
        }

        if (isNaN(parseInt(jobId ?? ''))) {
            if (currentJobCreationFlow === 'templateJob') {
                dispatch(createNewTemplateJob());
            } else {
                dispatch(createNewJob());
            }
        } else {
            if (currentJobCreationFlow === 'templateJob') {
                dispatch(updateTemplateJob({ jobId: parseInt(rePostJobId ? rePostJobId : jobId ?? ''), payload: jobInfo }));
            }
            else {
                dispatch(updateJob({ jobId: parseInt(rePostJobId ? rePostJobId : jobId ?? ''), payload: jobInfo }));
            }
        }
        // saveCompanyInfo();
    };

    const createJobWithTemplateJob = () => {
        const isRequiredFieldsNotFilled = getIsRequiredFieldsNotFilled();
        if (isRequiredFieldsNotFilled) {
            return setIsNextDisabled(true);
        }

        if (isJobIdWithTemplateCreated) {
            if (jobId) {
                dispatch(updateJob({ jobId: parseInt(jobId), payload: jobInfo }));
            }
        } else {
            dispatch(resetCreateNewJob());
            const { name, hiring_manager_id, province_id, postalcode, city, neighbourhood } = jobInfo;
            const useATemplateJobPayload: IUseATemplateJobPayload = {
                name,
                country_id: accountCountry?.id,
                hiring_manager_id,
                province_id,
                postalcode,
                city,
                neighbourhood
            };
            dispatch(createJobWithTemplate({ jobId: parseInt(rePostJobId ? rePostJobId : jobId ?? ''), payload: useATemplateJobPayload }));
        }
        // saveCompanyInfo();
    }

    useEffect(() => {
        if (jobId !== undefined && jobIdCreatedWithTemplate !== undefined &&
            jobIdCreatedWithTemplate === parseInt(jobId) && currentJobCreationFlow === 'useTemplateJob') {
            dispatch(getJobDetails({ jobId: parseInt(jobId) }));
        }
    }, [currentJobCreationFlow, dispatch, jobId, jobIdCreatedWithTemplate]);

    // Post job payload after new job id is created.
    useEffect(() => {
        if (newJobCreationStatus === 'success' && newJobId) {
            if (currentJobCreationFlow === 'templateJob') {
                dispatch(updateTemplateJob({ jobId: newJobId, payload: jobInfo }));
            } else {
                dispatch(updateJob({ jobId: newJobId, payload: jobInfo }));
            }
            dispatch(resetCreateNewJob());
        }
    }, [dispatch, currentJobCreationFlow, jobId, jobInfo, newJobCreationStatus, newJobId, rePostJobId]);

    // debouncing user input, calls API once user stops typing in zip code field
    const handleGetAreaInfo = useMemo(() =>
        debounce((zipCode) => dispatch(getAreaInformationByZipCode({ zipCode })), 1000), [dispatch]);

    // get area info
    useEffect(() => {
        if (accountCountry?.abbreviation === 'US') {
            if (jobInfo?.postalcode?.length >= 5 && jobInfo?.postalcode !== postalCodeRef.current &&
                getAreaInformationStatus !== 'pending') {
                handleGetAreaInfo(jobInfo?.postalcode);
                postalCodeRef.current = jobInfo?.postalcode;
            }
            if (isCityAndStateDisabled && (getAreaInformationStatus === 'failed' || getAreaInformationStatus === 'success')
                && jobInfo?.postalcode?.length >= 5)
                setIsCityAndStateDisabled(false);
        }
    }, [accountCountry?.abbreviation, getAreaInformationStatus, handleGetAreaInfo, isCityAndStateDisabled, jobInfo?.postalcode]);

    // updating job information for city and province_id based on area information
    useEffect(() => {
        if (areaInformation.city && areaInformation.state && getAreaInformationStatus === 'success') {
            const provinceId = jobStatesByCountry.find((state) => state.abbreviation === areaInformation.state)?.id;
            if (provinceId) setJobInfo((job) => ({ ...job, city: areaInformation.city, province_id: provinceId }));
            setIsValidZipCode(true);
            dispatch(resetAreaInfoByZipCode());
        } else if (getAreaInformationStatus === 'failed') {
            dispatch(resetAreaInfoByZipCode());
            setIsValidZipCode(false);
        }

    }, [areaInformation, getAreaInformationStatus, jobStatesByCountry, dispatch]);

    // Navigate to next page once Job is created or updated successfully.
    useEffect(() => {
        if (createOrUpdateJobStatus === 'success') {
            dispatch(setIsDetailsEnabled(true));
            notification.displayNotification({
                open: true,
                type: 'success',
                message: createOrUpdateJobResponse
            });
        }
    }, [createOrUpdateJobResponse, createOrUpdateJobStatus, dispatch, notification]);

    useEffect(() => {
        if (createOrUpdateJobStatus === 'success' && isDetailsEnabled) {
            if (currentJobCreationFlow === 'useTemplateJob') {
                navigate(`/employer/job/${jobId}/bundles`);
            } else {
                if (jobId) {
                    if (isNaN(parseInt(jobId))) {
                        navigate(`/employer/job/${newJobId ? newJobId :
                            (rePostJobId ? rePostJobId : jobId ?? '')}/details`);
                    } else {
                        dispatch(getJobDetails({ jobId: parseInt(jobId) }));
                    }
                }

            }
        }
    }, [createOrUpdateJobStatus, currentJobCreationFlow, dispatch, isDetailsEnabled, jobId, navigate, newJobId, rePostJobId]);

    useEffect(() => {
        if (createOrUpdateJobStatus === 'success' && isDetailsEnabled && getJobDetailsStatus === 'success') {
            dispatch(updateJobRequirementsInSlice([]));
            navigate(`/employer/job/${newJobId ? newJobId :
                (rePostJobId ? rePostJobId : jobId ?? '')}/details`);
        }
    }, [createOrUpdateJobStatus, dispatch, getJobDetailsStatus, isDetailsEnabled, jobId, navigate, newJobId, rePostJobId]);

    const showFailureAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    // Disable next button.
    useEffect(() => {
        if (isNextDisabled) {
            const { job_title_id, name, hiring_manager_id, remote_job_type,
                city, postalcode, province_id, experience, positiontype_id } = jobInfo;
            // If remote_job_type is 'onsite' then state,city and zip code are required.
            setIsNextDisabled(!name || (remote_job_type === 'Onsite' && currentJobCreationFlow !== 'templateJob' &&
                (!city || !postalcode)) || !experience || job_title_id === 0 || job_title_id === null ||
                // If remote_job_type is not 'remote' then state is not required.
                (remote_job_type !== 'Fully Remote' && currentJobCreationFlow !== 'templateJob' &&
                    (province_id === 0 || province_id === null)) ||
                (currentJobCreationFlow !== 'templateJob' && (hiring_manager_id === 0 || hiring_manager_id === null)) ||
                positiontype_id === 0 || positiontype_id === null || !remote_job_type);
        }
    }, [currentJobCreationFlow, jobInfo, isNextDisabled]);

    const getIsRequiredFieldsNotFilled = () => {
        const {
            job_title_id,
            name,
            hiring_manager_id,
            remote_job_type,
            city,
            postalcode,
            province_id,
            experience,
            positiontype_id,
        } = jobInfo;
        return (
            !name ||
            (remote_job_type === 'Onsite' &&
                currentJobCreationFlow !== 'templateJob' &&
                (!city || !postalcode)) ||
            !experience ||
            job_title_id === 0 ||
            job_title_id === null ||
            (remote_job_type !== 'Fully Remote' &&
                currentJobCreationFlow !== 'templateJob' &&
                (province_id === 0 || province_id === null)) ||
            (currentJobCreationFlow !== 'templateJob' &&
                (hiring_manager_id === 0 || hiring_manager_id === null)) ||
            positiontype_id === 0 ||
            positiontype_id === null ||
            !remote_job_type
        );
    };

    // Reset all states on unmount.
    useEffect(() => {
        return () => {
            dispatch(resetGetJobRoles());
            dispatch(resetGetJobsStatesByCountry());
            dispatch(resetGetJobHiringManagers());
            dispatch(resetCreateOrUpdateJob());
        }
    }, [dispatch]);

    const onZipCodeChange = (value: string) => {
        let postalcode = value;
        if (accountCountry?.abbreviation === 'US') {
            postalcode = value.replace(/[^0-9]/g, '');
        }
        setJobInfo({ ...jobInfo, postalcode });
    }

    useEffect(() => {
        if (useATemplateJobStatus === 'success') {
            dispatch(setIsBundlesEnabled(true));
        }
    }, [dispatch, useATemplateJobStatus]);

    useEffect(() => {
        if (isBundlesEnabled && useATemplateJobStatus === 'success' && jobIdCreatedWithTemplate) {
            navigate(`/employer/job/${jobIdCreatedWithTemplate}/bundles`);
            dispatch(setIsJobIdWithTemplateCreated(true));
            dispatch(resetUseATemplateJob());
        }
    }, [dispatch, isBundlesEnabled, jobIdCreatedWithTemplate, navigate, useATemplateJobStatus]);

    const setJobRoleWithTitle = (jobTitleId: number | undefined) => {
        setJobInfo({
            ...jobInfo,
            job_title_id: jobTitleId || 0,
            name: jobTitles?.find((jr) => jr.id === jobTitleId)?.title ?? '',
        });
    }

    const getJobLocationTooltipKey = (remoteJobType: JobLocationType | null) => {
        switch (remoteJobType) {
            case "Fully Remote":
                return "job_location_remote";
            case "Hybrid":
                return "job_location_hybrid";
            case "Onsite":
                return "job_location_onsite";
            default:
                return "job_location";
        }
    };

    return (<>
    <Fade in={true} timeout={800}>

        <Stack alignContent='center'>
            <ShPaper variant='outlined' borderRadius={0} headerSection >
                <Typography variant='subtitle1' gutterBottom> Lets start with job details</Typography>
                <Typography variant='caption' color='textSecondary'>Fill in the key details for the new job position, including the role, hiring manager, and more.</Typography>
            </ShPaper>
            <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                <Stack className='elements-stack' rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} minHeight='350px' paddingTop={1}>
                    {/* API failed status */}
                    {showFailureAlerts(createOrUpdateJobStatus, createOrUpdateJobResponse)}
                    {showFailureAlerts(getJobTitlesStatus, getJobTitlesResponse)}
                    {showFailureAlerts(getJobStatesByCountryStatus, getJobStatesByCountryResponse)}
                    {showFailureAlerts(getJobHiringManagersStatus, getJobHiringManagersResponse)}
                    {showFailureAlerts(newJobCreationStatus, newJobCreationResponse)}
                    <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
                        {currentJobCreationFlow !== 'useTemplateJob' &&
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                    {/* Using MUI Autocomplete component to display dropdowns with inbuilt search functionality*/}
                                    <Autocomplete noOptionsText={LovNoMatchesText} ListboxProps={AutocompletePopoverProps}
                                        size='small' disablePortal id='role' options={jobTitles}
                                        getOptionLabel={(option) => option.title || ''} fullWidth
                                        groupBy={(option) => option.industry}
                                        value={jobTitles?.find(jr => jr.id === jobInfo?.job_title_id) ?? null}
                                        renderInput={(params) => <ShTextFieldV2 error={isNextDisabled && !jobInfo?.job_title_id}
                                            required {...params} label='Role'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (<>{getJobTitlesStatus === 'pending' ?
                                                    <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                    {params.InputProps.endAdornment}</>)
                                            }} />}
                                        onChange={(e, newValue) => { setJobRoleWithTitle(newValue?.id) }} />
                                    <ShTooltip title={JobTitleTooltips['role']} placement="right">
                                        <HelpOutlineIcon fontSize="small" color='disabled' />
                                    </ShTooltip>
                                </Stack>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                <ShTextFieldV2 label="Job Title" variant="outlined" fullWidth size='small' value={jobInfo?.name ?? ''}
                                    onChange={(e) => setJobInfo({ ...jobInfo, name: e.target.value })} required error={isNextDisabled && !jobInfo?.name} />
                                <ShTooltip title={JobTitleTooltips['job_title']} placement="right">
                                    <HelpOutlineIcon fontSize="small" color='disabled' />
                                </ShTooltip>
                            </Stack>
                        </Grid>
                        {/* hiring manager */}
                        {
                            currentJobCreationFlow !== 'templateJob' &&
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                    <Autocomplete noOptionsText={LovNoMatchesText} ListboxProps={AutocompletePopoverProps}
                                        size='small' disablePortal id='h_m' options={jobHiringManagers}
                                        value={jobHiringManagers?.find(jr => jr.id === jobInfo?.hiring_manager_id) ?? null}
                                        getOptionDisabled={(option) => jobInfo?.optional_hiring_manager_id === option.id}
                                        getOptionLabel={(option) => (option.first_name + ' ' + option.last_name) || ''} fullWidth
                                        renderInput={(params) => <ShTextFieldV2 required error={isNextDisabled && !jobInfo?.hiring_manager_id}
                                            {...params} label='Hiring Manager'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (<>{getJobHiringManagersStatus === 'pending' ?
                                                    <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                    {params.InputProps.endAdornment}</>)
                                            }} />}
                                        onChange={(e, newValue) => { setJobInfo({ ...jobInfo, hiring_manager_id: newValue?.id || 0 }) }} />
                                    <ShTooltip title={JobTitleTooltips['hiring_manager']} placement="right">
                                        <HelpOutlineIcon fontSize="small" color='disabled' />
                                    </ShTooltip>
                                </Stack>
                            </Grid>
                        }
                        {/* additional hiring manager */}
                        {currentJobCreationFlow !== 'templateJob' &&
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                    <Autocomplete noOptionsText={LovNoMatchesText} ListboxProps={AutocompletePopoverProps} size='small'
                                        disablePortal id='a_h_m' options={jobHiringManagers}
                                        value={jobHiringManagers?.find(jr => jr.id === jobInfo?.optional_hiring_manager_id) ?? null}
                                        getOptionDisabled={(option) => jobInfo?.hiring_manager_id === option.id}
                                        getOptionLabel={(option) => (option.first_name + ' ' + option.last_name) || ''} fullWidth
                                        renderInput={(params) => <ShTextFieldV2 {...params} label='Additional Hiring Manager'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (<>{getJobHiringManagersStatus === 'pending' ?
                                                    <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                    {params.InputProps.endAdornment}</>)
                                            }} />}
                                        onChange={(e, newValue) => { setJobInfo({ ...jobInfo, optional_hiring_manager_id: newValue?.id || null }) }} />
                                    <ShTooltip placement="right" title={JobTitleTooltips['additional_hiring_manager']}>
                                        <HelpOutlineIcon fontSize="small" color='disabled' /></ShTooltip>
                                </Stack>
                            </Grid>
                        }
                        {/* job location */}
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='j_t' options={JobLocationTypes}
                                    value={JobLocationTypes?.find(r => r.value === jobInfo?.remote_job_type) ?? null}
                                    getOptionLabel={(option) => option.label || ''} fullWidth
                                    renderInput={(params) => <ShTextFieldV2 required error={isNextDisabled && !jobInfo?.remote_job_type}
                                        {...params} label='Job Location Type'
                                        InputProps={{ ...params.InputProps }} />}
                                    onChange={(e, newValue) => { setJobInfo({ ...jobInfo, remote_job_type: newValue?.value || null }) }} />
                                <ShTooltip placement="right" title={JobTitleTooltips[getJobLocationTooltipKey(jobInfo?.remote_job_type)]}>
                                    <HelpOutlineIcon fontSize="small" color='disabled' /></ShTooltip>
                            </Stack>
                        </Grid>
                        {/* Editor fold for location fields */}
                        {currentJobCreationFlow !== 'templateJob' &&
                            <>
                                {/* ZipCode */}
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                        <ShTextFieldV2 label="Zip Code" id="zip_code" variant="outlined" fullWidth size='small'
                                            value={jobInfo?.postalcode ?? ''} onChange={(e) => onZipCodeChange(e.target.value)}
                                            required={jobInfo?.remote_job_type === 'Onsite'}
                                            error={(jobInfo?.remote_job_type === 'Onsite') && (isNextDisabled && !jobInfo?.postalcode)}
                                            helperText={accountCountry?.abbreviation === 'US' && !isValidZipCode ?
                                                <Typography color={(theme) => theme.palette.warning.main} variant="caption">
                                                    Zip code not found</Typography> : ""}
                                            inputProps={{ maxLength: accountCountry?.abbreviation === 'US' ? 5 : 6 }} />
                                        <DummyBlock>
                                            <HelpOutlineIcon fontSize="small" />
                                        </DummyBlock>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                        <ShTextFieldV2 label="City" variant="outlined" fullWidth size='small' value={jobInfo?.city}
                                            onChange={(e) => setJobInfo({ ...jobInfo, city: e.target.value })}
                                            required={jobInfo?.remote_job_type === 'Onsite'}
                                            error={(jobInfo?.remote_job_type === 'Onsite') && (isNextDisabled && !jobInfo?.city)}
                                        // disabled={accountCountry?.abbreviation === 'US' && isCityAndStateDisabled}    
                                        />
                                        <DummyBlock>
                                            <HelpOutlineIcon fontSize="small" />
                                        </DummyBlock>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                        <Autocomplete noOptionsText={LovNoMatchesText} ListboxProps={AutocompletePopoverProps}
                                            size='small' disablePortal id='state'
                                            // options={jobStatesByCountry}
                                            // value={jobStatesByCountry?.find(jS => jS.id === jobInfo?.province_id) ?? null}
                                            options={jobStatesByCountry.length > 0 ? jobStatesByCountry : jobStatesByCountryFallBack}
                                            value={
                                                (jobStatesByCountry?.find(jS => jS.id === jobInfo?.province_id) ||
                                                    jobStatesByCountryFallBack.find(jS => jS.id === jobInfo?.province_id)) || null
                                            }
                                            getOptionLabel={(option) => option.name_en || ''} fullWidth
                                            renderInput={(params) => <ShTextFieldV2 required={jobInfo?.remote_job_type !== 'Fully Remote'}
                                                error={(jobInfo?.remote_job_type !== 'Fully Remote') && (isNextDisabled && !jobInfo?.province_id)}
                                                {...params}
                                                label={accountCountry?.abbreviation === 'CA' ? 'Province' : 'State'} InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (<>{(getJobStatesByCountryStatus === 'pending' ||
                                                        getAreaInformationStatus === 'pending') ?
                                                        <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                        {params.InputProps.endAdornment}</>)
                                                }} />}
                                            onChange={(e, newValue) => setJobInfo({ ...jobInfo, province_id: newValue?.id || 0 })}
                                        // disabled={accountCountry?.abbreviation === 'US' && isCityAndStateDisabled}
                                        />
                                        <DummyBlock>
                                            <HelpOutlineIcon fontSize="small" />
                                        </DummyBlock>
                                    </Stack>
                                </Grid>
                            </>
                        }
                        {currentJobCreationFlow !== 'templateJob' &&
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                    <ShTextFieldV2 label="Neighborhood/Address" id="neighborhood" variant="outlined" fullWidth size='small'
                                        value={jobInfo?.neighbourhood ?? ''} onChange={(e) => setJobInfo({ ...jobInfo, neighbourhood: e.target.value })} />
                                    <ShTooltip placement="right" title={JobTitleTooltips['neighborhood']}>
                                        <HelpOutlineIcon fontSize="small" color='disabled' />
                                    </ShTooltip>
                                </Stack>
                            </Grid>
                        }
                        {/* Employment type */}
                        {currentJobCreationFlow !== 'useTemplateJob' &&
                            <>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                        <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='e_s'
                                            options={requirementsMetaData?.positionTypes ?? []}
                                            value={requirementsMetaData?.positionTypes?.find(pT => pT.id === jobInfo?.positiontype_id) ?? null}
                                            getOptionLabel={(option) => option.name || ''} fullWidth
                                            renderInput={(params) => <ShTextFieldV2 required error={isNextDisabled && !jobInfo?.positiontype_id}
                                                {...params}
                                                label='Employment type'
                                                InputProps={{ ...params.InputProps }} />}
                                            onChange={(e, newValue) => {
                                                setJobInfo({
                                                    ...jobInfo, positiontype_id: newValue?.id ||
                                                        0, hours_per_week: undefined
                                                })
                                            }} />
                                        <ShTooltip placement="right" title={JobTitleTooltips['employment_type']}>
                                            <HelpOutlineIcon fontSize="small" color='disabled' />
                                        </ShTooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {/* Experience Level */}
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                        <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='e_l' options={ExperienceLevels ?? []}
                                            value={ExperienceLevels?.find(el => el.value === jobInfo?.experience) ?? null}
                                            getOptionLabel={(option) => option.label || ''} fullWidth
                                            renderInput={(params) => <ShTextFieldV2 required error={isNextDisabled && !jobInfo?.experience}
                                                {...params} label='Experience Level'
                                                InputProps={{ ...params.InputProps }} />}
                                            onChange={(e, newValue) => { setJobInfo({ ...jobInfo, experience: newValue?.value || '' }) }} />
                                        <ShTooltip placement="right" title={JobTitleTooltips['experience']}>
                                            <HelpOutlineIcon fontSize="small" color='disabled' />
                                        </ShTooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    {jobInfo?.positiontype_id && jobInfo?.positiontype_id !== FullTimeId &&
                                        <Stack columnGap={1} flexDirection='row' alignItems='center'>
                                            <ShTextFieldV2 type="number" label="Hours per week" id="hours_per_week" variant="outlined"
                                                fullWidth size='small' value={jobInfo?.hours_per_week ?? ''}
                                                onChange={handleHoursPerWeek} />
                                            <DummyBlock>
                                                <HelpOutlineIcon fontSize="small" />
                                            </DummyBlock>
                                        </Stack>
                                    }
                                </Grid>
                                {jobId && 
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Stack direction='row' spacing={2} alignItems='center'>
                                            <ShSwitch
                                                checked={jobInfo?.is_published_to_careers_page || false} 
                                                onChange={(e) => setJobInfo({ ...jobInfo, is_published_to_careers_page: e.target.checked })}
                                                color="primary"
                                            />
                                            <Typography>Publish to Careers Page</Typography>
                                        </Stack>
                                    </Grid>
                        }
                            </>
                        }
                    </Grid>
                    <Stack direction="row" spacing={2}>
                        {currentJobCreationFlow === 'useTemplateJob' ? (
                            <ShGreenBtn
                                variant="contained"
                                disableElevation
                                onClick={createJobWithTemplateJob}
                            >
                                {useATemplateJobStatus === 'pending'
                                    ? 'Finalizing...'
                                    : 'Finalize'}
                            </ShGreenBtn>
                        ) : (
                            <ShGreenBtn
                                variant="contained"
                                disableElevation
                                onClick={cOrUJob}
                            >
                                {createOrUpdateJobStatus === 'pending' ? 'Saving...' : 'Next'}
                            </ShGreenBtn>
                        )}
                        {(isNextDisabled || useATemplateJobStatus === 'pending') && (
                            <Stack display="flex" justifyContent="center">
                                <Typography
                                    color={(theme) => theme.palette.error.main}
                                    variant="caption"
                                >
                                    Please enter/select required values.
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </ShPaper>
        </Stack>
    </Fade>
    </>)
};
