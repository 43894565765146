import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICandidateAiSummary } from 'Modules/Core/Applicants/ApplicantPipeline/ApplicantCandidateAiSummaryModels';
import {
    IApplicant,
    IApplicantRatingUpdate, IApplicantStageParams, IApplicantSummary, IApplicantSummaryParams,
    IApplicantsApiParams, IApplicantsListResponse, IApplicantsListState
} from 'Modules/Core/Applicants/ApplicantsModel';
import { IAutoRejectCriteria } from 'Modules/Core/SettingsTs/ToolsAndAutomation/Automation/AutoReject/CriteriaModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse, IFailureResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';

const initialApplicantsListState: IApplicantsListState = {
    getApplicantsListStatus: 'idle',
    getApplicantsListResponse: '',
    getBatchAutoRejectStatus: 'idle',
    getBatchAutoRejectResponse: '',
    applicantsList: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    },
    applicantsSummary: {},
    aiSuggestedApplicants: [],
    generateCandidateAiSummaryStatus: 'idle',
    generateCandidateAiSummaryResponse: '',
    aiCandidatesSummary: {}
};

export const fetchApplicantsList = createAsyncThunk<IApplicantsListResponse, IApplicantsApiParams, { rejectValue: IBaseResponse }>(
    'applicantsList',
    async ({ jobId, pgNo, pgSize, sortCol, sortDir, search, finalMatches, stages, keywords, ratings }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_JOBS}/${jobId}/applicants?pageNo=${pgNo}&pageSize=${pgSize}&sortColumn=${sortCol}&sortDirection=${sortDir}${search ? `&search=${search}` : ''}${finalMatches ? `&finalMatches=${finalMatches}` : ''}${stages ? `&stages=${stages}` : ''}${keywords ? `&keywords=${keywords}` : ''}${ratings ? `&ratings=${ratings}` : ''} `)
            .then((response: AxiosResponse<IApplicantsListResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const fetchApplicantSummary = createAsyncThunk<IBaseResponse<IApplicantSummary>, IApplicantSummaryParams, { rejectValue: IBaseResponse }>(
    'applicantSummary',
    async ({ jobId, candidateEmployerJobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANT_SUMMARY}?jobId=${jobId}&candidateEmployerJobId=${candidateEmployerJobId}`)
            .then((response: AxiosResponse<IBaseResponse<IApplicantSummary>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getFingerprintImage = createAsyncThunk<string, { applicantId: number }, { rejectValue: IFailureResponse }>(
    'getFingerprintImage',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/fingerprints/baseball`)
            .then((response: AxiosResponse<string>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const changeApplicantStageInList = createAsyncThunk<IBaseResponse, IApplicantStageParams, { rejectValue: IBaseResponse }>(
    'changeApplicantStageInList',
    async ({ applicantId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/stage`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

//update applicant rating
export const updateApplicantRatingInList = createAsyncThunk<IBaseResponse, IApplicantRatingUpdate, { rejectValue: IBaseResponse }>(
    'updateApplicantRatingInList',
    async ({ applicantId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/rating`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);


export const autoRejectApplicants = createAsyncThunk< IBaseResponse, { criteria: IAutoRejectCriteria; jobId: string },{ rejectValue: IBaseResponse }>(
    'autoRejectApplicants',
    ({ criteria, jobId }, { rejectWithValue }) => {
        return httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYER_JOBS}/applicants/${jobId}/criteria`, criteria) // Send the full criteria object
            .then((response: AxiosResponse<IBaseResponse>) => {
                return response.data;
            })
            .catch((error) => {
                return rejectWithValue(error.response.data);
            });
    }
);

export const fetchAndGenerateCandidateAiSummary = createAsyncThunk<IBaseResponse<ICandidateAiSummary>, { resume_id: number; candidate_id: number,  candidate_employerjobs_id: number}, 
    { rejectValue: IBaseResponse }>(
    'fetchAndGenerateCandidateAiSummary',
    async ({ resume_id, candidate_id, candidate_employerjobs_id }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.AI_CANDIDATE_SUMMARY}`, {resume_id,candidate_id, candidate_employerjobs_id})
            .then((response: AxiosResponse<IBaseResponse<ICandidateAiSummary>>) => response?.data)
            .catch((error) => {
                return rejectWithValue(error.response.data);
            });
    }
);

const applicantsListSlice = createSlice({
    name: 'applicantsList',
    initialState: initialApplicantsListState,
    reducers: {
        toggleApplicantSummary: (state, action: PayloadAction<number>) => {
            state.applicantsList[action.payload].isExpanded = !state.applicantsList[action.payload].isExpanded;
        },
        resetApplicantsState: (state) => {
            state = { ...initialApplicantsListState };
        },
        resetStageChange: (state, action: PayloadAction<number>) => {
            const indx = state.applicantsList.findIndex(applicant => applicant.candidateEmployerJobId === action.payload);
            if (indx >= 0) {
                state.applicantsList[indx].changeStateStatus = 'idle';
                state.applicantsList[indx].changeStateResponse = '';
            }
        },
        resetUpdateApplicantRatingInList: (state, action: PayloadAction<number>) => {
            const indx = state.applicantsList.findIndex(applicant => applicant.candidateEmployerJobId === action.payload);
            if (indx >= 0) {
                state.applicantsList[indx].updateRatingStatus = 'idle';
                state.applicantsList[indx].updateRatingResponse = '';
            }
        },
        setAiSuggestedApplicants: (state, action: PayloadAction<IApplicant[]>) => {
            state.aiSuggestedApplicants = action.payload;
        },
        resetAiSuggestedApplicants: (state) => {
            state.aiSuggestedApplicants = [];
        },
        resetAiCandidateSummary: (state) => {
            state.aiCandidateSummary = undefined;
            state.generateCandidateAiSummaryStatus = 'idle'; 
            state.generateCandidateAiSummaryResponse = ''; 
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplicantsListState;
        });
        // fetch applicants list
        builder.addCase(fetchApplicantsList.pending, (state) => {
            state.getApplicantsListStatus = 'pending'
        });
        builder.addCase(fetchApplicantsList.fulfilled, (state, action) => {
            state.getApplicantsListStatus = 'success';
            state.applicantsList = action?.payload.data;
            // Form full names by combining first and last names 
            if (state.applicantsList?.length > 0) {
                state.applicantsList.forEach(applicant => {
                    let fullName = '';
                    if (applicant.firstName) {
                        fullName += applicant.firstName;
                    }
                    if (applicant.lastName) {
                        fullName += applicant.lastName;
                    }
                    if (!applicant.fullName && !applicant.lastName) {
                        fullName += applicant.email;
                    }
                    applicant.fullName = fullName;
                });
            }
            state.paginationParams = action?.payload.stats;
            state.isAtsPurchased = action.payload?.stats?.atsPurchased;
        });
        builder.addCase(fetchApplicantsList.rejected, (state, action) => {
            state.getApplicantsListStatus = 'failed';
            state.getApplicantsListResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // fetch applicant summary
        builder.addCase(fetchApplicantSummary.pending, (state, action) => {
            if (!(action.meta.arg.candidateEmployerJobId in state.applicantsSummary)) {
                state.applicantsSummary[action.meta.arg.candidateEmployerJobId] = { getSummaryStatus: 'pending' };
            }
            state.applicantsSummary[action.meta.arg.candidateEmployerJobId].getSummaryStatus = 'pending';
            const applicantIndx = state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.candidateEmployerJobId)
            if (applicantIndx >= 0 && action.meta.arg.isExpandPanel) {
                state.applicantsList[applicantIndx].isExpanded = true;
            }
            // For applicant profile page.
            state.getApplicantSummaryStatus = 'pending';
        });
        builder.addCase(fetchApplicantSummary.fulfilled, (state, action) => {
            state.applicantsSummary[action.meta.arg.candidateEmployerJobId] = {
                ...action?.payload.data,
                getSummaryStatus: 'success',
                baseballCardUrl: ''
            };
            // For applicant profile page.
            state.getApplicantSummaryStatus = 'success';
            state.applicantSummary = action?.payload?.data;
        });
        builder.addCase(fetchApplicantSummary.rejected, (state, action) => {
            state.applicantsSummary[action.meta.arg.candidateEmployerJobId].getSummaryStatus = 'failed';
            state.applicantsSummary[action.meta.arg.candidateEmployerJobId].getSummaryResponse = action?.payload?.message;
            // For applicant profile page.
            state.getApplicantSummaryStatus = 'failed';
            state.applicantSummary = undefined;
            state.getApplicantSummaryResponse = action?.payload?.message;
        });
        // fetch applicant fingerprint image
        builder.addCase(getFingerprintImage.pending, (state, action) => {
            if (state.applicantsSummary[action.meta.arg.applicantId]) {
                state.applicantsSummary[action.meta.arg.applicantId].getFingerprintImageStatus = 'pending';
            }
            // For applicant profile page.
            state.getApplicantFingerPrintImageStatus = 'pending';
        });
        builder.addCase(getFingerprintImage.fulfilled, (state, action) => {
            if (state.applicantsSummary[action.meta.arg.applicantId]) {
                state.applicantsSummary[action.meta.arg.applicantId].getFingerprintImageStatus = 'success';
                state.applicantsSummary[action.meta.arg.applicantId].baseballCardUrl = action.payload;
            }
            // For applicant profile page.
            state.getApplicantFingerPrintImageStatus = 'success';
            state.applicantFingerPrintImageUrl = action.payload;
        });
        builder.addCase(getFingerprintImage.rejected, (state, action) => {
            if (state.applicantsSummary[action.meta.arg.applicantId]) {
                state.applicantsSummary[action.meta.arg.applicantId].getFingerprintImageStatus = 'failed';
                state.applicantsSummary[action.meta.arg.applicantId].getFingerprintImageResponse = action?.payload?.message;
            }
            // For applicant profile page.
            state.getApplicantFingerPrintImageStatus = 'failed';
            state.getApplicantFingerPrintImageResponse = '';
            state.applicantFingerPrintImageUrl = undefined;
        });
        // change applicant stage
        builder.addCase(changeApplicantStageInList.pending, (state, action) => {
            if (state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId)) {
                // Get index from applicants list with applicant ID in params and update state.
                state.applicantsList[state.applicantsList.findIndex(al =>
                    al.candidateEmployerJobId === action.meta.arg.applicantId)].changeStateStatus = 'pending';
                state.stageChangeStatus = 'idle';
            }
        });
        builder.addCase(changeApplicantStageInList.fulfilled, (state, action) => {
            const indx = state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId);
            if (state.applicantsList[indx]) {
                state.applicantsList[indx].changeStateStatus = 'success';
                state.applicantsList[indx].changeStateResponse = action?.payload?.message;
                state.applicantsList[indx].jobStage = action.meta.arg.payload.stage;
                state.stageChangeStatus = 'success';
            }

        });
        builder.addCase(changeApplicantStageInList.rejected, (state, action) => {
            const indx = state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId);
            if (state.applicantsList[indx]) {
                state.applicantsList[indx].changeStateStatus = 'failed';
                state.stageChangeStatus = 'failed';
                state.applicantsList[indx].changeStateResponse = action?.payload?.message;
            }
        });
        // update rating
        builder.addCase(updateApplicantRatingInList.pending, (state, action) => {
            if (state.applicantsList[state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId)]) {
                // Get index from applicants list with applicant ID in params and update state.
                state.applicantsList[state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId)].updateRatingStatus = 'pending';
            }
        });
        builder.addCase(updateApplicantRatingInList.fulfilled, (state, action) => {
            const indx = state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId);
            if (state.applicantsList[indx]) {
                state.applicantsList[indx].updateRatingStatus = 'success';
                state.applicantsList[indx].updateRatingResponse = action?.payload?.message;
                state.applicantsList[indx].rating = action.meta.arg.payload.rating;
            }

        });
        builder.addCase(updateApplicantRatingInList.rejected, (state, action) => {
            const indx = state.applicantsList.findIndex(al => al.candidateEmployerJobId === action.meta.arg.applicantId);
            if (state.applicantsList[indx]) {
                state.applicantsList[indx].updateRatingStatus = 'failed';
                state.applicantsList[indx].updateRatingResponse = action?.payload?.message;
            }
        });

        builder.addCase(autoRejectApplicants.pending, (state) => {
            state.getBatchAutoRejectStatus = 'pending';
        });
        builder.addCase(autoRejectApplicants.fulfilled, (state, action) => {
            state.getBatchAutoRejectStatus = 'success';
            state.getBatchAutoRejectResponse = action.payload?.message ?? 'Applicants auto-rejected successfully.';
        });
        builder.addCase(autoRejectApplicants.rejected, (state, action) => {
            state.getBatchAutoRejectStatus = 'failed';
            state.getBatchAutoRejectResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // fetch applicant summary
        builder.addCase(fetchAndGenerateCandidateAiSummary.pending, (state) => {
            state.generateCandidateAiSummaryStatus = 'pending';
            state.generateCandidateAiSummaryResponse = '';
        });
        builder.addCase(fetchAndGenerateCandidateAiSummary.fulfilled, (state, action) => {
            if (!state.aiCandidatesSummary) {
                state.aiCandidatesSummary = {}; 
            }
        
            state.aiCandidatesSummary[action.meta.arg.candidate_employerjobs_id] = {
                ...action?.payload?.data,  
            };
        
            state.generateCandidateAiSummaryStatus = 'success';
            state.aiCandidateSummary = action?.payload?.data;
        });
        builder.addCase(fetchAndGenerateCandidateAiSummary.rejected, (state, action) => {
            state.generateCandidateAiSummaryStatus = 'failed';
            state.aiCandidateSummary = undefined;
            state.generateCandidateAiSummaryResponse = action?.payload?.message;
        });
        

    }
});

export const { toggleApplicantSummary, resetAiCandidateSummary, resetApplicantsState, resetStageChange, resetUpdateApplicantRatingInList, setAiSuggestedApplicants, resetAiSuggestedApplicants } = applicantsListSlice.actions;
export default applicantsListSlice;
