import { Route, Routes } from "react-router-dom";
import { ManageInterviews } from "Modules/Core/Help/HelpPages/ManageInterviews";
import { ManagingCandidates } from "Modules/Core/Help/HelpPages/ManagingCandidates";
import { SetPermissions } from "Modules/Core/Help/HelpPages/SetPermissions";
import { BillingAndPayments, ContactSupport, GettingStarted, PostAJob } from "Modules/Core/Help";
import { AccountCustomization } from "Modules/Core/Help/HelpPages/AccountCustomization";
import AccountSecurity from "Modules/Core/Help/HelpPages/AccountSecurity";
import { Help } from "Modules/Core/Help/Help";
import { OptimizeJobPosting } from "Modules/Core/Help/HelpPages/OptimizeJobPosting";
// import {Tasks} from "Modules/Core/Help/HelpPages/Tasks"
// import { Careers } from "Modules/Core/Help/HelpPages/Careers";


export const HelpRouter = () => {
    return (
        <Routes>
            {/* Help */}
            <Route path="/" element={<Help />} />
            <Route path="/getting-started" element={<GettingStarted />} />
            <Route path="/managing-candidates" element={<ManagingCandidates />} />
            <Route path="/optimize-job-posting" element={<OptimizeJobPosting />} />
            <Route path="/account-customization" element={<AccountCustomization />} />
            <Route path="/account-security" element={<AccountSecurity />} />
            <Route path="/billing-payments" element={<BillingAndPayments />} />
            <Route path="/contact-support" element={<ContactSupport />} />
            <Route path="/set-permissions" element={<SetPermissions />} />
            <Route path="/post-a-job" element={<PostAJob />} />
            <Route path="/manage-interviews" element={<ManageInterviews />} />
            {/* <Route path="/careers" element={<Careers />} /> */}
            {/* <Route path="/tasks" element={<Tasks />} /> */}
        </Routes>
    );
};
