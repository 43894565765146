import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { ShMyInterviewsIcon } from "assets/Icons";
import { IShFeatureTile } from 'shared/SharedModels';
import { PrimaryThemeColor, ShGreen, ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { IApplicant } from '../Applicants/ApplicantsModel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TuneIcon from '@mui/icons-material/Tune';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { GroupAdd } from '@mui/icons-material';

export const DashboardGetStartedFeatureTiles: IShFeatureTile[] = [
    {
        tileLabel: 'Post a Job',
        featureRedirectUrl: '/employer/job/new',
        tileIcon: <AddIcon color='primary' />,
        caption: 'Quickly create customized job listings to Attract Top Candidates.',
        featureName: 'Create Job'
    },
    {
        tileLabel: 'Active Jobs',
        featureRedirectUrl: '/employer/jobs',
        tileIcon: <WorkIcon color='primary' />,
        caption: 'View all active jobs. Track application status',
        featureName: 'View Jobs'
    },
    {
        tileLabel: 'Add Users',
        featureRedirectUrl: '/employer/settings/account/access-levels',
        tileIcon: <GroupAdd color='primary' />,
        caption: 'Add admins, recruiters, or team members to manage your company.',
        featureName: 'Manage Users'
    },
    {
        tileLabel: 'Interviews',
        featureRedirectUrl: '/employer/interviews',
        tileIcon: <ShMyInterviewsIcon  sx={{ transform: 'scale(0.85)', transformOrigin: 'center'}}
        primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />,
        caption: 'View upcoming interviews. Manage interview schedules.',
        featureName: 'Interviews'
    },
    // {
    //     tileLabel: 'Talent Dashboard',
    //     featureRedirectUrl: '/employer/talent-dashboard',  
    //     tileIcon: <FolderSpecialOutlinedIcon color='primary'/>,
    //     caption: 'Manage talent pool. Gain insights into your past hires & offers',
    //     featureName: 'Employee Insights'
    // },
    {
        tileLabel: 'Calendar',
        featureRedirectUrl: '/employer/calendar',
        tileIcon: <CalendarMonthIcon color='primary' />,
        caption: 'View all scheduled events. Track important dates.',
        featureName: 'Calendar'
    },
    {
        tileLabel: 'Analytics',
        featureRedirectUrl: '/employer/analytics',
        tileIcon: <QueryStatsIcon color='primary' />,
        caption: 'Gain insight into your candidate pool, hiring practices & more',
        featureName: 'Resources'
    },
    {
        tileLabel: 'Resources',
        featureRedirectUrl: '/employer/resources',
        tileIcon: <LibraryBooksIcon color='primary' />,
        caption: 'AI Powered Tools, Offer Letter Templates, Interview Kits',
        featureName: 'Resources'
    },
    {
        tileLabel: 'Settings',
        featureRedirectUrl: '/employer/settings/account/account-information',
        tileIcon: <SettingsIcon color='primary' />,
        caption: 'Manage account preferences. Update personal info.',
        featureName: 'Settings'
    },
    {
        tileLabel: 'Help & More',
        featureRedirectUrl: '/employer/help',
        tileIcon: <HelpIcon color='primary' />,
        caption: 'Find user guides and FAQs. Get in touch with customer service.',
        featureName: 'Help'
    },
];

export const DashboardToolsFeatureTiles: IShFeatureTile[] = [
    // {
    //     tileLabel: 'Interview Templates',
    //     featureRedirectUrl: '/employer/settings/tools/templates/interview-invitation',
    //     tileIcon: <AssignmentIcon color='primary' />,
    //     caption: 'Create and customize interview templates to streamline the hiring process.',
    //     featureName: 'Offer Letter Template'
    // },
    {
        tileLabel: 'Questionnaire Templates',
        featureRedirectUrl: '/employer/settings/tools/templates/form-templates',
        tileIcon: <DynamicFormIcon color='primary' />,
        caption: 'Create Custom Questionnaires with Tailored Questions and Auto-Reject Options to Better Evaluate Your Candidates',
        featureName: 'Questionnaire Templates'
    },
    {
        tileLabel: 'Application Confirmation Template',
        featureRedirectUrl: '/employer/settings/tools/templates/application-received',
        tileIcon: <NotificationsIcon color='primary' />,
        caption: 'Create a personalized email template to automatically notify candidates once they complete their job application.',
        featureName: 'Application Received Template'
    },
    {
        tileLabel: 'Rejection Templates',
        featureRedirectUrl: '/employer/settings/tools/templates/rejection-notification',
        tileIcon: <DoNotDisturbIcon color='primary' />,
        caption: 'Craft and manage rejection templates to communicate professionally with candidates.',
        featureName: 'Auto Reject'
    },
    {
        tileLabel: 'Interview Templates',
        featureRedirectUrl: '/employer/settings/tools/templates/interview-notification',
        tileIcon: <ShMyInterviewsIcon  sx={{ transform: 'scale(0.85)', transformOrigin: 'center'}}
        primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />,
        caption: 'Craft and manage interview invitation templates to communicate professionally with candidates.',
        featureName: 'Auto Reject'
    },
    // {
    //     tileLabel: 'Offer Letter Templates',
    //     featureRedirectUrl: '/employer/settings/tools/templates/offer-letter',
    //     tileIcon: <DescriptionIcon color='primary' />,
    //     caption: 'Design and maintain offer letter templates to secure top candidates efficiently.',
    //     featureName: 'Help'
    // },
    {
        tileLabel: 'Auto Reject Criteria',
        featureRedirectUrl: '/employer/settings/automation/auto-reject/criteria',
        tileIcon: <AutoDeleteIcon color='primary' />,
        caption: 'Define criteria for auto-rejection to streamline candidate screening.',
        featureName: 'Auto Reject Criteria'
    },
];


export const CareersPageToolsFeatureTiles: IShFeatureTile[] = [
    {
        tileLabel: 'View Your Careers Page',
        featureRedirectUrl: '/employer/settings/tools/templates/form-templates',
        tileIcon: <VisibilityIcon color='primary' />, 
        caption: 'View your custom careers page and see how it showcases your job listings to top talent.',
        featureName: 'View Careers Page'
    },
    {
        tileLabel: 'Customize Careers Page',
        featureRedirectUrl: '/employer/settings/account/company-information',
        tileIcon: <TuneIcon color='primary' />,
        caption: 'Customize your careers page to reflect your companys branding and attract the best candidates.',
        featureName: 'Questionnaire Templates'
    },
];

export const DashboardListSize = 30;
export const MaxDashboardApplicantSize = 999999;


export const getDotPropertiesAndMessage = (applicant: IApplicant, selectedJobName?: string) => {
    const jobStageColors: Record<string, { color: string, variant: 'filled' | 'outlined' }> = {
        new: { color: PrimaryThemeColor, variant: 'filled' }, // Blue
        reviewed: { color: '#1565C0', variant: 'filled' }, // Darker Blue
        interview: { color: ShGreen, variant: 'outlined' }, // Green
        second_interview: { color: '#388E3C', variant: 'outlined' }, // Darker Green
        offer: { color: '#4CAF50', variant: 'filled' }, // Light Green
        hired: { color: '#66BB6A', variant: 'filled' }, // Soft Green
        phone_screening: { color: '#0288D1', variant: 'outlined' }, // Light Blue
        rejected: { color: '#D32F2F', variant: 'filled' }, // Red
        not_qualified: { color: '#C62828', variant: 'filled' }, // Darker Red
        auto_rejected: { color: '#B71C1C', variant: 'filled' } // Deep Red
    };

    let applicantName = applicant.firstName && applicant.lastName 
        ? `${applicant.firstName} ${applicant.lastName}` 
        : applicant.email;

    let { color } = jobStageColors[applicant.jobStage] || { color: '#9E9E9E', variant: 'outlined' }; 
    let message = `${applicantName} was ${applicant.jobStage}`;

    switch (applicant.jobStage) {
        case 'new':
            message = `${applicantName} has applied to ${selectedJobName}`;
            break;
        case 'reviewed':
            message = `${applicantName} has been reviewed`;
            break;
        case 'interview':
            message = `${applicantName} is scheduled to be interviewed`;
            break;
        case 'second_interview':
            message = `${applicantName} 2nd interview has been scheduled`;
            break;
        case 'offer':
            message = `${applicantName} was given an offer for ${selectedJobName}`;
            break;
        case 'hired':
            message = `${applicantName} was hired for ${selectedJobName} 🎉`;
            break;
        case 'phone_screening':
            message = `${applicantName} has been scheduled for a phone interview`;
            break;
        case 'rejected':
            message = `${applicantName} was rejected from ${selectedJobName}`;
            break;
        case 'not_qualified':
            message = `${applicantName} has been categorized as not qualified for ${selectedJobName}`;
            break;
        case 'auto_rejected':
            message = `${applicantName} has been auto rejected for ${selectedJobName}`;
            break;
    }

    return {  color, message };
};


export const DashboardTileBordeRadius = 15;