import { Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckIcon from '@mui/icons-material/Check';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { updateArrayById } from 'shared/utils';
import { BundlesCardProps } from './BundlesModel';
import { FREE_JOB_POSTING_BUNDLE_ID } from './BundlesConstants';

export const BundlesCard: React.FC<BundlesCardProps> = ({
    addon,
    selectedAddOnBundles,
    setSelectedAddOnBundles,
    handleAddOnBundleSelect,
    getPricingLabel,
    employerDetails
}) => {
    return (
        <Grid item xs={12} key={addon.id}>
            <ShPaper
                borderColor={'#74C05A'}
                borderStyle={selectedAddOnBundles.includes(addon.id) ? 'solid' : ''}
                variant="outlined"
                transElevateOnHover
                onClick={() => {
                    handleAddOnBundleSelect(addon.id);
                    setSelectedAddOnBundles(updateArrayById<number, number>(addon.id, selectedAddOnBundles));
                }}>
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
                    {/* Checkbox for selecting addon */}
                    {addon.id !== FREE_JOB_POSTING_BUNDLE_ID  && 
                        <Checkbox
                            onChange={() => setSelectedAddOnBundles(updateArrayById<number, number>(addon.id, selectedAddOnBundles))}
                            checked={selectedAddOnBundles?.includes(addon.id)}
                            color="primary"
                        />
                    }
                    <Stack flex={1} mx={2}>
                        <Stack direction='row' spacing={2} alignItems='center'>
                            <Typography variant="body2" component="div">
                                {addon.name}
                            </Typography>
                            <Typography variant="body1" color="primary">
                                {!employerDetails?.free_trail_signup && getPricingLabel(addon)}
                            </Typography>
                        </Stack>
                        <Typography variant="caption" color="text.secondary">
                            {addon.description}
                        </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1.5}>
                        {addon.features_included?.split(',').map((feature: string, index: number) => (
                            <Stack direction="row" alignItems="center" key={index} justifyContent='flex-end'>
                                <CheckIcon color="success" />
                                <Typography ml={1} mr={1} fontSize={13}>
                                    {feature.trim()}
                                </Typography>
                                {addon.features_included && index < addon.features_included?.split(',').length - 1 && (
                                    <Divider orientation='vertical' />
                                )}
                            </Stack>
                        ))}
                        {addon.id !== FREE_JOB_POSTING_BUNDLE_ID  && 
                            <ShButton textColor='white' color='success' size='small' variant='contained' startIcon={<AddShoppingCartIcon />}
                                onClick={() => handleAddOnBundleSelect(addon.id)}>
                                Add to cart
                            </ShButton>
                        }
                    </Stack>
                </Stack>
            </ShPaper>
        </Grid>
    );
};

export default BundlesCard;
