import { Box, Divider, Fade, Stack, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";

export const ProgrammingLanguages: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.programming_languages?.length) return null;

    return (
    <Fade in={true} timeout={800}>
        <Stack spacing={2}>
         {candidate.programming_languages.length > 0 && (
                <Box mt={2} mb={2}>
                    <Typography fontSize={18}>PROGRAMMING LANGUAGES</Typography>
                    <Divider flexItem/>
                    <Stack direction="row" flexWrap="wrap" gap={1} mt={1}>
                        {candidate.programming_languages.map((lang, index) => (
                        <ShChip hideBoxShadow size='small' key={index} label={lang} color="secondary" />
                        ))}
                    </Stack>
                </Box>
            )}
        </Stack>
    </Fade>
    );
}
