import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IShButton } from 'shared/SharedStyles/ShInputs';

export const ShBanner = styled(Box)(
    ({ theme }) => ({
        display: 'flex',
        width: '75%',
        margin:'auto',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
        borderRadius: 10,
        justifyContent: 'center'
    })
);


export const BannerContainer = styled(Box)(({ theme }) => ({
    // background: "linear-gradient(90deg, #ff416c, #ff4b2b)", //red
    // background: "linear-gradient(90deg, #00c851, #007e33)", //green
    background: "linear-gradient(90deg, #11998e, #23DA68)", // green blue
    // background: "linear-gradient(90deg, #0072ff, #00c6ff)",
    color: "#fff",
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
    animation: "fadeIn 1s ease-in-out",
    "@keyframes fadeIn": {
      "0%": { opacity: 0, transform: "translateY(-10px)" },
      "100%": { opacity: 1, transform: "translateY(0)" },
    },
  }));

  export const BannerButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "borderRadius",
  })<IShButton>(({ theme, borderRadius }) => ({
    backgroundColor: "#fff",
    color: "#11998e",
    fontWeight: "bold",
    borderRadius: borderRadius ? theme.spacing(borderRadius) : theme.shape.borderRadius * 2,
    padding: theme.spacing(1, 1.5),
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
  }));
