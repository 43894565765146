import { Box, Divider, Fade, Stack, Typography } from "@mui/material";
import { CandidateSummaryProps } from "../ApplicantCandidateAiSummaryModels";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShGreen } from "shared/SharedStyles/styleConstants";

export const CertificationsSection: React.FC<CandidateSummaryProps> = ({ candidate }) => {

    if (!candidate?.certifications?.length) return null;

    return (
        <Fade in={true} timeout={800}>
            <Stack spacing={2}>
            {candidate.certifications.length > 0 && (
                    <Box mt={2}>
                    <Typography fontSize={18}>CERTIFICATIONS</Typography>
                        <Divider flexItem/>
                        <Stack direction="row" flexWrap="wrap" gap={1} mt={1}>
                            {candidate.certifications.map((cert, index) => (
                                <ShChip hideBoxShadow bgColor={ShGreen} textColor="white" size='small' key={index} label={cert} color="success" />
                            ))}
                        </Stack>
                    </Box>
                )}
            </Stack>
        </Fade>
    );
}
