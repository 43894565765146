import { SvgIcon } from '@mui/material';
import { IShIcon } from 'shared/SharedModels';

export const ShMyInterviewsIcon = ({ primaryColor, secondaryColor, sx = {} }: IShIcon) => {
    return (<>
        <SvgIcon viewBox='0 0 20 20' sx={{ ...sx}}>
            <path d="M17.7724 3.69314C17.7724 5.03958 16.6809 6.13104 15.3346 6.13104C13.9882 6.13104 12.8967 5.03958 12.8967 3.69314C12.8967 2.34685 13.9882 1.25539 15.3346 1.25539C16.6809 1.25539 17.7724 2.34685 17.7724 3.69314Z" fill={primaryColor} />
            <path d="M17.7724 3.69314C17.7724 5.03958 16.6809 6.13104 15.3346 6.13104C13.9882 6.13104 12.8967 5.03958 12.8967 3.69314C12.8967 2.34685 13.9882 1.25539 15.3346 1.25539C16.6809 1.25539 17.7724 2.34685 17.7724 3.69314Z" fill={primaryColor} />
            <path d="M11.1154 6.85076L9.95827 5.11568V2.15516C9.95827 0.973819 9.00551 0 7.80356 0H2.15517C0.959328 0 0 0.968021 0 2.15516V5.60671C0 6.7911 0.955818 7.76171 2.15517 7.76171H10.6278C11.0946 7.76171 11.3748 7.23986 11.1154 6.85076ZM4.96281 5.5652H3.11282C2.79726 5.5652 2.52581 5.32244 2.51116 5.00719C2.49575 4.67058 2.76339 4.39333 3.09649 4.39333H4.97944C5.31193 4.39333 5.58018 4.67058 5.56462 5.00704C5.55012 5.32244 5.27852 5.5652 4.96281 5.5652ZM6.86224 3.36839H3.11312C2.79772 3.36839 2.52642 3.12623 2.51131 2.81129C2.49514 2.47468 2.76308 2.19651 3.09649 2.19651H6.84561C7.16131 2.19651 7.43292 2.43928 7.44742 2.75468C7.46298 3.09114 7.19473 3.36839 6.86224 3.36839Z" fill={secondaryColor} />
            <path d="M4.55021 14.5372C2.02654 14.5459 0 16.6293 0 19.153V19.2814C0 19.6783 0.321658 20 0.718542 20H8.41407C8.81095 20 9.13261 19.6783 9.13261 19.2814V19.1034C9.13261 16.5761 7.07953 14.5283 4.55021 14.5372Z" fill={primaryColor} />
            <path d="M7.4417 11.662C7.4417 13.25 6.15446 14.5374 4.56631 14.5374C2.97831 14.5374 1.69092 13.25 1.69092 11.662C1.69092 10.074 2.97831 8.78665 4.56631 8.78665C6.15446 8.78665 7.4417 10.074 7.4417 11.662Z" fill={primaryColor} />
            <path d="M19.0326 9.41412H11.6367C11.8548 7.56536 13.427 6.13087 15.3349 6.13087C17.2422 6.13087 18.8145 7.56536 19.0326 9.41412Z" fill={primaryColor} />
            <path d="M19.0326 9.41412H11.6367C11.8548 7.56536 13.427 6.13087 15.3349 6.13087C17.2422 6.13087 18.8145 7.56536 19.0326 9.41412Z" fill={primaryColor} />
            <path d="M19.4142 10.625C19.7378 10.625 20.0001 10.8873 20.0001 11.211V18.1591C20.0001 18.4919 19.7224 18.7603 19.3856 18.7443C19.0708 18.7293 18.8282 18.4579 18.8282 18.1423V17.4895H10.0731C9.67928 16.1489 8.8086 15.0098 7.65747 14.2711C8.25348 13.566 8.61375 12.6551 8.61375 11.6617C8.61375 11.3036 8.56675 10.9563 8.47886 10.625H19.4142Z" fill={secondaryColor} />
        </SvgIcon>
    </>)
};