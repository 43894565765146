import React from 'react';
import { Grid, Grow } from '@mui/material';
import { IWidget } from '../DashboardModel';
import { WidgetsConfig } from './WidgetFactoryConfig';

const WidgetFactory: React.FC = () => {
    const rows: { [key: number]: IWidget[] } = {};

    // Organize widgets by rows
    WidgetsConfig.forEach((widget: IWidget) => {
        if (!rows[widget.row]) {
            rows[widget.row] = [];
        }
        rows[widget.row].push(widget);
    });

    return (
        <React.Fragment>
            {Object.keys(rows).map((row) => (
                <Grid container spacing={1} key={`row-${row}`}>
                    {rows[parseInt(row)].map((widget: IWidget, index) => {
                        const WidgetComponent = React.memo(widget.component);
                        return (
                            <Grow in={true}  timeout={1000 + index * 100} key={widget.id}>
                                <Grid item {...widget.gridProps}>
                                    <WidgetComponent />
                                </Grid>
                            </Grow>
                        );
                    })}
                </Grid>
            ))}
        </React.Fragment>
    );
};

export default WidgetFactory;
