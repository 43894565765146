import { Box, Tab } from "@mui/material";
import { SettingsWrapper } from "Modules/Core/SettingsTs/Settings.styled";
import { SettingsPath, SettingsPathEnum } from "Modules/Core/SettingsTs/SettingsModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { RoleAccessControlMap } from "../../AuthV2/AuthConstants";
import { ShTabsV2 } from "shared/SharedStyles/ShNavigation";
import { getCompanyInfo, resetLogoUpdate, resetUpdateCompany } from "store/slices/employer/settingsTs/company-info-slice";
import { SUBSCRIPTION_EMPLOYER_PRICING_GROUP } from "./Subscriptions/SubscriptionsConstants";

export const AccountSettings = () => {
    const dispatch = useAppDispatch();
    const pathname = useLocation()?.pathname?.split('/').pop();
    const [curPage, setCurPage] = useState<SettingsPath>(pathname as SettingsPath);
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const { accountAccess } = useAppSelector((state) => state.auth.auth);

    useEffect(() => {
        if (!companyInfo.id) {
            dispatch(getCompanyInfo());
        }

        return () => {
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        };
    }, [companyInfo, dispatch]);

    // Set tab selection based on url path.
    useEffect(() => {
        if (pathname) {
            setCurPage(pathname as SettingsPath);
        }
    }, [pathname, accountAccess]);

    return (<>
        <SettingsWrapper>
            <Box paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }} padding={1}>
                <ShTabsV2 aria-label="Settings Tabs" variant="scrollable" scrollButtons='auto'
                    allowScrollButtonsMobile value={curPage} onChange={(e, nV) => setCurPage(nV as SettingsPath)}>
                    <Tab label="Account Information" value={SettingsPathEnum.AccountInformation} component={Link}
                        to='/employer/settings/account/account-information' />
                    {RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.["Company Details"]?.visibility !== 'hidden' &&
                        <Tab label="Company Details" value={SettingsPathEnum.CompanyInformation}
                            component={Link} to='/employer/settings/account/company-information' disabled={
                                RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                                    ?.["Company Details"]?.visibility === 'disabled'} />}
                    {RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.["Company Details"]?.visibility !== 'hidden' &&
                        <Tab label="Careers Page" value={SettingsPathEnum.CareersPage}
                            component={Link} to='/employer/settings/account/customize-careers-page' disabled={
                                RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                                    ?.["Careers Page Customization"]?.visibility === 'disabled'} />}
                    {RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.['Manage Users']?.visibility !== 'hidden' &&
                        <Tab label="Manage Users" value={SettingsPathEnum.AccessLevels}
                            component={Link} to='/employer/settings/account/access-levels' disabled={
                                RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                                    ?.['Manage Users']?.visibility === 'disabled'} />}
                    
                    <Tab label="Password & Security" value={SettingsPathEnum.Security}
                        component={Link} to='/employer/settings/account/security' />

                    {companyInfo.employer_pricing_group_id === SUBSCRIPTION_EMPLOYER_PRICING_GROUP &&
                        <Tab label="Subscriptions" value={SettingsPathEnum.Subscriptions}
                            component={Link} to='/employer/settings/account/subscriptions' />
                    }
                    {/* <Tab label="Branding" value={SettingsPathEnum.Branding}
                        component={Link} to='/employer/settings/account/branding' /> */}
                    {/* <Tab label="Integrations" value={SettingsPathEnum.Integrations}
                        component={Link} to='/employer/settings/account/integrations' /> */}
                </ShTabsV2>
            </Box>
            <Box className="settings-outlet" padding={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
                <Outlet />
            </Box>
        </SettingsWrapper>
    </>);
};