import { ApplicantResume } from "Modules/Core/Applicants/ApplicantProfile/ApplicantResume";
import { ApplyJobHome } from "Modules/Core/ApplyJob/ApplyJobHome";
import { ApplyJobQuestionnaire } from "Modules/Core/ApplyJob/ApplyJobQuestionnaire";
import { ApplyJobWrapper } from "Modules/Core/ApplyJob/ApplyJobWrapper";
import { AuthV2 } from "Modules/Core/AuthV2/AuthV2";
import { ResetPassword } from "Modules/Core/AuthV2/ResetPassword";
import { SocialLogin } from "Modules/Core/AuthV2/SocialLogin";
import { CandidateCareerTools } from "Modules/Core/Candidate/CandidateCareerTools";
import { CandidateHistory } from "Modules/Core/Candidate/CandidateHistory";
import { CandidateHome } from "Modules/Core/Candidate/CandidateHome";
import { CandidateJobNotifications } from "Modules/Core/Candidate/CandidateJobNotifications";
import { CandidateProfile } from "Modules/Core/Candidate/CandidateProfile";
import { CandidateReport } from "Modules/Core/Candidate/CandidateReport";
import CandidateRequestTime from "Modules/Core/Candidate/CandidateRequestTime";
import { CandidateResume } from "Modules/Core/Candidate/CandidateResume";
import { CandidateApplicationWrapper } from "Modules/Core/Layouts/CandidateLayout/CandidateApplicationWrapper";
import { CandidateLayoutWrapper } from "Modules/Core/Layouts/CandidateLayout/CandidateLayoutWrapper";
import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { Survey } from "Modules/Core/Survey/Survey";
import { SurveyHome } from "Modules/Core/Survey/SurveyHome";
import { SurveyLogin } from "Modules/Core/Survey/SurveyLogin";
import { SurveySignUp } from "Modules/Core/Survey/SurveySignUp";
import { SurveySocialSignUp } from "Modules/Core/Survey/SurveySocialSignUp";
import { SurveyWrapper } from "Modules/Core/Survey/SurveyWrapper";
import { Navigate, Route, Routes } from "react-router-dom";

export const CandidateRouter = () => {
    return (<>
        <Routes>
            <Route path="/" element={<CandidateLayoutWrapper />} >
                {/* Error 404 page */}
                <Route path="*" element={<ErrorPage />} />
                <Route path="/" element={<CandidateApplicationWrapper />} >
                    {/* Social sign up */}
                    <Route path="/login/:provider/:status" element={<SocialLogin buttonType="icon" usedIn="candidate_apply_job" />} />

                    {/* Survey */}
                    <Route path="/" element={<SurveyWrapper />} >
                        <Route path="/" element={<Navigate replace to="/candidate/new" />} />
                        <Route path="/new" element={<SurveyHome />} />
                        <Route path="/login" element={<SurveyLogin />} />
                        <Route path="/sign-up" element={<SurveySignUp />} />
                        <Route path="/sign-up/:provider/:status" element={<SurveySocialSignUp />} />
                        <Route element={<AuthV2 appContext="candidate_survey" />}>
                            <Route path="/survey" element={<Survey navigatedFrom="survey" />} />
                        </Route>
                        {/* Password reset component */}
                        <Route path="/password/reset" element={<ResetPassword />} />
                    </Route>

                    <Route path="/request-interview-time/:requestIdentifier" element={<CandidateRequestTime />} />

                    {/* Apply job */}
                    <Route path="/" element={<ApplyJobWrapper />} >
                        <Route path="/apply-job/:jobCode" element={<ApplyJobHome/>} />
                        <Route element={<AuthV2 appContext="candidate_apply_job" />}>
                            <Route path="/apply-job/:jobCode/questionnaire" element={<ApplyJobQuestionnaire />} />
                            <Route path="/apply-job/:jobCode/assessment" element={<Survey navigatedFrom="apply_job" />} />
                        </Route>
                        {/* Password reset component */}
                        <Route path="/password/reset" element={<ResetPassword />} />
                    </Route>
                </Route>

                {/* Candidate details pages */}
                <Route element={<AuthV2 appContext="candidate_survey" />}>
                    <Route path="/" element={<Navigate replace to="/home" />} />
                    <Route path="/home" element={<CandidateHome />} />
                    <Route path="/settings" element={<CandidateJobNotifications />} />
                    <Route path="/report" element={<CandidateReport />} />
                    <Route path="/profile" element={<CandidateProfile />} />
                    <Route path="/career-tools" element={<CandidateCareerTools />} />
                    <Route path="/history" element={<CandidateHistory />} />
                    <Route path="/" element={<CandidateResume />} >
                        <Route path="/:applicantId/resume" element={<ApplicantResume />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    </>)
}