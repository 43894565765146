import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import { getAccInfo } from 'store/slices/employer/settingsTs/acc-info-slice';
import { CandidateDashboardTiles } from './CandidateConstants';

export const CandidateHome = () => {

    const dispatch = useAppDispatch();
    const { accountInfo } = useAppSelector((state) => state.employer.employerSettings.accInfo);

    useEffect(() => {
        dispatch(getAccInfo());
    }, [dispatch]);

    return (<>
        <Container maxWidth='md'>
            {accountInfo?.first_name &&
                <Stack padding={{ xs: 1, sm: 2, md: 3, lg: 4 }} direction='row' alignItems='center' columnGap={1}>
                    <Avatar>{accountInfo?.first_name ? accountInfo?.first_name[0] : <PersonIcon />}</Avatar>
                    <Stack direction='row' alignItems='center' columnGap={1}>
                        <Typography variant="h4">{`Welcome ${accountInfo?.first_name ? `back,` : '!'}`}</Typography>
                        <Typography variant="h4" color={PrimaryThemeColor}>{accountInfo?.first_name}</Typography>
                    </Stack>
                </Stack>}
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                {!(accountInfo.first_name || accountInfo.last_name) &&
                    <ShAlert severity='warning'>Your profile seems to be incomplete. Click&nbsp;
                        <ShMuiLink component={RouterLink} to='/candidate/profile' color='inherit'>here</ShMuiLink>&nbsp;to update.
                    </ShAlert>}
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Career Seeker Resources</Typography>
                <Stack rowGap={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                    {CandidateDashboardTiles.map(candidateTile => (
                        <ShPaper key={candidateTile.tileLabel} transElevateOnHover cursor='pointer' noPadding>
                            <ShMuiLink padding={2} component={RouterLink} display='flex' alignItems='center' width='100%'
                                columnGap={1} to={candidateTile.featureRedirectUrl} noUnderline color='inherit'>
                                {candidateTile.tileIcon}
                                <Box>
                                    <Typography variant='body1'>{candidateTile.tileLabel}</Typography>
                                    <Typography variant='caption'>{candidateTile.caption}</Typography>
                                </Box>
                            </ShMuiLink>
                        </ShPaper>
                    ))}
                </Stack>
            </Box>
        </Container>
    </>)
}