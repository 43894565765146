import React, { useState } from "react";
import { List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import helpPageCards from "./HelpContent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  SidebarContainer,
  SidebarButton,
  CollapsedSidebarContainer,
} from "./Help.styled";

export const CommonQuestionSidebar: React.FC = () => {
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(true);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const isActive = (path: string) => location.pathname === path;

  return (
    <>
      {open ? (
        <SidebarContainer>
          <SidebarButton onClick={() => setOpen(!open)}>
            <KeyboardArrowRightIcon />
          </SidebarButton>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {helpPageCards.map((card, index) => (
              <Tooltip title={card.title} key={index} placement="right">
                <ListItem
                  button
                  component={Link}
                  to={card.path}
                  onMouseEnter={() => setHoveredItem(card.path)}
                  onMouseLeave={() => setHoveredItem(null)}
                  style={{
                    background:
                      isActive(card.path) || hoveredItem === card.path
                        ? "linear-gradient(135deg, #e3f2fd, #bbdefb)"
                        : "transparent",
                    borderRadius: "8px",
                    transition:
                      "background 0.3s ease-in-out, transform 0.2s ease",
                    transform:
                      isActive(card.path) || hoveredItem === card.path
                        ? "scale(1.03)"
                        : "scale(1)",
                  }}
                >
                  <ListItemText
                    primary={card.title}
                    style={{
                      color:
                        isActive(card.path) || hoveredItem === card.path
                          ? "#0d47a1"
                          : "#333",
                      fontWeight: isActive(card.path) ? "bold" : "normal",
                    }}
                  />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </SidebarContainer>
      ) : (
        <CollapsedSidebarContainer>
          <SidebarButton onClick={() => setOpen(!open)}>
            <KeyboardArrowLeftIcon />
          </SidebarButton>
        </CollapsedSidebarContainer>
      )}
    </>
  );
};

export default CommonQuestionSidebar;
