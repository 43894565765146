import { Chip, Grow, Stack, Tab, Tabs } from '@mui/material';
import { jobOfferTemplateData } from 'Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateConstants';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { IsSmScreen, useAppDispatch } from 'helpers/hooks';
import React, { useEffect, useState } from 'react';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { EmployerOfferTemplatePreviewFields } from './EmployerOfferTemplatePreviewFields';
import templateFunctions from './EmployerOfferTemplateMapping';
import { useLocation } from 'react-router-dom';
import { tabData } from '../EmployerResourcesConstants';
import { setCurrentBreadCrumb } from 'store/slices/app/breadcrumb-slice';

export const EmployerOfferTemplateSinglePage = () => {
    
    const location = useLocation();
    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const type = pathParts[pathParts.length - 1].split('-')[0] as 'formal' | 'general' | 'informal' | 'internal' | 'sales';
        setTemplateType(type);

        switch (type) {
            case 'general':
                setTabValue('1');
                break;
            case 'formal':
                setTabValue('2');
                break;
            case 'internal':
                setTabValue('3');
                break;
            case 'informal':
                setTabValue('4');
                break;
            case 'sales':
                setTabValue('5');
                break;
            default:
                setTabValue('1'); 
                break;
        }
    }, [location.pathname]);
    const [templateType, setTemplateType] = useState<'formal' | 'general' | 'internal' |'informal' | 'sales'>('formal');
    const { generateDocument, generatePreviewContent } = templateFunctions[templateType];
    const [salary, setSalary] = useState(jobOfferTemplateData.salary);
    const [startDate, setStartDate] = useState(jobOfferTemplateData.startDate);
    const [companyName, setCompanyName] = useState(jobOfferTemplateData.companyName);
    const [candidateName, setCandidateName] = useState(jobOfferTemplateData.candidateName);
    const [jobTitle, setJobTitle] = useState(jobOfferTemplateData.jobTitle);
    const [employmentType, setEmploymentType] = useState(jobOfferTemplateData.employmentType);
    const [employmentHours, setEmploymentHours] = useState(jobOfferTemplateData.employmentHours);
    const [department, setDepartment] = useState(jobOfferTemplateData.department);
    const [bonusPrograms, setBonusPrograms] = useState(jobOfferTemplateData.bonusPrograms);
    const [vacationDays, setVacationDays] = useState(jobOfferTemplateData.vacationDays);
    const [contractDuration, setContractDuration] = useState(jobOfferTemplateData.contractDuration);
    const [employmentAgreement, setEmploymentAgreement] = useState(jobOfferTemplateData.employmentAgreement);
    const [responseDate, setResponseDate] = useState(jobOfferTemplateData.responseDate);
    const [managerName, setManagerName] = useState(jobOfferTemplateData.managerName);
    const [contactDetails, setContactDetails] = useState(jobOfferTemplateData.contactDetails);
    const [yourName, setYourName] = useState(jobOfferTemplateData.yourName);
    const [signature, setSignature] = useState(jobOfferTemplateData.signature);
    const [benefits, setBenefits] = useState<string[]>(jobOfferTemplateData.benefits);
    const isSmScreen = IsSmScreen();
    const [tabValue, setTabValue] = useState('1');
    const dispatch = useAppDispatch();

    useEffect(() => {
        const crumbName = location.pathname.split('/').pop()?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '';
        const bc = {
            pathname: location.pathname,
            breadcrumbs: [
                { displayName: 'Home', href: '/employer/dashboard', isActive: true },
                { displayName: 'Resources', href: '/employer/resources', isActive: true },
                { displayName: 'Offer Letter Templates', href: '/employer/resources/offer-letter-templates', isActive: true },
                { displayName:  crumbName, href: '', isActive: false }
            ]
        };
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    });

    const handleAddBenefit = (newBenefit: string) => {
        if (newBenefit.trim() !== '') {
            setBenefits([...benefits, newBenefit.trim()]);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newBenefit = (e.target as HTMLInputElement).value.trim();
            handleAddBenefit(newBenefit);
            (e.target as HTMLInputElement).value = '';
        }
    };

    const handleDeleteBenefit = (index: number) => {
        const updatedBenefits = [...benefits];
        updatedBenefits.splice(index, 1);
        setBenefits(updatedBenefits);
    };

    const handleDownload = async () => {
        try {
           const doc = generateDocument({
            companyName: companyName || jobOfferTemplateData.companyName,
            candidateName: candidateName ||  jobOfferTemplateData.candidateName,
            jobTitle: jobTitle || jobOfferTemplateData.jobTitle,
            employmentType: employmentType || jobOfferTemplateData.employmentType,
            employmentHours: employmentHours || jobOfferTemplateData.employmentHours,
            department: department || jobOfferTemplateData.department,
            salary: salary || jobOfferTemplateData.salary,
            vacationDays: vacationDays || jobOfferTemplateData.vacationDays,
            bonusPrograms: bonusPrograms || jobOfferTemplateData.bonusPrograms,
            startDate: startDate || jobOfferTemplateData.startDate,
            contractDuration: contractDuration || jobOfferTemplateData.contractDuration,
            employmentAgreement: employmentAgreement || jobOfferTemplateData.employmentAgreement,
            responseDate: responseDate || jobOfferTemplateData.responseDate,
            managerName: managerName || jobOfferTemplateData.managerName,
            contactDetails: contactDetails || jobOfferTemplateData.contactDetails,
            yourName: yourName || jobOfferTemplateData.yourName,
            signature: signature || jobOfferTemplateData.signature,
            benefits: benefits || jobOfferTemplateData.benefits
        });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'formal_job_offer_template.docx');

        } catch (error) {
            console.error('Error generating or downloading document:', error);
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setTabValue(newValue);
        switch (newValue) {
            case '1':
                setTemplateType('general');
                break;
            case '2':
                setTemplateType('formal');
                break;
            case '3':
                setTemplateType('internal');
                break;
            case '4':
                setTemplateType('informal');
                break;
            case '5':
                setTemplateType('sales');
                break;
            default:
                setTemplateType('formal');
                break;
        }
    };

    return (
              <>
                <Stack direction='row' justifyContent='space-between' paddingLeft={3}>
                    <Stack>
                        <Tabs value={tabValue} onChange={handleTabChange} textColor="inherit" indicatorColor="primary" variant="scrollable" scrollButtons="auto">
                                {tabData.map((tab) => (
                                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                                ))}
                        </Tabs>
                    </Stack>
                </Stack>
                <Stack direction={isSmScreen ? 'column' : 'row'} padding={3}>
                    <EmployerOfferTemplatePreviewFields
                        isSmScreen={isSmScreen}
                        salary={salary}
                        startDate={startDate}
                        companyName={companyName}
                        candidateName={candidateName}
                        jobTitle={jobTitle}
                        employmentHours={employmentHours}
                        department={department}
                        bonusPrograms={bonusPrograms}
                        responseDate={responseDate}
                        employmentType={employmentType}
                        vacationDays={vacationDays}
                        contractDuration={contractDuration}
                        employmentAgreement={employmentAgreement}
                        managerName={managerName}
                        contactDetails={contactDetails}
                        yourName={yourName}
                        signature={signature}
                        benefits={benefits}
                        setSalary={setSalary}
                        setStartDate={setStartDate}
                        setCompanyName={setCompanyName}
                        setCandidateName={setCandidateName}
                        setJobTitle={setJobTitle}
                        setEmploymentHours={setEmploymentHours}
                        setDepartment={setDepartment}
                        setBonusPrograms={setBonusPrograms}
                        setResponseDate={setResponseDate}
                        setEmploymentType={setEmploymentType}
                        setVacationDays={setVacationDays}
                        setContractDuration={setContractDuration}
                        setEmploymentAgreement={setEmploymentAgreement}
                        setManagerName={setManagerName}
                        setContactDetails={setContactDetails}
                        setYourName={setYourName}
                        setSignature={setSignature}
                        handleKeyDown={handleKeyDown}
                        handleDeleteBenefit={handleDeleteBenefit} 
                    /> 
                    <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Stack padding={isSmScreen ? 0 : 3} paddingBottom={10} maxWidth={'850px'}>
                            <ShPaper variant='outlined' overflow='auto' height ='850px'>
                                <Stack padding={2} direction={'row'} spacing={3} justifyContent={'space-between'}>
                                    <ShButton onClick={handleDownload} variant="contained" color="primary" size='small'> Download (.docx) </ShButton>
                                    <Chip color='success' style={{ color: 'white', maxWidth: '110px' }} label="Live Preview" />
                                </Stack>
                                <Stack padding={2} direction={'column'} spacing={3}>
                                        <Stack spacing={3}>
                                            {generatePreviewContent({
                                                companyName: companyName,
                                                candidateName: candidateName,
                                                jobTitle: jobTitle,
                                                employmentType: employmentType,
                                                employmentHours: employmentHours,
                                                department: department,
                                                salary: salary,
                                                vacationDays: vacationDays,
                                                bonusPrograms: bonusPrograms,
                                                startDate: startDate,
                                                contractDuration: contractDuration,
                                                employmentAgreement: employmentAgreement,
                                                responseDate: responseDate,
                                                managerName: managerName,
                                                contactDetails: contactDetails,
                                                yourName: yourName,
                                                signature: signature,
                                                benefits: benefits
                                            })}
                                        </Stack>
                                </Stack>
                            </ShPaper>
                        </Stack>
                    </Grow>
                 </Stack>    
            </>
    );
};

export default EmployerOfferTemplateSinglePage;
