import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { getCompanyInfo, resetUpdateCompany, resetLogoUpdate } from "store/slices/employer/settingsTs/company-info-slice";
import {
    CircularProgress,
    Typography,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Link,
} from "@mui/material";
import { getPaymentHistory } from "store/slices/employer/create-job/create-job-payments-slice";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { ShChip, ShTable, ShTableContainer } from "shared/SharedStyles/ShDataDisplay";

export const PaymentHistory = () => {
    const dispatch = useAppDispatch();
    
    const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const { paymentHistory, getPaymentHistoryStatus } = useAppSelector((state) => state.employer.createJob.createJobPayments);

    useEffect(() => {
        if (!companyInfo.id) {
            dispatch(getCompanyInfo());
        }

        if (companyInfo.stripe_customer_id) {
            dispatch(getPaymentHistory({ stripe_customer_id: companyInfo.stripe_customer_id }));
        }

        return () => {
            dispatch(resetUpdateCompany());
            dispatch(resetLogoUpdate());
        };
    }, [companyInfo, dispatch]);

    return (
        <ShContainer maxWidth="xl">
            <ShPaper variant="outlined">
                <Typography variant="h6" gutterBottom>
                    Payment History
                </Typography>

                {getPaymentHistoryStatus === "pending" && (
                    <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                        <CircularProgress />
                    </Box>
                )}

                {getPaymentHistoryStatus === "failed" && (
                    <Typography color="error" align="center">
                        Failed to fetch payment history.
                    </Typography>
                )}

                {getPaymentHistoryStatus === "success" && paymentHistory?.length > 0 ? (
                    <ShTableContainer>
                        <ShTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Date</strong></TableCell>
                                    <TableCell><strong>ID</strong></TableCell>
                                    <TableCell><strong>Amount</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>
                                    <TableCell><strong>Invoice</strong></TableCell>
                                    <TableCell><strong>Created At</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentHistory.map((payment) => (
                                    <TableRow key={payment.id}>
                                        <TableCell>{new Date(payment.createdAt).toLocaleDateString()}</TableCell>
                                        <TableCell>{payment.id}</TableCell>
                                        <TableCell>${payment.amount} {payment.currency}</TableCell>
                                        <TableCell>
                                            <ShChip size='small' 
                                                    label={payment.status.toLocaleUpperCase()} 
                                                    bgColor = {payment.status === "successful" ? ShGreen : "orange"} 
                                                    textColor="white"/>
                                        </TableCell>
                                        <TableCell>
                                            <Link href={payment.invoiceUrl} target="_blank" rel="noopener" color="primary">
                                                View Invoice
                                            </Link>
                                        </TableCell>
                                        <TableCell>{new Date(payment.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </ShTable>
                    </ShTableContainer>
                ) : (
                    getPaymentHistoryStatus === "success" && (
                        <Typography align="center" color="textSecondary">
                            No payment history available.
                        </Typography>
                    )
                )}
            </ShPaper>
        </ShContainer>
    );
};

export default PaymentHistory;
