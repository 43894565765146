import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Chip, IconButton, Popover, Stack, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { IsSmScreen, useAppSelector } from "helpers/hooks";
import { MouseEvent, useState } from 'react';
import { ShTable, ShTableContainer } from 'shared/SharedStyles/ShDataDisplay';
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { toTitleCase } from 'shared/utils';
import { RoleAccessLevelsTableHeads, RoleAccessMap } from '../../SettingsConstants';
import { AccessLevelTableHead, IAccessLevels, IAccount, RoleAccessLevelTableHead } from '../../SettingsModel';
import { AccountActions } from './AccountActions';

export const AccessLevelListView = ({ editUser, paginatedAccountList }: IAccessLevels) => {
    const { rolesList } = useAppSelector((state) => state.employer.employerSettings.accessLevels);
    const isSmScreen = IsSmScreen();
    const [roleAccessPopoverEl, setRoleAccessPopoverEl] = useState<{ [key: number]: HTMLElement | null }>({ 0: null });

    const openRoleAccessPopover = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => {
        e.stopPropagation();
        setRoleAccessPopoverEl({ ...roleAccessPopoverEl, [indx]: e.currentTarget });
    };

    const renderCell = (headerId: AccessLevelTableHead | RoleAccessLevelTableHead, account: IAccount) => {
        switch (headerId) {
            case 'ASSIGN':
            case 'COMMENT':
            case 'CREATE':
            case 'VIEW':
            case 'ALL_JOBS':
                return <TableCell key={headerId} align='center'>{RoleAccessMap[account.role_id]?.includes(headerId) ?
                    <CheckIcon fontSize='small' color='success' /> : <ClearIcon fontSize='small' color='error' />}</TableCell>;
            default:
                break;
        }
        return (<></>);
    };

    return (<>
        <Stack rowGap={1}>
            {paginatedAccountList?.map((acc) => (
                <ShPaper variant="outlined" key={acc.id}>
                    <Stack direction='row' columnGap={{ xs: 1, sm: 2, md: 3 }} alignItems='center'
                        flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'nowrap' }} justifyContent='space-between'>
                        <Box>
                            <Typography variant="body1" fontWeight='bold'>{acc.fullName}</Typography>
                            <Typography variant="body2">
                                {rolesList.find(rL => rL.name === acc.role?.name)?.display_name ?? acc.role?.name}
                            </Typography>
                            <Typography variant="subtitle2" color={'gray'} maxWidth={{ xs: '225px', sm: '350px', md: 'unset' }}
                                overflow='hidden' textOverflow='ellipsis'>{acc.account?.email}</Typography>
                        </Box>
                        <Stack direction='row' alignItems='center' columnGap={1}>
                            {isSmScreen ? <>
                                <Tooltip title="View Access Levels" placement='left' onClick={e => e.stopPropagation()}>
                                    <IconButton aria-describedby={roleAccessPopoverEl[acc.id] !== null ? 'role-access-popover' : ''}
                                        onClick={(e) => { openRoleAccessPopover(e, acc.id) }}>
                                        <ManageAccountsIcon />
                                    </IconButton>
                                </Tooltip>
                                {roleAccessPopoverEl[acc.id] &&
                                    <Popover
                                        id={roleAccessPopoverEl[acc.id] !== null ? 'role-access-popover' : ''}
                                        open={roleAccessPopoverEl[acc.id] !== null}
                                        anchorEl={roleAccessPopoverEl[acc.id]}
                                        onClose={() => setRoleAccessPopoverEl({ ...roleAccessPopoverEl, [acc.id]: null })}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                        <ShTableContainer>
                                            <ShTable>
                                                <TableHead>
                                                    <TableRow>
                                                        {RoleAccessLevelsTableHeads.map(th => (
                                                            <TableCell key={th.columnName} align='center'>
                                                                <Typography variant='body2' fontWeight={600}>{th.label}</Typography>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={acc.account?.email}>
                                                        {RoleAccessLevelsTableHeads.map(th => (renderCell(th.columnName, acc)))}
                                                    </TableRow>
                                                </TableBody>
                                            </ShTable>
                                        </ShTableContainer>
                                    </Popover>}
                            </> :
                                <>
                                    <Stack direction='row' flexWrap='wrap' columnGap={1}
                                        rowGap={1} justifyContent='flex-end'>
                                        {RoleAccessLevelsTableHeads.map(accessLevelHeader => (
                                            <Chip size='small' label={toTitleCase(accessLevelHeader.columnName)}
                                                key={accessLevelHeader.columnName}
                                                variant='outlined' icon={RoleAccessMap[acc.role_id]?.includes(accessLevelHeader.columnName) ?
                                                    <CheckIcon fontSize='small' color='success' /> :
                                                    <ClearIcon fontSize='small' color='error' />} />
                                        ))}
                                    </Stack>
                                </>}
                            <AccountActions account={acc} editUser={editUser} />
                        </Stack>
                    </Stack>
                </ShPaper>
            ))}
        </Stack>
    </>);
}