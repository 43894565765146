import { SvgIcon } from "@mui/material";
import React from "react";

export const IndeedMcIcon = () => {
  return (
    <>
      <SvgIcon>
        <path
          d="M6.54691 17.9675V10.6667C6.75958 10.6859 6.96425 10.6955 7.17852 10.6955C8.19709 10.6955 9.15649 10.4283 9.97039 9.95154V17.9659C9.97039 18.6507 9.80729 19.1563 9.49069 19.4939C9.17568 19.8299 8.75674 19.9979 8.24826 19.9979C7.74777 19.9979 7.35121 19.8299 7.02501 19.4827C6.71001 19.1467 6.54691 18.6411 6.54691 17.9675ZM6.5661 0.47154C8.68639 -0.27246 11.1009 -0.23246 12.9142 1.29394C13.2516 1.60114 13.6369 1.98834 13.7904 2.44434C13.9743 3.02034 13.1492 2.38354 13.0357 2.30514C12.4441 1.92754 11.854 1.61074 11.192 1.39314C7.62624 0.32274 4.25233 2.25714 2.15443 5.26194C1.27817 6.58994 0.707321 7.98834 0.23881 9.52594C0.187642 9.69394 0.147667 9.91314 0.0549242 10.0603C-0.0378184 10.2299 0.0149489 9.60594 0.0149489 9.58514C0.0853054 8.94994 0.218023 8.33554 0.382721 7.72114C1.35012 4.44754 3.4896 1.72114 6.5661 0.47154ZM10.6724 6.35314C10.6724 7.74354 9.54505 8.87154 8.15551 8.87154C6.76597 8.87154 5.64027 7.74354 5.64027 6.35314C5.64027 4.96274 6.76597 3.83474 8.15551 3.83474C9.54505 3.83474 10.6724 4.96114 10.6724 6.35314Z"
          fill="#003A9B"
        />
      </SvgIcon>
    </>
  );
};
