import React, { useState } from "react";
import { Box, Typography, Paper, IconButton, Slide, Divider, Stack } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { ShButton, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { addBotResponse, addUserMessage, sendMessageToSupport } from "store/slices/common/chatbot-slice";
import { styled } from "@mui/system";
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";

const ChatWindow = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  left: 20,
  width: 640,
  height: 800,
  display: "flex",
  flexDirection: "column",
  borderRadius: 12,
  overflow: "hidden",
  zIndex: 9999,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
}));

const ChatHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: PrimaryThemeColor, 
  color: "white",
  padding: "16px",
  borderRadius: "12px 12px 0 0",  
});

const MessageContainer = styled(Box)({
  flex: 1,
  padding: "16px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

const Message = styled(Typography)(({ sender }: { sender: "user" | "bot" }) => ({
  alignSelf: sender === "user" ? "flex-end" : "flex-start",
  backgroundColor: sender === "user" ? PrimaryThemeColor : "#e0e0e0",
  color: sender === "user" ? "white" : "black",
  borderRadius: 16,
  padding: "10px 16px",
  maxWidth: "80%",
  wordWrap: "break-word",
}));

// Floating Button for Opening Chat
const FloatingButton = styled(IconButton)({
  position: "fixed",
  bottom: 20,
  left: 5,
  backgroundColor: PrimaryThemeColor,
  color: "white",
  "&:hover": {
    backgroundColor: "#303f9f",  
  },
  width: 50,
  height: 50,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  zIndex: 9999,
});

export const ChatBot: React.FC = () => {
  const dispatch = useAppDispatch();
  const { messages, status } = useAppSelector((state) => state.common.chatbot);
  const { accountInfo } = useAppSelector((state) => state.employer.employerSettings.accInfo);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userMessage, setUserMessage] = useState<string>("");

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;
    const accountId = accountInfo.id.toString();
    dispatch(addUserMessage({ sender: "user", text: userMessage, accountId }));

    if (accountId) {
      try {
        dispatch(addBotResponse({ sender: "bot", text: "Sending...", accountId }));

        dispatch(
          sendMessageToSupport({
            accountId,
            emailBody: userMessage,
          })
        );
      } catch (error) {
        dispatch(
          addBotResponse({
            sender: "bot",
            text: "There was an unexpected error while sending the message.",
          })
        );
      }
    }
    setUserMessage("");
  };

  return (
    <Box>
      {/* Floating Chat Button */}
      {!isOpen && (
        <FloatingButton onClick={() => setIsOpen(true)}>
          <ChatIcon />
        </FloatingButton>
      )}

      {/* Chat Window */}
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <ChatWindow>
          {/* Chat Header */}
          <ChatHeader>
            <Typography variant="subtitle1">HireBot - SmoothHiring</Typography>
            <IconButton onClick={() => setIsOpen(false)} color="inherit">
              <CloseIcon />
            </IconButton>
          </ChatHeader>

          {/* Chat Messages */}
          <MessageContainer>
            {messages.map((message, index) => (
               <>
                <Message key={index} sender={message.sender}>
                  {message.text}
                </Message>
                </>
            ))}
          </MessageContainer>
          <Divider/>
          {/* Message Input */}
          <Stack direction="row" alignItems="center" p={2} spacing={2}>
            <ShTextFieldV2
              fullWidth
              multiline
              variant="outlined"
              size="small"
              placeholder="Type your message..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            />
            <ShButton
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              disabled={!userMessage.trim() || status === "loading"}
              endIcon={<SendIcon />}
            >
              {status === "loading" ? "Sending..." : "Send"}
            </ShButton>
          </Stack>
        </ChatWindow>
      </Slide>
    </Box>
  );
};

