import { Box, FormControl, Grid, Grow, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { TemplateHomesStack } from 'Modules/Marketing/Resources/Resources.styled';
import { CompanyPolicies } from 'Modules/Marketing/Resources/Templates/PolicyTemplates/PolicyTemplateConstants';
import { IsSmScreen } from "helpers/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShButton, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";

export const EmployerPolicyTemplateHome = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const isSmScreen = IsSmScreen();

    const truncateText = (text: string, maxLength: number): string => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleCategoryChange = (event: SelectChangeEvent<string>) => {
        setSelectedCategory(event.target.value as string);
    };

    const filteredPolicyTemplates = Object.entries(CompanyPolicies).map(([category, policyDesc]) => {
        const filteredPolicies = policyDesc.filter(policyDesc =>
            policyDesc.toLowerCase().includes(searchQuery)
        );
        return { category, descriptions: filteredPolicies };
    }).filter(({ category, descriptions }) =>
        descriptions.length > 0 && (selectedCategory === '' || category === selectedCategory)
    );

    const sortedPolicyTemplates = filteredPolicyTemplates.sort((a, b) => a.category.localeCompare(b.category));

    const handleButtonClick = (description: string) => {
        const formattedTemplateTitle = description.toLowerCase().replace(/ /g, '-');
        navigate(`${formattedTemplateTitle}`);
    };

    return (
        <ShContainer maxWidth="xl" height="100%" >
            <TemplateHomesStack marginBottom={4} marginTop={4} >
                <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                    <Stack justifyContent='center' padding={isSmScreen ? 0 : 2}>
                        <Typography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryThemeColor}>
                            HR Templates | Company Policies
                        </Typography>
                        {isSmScreen ?
                            <Typography textAlign="center" gutterBottom fontSize={20} fontWeight={700}>
                                HR Company Policy Templates
                            </Typography>
                            :
                            <Typography textAlign="center" gutterBottom fontSize={30} fontWeight={700}>
                                HR Company Policy Templates
                            </Typography>

                        }
                        {isSmScreen ?
                            <Typography textAlign="center" fontSize={15} >
                                Our extensive collection of company policies is designed to provide clarity, ensure compliance, and foster a positive workplace culture. With over 100 meticulously crafted templates, our policies cover a wide range of topics crucial to modern businesses. These templates are developed to help you establish clear guidelines, maintain consistency, and ensure that all employees understand their roles and responsibilities.</Typography>
                            :
                            <Typography textAlign="center" fontSize={18} >
                                Our extensive collection of company policies is designed to provide clarity, ensure compliance, and foster a positive workplace culture. With over 100 meticulously crafted templates, our policies cover a wide range of topics crucial to modern businesses. These templates are developed to help you establish clear guidelines, maintain consistency, and ensure that all employees understand their roles and responsibilities.</Typography>
                        }
                    </Stack>
                </Grow>
            </TemplateHomesStack>
            <Stack direction={isSmScreen ? 'column' : 'row'} spacing={3} marginBottom={4} >
                <Box display="flex" minWidth={330}>
                    <ShTextFieldV2
                        label="Search Company Policy Templates"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                        size="medium"
                    />
                </Box>
                <FormControl variant="outlined" sx={{ minWidth: 330 }}>
                    <InputLabel id="category-select-label">Filter by Category</InputLabel>

                    <Select labelId="category-select-label" id="category-select" value={selectedCategory} onChange={handleCategoryChange} label="Filter by Category">
                        <MenuItem value="">All Categories</MenuItem>
                        {Object.keys(CompanyPolicies).map((category) => (
                            <MenuItem key={category} value={category}>{category.split("_").join(" ")}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            {sortedPolicyTemplates.map(({ category, descriptions }) => (
                <Box key={category} marginBottom={5}>
                    <Typography padding={1} paddingBottom={2} variant="h5" fontWeight={700} gutterBottom color={PrimaryThemeColor}>
                        {category.split("_").join(" ")}
                    </Typography>
                    <Grid container spacing={1.5}>
                        {descriptions.map((description, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <ShButton color="inherit" onClick={() => handleButtonClick(description)}>
                                    <Typography textAlign='left' variant="body2"> 
                                        {truncateText(description,40)}
                                    </Typography>
                                </ShButton>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </ShContainer>
    );
};

export default EmployerPolicyTemplateHome;
