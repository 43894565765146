export type SubscriptionPlan = "entry-plan-monthly" | "starter-plan-monthly" | "growth-plan-monthly" | "scale-plan-monthly" |
                              "entry-plan-yearly" | "starter-plan-yearly" | "growth-plan-yearly" | "scale-plan-yearly";

export const PLAN_NAME_MAP: Record<SubscriptionPlan, string> = {
    "entry-plan-monthly": "Entry (Monthly)",
    "starter-plan-monthly": "Starter (Monthly)",
    "growth-plan-monthly": "Growth (Monthly)",
    "scale-plan-monthly": "Scale (Monthly)",
    "entry-plan-yearly": "Entry (Yearly)",
    "starter-plan-yearly": "Starter (Yearly)",
    "growth-plan-yearly": "Growth (Yearly)",
    "scale-plan-yearly": "Scale (Yearly)",
  };
  
export type SubscriptionPlanFeatures = {
    activeJobPostings?: number | 'Unlimited';
    careersPageJobPostings?: number | 'Unlimited';
    users?: number | 'Unlimited';
    candidates?: number | 'Unlimited';
    // candidatePools?: boolean;
    brandedCareerSite?: boolean;
    jobBoardDistribution?: boolean;
    // gdprCompliance?: boolean;
    // multiLanguageSupport?: boolean;
    resumeParsing?: boolean;
    // backgroundScreening?: boolean;
    candidateAutomations?: boolean;
    candidateQuestionnaires?: boolean;
    customizablePipelineStages?: boolean;
    eeocOfccpReporting?: boolean;
    emailCalendarIntegration?: boolean;
    // mobileApps?: boolean;
    interviewSelfScheduling?: boolean;
    // liveVideoMeetings?: boolean;
    realTimeAnalytics?: boolean;
    // slackIntegration?: boolean;
    taskManagement?: boolean;
    // videoTutorials?: boolean;
    // weeklyWebinars?: boolean;
    // automatedReferenceChecking?: boolean;
    // customizableInterviewGuides?: boolean;
    // customizableScorecards?: boolean;
    // employeeReferrals?: boolean;
    // eSignatures?: boolean;
    // externalRecruiterSupport?: boolean;
    // helloMessenger?: boolean;
    // multipleLocations?: boolean;
    // multipleRecruitingPipelines?: boolean;
    advancedQuestionnaires?: boolean;
    assessmentIntegrations?: boolean;
    // candidateComparison?: boolean;
    // candidateNurtureCampaigns?: boolean;
    // customRolesPermissions?: boolean;
    // departmentList?: boolean;
    // hrisIntegrations?: boolean;
    // jobApprovals?: boolean;
    // offerApprovals?: boolean;
    // offerManagement?: boolean;
    // tagLocking?: boolean;
  };

  