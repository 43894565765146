import { useEffect, useState } from 'react';
import { Box, Fade, Typography, CircularProgress, Stack, Autocomplete, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import SaveIcon from '@mui/icons-material/Save';
import { ICareerPage } from 'Modules/Subdomain/EmployerSubDomainLayout/EmployerSubDomainModel';
import { getCareerPageDetails, updateCareerPageDetails } from 'store/slices/employer/employer-sub-domain/employer-sub-domain-slice';
import { ShGreenBtn, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { fontFamilies, headingFontWeights, headingSizes, iconMap } from './CareersPageConstants';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ColorPicker, shades } from './CareersPageColorPicker';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { resetUpdateAccInfo } from 'store/slices/employer/settingsTs/acc-info-slice';
import { ShGreen, shBlue } from 'shared/SharedStyles/styleConstants';

export const CareersPageBranding = () => {
    const dispatch = useAppDispatch();
    const { careerPage, updateCareerPageStatus, updateCareerPageResponse} = useAppSelector(state => state.employer.employerSubDomain);
    const { companyInfo } = useAppSelector(state => state.employer.employerSettings.companyInfo);
    const headingSize = 'h6'
    const headingFontWeight = '400'; 
    const [careerPageDetails, setCareerPageDetails] = useState<ICareerPage>({
        employerId: 0,
        typography: careerPage?.typography || '',
        heading_size: careerPage?.heading_size || '',
        heading_font_weight:careerPage?.heading_font_weight || '',
        primary_color: careerPage?.primary_color,
        secondary_color: careerPage?.secondary_color,
        tertiary_color: careerPage?.tertiary_color || '',
        cta_text: '',
        cta_link: '',
        seo_title: careerPage?.seo_title || '',
        seo_description: careerPage?.seo_description || '',
        social_links: {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: '',
        },
    });
    const [primaryColor, setPrimaryColor] = useState<string>(careerPageDetails?.primary_color || ShGreen);
    const [secondaryColor, setSecondaryColor] = useState<string>(careerPageDetails?.secondary_color || shBlue);

    useEffect(() => {
        if (!careerPage && companyInfo.id !== 0) {
            dispatch(getCareerPageDetails({ employerId: companyInfo.id }));
        }
    }, [careerPage, dispatch, companyInfo.id]);
    
    useEffect(() => {
        if (careerPage) {
            setCareerPageDetails({
                ...careerPage,
                social_links: careerPage.social_links ?? {},
                primary_color: careerPage.primary_color,
                secondary_color: careerPage.secondary_color
            });
        }
    }, [careerPage]);
    
    
    
    const handleInputChange = (field: string, value: any) => {
        
        if (field === 'social_links') {
            setCareerPageDetails((prev) => ({
                ...prev,
                social_links: value, 
            }));
        } else {
            setCareerPageDetails((prev) => ({
                ...prev,
                [field]: value,
            }));
            if(careerPageDetails.primary_color && careerPageDetails.secondary_color){
                setPrimaryColor(careerPageDetails?.primary_color)
                setSecondaryColor(careerPageDetails?.secondary_color)
            }
        }
    };

    const handleSaveChanges = async () => {
        await dispatch(
            updateCareerPageDetails({
                employerId: companyInfo.id,
                data: { ...careerPageDetails },
            })
        );
        dispatch(getCareerPageDetails({ employerId: companyInfo.id }));
    };

    const InfoUpdateAlerts = () => {
        switch (updateCareerPageStatus) {
          case "pending":
            return <ShAlert severity="info">Updating Information...</ShAlert>;
          case "success":
            return (
              <ShAlert
                severity="success"
                onClose={() => dispatch(resetUpdateAccInfo())}
              >
                <Typography>{updateCareerPageResponse}</Typography>
              </ShAlert>
            );
          case "failed":
            return <ShAlert severity="error">{updateCareerPageResponse}</ShAlert>;
          default:
            return <></>;
        }
      };


    return (
        <Box>
            <Box marginBottom={2}>{InfoUpdateAlerts()}</Box>
            <Fade in={true} timeout={800}>
                <Stack spacing={2}>
                    <Grid container spacing={2}>

                          {/* Company Theme */}
                          <Grid item xs={12} sm={6}>
                            <Stack>
                                <ShPaper variant='outlined' headerSection borderRadius={0}>
                                    <Typography variant='body2'> Company Theme</Typography>
                                    <Typography variant='caption'> Customize the colors on your career page. Check out your career page to see changes</Typography>
                                </ShPaper>
                                <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                                    <Stack spacing={2}>
                                        <ShPaper variant='outlined'>
                                            <ColorPicker label="Primary" color={careerPageDetails?.primary_color || primaryColor} setColor={setPrimaryColor} 
                                                    shadeColors={shades.primary} 
                                                    onColorChange={(color) => handleInputChange('primary_color', color)}/>
                                        </ShPaper>
                                        <ShPaper variant='outlined'>
                                            <ColorPicker label="Secondary" color={careerPageDetails?.secondary_color || secondaryColor} setColor={setSecondaryColor} 
                                                    shadeColors={shades.secondary}  
                                                    onColorChange={(color) => handleInputChange('secondary_color', color)}/>
                                        </ShPaper>
                                    </Stack>
                                </ShPaper>
                            </Stack>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                            <Stack>
                                <ShPaper variant='outlined' headerSection borderRadius={0}>
                                    <Typography variant='body2'> Page Typography</Typography>
                                    <Typography variant='caption'>Choose fonts and text styles for your career page. Select font family, heading sizes, and weights.</Typography>
                                </ShPaper>
                                <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                                    <Stack spacing={2}>
                                        <Autocomplete value={careerPageDetails.typography || ''} onChange={(e, newValue) => handleInputChange('typography', newValue)}
                                            options={fontFamilies}
                                            renderInput={(params) => (
                                                <ShTextFieldV2
                                                    {...params}
                                                    fullWidth
                                                    size="small"
                                                    label="Font Family"
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <li {...props} style={{ fontFamily: option }}>
                                                    {option}
                                                </li>
                                            )}
                                        />
                                    <Autocomplete
                                        value={
                                            careerPageDetails.heading_size
                                                ? headingSizes.find((size) => size.value === careerPageDetails.heading_size) || null
                                                : headingSizes.find((size) => size.value === headingSize) || null
                                        }
                                        onChange={(e, newValue) => {
                                            handleInputChange('heading_size', newValue?.value || ''); 
                                        }}
                                        options={headingSizes}
                                        getOptionLabel={(option) => option.label} 
                                        renderInput={(params) => (
                                            <ShTextFieldV2
                                                {...params}
                                                fullWidth
                                                size="small"
                                                label="Heading Size"
                                            />
                                        )}
                                    />

                                    <Autocomplete
                                        value={
                                            careerPageDetails.heading_font_weight
                                                ? headingFontWeights.find((weight) => weight.value === careerPageDetails.heading_font_weight) || null
                                                : headingFontWeights.find((weight) => weight.value === headingFontWeight) || null
                                        }
                                        onChange={(e, newValue) => {
                                            handleInputChange('heading_font_weight', newValue?.value || '');  
                                        }}
                                        options={headingFontWeights}
                                        getOptionLabel={(option) => option.label} 
                                        renderInput={(params) => (
                                            <ShTextFieldV2
                                                {...params}
                                                fullWidth
                                                size="small"
                                                label="Heading Font Weight"
                                            />
                                        )}
                                    />
                                    </Stack>
                                </ShPaper>

                                {/* <Stack pt={2}>
                                    <ShPaper variant='outlined' headerSection borderRadius={0}>
                                        <Typography variant='body2'> Call To Actions</Typography>
                                        <Typography variant='caption'>Set up buttons that encourage visitors to take action. Add custom text and destination links.</Typography>
                                    </ShPaper>
                                    <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                                        <Stack spacing={2}>
                                            <ShTextFieldV2 fullWidth size="small" label="Call to Action Text" value={careerPageDetails.cta_text || ''}
                                                onChange={(e) => handleInputChange('cta_text', e.target.value)} />
                                            <ShTextFieldV2 fullWidth size="small" label="Call to Action Link" value={careerPageDetails.cta_link || ''}
                                                onChange={(e) => handleInputChange('cta_link', e.target.value)} />
                                        </Stack>
                                    </ShPaper>
                                </Stack> */}

                                <Stack pt={1}>
                                    <ShPaper variant='outlined' headerSection borderRadius={0}>
                                        <Typography variant='body2'> Search Engine Optimization</Typography>
                                        <Typography variant='caption'>Improve your page visibility in search results. Add titles and descriptions that will show up on browser searches.</Typography>
                                    </ShPaper>
                                    <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                                        <Stack spacing={2}>
                                            <ShTextFieldV2 fullWidth size="small" label="SEO Title" value={careerPageDetails.seo_title || ''}
                                                onChange={(e) => handleInputChange('seo_title', e.target.value)} />
                                            <ShTextFieldV2 fullWidth size="small" label="SEO Description" value={careerPageDetails.seo_description || ''}
                                                onChange={(e) => handleInputChange('seo_description', e.target.value)} />
                                        </Stack>
                                    </ShPaper>
                                </Stack>
                            </Stack>

                            <Stack pt={1}>
                                <ShPaper variant='outlined' headerSection borderRadius={0}>
                                    <Typography variant='body2'> Social Links</Typography>
                                    <Typography variant='caption'>Connect your social media accounts. Help candidates learn more about your company culture.</Typography>
                                </ShPaper>
                                <ShPaper variant='outlined' borderRadius={0} noBorderTop>
                                    <Stack spacing={2}>
                                        {['facebook', 'twitter', 'linkedin', 'instagram'].map((key) => {
                                            return (
                                            <Stack direction="row" alignItems="center" spacing={2} key={key}>
                                                {iconMap[key as 'facebook' | 'twitter' | 'linkedin' | 'instagram']}
                                                    <ShTextFieldV2 fullWidth size="small" 
                                                        label={key.charAt(0).toUpperCase() + key.slice(1)} 
                                                        value={careerPageDetails.social_links?.[key] ?? ''}
                                                        onChange={(e) =>
                                                            setCareerPageDetails((prev) => ({
                                                            ...prev,
                                                            social_links: {
                                                                ...prev.social_links,
                                                                [key]: e.target.value,
                                                            },
                                                            }))
                                                        }
                                                    />
                                            </Stack>
                                            );
                                        })}
                                    </Stack>
                                </ShPaper>
                            </Stack>
                        </Grid>

                      
                    </Grid>
                </Stack>
            </Fade>
            <Box mt={2} display="flex" alignItems="center">
                <ShGreenBtn
                disableElevation
                variant="contained"
                onClick={handleSaveChanges}
                disabled={updateCareerPageStatus === 'pending'}
                startIcon={
                    updateCareerPageStatus === 'pending' ? (
                    <CircularProgress size={20} color="inherit" />
                    ) : (
                    <SaveIcon />
                    )}>
                {updateCareerPageStatus === 'pending' ? 'Saving...' : 'Save Changes'}
                </ShGreenBtn>
            </Box>
        </Box>
    );
};

export default CareersPageBranding;
