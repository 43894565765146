import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
  DefaultRoleForRAC,
  RoleAccessControlMap,
} from 'Modules/Core/AuthV2/AuthConstants';
import { TFeatures } from 'Modules/Core/AuthV2/AuthModel';
import { useAppSelector } from 'helpers/hooks';
import { useDispatch } from 'react-redux';
import { NavLink, Link as RouterLink, useLocation } from 'react-router-dom';
import { INavLink, ISideNav } from 'shared/SharedModels';
import { LeftNavButtonStyled } from 'shared/SharedStyles/ShInputs';
import {
  MobileOnlyStack,
  ShIconWrapper,
  SideNavLinksStack,
} from 'shared/SharedStyles/ShLayouts';
import { togglePricingDialog } from 'store/slices/app/app-info-slice';
import { CoreSideNavLinks } from './AppLayoutConstants';
import { useEffect, useState } from 'react';
import SideNavIcon from './components/SideNavIcon';
import { isEmpty } from 'lodash';
import { toggleNotification } from 'store/slices/employer/create-task/task-slice';

export const SideNav = ({ isMiniDrawer, toggleDrawer }: ISideNav) => {
  const dispatch = useDispatch();
  const { accountAccess } = useAppSelector((state) => state.auth.auth);
  const [openSubNav, setOpenSubNav] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const isSubLinkActive = (subNav: INavLink[]) => {
      return subNav.some((subLink) =>
        location.pathname.includes(subLink.redirectTo!)
      );
    };

    CoreSideNavLinks.forEach((link) => {
      if (link.subNav && isSubLinkActive(link.subNav)) {
        setOpenSubNav(link.feature);
      }
    });
  }, [location.pathname]);

  const handleSubNavToggle = (feature: string) => {
    setOpenSubNav(openSubNav === feature ? null : feature);
  };

  const LabelWithDynamicTooltip = (
    feature: TFeatures,
    label: string,
    subNav: {
      isSubNavEnabled: boolean;
      isSubNavOpened: boolean;
    },
    isDisabled: boolean
  ) => {
    return (
      <Stack flex={1}>
        {isMiniDrawer ? (
          <Tooltip placement="right-end" title={label} arrow>
            <ShIconWrapper>
              <SideNavIcon feature={feature} isDisabled={isDisabled} />
            </ShIconWrapper>
          </Tooltip>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <ShIconWrapper>
                <SideNavIcon feature={feature} isDisabled={isDisabled} />
              </ShIconWrapper>
              <Typography variant="body2">{label}</Typography>
            </Stack>
            {subNav.isSubNavEnabled && (
              <KeyboardArrowDownIcon
                fontSize="large"
                style={{
                  transform: subNav.isSubNavOpened ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            )}
          </Stack>
        )}
      </Stack>
    );
  };

  const renderSubNavLinks = (subNav: INavLink[]) => {
    return (
      <Stack pl={2}>
        {subNav.map((subLink, subIndex) => (
          <NavLink
            key={subIndex}
            to={subLink.redirectTo!}
            aria-label={subLink.label}
            onClick={toggleDrawer}
          >
            {LabelWithDynamicTooltip(
              subLink.feature,
              subLink.label,
              { isSubNavOpened: false, isSubNavEnabled: false },
              RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]
                ?.featureMap?.[subLink.feature]?.visibility === 'disabled'
            )}
          </NavLink>
        ))}
      </Stack>
    );
  };

  return (
    <Stack height="100%" display="flex" justifyContent="space-between">
      <Box>
        <SideNavLinksStack>
          {CoreSideNavLinks.map(
            (link, index) =>
              RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC]
                ?.featureMap?.[link.feature]?.visibility !== 'hidden' && (
                <Stack key={index}>
                  <NavLink
                    to={link.redirectTo}
                    aria-label={link.label}
                    onClick={() => {
                      if (isEmpty(link.subNav)) {
                        return toggleDrawer && toggleDrawer();
                      }
                      link.subNav && handleSubNavToggle(link.feature);
                    }}
                    className={({ isActive }) => {
                      if (link.redirectTo === '#') return '';
                      return isActive
                        ? 'active'
                        : RoleAccessControlMap[
                            accountAccess.role ?? DefaultRoleForRAC
                          ]?.featureMap?.[link.feature]?.visibility ===
                          'disabled'
                        ? 'disabled'
                        : '';
                    }}
                  >
                    {LabelWithDynamicTooltip(
                      link.feature,
                      link.label,
                      {
                        isSubNavOpened: openSubNav === link.feature,
                        isSubNavEnabled: !isEmpty(link.subNav),
                      },
                      RoleAccessControlMap[
                        accountAccess.role ?? DefaultRoleForRAC
                      ]?.featureMap?.[link.feature]?.visibility === 'disabled'
                    )}
                  </NavLink>
                  {link.subNav &&
                    openSubNav === link.feature &&
                    !isMiniDrawer &&
                    renderSubNavLinks(link.subNav)}
                </Stack>
              )
          )}
        </SideNavLinksStack>
        <MobileOnlyStack>
          <Divider />
          <LeftNavButtonStyled
            startIcon={<MonetizationOnOutlinedIcon />}
            onClick={() => dispatch(togglePricingDialog())}
          >
            Pricing
          </LeftNavButtonStyled>
          <LeftNavButtonStyled
            startIcon={<HelpOutlineOutlinedIcon />}
            component={RouterLink}
            to="/employer/help"
          >
            Help
          </LeftNavButtonStyled>
          <LeftNavButtonStyled
            startIcon={<NotificationsOutlinedIcon />}
            onClick={() => dispatch(toggleNotification())}
          >
            Notifications
          </LeftNavButtonStyled>
        </MobileOnlyStack>
      </Box>
    </Stack>
  );
};
