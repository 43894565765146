import { CircularProgress, Stack, Typography } from "@mui/material";
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants";
import { JobsFooter } from "Modules/Core/JobsTs/JobsListTs/JobsFooter";
import { JobsListView } from "Modules/Core/JobsTs/JobsListTs/JobsListView";
import { JobsTableView } from "Modules/Core/JobsTs/JobsListTs/JobsTableView";
import { JobsToolBar } from "Modules/Core/JobsTs/JobsListTs/JobsToolBar";
import { IJobsApiParams, JobsSortCols } from "Modules/Core/JobsTs/JobsModel";
import { useNotification } from "Modules/Core/Notification";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ISortParamsBase, LayoutTypes, SortOrder } from "shared/SharedModels";
import { ShAlert, ShBackdrop } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ListWrapper } from "shared/SharedStyles/ShLayouts";
import { DefaultPageSize } from "shared/constants";
import { updateArrayById } from "shared/utils";
import { resetJobDelete, resetRePostJob, resetStopHire } from "store/slices/employer/jobs/jobs-list-actions-slice";
import { fetchJobSummary, fetchJobsList, toggleJobSummary } from "store/slices/employer/jobs/jobs-list-slice";
import { JobType } from "store/slices/employer/jobs/jobs-slice-model";
import { DefaultJobsSortParams } from "../JobsConstants";

export const JobsList = () => {
    const dispatch = useAppDispatch();
    const isSmScreen = IsSmScreen();
    const navigate = useNavigate();
    const notification = useNotification();
    const location = useLocation();
    const pathname = location?.pathname?.split('/').pop();
    const [jobsType, setJobsType] = useState<JobType>(pathname as JobType);
    const { jobsList, getJobsListStatus, paginationParams, jobsSummary, getJobsListResponse,
        activeCount, draftCount, expiredCount, templateCount } = useAppSelector(state => state.employer.employerJobs.jobsList);
    const { deleteJobResponse, deleteJobStatus, stopHireJobResponse, stopHireJobStatus,
        rePostJobStatus, rePostJobResponse
    } = useAppSelector(state => state.employer.employerJobs.jobsListActions);
    const { accountAccess } = useAppSelector(state => state.auth.auth);
    const [layoutType, setLayoutType] = useState<LayoutTypes>(LayoutTypes.Table);
    const [sortParams, setSortParams] = useState<ISortParamsBase<JobsSortCols>>(DefaultJobsSortParams);
    const [selectedJobs, setSelectedJobs] = useState<number[]>([]);
    const [searchKey, setSearchKey] = useState<string>("");

    // Set Jobs Type
    useEffect(() => {
        if (pathname) {
            setJobsType(pathname as JobType);
        }
    }, [pathname]);

    /*
        Set Layout Type to List in smaller screen.
        Layout toggler will be hidden in small screen to block user from going to Table view.
    */
    useEffect(() => {
        if (isSmScreen) {
            setLayoutType(LayoutTypes.List);
        }
    }, [isSmScreen]);

    // Get jobs to form payload and make the API call.
    const getJobs = useCallback((pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<JobsSortCols>, search?: string) => {
        // Reset delete state to avoid infinite rerenders if the function is toggled after deleting a Job.
        dispatch(resetJobDelete());
        dispatch(resetStopHire());
        const params: IJobsApiParams = {
            jobType: jobsType ?? 'active',
            pageNumber: search ? 1 : pageNo ?? 1,
            pageSize: pageSize ?? DefaultPageSize,
            sortDirection: sortParams?.order ?? 'asc',
            sortColumn: sortParams?.orderBy ?? 'name',
            search: search ?? '',
        };
        dispatch(fetchJobsList(params));
    }, [dispatch, jobsType]);

    // Get jobs list on page load.
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNoString = searchParams.get('page');
        const page = Number(pageNoString) || undefined;

        getJobs(page, undefined, DefaultJobsSortParams);
        return () => {
            dispatch(resetRePostJob());
        }
    }, [dispatch, getJobs, location.search]);

    /*
        Call get Job summary only if the Job summary isn't already loaded.
        If Job summary is already loaded, call 'toggleJobSummary' reducer in Jobs slice to toggle expand/collapse.
    */
    const getJobSummary = (jobId: number) => {
        if (jobsType !== 'draft') {
            if (jobId in jobsSummary && jobsSummary[jobId].getSummaryStatus !== 'failed') {
                dispatch(toggleJobSummary(jobsList.findIndex(al => al.id === jobId)));
            } else {
                dispatch(fetchJobSummary({ jobId: jobId }));
            }
        }
    };

    const sortList = (sortBy: JobsSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        /*
           Call get jobs function with page number and size from pagination params.
       */
        getJobs(paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize,
            { order: order, orderBy: sortBy },
            searchKey);
    };

    const searchInJobs = (searchKey: string) => {
        getJobs(paginationParams.requestedPageNumber, paginationParams.requestedPageSize, sortParams, searchKey);
        setSearchKey(searchKey);
    };

    const Alerts = () => {
        switch (getJobsListStatus) {
            case 'pending':
                return <ShBackdrop open={true}><CircularProgress color="inherit" /></ShBackdrop>;
            case 'failed':
                return <ShAlert severity="error">{getJobsListResponse}</ShAlert>;
            default:
                <></>;
        }
    };

    // Refresh List after job is deleted.
    useEffect(() => {
        if (deleteJobStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: deleteJobResponse ?? ''
            });
            // Set page number to previous page if the last record is deleted from the page and page is not 1st one.
            let pageNo = paginationParams.requestedPageNumber;
            if (pageNo > 1 && paginationParams.count === 1) {
                pageNo--;
            }
            getJobs(pageNo, paginationParams.requestedPageSize, sortParams, searchKey);
        }
    }, [deleteJobResponse, deleteJobStatus, getJobs, notification, paginationParams.count,
        paginationParams.requestedPageNumber, paginationParams.requestedPageSize, searchKey, sortParams]);

    // Refresh List after job is stop hire.
    useEffect(() => {
        if (stopHireJobStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: stopHireJobResponse ?? ''
            });
            // Set page number to previous page if the last record is deleted from the page and page is not 1st one.
            let pageNo = paginationParams.requestedPageNumber;
            if (pageNo > 1 && paginationParams.count === 1) {
                pageNo--;
            }
            getJobs(pageNo, paginationParams.requestedPageSize, sortParams, searchKey);
        }
    }, [stopHireJobResponse, stopHireJobStatus, getJobs, notification, paginationParams.count,
        paginationParams.requestedPageNumber, paginationParams.requestedPageSize, searchKey, sortParams]);

    const renderDraftJobsButton = () => {
        const featureMap = RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap;

        return (<>
            {
                featureMap && featureMap['Draft Jobs'] ? <>
                    {
                        featureMap['Draft Jobs'].visibility === 'hidden' ? <></> :
                            featureMap['Draft Jobs'].visibility === 'disabled' ?
                                <ShButton variant="text" disabled>Draft Jobs</ShButton> :
                                <ShButton variant="text" onClick={() => navigate('/employer/jobs/draft')}>
                                    Draft Jobs</ShButton>
                    }
                </> : <>
                    <ShButton variant="text" onClick={() => navigate('/employer/jobs/draft')}>
                        Draft Jobs</ShButton>
                </>
            }
        </>)
    }

    const renderPostJobButton = () => {
        const featureMap = RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap;

        return (<>
            {
                featureMap && featureMap['Draft Jobs'] ? <>
                    {
                        featureMap['Draft Jobs'].visibility === 'hidden' ? <></> :
                            featureMap['Draft Jobs'].visibility === 'disabled' ?
                                <ShButton variant="text" disabled color='success'>Post a New Job</ShButton> :
                                <ShButton variant="text" onClick={() => navigate('/employer/job/new')}
                                    color='success'>Post a New Job</ShButton>
                    }
                </> : <>
                    <ShButton variant="text" onClick={() => navigate('/employer/job/new')}
                        color='success'>Post a New Job</ShButton>
                </>
            }
        </>)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const jobIdInString = searchParams.get('jobId');
        const jobId = jobIdInString ? Number(jobIdInString) : null;
        const isExpand = searchParams.get('isExpand');

        if (
            jobId && isExpand === 'true' &&
            jobsList.some((job) => job.id === jobId) &&
            isEmpty(jobsSummary)
        ) {
            dispatch(fetchJobSummary({ jobId }));
        }
    }, [dispatch, jobsList, location.search, jobsSummary]);

    return (<>
        <ListWrapper>
            <>
                {/* JobsToolBar component holds the template with Search, Sort and Layout toggle elements. */}
                <JobsToolBar layoutType={layoutType} searchInJobs={searchInJobs} searchKey={searchKey}
                    setLayoutType={setLayoutType} sortList={sortList} sortParams={sortParams} jobsType={jobsType}
                    setJobsType={setJobsType} activeCount={activeCount} draftCount={draftCount}
                    expiredCount={expiredCount} templateCount={templateCount} />
                {/* Show loading while list is being fetched. */}
                {Alerts()}
                {rePostJobStatus === 'failed' &&
                    <ShAlert severity="error" onClose={() => dispatch(resetRePostJob())}>
                        {rePostJobResponse}
                    </ShAlert>}
                {/* Switch between layout types. */}

                {getJobsListStatus !== 'pending' && <>
                    {jobsList?.length > 0 ? (
                        layoutType === LayoutTypes.Table ?
                            <JobsTableView getJobSummary={getJobSummary} jobsList={jobsList}
                                jobsSummary={jobsSummary} jobsType={jobsType}
                                selectJob={(jobId) => setSelectedJobs(updateArrayById<number, number>(jobId, selectedJobs))}
                                selectedJobs={selectedJobs} sortList={sortList} sortParams={sortParams} /> 
                                :
                            <JobsListView jobsType={jobsType} getJobSummary={getJobSummary}
                                jobsList={jobsList} jobsSummary={jobsSummary}
                                selectJob={(jobId) => setSelectedJobs(updateArrayById<number, number>(jobId, selectedJobs))}
                                selectedJobs={selectedJobs} />
                    ) : (
                        getJobsListStatus === 'success' && <Stack rowGap={1}>
                            {jobsType === 'active'
                                ? (
                                    <Typography variant="subtitle1" padding={3} margin='auto'>
                                        You don't have any  Active jobs.
                                        Post a previously saved job from
                                        {renderDraftJobsButton()} or {renderPostJobButton()}
                                    </Typography>
                                ) : <ShAlert severity="info">No jobs found</ShAlert>
                            }
                        </Stack>
                    )}
                    {jobsList?.length > 0 && paginationParams && paginationParams.totalPages > 0 && (
                        <JobsFooter paginationParams={paginationParams} isSmScreen={isSmScreen}
                            selectedJobs={selectedJobs} jobsType={jobsType}
                            getJobs={(pageNo, pageSize) => getJobs(pageNo, pageSize, sortParams, searchKey)} hasMoreButton />
                    )}
                </>}
            </>
        </ListWrapper>
    </>);
};