export interface IAutoRejectCriteria {
    id: number;
    dateCreated: string;
    criteriaName: string; 
    criteriaDesc: string; 
    resumeScoreThreshold: number;
    selectedFits: string[]; 
    isPreferred: boolean; 
}

export const fitOptions = [
    { label: 'Strong Fit', value: 'strong', matchTypeKey: 'STRONG' },
    { label: 'Good Fit', value: 'match,good', matchTypeKey: 'GOOD' },
    { label: 'Fair Fit', value: 'fair', matchTypeKey: 'FAIR' },
    { label: 'Weak Fit', value: 'none,weak', matchTypeKey: 'WEAK' },
    { label: 'Distortion', value: 'distortion', matchTypeKey: 'DISTORTION' },
];