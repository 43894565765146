import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    ICreateInterviewRequestPayload,
    ICreateScheduledInterviewPayload,
    IInterviewDetails,
    IInterviewsActionsState,
    IScheduleInterviewRequest,
    IUpdateAcceptedTimeSlots,
    IUpdateInterviewCancelStatusParams,
    IUpdateInterviewCompleteStatusParams,
    IUpdateInterviewRequest,
    IUpdateInterviewScheduleParams,
} from 'Modules/Core/Interviews/InterviewsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IAllJobDetails } from '../jobs/jobs-slice-model';

const initialInterviewsActionsState: IInterviewsActionsState = {
    createInterviewScheduleResponse: '',
    createInterviewScheduleStatus: 'idle',
    getJobDetailsResponse: '',
    getJobDetailsForInterviewStatus: 'idle',
    updateInterviewScheduleResponse: '',
    updateInterviewScheduleStatus: 'idle',
    updateInterviewStateResponse: '',
    updateInterviewStateStatus: 'idle',
    updateAcceptedTimeSlotsStatus: 'idle',
    updateInterviewRequestStatus: 'idle',
    updateInterviewRequestResponse: '',
    updateAcceptedTimeSlotsResponse: '',
    scheduleInterviewStatus: 'idle',
    scheduleInterviewResponse: '',
    createScheduledInterviewStatus: 'idle',
    createScheduledInterviewResponse: ''
};

export const createInterviewRequest = createAsyncThunk<(IBaseResponse & { interview: IInterviewDetails }), ICreateInterviewRequestPayload, { rejectValue: IBaseResponse }>(
    "createInterviewRequest",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INTERVIEWS}`, payload)
            .then((response: AxiosResponse<(IBaseResponse & { interview: IInterviewDetails })>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewCancelStatus = createAsyncThunk<IBaseResponse, IUpdateInterviewCancelStatusParams, { rejectValue: IBaseResponse }>(
    "updateInterviewStatus",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS}/${id}/status`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewCompleteStatus = createAsyncThunk<IBaseResponse, IUpdateInterviewCompleteStatusParams, { rejectValue: IBaseResponse }>(
    "updateInterviewStatus",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS}/${id}/status`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewSchedule = createAsyncThunk<IBaseResponse, IUpdateInterviewScheduleParams, { rejectValue: IBaseResponse }>(
    "updateInterviewSchedule",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS}/${id}`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateInterviewRequest = createAsyncThunk<IBaseResponse, IUpdateInterviewRequest, { rejectValue: IBaseResponse }>(
    "updateInterviewRequest",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.INTERVIEWS_UPDATE_INTERVIEW_REQUEST}/${id}`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const scheduleInterview = createAsyncThunk<IBaseResponse, IScheduleInterviewRequest, { rejectValue: IBaseResponse }>(
    "scheduleInterview",
    async ({ id, body }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INTERVIEWS_SCHEDULE_INTERVIEW}/${id}`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const createScheduledInterview = createAsyncThunk<IBaseResponse, ICreateScheduledInterviewPayload, { rejectValue: IBaseResponse }>(
    "createScheduledInterview",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INTERVIEWS_CREATE_SCHEDULED_INTERVIEW}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobDetailsForInterview = createAsyncThunk<IBaseResponse<IAllJobDetails>, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getJobDetailsForInterview",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}`)
            .then((response: AxiosResponse<IBaseResponse<IAllJobDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateAcceptTimeSlots = createAsyncThunk<IBaseResponse, IUpdateAcceptedTimeSlots, { rejectValue: IBaseResponse }>(
    "updateAcceptTimeSlots",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INTERVIEWS_ACCEPT_TIME_SLOTS}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const interviewsActionsSlice = createSlice({
    name: 'interviewsActions',
    initialState: initialInterviewsActionsState,
    reducers: {
        resetCreateInterview: (state) => { state.createInterviewScheduleResponse = ''; state.createInterviewScheduleStatus = 'idle' },
        resetUpdateInterviewStatus: (state) => { state.updateInterviewStateResponse = ''; state.updateInterviewStateStatus = 'idle' },
        resetUpdateInterviewSchedule: (state) => { state.updateInterviewScheduleResponse = ''; state.updateInterviewScheduleStatus = 'idle' },
        resetUpdateInterviewRequest: (state) => { state.updateInterviewRequestResponse = ''; state.updateInterviewRequestStatus = 'idle' },
        resetCreateScheduledInterview: (state) => { state.createScheduledInterviewResponse = ''; state.createScheduledInterviewStatus = 'idle' },
        resetScheduleInterview: (state) => { state.scheduleInterviewResponse = ''; state.scheduleInterviewStatus = 'idle' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialInterviewsActionsState;
        });
        // create interview
        builder.addCase(createInterviewRequest.pending, (state) => {
            state.createInterviewScheduleStatus = 'pending'
        });
        builder.addCase(createInterviewRequest.fulfilled, (state, action) => {
            state.createInterviewScheduleStatus = 'success';
            state.createInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.currentInterviewDetails = action.payload.interview;
        });
        builder.addCase(createInterviewRequest.rejected, (state, action) => {
            state.createInterviewScheduleStatus = 'failed';
            state.createInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update interview status
        builder.addCase(updateInterviewCancelStatus.pending, (state) => {
            state.updateInterviewStateStatus = 'pending'
        });
        builder.addCase(updateInterviewCancelStatus.fulfilled, (state, action) => {
            state.updateInterviewStateStatus = 'success';
            state.updateInterviewStateResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateInterviewCancelStatus.rejected, (state, action) => {
            state.updateInterviewStateStatus = 'failed';
            state.updateInterviewStateResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update interview schedule
        builder.addCase(updateInterviewSchedule.pending, (state) => {
            state.updateInterviewScheduleStatus = 'pending'
        });
        builder.addCase(updateInterviewSchedule.fulfilled, (state, action) => {
            state.updateInterviewScheduleStatus = 'success';
            state.updateInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateInterviewSchedule.rejected, (state, action) => {
            state.updateInterviewScheduleStatus = 'failed';
            state.updateInterviewScheduleResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update interview schedule
        builder.addCase(updateInterviewRequest.pending, (state) => {
            state.updateInterviewRequestStatus = 'pending'
        });
        builder.addCase(updateInterviewRequest.fulfilled, (state, action) => {
            state.updateInterviewRequestStatus = 'success';
            state.updateInterviewRequestResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateInterviewRequest.rejected, (state, action) => {
            state.updateInterviewRequestStatus = 'failed';
            state.updateInterviewRequestResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // Job details
        builder.addCase(getJobDetailsForInterview.pending, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'pending';
        });
        builder.addCase(getJobDetailsForInterview.fulfilled, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'success';
            state.jobDetails = action.payload.data;
        });
        builder.addCase(getJobDetailsForInterview.rejected, (state, action) => {
            state.getJobDetailsForInterviewStatus = 'failed';
            state.getJobDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update accepted time slots
        builder.addCase(updateAcceptTimeSlots.pending, (state) => {
            state.updateAcceptedTimeSlotsStatus = 'pending'
        });
        builder.addCase(updateAcceptTimeSlots.fulfilled, (state, action) => {
            state.updateAcceptedTimeSlotsStatus = 'success';
            state.updateAcceptedTimeSlotsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateAcceptTimeSlots.rejected, (state, action) => {
            state.updateAcceptedTimeSlotsStatus = 'failed';
            state.updateAcceptedTimeSlotsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // scheduleInterview 
        builder.addCase(scheduleInterview.pending, (state) => {
            state.scheduleInterviewStatus = 'pending'
        });
        builder.addCase(scheduleInterview.fulfilled, (state, action) => {
            state.scheduleInterviewStatus = 'success';
            state.scheduleInterviewResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(scheduleInterview.rejected, (state, action) => {
            state.scheduleInterviewStatus = 'failed';
            state.scheduleInterviewResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // createScheduledInterview
        builder.addCase(createScheduledInterview.pending, (state) => {
            state.createScheduledInterviewStatus = 'pending'
        });
        builder.addCase(createScheduledInterview.fulfilled, (state, action) => {
            state.createScheduledInterviewStatus = 'success';
            state.createScheduledInterviewResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(createScheduledInterview.rejected, (state, action) => {
            state.createScheduledInterviewStatus = 'failed';
            state.createScheduledInterviewResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetCreateInterview, resetUpdateInterviewStatus, resetUpdateInterviewSchedule, resetUpdateInterviewRequest, resetScheduleInterview, resetCreateScheduledInterview } = interviewsActionsSlice.actions;
export default interviewsActionsSlice;
