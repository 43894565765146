import React, { useState, useEffect } from 'react';
import './JellyLoader.css';

interface JellyLoaderProps {
  size?: string;
  speed?: string;
  color?: string; // Default color (only used if no cycleColors)
  cycleColors?: string[]; // Array of colors to cycle through
  cycleSpeed?: number; // Interval speed for cycling colors
}

const JellyLoader: React.FC<JellyLoaderProps> = ({
  size = '60px',
  speed = '2.6s',
  color = '#74C05A',
  cycleColors,
  cycleSpeed = 1000, // Default interval is 1 second
}) => {
  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    if (cycleColors && cycleColors.length > 0) {
      let index = 0;
      const interval = setInterval(() => {
        setCurrentColor(cycleColors[index]);
        index = (index + 1) % cycleColors.length; // Loop through the colors
      }, cycleSpeed);

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [cycleColors, cycleSpeed]);

  return (
    <div
      className="jelly-container"
      style={{
        '--uib-size': size,
        '--uib-color': currentColor,
        '--uib-speed': speed,
      } as React.CSSProperties}
    >
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <svg width="0" height="0" className="svg">
        <defs>
          <filter id="uib-jelly-ooze">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="3"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="ooze"
            />
            <feBlend in="SourceGraphic" in2="ooze" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default JellyLoader;
