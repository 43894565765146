import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Rating, Stack, Tooltip, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { ApplicantsFiltersMap, MatchTypes } from 'Modules/Core/Applicants/ApplicantsConstants';
import { FilterPopover } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsList.styled';
import { FilterType, IApplicantsFilter, IFilterComponent } from 'Modules/Core/Applicants/ApplicantsModel';
import { DistortionIcon, FitIcon, StrongFitIcon, WeakFitIcon } from 'assets/Icons';
import { FairFitIcon } from 'assets/Icons/FairFitIcon';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ShButton, ShFormControlLabel } from 'shared/SharedStyles/ShInputs';
import { PrimaryThemeColorLight } from 'shared/SharedStyles/styleConstants';
import { toTitleCase } from 'shared/utils';
import { getJobFilterKeywords } from 'store/slices/employer/applicants/applicants-list-actions-slice';
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';

export const ApplicantsFilters = ({ applyFilters, selectedFilters }: IFilterComponent) => {
    const dispatch = useAppDispatch();
    const { jobId } = useParams();
    const { getJobFilterKeywordsStatus, jobFilterKeywords } = useAppSelector(state => state.employer.applicants.applicantsListActions);
    const { jobDetailsInInfo } = useAppSelector((state) => state.employer.employerJobs.jobInfo);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [filters, setFilters] = useState<IApplicantsFilter>(selectedFilters);
    const [jobFilters, setJobFilters] = useState<IApplicantsFilter>(ApplicantsFiltersMap);
    const [filterExpanded, setFilterExpanded] = useState<string | false>('panel_stages');

    // Get filter keywords based on job id.
    useEffect(() => {
        if (jobId) {
            dispatch(getJobFilterKeywords({ jobId: jobId }));
            dispatch(getJobDetails({ jobId: parseInt(jobId) }));
        }
    }, [dispatch, jobId]);

    // If the keywords are fetched, update the existing keywords with new one.
    useEffect(() => {
        if (getJobFilterKeywordsStatus === 'success' && jobFilterKeywords) {
            const newJobFilters = { ...ApplicantsFiltersMap };
            newJobFilters.keywords = [];
            jobFilterKeywords.forEach(keyWord => {
                newJobFilters.keywords.push({ label: toTitleCase(keyWord), value: keyWord });
            });
            setJobFilters(newJobFilters);
        }
    }, [getJobFilterKeywordsStatus, jobFilterKeywords]);

    const addFilter = (filterType: FilterType, val: string) => {
        const tmpStages = [...filters[filterType]];
        const indx = tmpStages.map(ts => ts.value).indexOf(val);
        if (indx === -1) {
            const obj = jobFilters[filterType].find(s => s.value === val);
            if (obj) {
                tmpStages.push(obj);
            }
        } else {
            tmpStages.splice(indx, 1);
        }
        setFilters({ ...filters, [filterType]: tmpStages });
    };

    // Update filters when removed from chips in ApplicantsList component.
    useEffect(() => { setFilters(selectedFilters) }, [selectedFilters]);

    return (<>
        <Tooltip title="Filters">
            <IconButton aria-label="filters" onClick={e => setAnchorEl(e.currentTarget)}>
                <Typography variant='body1' color={'gray'} ml={1} mr={1}> Filters</Typography>
                {anchorEl === null ? <FilterListIcon /> : <FilterListOffIcon />}
            </IconButton>
        </Tooltip>

        <FilterPopover id='filter-popover' open={open} anchorEl={anchorEl} elevation={0}
            onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <Box className='filters-wrapper'>
                {/* Stages */}
                <Accordion elevation={0} expanded={filterExpanded === `panel_stages`}
                    onChange={(e, isExpanded) => setFilterExpanded(isExpanded ? `panel_stages` : false)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel_stages-a-content`}>
                        <Typography variant='subtitle2' fontWeight={600}>Stages</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack>
                            {/* Map filters constant array with filter type key */}
                            {jobFilters.stages.map(stg => (
                                <ShFormControlLabel onHoverBackground={PrimaryThemeColorLight} key={stg.label} id={stg.label} aria-label={stg.label} label={stg.label}
                                    labelPlacement="start" value={stg.value}
                                    control={<Checkbox checked={filters.stages?.map(s => s.value)?.includes(stg.value)}
                                        onChange={() => addFilter('stages', stg.value)} />}
                                />
                            ))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                {/* Fit */}
                {!jobDetailsInInfo?.ats_purchased &&
                    <Accordion elevation={0} expanded={filterExpanded === `panel_fit`}
                        onChange={(e, isExpanded) => setFilterExpanded(isExpanded ? `panel_fit` : false)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel_fit-a-content`}>
                            <Typography variant='subtitle2' fontWeight={600}>Job Fit</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack>
                                {/* Map filters constant array with filter type key with conditionally rendering icons */}
                                {jobFilters.fit.map(fit => (
                                    <ShFormControlLabel onHoverBackground={PrimaryThemeColorLight} key={fit.label} id={fit.label} value={fit.value} aria-label={fit.label}
                                        labelPlacement="start" label={<Typography className='icon-label'>
                                            {
                                                fit.value === MatchTypes.strong ||
                                                    fit.value === MatchTypes.strongFit
                                                    ? <StrongFitIcon /> :
                                                    fit.value === MatchTypes.distortion
                                                        ? <DistortionIcon /> :
                                                        fit.value.split(',').includes(MatchTypes.good) ||
                                                            fit.value.split(',').includes(MatchTypes.match)
                                                            ? <FitIcon /> :
                                                            fit.value === MatchTypes.match ||
                                                                fit.value === MatchTypes.fair
                                                                ? <FairFitIcon /> :
                                                                <WeakFitIcon />
                                            }
                                            {fit.label}</Typography>} control={
                                                <Checkbox checked={filters.fit?.map(s => s.value)?.includes(fit.value)}
                                                    onChange={() => addFilter('fit', fit.value)} />} />
                                ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>}
                {/* Keywords */}
                <Accordion elevation={0} expanded={filterExpanded === `panel_keywords`}
                    onChange={(e, isExpanded) => setFilterExpanded(isExpanded ? `panel_keywords` : false)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel_keywords-a-content`}>
                        <Typography variant='subtitle2' fontWeight={600}>Keywords</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack>
                            {jobFilters.keywords.map(kw => (
                                <ShFormControlLabel onHoverBackground={PrimaryThemeColorLight} key={kw.label} id={kw.label} value={kw.value} label={kw.label} labelPlacement="start"
                                    control={<Checkbox checked={filters.keywords?.map(s => s.value)?.includes(kw.value)}
                                        onChange={() => addFilter('keywords', kw.value)} />} />
                            ))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
                {/* Rating */}
                <Accordion elevation={0} expanded={filterExpanded === `panel_rating`}
                    onChange={(e, isExpanded) => setFilterExpanded(isExpanded ? `panel_rating` : false)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel_rating-a-content`}>
                        <Typography variant='subtitle2' fontWeight={600}>Rating</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack>
                            {jobFilters.rating.map(rt => (
                                <ShFormControlLabel onHoverBackground={PrimaryThemeColorLight} key={rt.label} id={rt.label} value={rt.value} aria-label={rt.label} labelPlacement="start"
                                    label={<Rating size='small' name={rt.label} value={parseInt(rt.value) ?? 0} readOnly />}
                                    control={<Checkbox checked={filters.rating?.map(s => s.value)?.includes(rt.value)}
                                        onChange={() => addFilter('rating', rt.value)} />}
                                />
                            ))}
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Stack className='actions'>
                <ShButton size='small' variant='outlined'
                    onClick={() => setFilters({ stages: [], fit: [], keywords: [], rating: [] })}>Clear All</ShButton>
                {/* Apply button has to be clicked to apply the filters. This invokes the API call in ApplicantsList component.*/}
                <ShButton size='small' variant="contained" disableElevation onClick={() => { applyFilters(filters); setAnchorEl(null) }}>Apply</ShButton>
            </Stack>
        </FilterPopover>
    </>);
};