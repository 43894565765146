import AddIcon from '@mui/icons-material/Add';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box, CircularProgress, Fade, FormControl, FormControlLabel, FormLabel, IconButton,
    InputAdornment, MenuItem, Stack, Tooltip, Typography,
    useTheme
} from "@mui/material";
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { SalaryRegEx } from "shared/constants";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShCheckbox, ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { IAiJobDescriptionAndInterviewKitPayload } from 'store/slices/app/app-model';
import { getAiSalaryPrediction } from "store/slices/app/resources-slice";
import { createOrUpdateJobRequirements, resetCreateOrUpdateJobRequirements } from 'store/slices/employer/create-job/create-job-details-slice';
import { setIsPreviewEnabled, updateAllJobCompensation, updateJobCompensation } from 'store/slices/employer/create-job/create-job-shared-slices';
import { IJobCompensation } from "store/slices/employer/jobs/jobs-slice-model";
import { useNotification } from '../Notification';
import { JobDetailsActionsStack, ShInputLabel, ShSelect } from "./CreateJob/CreateJob.styled";
import { DefaultBenefits } from './CreateJob/CreateJobConstants';
import { IAdditionalCompensation } from './CreateJob/CreateJobModel';


export const Compensation = () => {

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { jobId } = useParams();
    const isRouteEnabled = useOutletContext();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { aiSalaryPrediction, getAiSalaryPredictionStatus } = useAppSelector(state => state.app.resources);
    const { allJobDetails, jobTitleInfo, jobDetails, requirementsMetaData,
    } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { createOrUpdateJobReqStatus, getAdditionalCompensationsStatus, createOrUpdateJobReqResponse } = useAppSelector((state) => state.employer.createJob.createJobDetails);
    const { getJobDetailsStatus } = useAppSelector((state) => state.employer.employerJobs.jobInfo);
    const [newBenefitToBeAdded, setNewBenefitToBeAdded] = useState<string>('');
    const [errorBenefitAlertOpen, setErrorBenefitAlertOpen] = useState(false);
    const [errorAdditionalCompTooltipOpen, setErrorAdditionalCompTooltipOpen] = useState(false);
    const [newCompensation, setNewCompensation] = useState('');
    const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);
    const [selectedCompensations, setSelectedCompensations] = useState<string[]>([]);
    const [_additionalCompensations, _setAdditionalCompensations] = useState<string[]>([]);
    const [_benefits, _setBenefits] = useState<string[]>([]);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [role, setRole] = useState<string>('');
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
    const [isBackClicked, setIsBackClicked] = useState<boolean>(false);

    const [_jobCompensation, _setJobCompensation] = useState<IJobCompensation>({
        additional_compensations: '',
        salary_details: '',
        benefits: '',
        salary_type_id: 0
    });

    // Update job details fetched from API.
    useEffect(() => {
        if (getJobDetailsStatus === 'success' && jobDetails) {
            _setJobCompensation({
                additional_compensations: jobDetails.additional_compensations,
                salary_details: jobDetails.salary_details,
                benefits: jobDetails.benefits,
                salary_type_id: jobDetails.salary_type_id,
                requirements: jobDetails.requirements
            } as any);
            if (jobDetails.additional_compensations) {
                _setAdditionalCompensations(jobDetails.additional_compensations.split(','));
            }
            if (jobDetails.benefits) {
                _setBenefits([...jobDetails.benefits.split(','), ...DefaultBenefits]);
            } else {
                _setBenefits([...DefaultBenefits]);
            }
        }
    }, [getJobDetailsStatus, jobDetails]);

    useEffect(() => {
        if (allJobDetails?.additional_compensations && allJobDetails.additional_compensations.length > 0) {
            const parsedCompensations = allJobDetails.additional_compensations;
            const parsedBenefits = allJobDetails.benefits?.split(',');
            _setAdditionalCompensations((prevCompensations) =>
                Array.from(new Set([...prevCompensations, ...parsedCompensations]))
            );
            setSelectedCompensations(parsedCompensations);
            if (parsedBenefits) {
                setSelectedBenefits(parsedBenefits);
            }
        }
    }, [allJobDetails?.additional_compensations, allJobDetails?.benefits]);

    // Fetch additional compensations from the API
    const fetchCompensations = async () => {
        try {
            const response = await httpAdapterInstance.get(`${EmployerApiEndpoints.ADDITIONAL_COMPENSATION}`);
            const fetchedCompensations = response.data?.data
                .map((item: IAdditionalCompensation) => item.name) || [];
            _setAdditionalCompensations(fetchedCompensations);
            // _setJobCompensation((prevDetails) => ({
            //     ...prevDetails,
            //     additional_compensations: fetchedCompensations.filter((item: string) => item.trim().length > 0).join(','),
            // }));
        } catch (error) {
            console.error('Error fetching compensations:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchCompensations();
    }, []);

    const handleAiSalaryGeneratorSubmit = async () => {
        if (jobTitleInfo?.name) {
            setIsSubmitted(true);
            let payload: IAiJobDescriptionAndInterviewKitPayload = { role };
            payload.postalcode = allJobDetails?.postalcode
            payload.industry = allJobDetails?.employer_company_name
            payload.role = jobTitleInfo.name
            payload.city = allJobDetails?.city;
            payload.experience = allJobDetails?.experience;
            dispatch(getAiSalaryPrediction(payload));
        }
    };

    const validateSalary = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const reg = new RegExp(SalaryRegEx);
        const _isSalaryInValid = !reg.test(e?.target.value ?? '');
        // Clear salary_type_id if salary_details are empty.
        _setJobCompensation((prev) => {
            return {
                ...prev,
                salary_details: e?.target.value ?? '',
                salary_type_id: _isSalaryInValid && !e?.target.value ? undefined : prev.salary_type_id
            }
        });
    };

    // Handle adding new compensation
    const addNewCompensation = () => {
        if (newCompensation.trim() === '') return;

        if (_additionalCompensations.includes(newCompensation)) {
            setErrorAdditionalCompTooltipOpen(true);
            return;
        }

        // Add the new compensation
        _setAdditionalCompensations((prevCompensations) => {
            const newCompensations = [...prevCompensations, newCompensation.trim()];

            setSelectedCompensations((prevSelected) => [
                ...prevSelected,
                newCompensation.trim(),
            ]);
            _setJobCompensation((prevDetails) => ({
                ...prevDetails,
                additional_compensations: [...selectedCompensations, newCompensation.trim()].join(','),
            }));

            return newCompensations;
        });

        setNewCompensation('');
        setErrorAdditionalCompTooltipOpen(false);
    };

    const addNewBenefit = () => {
        const isBenefitEmpty = newBenefitToBeAdded.trim().length === 0;
        const isBenefitDuplicate = _benefits.includes(newBenefitToBeAdded);

        setErrorBenefitAlertOpen(isBenefitDuplicate ? true : false);

        if (isBenefitEmpty || isBenefitDuplicate) return;

        _setBenefits(prevChips => {
            const updatedChips = [...prevChips, newBenefitToBeAdded];
            setSelectedBenefits((prevSelected) => [
                ...prevSelected,
                newBenefitToBeAdded.trim(),
            ]);
            _setJobCompensation((prevDetails) => ({
                ...prevDetails,
                benefits: [...selectedBenefits, newBenefitToBeAdded.trim()].join(','),
            }));
            return updatedChips;
        });
        setNewBenefitToBeAdded('');
    };

    useEffect(() => {
        if (isSubmitted && aiSalaryPrediction) {
            const { salary_prediction } = aiSalaryPrediction;
            const { lowEnd, highEnd, currency } = salary_prediction;
            if (
                typeof lowEnd === 'number' &&
                !isNaN(lowEnd) &&
                typeof highEnd === 'number' &&
                !isNaN(highEnd) &&
                typeof currency === 'string' &&
                currency.trim() !== ''
            ) {
                const formattedSalary = `${lowEnd.toLocaleString()}${currency} - ${highEnd.toLocaleString()}${currency}`;
                _setJobCompensation(prevJobDetails => ({
                    ...prevJobDetails,
                    salary_details: formattedSalary
                }));
            }
            if (allJobDetails?.name) {
                setRole(allJobDetails.name);
            }
        }
    }, [aiSalaryPrediction, allJobDetails?.name, isSubmitted]);

    // Disable next button.
    useEffect(() => {
        setIsNextDisabled(
            _jobCompensation?.salary_details?.length > 0 && !_jobCompensation?.salary_type_id
        );
    }, [_jobCompensation?.salary_details, _jobCompensation?.salary_type_id]);
    

    const cOrUJobCompensation = () => {
        let payload = { ..._jobCompensation };
        if (payload.salary_type_id === undefined) {
            payload.salary_type_id = 0;
        }
        if (selectedCompensations) {
            payload.additional_compensations = selectedCompensations
                .join(',')
                .split(',')
                .filter(item => item.trim().length > 0) // Remove empty values
                .join(',');
        }
        if (selectedBenefits) {
            payload.benefits = selectedBenefits
                .join(',')
                .split(',')
                .filter(item => item.trim().length > 0) // Remove empty values
                .join(',');
        }
        dispatch(createOrUpdateJobRequirements({ jobId: parseInt(jobId ?? ''), payload: payload as any }));
    };

    const onJobCompensationBack = () => {
        setIsBackClicked(true);
        cOrUJobCompensation();
    }

    // Navigate to next page once Job is created or updated successfully.
    useEffect(() => {
        if (isRouteEnabled && createOrUpdateJobReqStatus === 'success') {
            displayNotification({
                open: true,
                type: 'success',
                message: createOrUpdateJobReqResponse
            });
            dispatch(setIsPreviewEnabled(true));
            // Update 'allJobDetails' and 'jobRequirements' in shared state.
            const jC = { ..._jobCompensation };

            dispatch(updateAllJobCompensation(jC));
            dispatch(updateJobCompensation(jC));
            dispatch(resetCreateOrUpdateJobRequirements());
            if (isBackClicked) {
                navigate(`/employer/job/${jobId}/details`);
            } else {
                navigate(`/employer/job/${jobId}/preview`);
            }
        }
    }, [_jobCompensation, createOrUpdateJobReqResponse, createOrUpdateJobReqStatus, dispatch,
        displayNotification, isBackClicked, isRouteEnabled, jobId, navigate]);


    return (<>
        <Fade in={true} timeout={800}>
            <Stack alignContent='center'>
                <ShPaper variant='outlined' borderRadius={0} headerSection >
                    <Typography variant='subtitle1' gutterBottom>Enter Compensation - Optional</Typography>
                    <Typography variant='caption' color='textSecondary'>Include the salary, additional compensation, and benefits.
                        This will help applicants know the total compensation the position offers.</Typography>
                </ShPaper>
                <ShPaper variant='outlined' noBorderTop>
                    <Box height='100%' maxHeight='100%' overflow='auto' display='flex' flexDirection='column'
                        rowGap={2} paddingX={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='compensation_field'>
                                <Typography component={'span'} variant="body2" gutterBottom>Compensation</Typography>
                            </FormLabel>
                            <Stack flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                                justifyContent='space-between' columnGap={1} rowGap={1} width='100%' alignItems='flex-start'>
                                <Stack alignItems='flex-start'>
                                    <ShTextFieldV2
                                        id='compensation_field'
                                        variant="outlined"
                                        size='small'
                                        helperText="ex: 25, 45k, 30k - 40k"
                                        value={_jobCompensation.salary_details ?? ''}
                                        onChange={(e) => validateSalary(e)}
                                        InputProps={{
                                            endAdornment: (
                                                allJobDetails?.remote_job_type !== 'Fully Remote' && (
                                                    <InputAdornment position='end'>
                                                        <Tooltip title="Generate a salary range estimate with AI" arrow>
                                                            <IconButton
                                                                onClick={() => handleAiSalaryGeneratorSubmit()}
                                                                disabled={getAiSalaryPredictionStatus === 'pending'}
                                                            >
                                                                <AutoAwesomeIcon color='primary' fontSize='small' />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            )
                                        }}
                                    />
                                    {getAiSalaryPredictionStatus === 'pending' && (
                                        <CircularProgress size='1.5rem' />
                                    )}
                                    <Typography variant='caption' color='InfoText' display='flex' alignItems='center' columnGap={1} gutterBottom>
                                        <InfoOutlinedIcon fontSize='small' color='primary' />
                                        Enter the amount of compensation for the position. Employers who add compensation get more candidates!
                                    </Typography>
                                    {(!_jobCompensation?.salary_details && (_jobCompensation?.salary_type_id !== undefined && _jobCompensation?.salary_type_id !== null)) &&
                                        <Typography variant='caption' color={theme.palette.warning.main}>
                                            Please enter compensation</Typography>}
                                </Stack>
                                <Stack alignItems="flex-end">
                                    <FormControl
                                        size="small"
                                        color="primary"
                                        disabled={!_jobCompensation.salary_details}
                                        sx={{ minWidth: 200 }}
                                    >
                                        <ShInputLabel id="compensation-type-label">Compensation Type</ShInputLabel>
                                        <ShSelect
                                            labelId="compensation-type-label"
                                            value={_jobCompensation.salary_type_id ?? ''}
                                            onChange={(e) =>
                                                _setJobCompensation({ ..._jobCompensation, salary_type_id: Number(e.target.value) })
                                            }
                                            displayEmpty
                                        >
                                            {requirementsMetaData?.salaryTypes?.map((slt) => (
                                                <MenuItem key={slt.id} value={slt.id}>
                                                    {slt.monster_name}
                                                </MenuItem>
                                            ))}
                                        </ShSelect>
                                    </FormControl>
                                    {_jobCompensation.salary_details?.length > 0 && !_jobCompensation.salary_type_id && (
                                        <Typography variant="caption" color={theme.palette.warning.main}>
                                            Select compensation type
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        </FormControl>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="additional_compensation_field">
                                <Typography component={'span'} variant="body2" gutterBottom>
                                    <Stack columnGap={1} flexDirection="row" alignItems="center">
                                        Additional Compensation
                                        <ShTooltip
                                            title="Additional compensation includes any earnings beyond base salary, such as bonuses, commissions, equity, profit-sharing and other incentives."
                                            placement="right"
                                        >
                                            <HelpOutlineIcon fontSize="small" color="disabled" />
                                        </ShTooltip>
                                    </Stack>
                                </Typography>
                            </FormLabel>

                            <Stack rowGap={1} marginTop={1} spacing={1}>
                                {errorAdditionalCompTooltipOpen && (
                                    <ShAlert severity="warning">
                                        This compensation has already been added.
                                    </ShAlert>
                                )}
                                {getAdditionalCompensationsStatus === 'pending' && <CircularProgress size="1.5rem" />}

                                <Stack direction="row" columnGap={1} rowGap={1} flexWrap="wrap">
                                    {Array.from(new Set([..._additionalCompensations, ...selectedCompensations].filter(item => item))).map((compensation, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <ShCheckbox
                                                    checked={selectedCompensations.includes(compensation)}
                                                    onChange={(event) => {
                                                        if (event.target.checked) {
                                                            setSelectedCompensations([...selectedCompensations, compensation]);
                                                        } else {
                                                            setSelectedCompensations(
                                                                selectedCompensations.filter(item => item !== compensation)
                                                            );
                                                        }
                                                    }}
                                                />
                                            }
                                            label={compensation}
                                        />
                                    ))}
                                    <ShTextFieldV2 size="small" variant="outlined" id="compensation" label="Add a Compensation"
                                        value={newCompensation}
                                        onChange={(e) => setNewCompensation(e.target.value)}
                                        maxWidth="220px"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                addNewCompensation();
                                            }
                                        }}
                                        disabled={getAdditionalCompensationsStatus === 'pending'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ShTooltip title="Add Additional Compensation" placement="left-end">
                                                        <IconButton edge="end" onClick={addNewCompensation}>
                                                            <AddIcon fontSize="small" />
                                                        </IconButton>
                                                    </ShTooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </FormControl>
                        <FormControl fullWidth margin='dense'>
                            <FormLabel htmlFor="benefits_field">
                                <Typography component={'span'} variant="body2" gutterBottom>
                                    <Stack columnGap={1} flexDirection='row' alignItems='center'>Benefits
                                        <ShTooltip
                                            title="Often overlooked are the benefits that you can offer your employees to sell the job and company. Free coffee and snacks,
                                            discounted gym membership and many other benefits can greatly impact the quality and quantity of applicants."
                                            placement="right"><HelpOutlineIcon fontSize="small" color='disabled' /></ShTooltip>
                                    </Stack></Typography>
                            </FormLabel>
                            {errorBenefitAlertOpen && (
                                <ShAlert marginBottom={1.5} severity='warning'>
                                    Benefit already added.
                                </ShAlert>
                            )}

                            <Stack direction="row" columnGap={1} rowGap={1} flexWrap="wrap">
                                {Array.from(new Set([..._benefits, ...selectedBenefits])).map((benefit, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <ShCheckbox
                                                checked={selectedBenefits.includes(benefit)}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        setSelectedBenefits([...selectedBenefits, benefit]);
                                                    } else {
                                                        setSelectedBenefits(
                                                            selectedBenefits.filter(item => item !== benefit)
                                                        );
                                                    }
                                                }}
                                            />
                                        }
                                        label={benefit}
                                    />
                                ))}
                                <ShTextFieldV2 size='small' variant='outlined' id='benefit' label='Add a Benefit'
                                    value={newBenefitToBeAdded} onChange={(e) => setNewBenefitToBeAdded(e.target.value)}
                                    maxWidth='170px'
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            addNewBenefit();
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <ShTooltip title='Add Benefit' placement='left-end'>
                                                <IconButton
                                                    edge='end' onClick={addNewBenefit}>
                                                    <AddIcon fontSize='small' />
                                                </IconButton>
                                            </ShTooltip>
                                        </InputAdornment>
                                    }} />
                            </Stack>
                            {/* <textarea className='multiline-text' id='benefits_field'
                                placeholder="Enter the job benefits of the position you are going to fill, such as: free snacks and coffee."
                                value={_jobCompensation.benefits ?? ''} onChange={(e) => _setJobCompensation({ ..._jobCompensation, benefits: e.target.value })} /> */}
                        </FormControl>
                        <JobDetailsActionsStack paddingY={1}>
                            <ShButton variant='contained' size='small' disableElevation onClick={onJobCompensationBack}>Back</ShButton>
                            <Stack direction='row' columnGap={1}>
                                <ShGreenBtn variant='contained' disableElevation onClick={cOrUJobCompensation}
                                    disabled={isNextDisabled || createOrUpdateJobReqStatus === 'pending'}>
                                    {createOrUpdateJobReqStatus === 'pending' ? 'Saving...' : 'Next'}
                                </ShGreenBtn>
                            </Stack>
                        </JobDetailsActionsStack>
                    </Box>
                </ShPaper>
            </Stack>
        </Fade>
    </>);
}