import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import SvgIcon from "@mui/material/SvgIcon";
import { StrongFitIcon, WeakFitIcon, DistortionIcon, FitIcon } from "assets/Icons";
import { FairFitIcon } from "assets/Icons/FairFitIcon";
import { IIncompleteApplicant, IApplicant, TInterviewStage } from "./ApplicantsModel";

export const renderFingerprintImage = (imageUrl: string, parentElementId: string): void => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(imageUrl ?? '', 'image/svg+xml');
    const svgEle = doc.querySelector('svg');
    const parentEle = document.getElementById(parentElementId);
    if (svgEle && !parentEle?.hasChildNodes()) {
        parentEle?.appendChild(svgEle);
    }
};

interface ChipInfo {
    chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default';
    chipLabel: string;
    chipVariant: 'filled' | 'outlined';
    iconComponent: JSX.Element;
}

export const getApplicantChipInfo = (matchType: string): ChipInfo => {
    /*
    * To handle cases where there are two values, normalize it match on the 0th or 1st element
    * { label: 'Good Fit', value: 'match,good', matchTypeKey: 'GOOD' },
    * { label: 'Weak Fit', value: 'none,weak', matchTypeKey: 'WEAK' },
    */
    let matchTypeNormalized = matchType?.split(',')[0];
    let chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default' = 'default';
    let chipLabel = matchType;
    let chipVariant: 'filled' | 'outlined' = 'filled';
    let iconComponent = <></>; 

    switch (matchTypeNormalized) {
        case 'strong':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'strong fit':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'good':
            chipColor = 'default';
            chipLabel = 'Good Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'match':
            chipColor = 'default';
            chipLabel = 'Good Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'fair':
            chipColor = 'default';
            chipLabel = 'Fair Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FairFitIcon} fontSize='small' />;
            break;
        case 'none':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'weak':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'distortion':
            chipColor = 'default';
            chipLabel = 'Distortion';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={DistortionIcon} fontSize='small' />;
            break;
        default:
            chipColor = 'default';
            chipLabel = matchType ? `Unknown (${matchType})` : 'Unknown'; // Show value if provided
            chipVariant = 'outlined';
            break;
    }

    return { chipColor, chipLabel, chipVariant, iconComponent };
};

interface ResumeScoreGaugeProps {
    score: number; // Expecting a score between 0 and 100
}

export const ResumeScoreGauge: React.FC<ResumeScoreGaugeProps> = ({ score }) => {
    const value = Math.min(Math.max(score, 0), 100);

    let arcColor;
    if (value >= 80) {
        arcColor = '#4CAF50'; // Green
    } else if (value >= 60) {
        arcColor = '#8BC34A'; // Light Green
    } else if (value >= 40) {
        arcColor = '#FFC107'; // Amber/Yellow
    } else if (value >= 20) {
        arcColor = '#FF9800'; // Orange
    } else {
        arcColor = '#F44336'; // Red
    }

    return (
        <Box width='100px'>
            <Typography variant="body2"> {value}% </Typography>
            <LinearProgress sx={{ 
                 bgcolor: '#e0e0e0',
                 '& .MuiLinearProgress-bar': {
                        backgroundColor: arcColor,
                    }}} 
            variant="determinate" value={value} />
        </Box>
    );
};

export const convertToApplicant = (incompleteApplicant: IIncompleteApplicant): IApplicant => {
    return {
        applicationCompletedAt: incompleteApplicant.updatedAt,
        atsPurchased: incompleteApplicant.is_background_checks,
        candidateEmployerJobId: incompleteApplicant.id,
        candidateId: incompleteApplicant.candidate_id,
        id: incompleteApplicant.id,
        commentTotal: 0,
        distortionCount: 0,
        email: incompleteApplicant.candidate.account.email,
        employerJobId: incompleteApplicant.employerjob.id,
        firstName: incompleteApplicant.candidate.first_name,
        fullName: incompleteApplicant.candidate.first_name + incompleteApplicant.candidate.last_name,
        jobStage: incompleteApplicant.stage as TInterviewStage,
        stageModifiedAt: new Date(),
        lastName: incompleteApplicant.candidate.last_name,
        matchType: 'Incomplete',
        resumeScore: incompleteApplicant.resume_match_score,
        rating: parseInt(incompleteApplicant.rating),
    };
};